<template>
  <div class="middleground-main">
    <div class="middleground">
      <div class="header">
        <div class="header-col-1">
          <div class="header-col-4">日期：{{ nowdate }}</div>
        </div>
        <div class="header-col-1">
          <div class="header-col-2"><img src="/images/mb_03.png" /></div>
          <div class="header-col-3">
            <div class="header-col-3-title">{{ title }}</div>
            <div class="header-col-3-img">
              <img src="/images/mb_08.png" />
            </div>
          </div>
          <div class="header-col-2"><img src="/images/mb_05.png" /></div>
        </div>
        <div class="header-col-1">
          <div class="header-col-5">
            <Icon :type="maxBtn ? 'md-contract' : 'md-expand'" size="30" @click="fullscreenHandle"
              style="cursor:pointer;" />
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-items">
          <div class="content-item">
            <img src="/images/mb_12.png" class="content-item-icon" />
            <div class="content-item-title">档案分类</div>
            <div id="eCharts1" class="eCharts-small"></div> 
          </div>
          <div class="content-item">
            <img src="/images/mb_12.png" class="content-item-icon" />
            <div class="content-item-title">年度统计</div>
            <div id="eCharts2" class="eCharts-small"></div>
          </div>
        </div>
        <div class="content-items content-items-center">
          <div class="content-item content-item-center">
            <div class="center-total">
              <i-circle class="i-circle" :size="180" :trail-width="8" :stroke-width="10" :percent="75"
                stroke-linecap="round" stroke-color="#2db7f5">
                <div>
                  <h2 class="i-circle-name"> {{ totalData.archiveTotal }}</h2>
                  <p class="i-circle-name">档案总数</p>
                </div>
              </i-circle>
              <i-circle class="i-circle" :size="180" :trail-width="8" :stroke-width="10" :percent="60"
                stroke-linecap="round" stroke-color="#ed4014">
                <h2 class="i-circle-name"> {{ totalData.availableTotal }}</h2>
                <p class="i-circle-name">未到期</p>
              </i-circle>
              <i-circle class="i-circle" :size="180" :trail-width="8" :stroke-width="10" :percent="80"
                stroke-linecap="round" stroke-color="#ff9900">
                <h2 class="i-circle-name"> {{ totalData.unavailableTotal }}</h2>
                <p class="i-circle-name">过期</p>
              </i-circle>
            </div>
          </div>
        </div>
        <div class="content-items">
          <div class="content-item content-item-right">
            <img src="/images/mb_12.png" class="content-item-icon" />
            <div class="content-item-title">档案科室</div>
            <div id="eCharts3" class="eCharts-small"></div> 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//路由定义：@ROUTER=/archivesStatistics
import * as echarts from 'echarts';
export default {
  components: {
  },

  data() {
    return {
      title: "档案统计",
      zoom: 14,
      map: null,
      dataList: [],
      coverActived: 0,
      maxBtn: false,
      nowdate: "",
      querySendRecord: [],
      coverRepairRecord: [],
      coverWardRecord: [],
      coverWard_i: 0,
      totalData: {},
      objectCoverData: { i: 0, currObjectName: "", dataList: [] },
      dataCoverList: [],
      lineColor: {},
      initCenter: null,
    };
  },
  created() {
    $(document).attr("title", this.title);
    $("body").css("background", "#00133c");
    this.getTitle();
    //定时检测是否静止，回到全地图模式
    this.timer4 = setInterval(() => {
      this.nowdate = this.$core.formatDate(new Date(), "yyyy-MM-dd HH:mm:ss");
      var time = (new Date()).getTime();
      if (time > (this.timer5 || 0) + 60000) {
        if (this.map && this.initCenter && this.initCenter.lat && this.initCenter.lng && this.initCenter.zoom) {
          
        }
      }
    }, 1000);
    this.loadBaseData();
  },
  mounted() {
    this.loadTimeOutData();
    //地图锚点轮巡，弃用
    // this.coverWardRecordMapShow();
  },
  destroyed() {
    $("body").css("background", "#ffffff");
    clearInterval(this.timer4);
    clearTimeout(this.timer1);
    clearTimeout(this.timer2);
    clearTimeout(this.timer3);
  },
  methods: {
    getTitle() {
      var deptid = this.$core.getBranchUserInfo("deptid") || "0";
      var parentId = "0";
      if (deptid.length > 3) {
        parentId = deptid.substring(0, deptid.length - 3);
      }
      this.$get({
        url: "/gateway/api/organization/app/list", data: { orgType: "dept", parentId: parentId }, success: (res) => {
          if (res.code == "200" && res.dataList) {
            var dept = res.dataList.find(item => item.orgCode == deptid);
            if (dept) {
              this.title = dept.orgName + "智慧档案数据中台";
              $(document).attr("title", this.title);
            }
          }
        }
      })
    },
    fullscreenHandle() {
      this.toggleFullScreen();
      this.maxBtn = !this.maxBtn;
    },
    loadBaseData() {
    },
    loadTimeOutData() {
      this.loadData();
      this.timer1 = setTimeout(this.loadTimeOutData, 60 * 1000);
    },
    loadData() {
      this.$get({
        url: "/smartarchives/api/archives/statistics", data: {}, success: (res) => {
          if (res.code == "200" && res.data) {
            this.timer2 = setTimeout(() => {
              if (res.data.type) {
                var xAxis = [];
                var data = [];
                res.data.type && res.data.type.map(ss => {
                  xAxis.push(ss.archiveTypeName)
                  data.push(ss.typeCount)
                })
                this.createChart('eCharts1', "line", { xAxis: xAxis, data: data }, 0)
              }
              if (res.data.year) {
                var xAxis = [];
                var data = [];
                res.data.year && res.data.year.map(ss => {
                  xAxis.push(ss.archiveYear)
                  data.push(ss.yearCount)
                })
                this.createChart('eCharts2', "line", { xAxis: xAxis, data: data }, 0)
              }
              this.totalData.archiveTotal = res.data.archiveTotal
              this.totalData.availableTotal = res.data.availableTotal
              this.totalData.unavailableTotal = res.data.unavailableTotal
              if (res.data.org) {
                var xAxis = [];
                var data = [];
                res.data.org && res.data.org.map(ss => {
                  xAxis.push(ss.orgName)
                  data.push(ss.orgCount)
                })
                this.createChart('eCharts3', "bar", { xAxis: xAxis, data: data }, 0)
              }


            }, 1000);
          }
        }
      })
      // this.$get({
      //   url: "/smartarchives/api/archives/statistics", data: {  }, success: (res) => {
      //     if (res.code == "200" && res.data) {
      //       this.querySendRecord = res.data
      //     }
      //   }
      // })
      // this.$get({
      //   url: "/smartcovers/cover/queryCoverRepairRecord", data: { pageSize: 40, functionid: "0203" }, success: (res) => {
      //     if (res.code == "200" && res.dataList) {
      //       this.coverRepairRecord = res.dataList
      //     }
      //   }
      // })
      // this.$get({
      //   url: "/smartcovers/cover/queryCoverWardRecord", data: { pageSize: 40, functionid: "0202" }, success: (res) => {
      //     if (res.code == "200" && res.dataList) {
      //       this.coverWardRecord = res.dataList
      //     }
      //   }
      // })
    
      // this.$get({
      //   url: "/smartcovers/object/queryObjectCover",
      //   data: { pageSize: 1000 ,listFlag:0},
      //   success: (res) => {
      //     if (res.code == "200" && res.dataList) {
      //       this.objectCoverData.dataList = res.dataList
      //     }
      //   }
      // })
      // this.$get({
      //   url: "/smartcovers/cover/queryCover", data: { pageSize: 1000000 }, success: (res) => {
      //     if (res.code == "200") {
      //       this.dataCoverList = res.dataList
      //       if (this.dataCoverList && this.dataCoverList.length > 0 && !this.initCenter) {
      //         var maxLngLat = { lng: 0, lat: 0 };
      //         var minLngLat = { lng: 180, lat: 90 };
      //         this.dataCoverList.map(item => {
      //           minLngLat = { lng: Math.min(item.longitude || 0, maxLngLat.lng), lat: Math.min(item.latitude || 0, maxLngLat.lat) };
      //           maxLngLat = { lng: Math.max(item.longitude || 0, maxLngLat.lng), lat: Math.max(item.latitude || 0, maxLngLat.lat) };
      //         })
      //         this.initCenter = { lng: (maxLngLat.lng + minLngLat.lng) / 2, lat: (maxLngLat.lat + minLngLat.lat) / 2, zoom: this.getZoom(maxLngLat.lng, minLngLat.lng, maxLngLat.lat, minLngLat.lat) };
      //         this.showObjectLine(res.dataList[0]);
      //       }
      //     }
      //   }
      // })
    },
    getZoom(maxJ, minJ, maxW, minW) {
      if (maxJ == minJ && maxW == minW) return 10;
      var diff = maxJ - minJ;
      if (diff < (maxW - minW) * 2.1) diff = (maxW - minW) * 2.1;
      diff = parseInt(10000 * diff) / 10000;

      var zoomArr = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13);
      var diffArr = new Array(180, 90, 45, 22, 11, 5.5, 2.75, 1.37, 0.68, 0.34, 0.17, 0.08, 0.04);

      for (var i = 0; i < diffArr.length; i++) {
        if ((diff - diffArr[i]) >= 0) {
          return zoomArr[i];
        }
      }
      return 14;
    },
    convertObject(target, inFeild, outFeild, value) {
      var object = this[target].find(item => value == item[inFeild])
      return object ? object[outFeild] : "无"
    },
    
    showObjectLine(cover, count) {
      if (this.objectCoverData.dataList.length > 0) {
        if (!cover) {
          cover = {};
        }
        var i = this.objectCoverData.dataList.findIndex(item => item.objectNum == cover.objectNum);
        if (i < 0) i = this.objectCoverData.i + (count || 1);
        if (i < 0) i = this.objectCoverData.dataList.length - 1;
        if (i > this.objectCoverData.dataList.length - 1) i = 0;
        var list = this.objectCoverData.dataList[i];
        var xAxis = [];
        var data = [];
        var data0 = [];
        var data1 = [];
        var data2 = [];
        var data3 = [];
        list && list.coverStatistics && list.coverStatistics.map((ss, i) => {
          xAxis.push(i + 1)// xAxis.push(ss.coverName)
          var color = ((Number(ss.waterLevel1) || 0) >= (ss.depth || 200) - 30) || ((Number(ss.waterLevel2) || 0) >= (ss.depth || 200) - 30) || ((Number(ss.waterLevel3) || 0) >= (ss.depth || 200) - 30) ? "#ff0000" : "rgb(255, 70, 131)"
          data0.push({ value: Number(ss.depth) || "", itemStyle: { borderColor: color, color: color } })
          data1.push({ value: Number(ss.waterLevel1) || "", itemStyle: { borderColor: color, color: color } })
          data2.push({ value: Number(ss.waterLevel2) || "", itemStyle: { borderColor: color, color: color } })
          data3.push({ value: Number(ss.waterLevel3) || "", itemStyle: { borderColor: color, color: color } })
        })
        data = [{
          name: "井深", data: data0
        }, {
          name: list.time1 ? list.time1 + "时" : "水位1", data: data1
        }, {
          name: list.time2 ? list.time2 + "时" : "水位2", data: data2
        }, {
          name: list.time3 ? list.time3 + "时" : "水位3", data: data3
        },]
        this.objectCoverData.currObjectName = list.objectName;
        this.createChart2('eCharts4', "line", { xAxis: xAxis, data: data }, 0)
        this.objectCoverData.i = i;
      }
    },
    coverWardRecordMapShow() {
      var filterWardData = [];
      if (this.coverWardRecord.length > 0) {
        this.coverWardRecord.map(item => {
          if (filterWardData.findIndex(item2 => item2.coverId == item.coverId) == -1) {
            filterWardData.push(item);
          }
        })
      }
      if (filterWardData.length > 0 && this.map) {
        var i = this.coverWard_i;
        if (i > filterWardData.length - 1) i = 0;
        var cover = filterWardData[i];
        this.coverActived = cover.coverId;
        this.center = { lat: cover.latitude || 0, lng: cover.longitude || 0 };
        this.map.centerAndZoom(new T.LngLat(this.center.lng || 0, this.center.lat || 0));
        this.coverWard_i = i + 1;
        this.timer3 = setTimeout(this.coverWardRecordMapShow, 10000);
      } else
        this.timer3 = setTimeout(this.coverWardRecordMapShow, 1000);
    },
    createChart(id, type, data, showType, param) {
      let ec = echarts.init(document.getElementById(id))
      let option = {
        grid: {
          top: 20,
          bottom: 40,
          left: 40,
          right: 20, ...param && param.grid
        },
        xAxis: {
          type: showType == 1 ? 'value' : "category",
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 0,
            lineStyle: { color: "#888888" }, ...param && param.xAxisLabel
          },
          axisLine: {
            lineStyle: { color: "#888888" }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#333333',
              width: showType == 1 ? 1 : 0,
            },
          },
          data: data.xAxis,
        },
        yAxis: {
          type: showType == 1 ? 'category' : "value",
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 0, ...param && param.yAxisLabel
          },
          axisLine: {
            lineStyle: { color: "#888888" }
          },
          splitLine: {
            show: true,
            lineStyle: {

              color: '#333333',
              width: showType == 1 ? 0 : 1,
            },
          },
          data: data.xAxis,

        },
        series: [
          {
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: '#2db7f5'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#2db7f5'
                },
                {
                  offset: 1,
                  color: '#000a32'
                }
              ])
            },
            data: data.data,
            smooth: true,
            type: type
          }
        ],
      };
      ec.setOption(option);
    },
    createChart2(id, type, data, showType, param) {
      let ec = echarts.init(document.getElementById(id))
      var datas = [];
      var colors = ["#5cadff", "#ed4014", "#19be6b", "#ff9900", "#2db7f5"]
      data && data.data && data.data.map((item, idx) => {
        datas.push({
          name: item.name,
          symbol: 'none',
          sampling: 'lttb',
          itemStyle: {
            color: colors[idx % 5]
          },
          data: item.data,
          smooth: true,
          type: type
        })
      })
      let option = {
        grid: {
          top: 20,
          bottom: 45,
          left: 40,
          right: 20, ...param && param.grid
        },
        legend: {
          x: "left",
          y: "bottom",
          data: data.data.map(item => { return item.name }),
          textStyle: {
            color: "rgba(205, 205, 205, 1)"
          }
        },
        xAxis: {
          type: showType == 1 ? 'value' : "category",
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 0,
            lineStyle: { color: "#888888" }, ...param && param.xAxisLabel
          },
          axisLine: {
            lineStyle: { color: "#888888" }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#333333',
              width: showType == 1 ? 1 : 0,
            },
          },
          data: data.xAxis,
        },
        yAxis: {
          type: showType == 1 ? 'category' : "value",
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 0, ...param && param.yAxisLabel
          },
          axisLine: {
            lineStyle: { color: "#888888" }
          },
          splitLine: {
            show: true,
            lineStyle: {

              color: '#333333',
              width: showType == 1 ? 0 : 1,
            },
          },
          data: data.xAxis,

        },
        series: datas,
      };
      ec.setOption(option);
    },

    // 在全屏与非全屏之间来回切换
    toggleFullScreen(d) {
      this.isFullScreen() ? this.exitFullScreen() : this.fullScreen();
    },

    /**实现F11全屏效果*/
    fullScreen() {
      if (this.isFullScreen()) return;
      var docElm = document.documentElement;
      /*W3C*/
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      }/*FireFox */ else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      }/*Chrome等 */ else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      }/*IE11*/ else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    },
    /**退出F11全屏*/
    exitFullScreen() {
      if (!this.isFullScreen()) return;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    /**判断是否全屏*/
    isFullScreen() {
      return document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement ? true : false;
    },
    /**判断全屏模式是否是可用*/
    isFullscreenEnabled() {
      return document.fullscreenEnabled || document.mozFullScreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled || false;
    },
    /**判断整个页面被一个标签铺满*/
    isFullscreenForNoScroll() {
      var explorer = window.navigator.userAgent.toLowerCase();
      if (explorer.indexOf("chrome") > -1) {/*webkit*/
        return (document.body.scrollHeight === window.screen.height && document.body.scrollWidth === window.screen.width);
      } else {/*IE 9+  fireFox*/
        return (window.outerHeight === window.screen.height && window.outerWidth === window.screen.width);
      }
    },
    /**实现局部div、dom元素全屏*/
    fullScreenForDOM(sel) {
      typeof sel == "string" && (sel = document.querySelector(sel));
        /**el是具体的dom元素*/var rfs = sel.requestFullScreen || sel.webkitRequestFullScreen || sel.mozRequestFullScreen || sel.msRequestFullScreen, wscript;
      if (typeof rfs != "undefined" && rfs) {
        rfs.call(sel);
        return;
      }
      if (typeof window.ActiveXObject != "undefined") {
        wscript = new ActiveXObject("WScript.Shell");
        if (wscript) {
          wscript.SendKeys("{F11}");
        }
      }
    },
    exitFullScreenForDOM(sel) {
      typeof sel == "string" && (sel = document.querySelector(sel));
        /**el是具体的dom元素*/var el = document, cfs = sel.cancelFullScreen || sel.webkitCancelFullScreen || sel.mozCancelFullScreen || sel.exitFullScreen, wscript;
      if (typeof cfs != "undefined" && cfs) {
        cfs.call(el);
        return;
      }
      if (typeof window.ActiveXObject != "undefined") {
        wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
    }

  }
};
</script>
    
<style scoped lang='less'>
.middleground-main {
  width: 100%;
  height: 100%;
  min-height: 900px;
  font-size: 16px;
  background-color: #00133c;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  .middleground {
    width: 100%;
    height: 100%;
    min-width: 1600px;
    min-height: 900px;
    display: flex;
    flex-direction: column;

    .header {
      width: 100%;
      height: 60px;
      background-color: #000716;
      box-shadow: inset 0px -7px 7px -7px #00e4ff;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-col-1 {
        padding: 0 20px;
        display: flex;
        font-size: 18px;
      }

      .header-col-2 {}

      .header-col-3 {
        font-size: 30px;
        padding: 0 20px;
        letter-spacing: 0.1em;
        display: flex;
        text-align: center;
        flex-direction: column;

        .header-col-3-title {
          height: 51px;
        }

        .header-col-3-img {
          position: relative;
          margin-bottom: -41px;
        }
      }

      .header-col-4 {
        width: 300px;
      }

      .header-col-5 {
        width: 300px;
        text-align: right;
      }
    }

    .content {
      width: 100%;

      .content-items {
        width: 450px;
        margin: 30px 10px 0 10px;
        float: left;
        display: flex;
        flex-direction: column;

        .content-item {
          margin: 18px 0;
          width: 100%;
          height: 458px;
          box-shadow: inset 0px 0px 7px 0px #00e4ff;

          .content-item-icon {
            position: relative;
            margin-top: -32px;
            z-index: 0;
          }

          .content-charts-title {

            position: relative;
            margin: -20px 0 0 15px;
            z-index: 1;
            font-size: 12px;
            color: #ffe400;
            padding: 5px 0;
          }

          .content-item-title {

            position: relative;
            margin: -20px 0 0 15px;
            z-index: 1;
          }

          .content-item-carousel {
            width: calc(100% -40px);
            margin: 10px 20px;
            height: 410px;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .ivulistitem-1 {
              background-color: #000a32;
              padding: 10px 10px;
            }

            .ivulistitem-2 {
              background-color: #021a54;
              padding: 10px 10px;
            }

            .ivulistitem-wram {
              font-size: 12px;
              color: #ff451a;
              padding-right: 10px;
            }

            .ivulistitem-green {
              font-size: 12px;
              color: #5ff4d4;
              padding-right: 10px;
            }

            /deep/.ivu-list-split .ivu-list-item {
              border-bottom: 0px;
            }

            /deep/.ivu-list-item-meta-description {

              color: rgb(210, 210, 210);
              font-size: 14px;
              line-height: 22px;
            }

            /deep/.ivu-list-item-meta-title {
              font-weight: 500;
              margin-bottom: 4px;
              color: rgb(255, 255, 255);
              font-size: 16px;
              line-height: 22px;
            }

            /deep/.ivu-list-item-action>li {
              position: relative;
              display: inline-block;
              padding: 0 8px;
              color: rgb(255, 255, 255);
              font-size: 16px;
              line-height: 22px;
              text-align: center;
              cursor: pointer;
            }
          }

          .content-item-carousel-small {
            height: 280px;
          }

          .content-item-carousel-big {
            height: 730px;
            margin-bottom: 50px;
          }
        }

        .content-item-right {
          width: 100%;
          height: 952px;
        }

        .content-item-center {
          width: 100%;
          height: 952px;

          .center-total {
            width: 100%;
            display: flex;
            justify-content: center;

            .i-circle {
              margin: 20px;

              .i-circle-name {
                line-height: 1.8em;
              }
            }
          }
        }
      }

      .content-items-center {
        width: calc(100% - 960px);
      }
    }

    .eCharts-small {
      width: 100%;
      height: 150px;
      position: relative;
      top: -20px;
    }

    .eCharts-big {
      width: 100%;
      height: 440px;
      position: relative;
    }
  }

  .map-view {
    height: 500px;
    width: calc(100% - 20px);
    margin: 10px;
    z-index: 1;
    border-radius: 4px;

    /deep/.tdt-label {
      background: transparent;
      border: 0px;
      box-shadow: 0px 0px 0px #999;
      padding: 0px;
      margin: 10px 0 0 -10px;

      .marker-div {
        position: relative;
        display: flex;
        align-items: center;
        left: -20px;
        top: -36px;

        .marker-name {
          color: #fff;
          background: #808695;
          border-radius: 2px;
          padding: 8px 8px;
          line-height: 1em;
          white-space: nowrap;
          font-size: 12px;
          opacity: 0.9;
          box-shadow: 1px 1px 2px #80869588;
          margin-left: -10px;
        }

        .marker-name-active {
          color: #fff;
          background: #808695;
          border-radius: 2px;
          padding: 8px 8px;
          line-height: 1em;
          white-space: nowrap;
          font-size: 12px;
          opacity: 0.9;
          box-shadow: 1px 1px 2px #80869588;
          margin-left: -10px;
        }

        .marker-img {
          width: 40px;
          height: 40px;
          transition: all 0.4s;
          cursor: pointer;
        }

        .marker-img-active {
          width: 65px;
          height: 65px;
          transition: all 0.4s;
          margin-left: -18px;
          margin-top: -25px;
          cursor: pointer;
        }
      }
    }

  }

  .nextBtn {
    color: #888888;
    font-size: 20px;
    cursor: pointer;

    &:hover {
      color: #fff;
    }
  }

  .marquee {
    animation: marquee 50s linear infinite;
    margin-top: 100%;
  }

  .marquee:hover {
    animation-play-state: paused;
  }

  @keyframes marquee {
    0% {
      transform: translateY(0);
    }

    96% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(-100%);
    }
  }
}
</style>