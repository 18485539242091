<template>
  <FNCContent>
    <template v-slot:title> {{ title }} </template>

    <template v-slot:toolsbarLeft>
      <Select v-model="pageParams.status" style="width: 120px" placeholder="状态" @on-change="loadData(1)">
        <Option value="">全部</Option>
        <Option v-for="item in statusList" :value="item.value" :key="item.name">{{ item.name }}</Option>
      </Select>

      <!-- <div style="width:250px;"><Input search enter-button="查询" clearable v-model="pageParams.keyword"
          placeholder="输入档案名称" @on-search="loadData(1)" /></div> -->
    </template>

    <template v-slot:toolsbarRight>

      <Button type="info" class="addDiv" @click="() => { batchDestroyaudit() }" v-if="right.A">
        <Icon type="md-add-circle" size="18" />创建销毁工单
      </Button>
      <!-- <Button type="warning" class="deleteDiv" @click="() => { deleteData() }" v-if="right.D">
        <Icon type="md-trash" size="18" />删除
      </Button> -->
      <!-- <Button type="warning" class="deleteDiv" @click="() => { historyDataWin() }" v-if="right.D">
        <Icon type="md-trash" size="18" />历史版本
      </Button> -->

    </template>

    <template v-slot:contentArea>
      <FNCTable ref="fncTable" :loading="loading" :fixTable="true" :talbeColumns="talbeColumns" :tableData="tableData"
        :pageParams="pageParams" :pagesizeOpts="pagesizeOpts" @handleLoadData="loadData"></FNCTable>
      <FNCModal ref="editModal" title="编辑" :fullscreen="false" width="800px" height="720px">
        <template v-slot:contentarea>
          <Edit ref="editContent" @closeModal="closeModal"></Edit>
        </template>

        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closeModal">
            <Icon type="md-close" />取消
          </Button>
          <Button type="primary" @click="submitData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />保存
          </Button>
        </template>
      </FNCModal>
      <FNCModal ref="batchDestroyauditContent" title="创建销毁工单" :fullscreen="false" width="1000px" height="500px">

        <template v-slot:contentarea>
          <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
            <FormItem label="档案" prop="archiveId">
              <Select v-model="formItems.archiveId" @on-select="getArchiveParam" filterable
                :remote-method="remoteMethod1" :loading="loading1">
                <Option v-for="(option, index) in archiveList" :value="option.id" :key="index">{{ option.fileName }}
                </Option>
              </Select>
            </FormItem>
            <FormItem label="销毁原因" prop="destroyReason">
              <Input type="textarea" v-model="formItems.destroyReason" placeholder="销毁原因" rows="4"></Input>
            </FormItem>
          </Form>

          <!-- <FNCTable ref="batchDestroyauditTable" :loading="loading1" :fixTable="true" :talbeColumns="batchDestroyauditColumns" :tableData="batchDestroyauditData"
        :pageParams="pageParams1" :pagesizeOpts="pagesizeOpts1" @handleLoadData="loadData1"></FNCTable> -->
        </template>

        <template v-slot:toolsbar>
          <!-- <Button type="info" style="margin-right: 8px" @click="closehistoryModal">
            <Icon type="md-close" />取消
          </Button> -->
          <Button type="primary" @click="submitData1" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />保存
          </Button>
        </template>
      </FNCModal>
      <!-- <FNCModal ref="fileListContent" title="文件列表" :fullscreen="false" width="800px" height="500px">
        <template v-slot:contentarea>
          <Table :columns="fileColumns" :data="fileList"></Table>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closefileListModal">
            <Icon type="md-close" />取消
          </Button>
        </template>
      </FNCModal> -->
      <!-- <FNCModal ref="viewModal" :title="viewTitle" :fullscreen="false" width="80%" height="750px">
        <template v-slot:contentarea>
            <div style="margin:-10px;border-radius: 10px;">
                <FNCOfficePreview :src="filePath" height="748px" :toolbar="true" :download="true"
                            :isAuthorize="true" @error="() => { }" @rendered="() => { }"></FNCOfficePreview>
            </div>
        </template>
      </FNCModal> -->
      <!-- <FNCModal ref="contentModal" :title="历史版本" :fullscreen="false" width="90%" height="500px">
        <template v-slot:contentarea>
            <div v-html="beautifyJson(jsonData)">
            </div>
        </template>
      </FNCModal> -->
    </template>
  </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/destroyaudit
import FNCContent from "@/components/FNCContent";
import FNCTable from "@/components/FNCTable";
import FNCModal from "@/components/FNCModal";
import Edit from "./childrens/Edit";
// import FNCOfficePreview from "@/components/FNCOfficePreview";
// import {orgCascader} from "@/utils/orgCascader";
export default {
  components: {
    FNCTable,
    FNCContent,
    FNCModal,
    Edit,
    // FNCOfficePreview,
  },

  data() {
    return {
      
      ruleItems: {
        档案: [
          { required: true, message: '档案不能为空', trigger: 'blur' }
        ],
        destroyReason: [
          { required: true, message: '销毁原因不能为空', trigger: 'blur' }
        ],
        // mcCode: [
        //   { required: true, message: '柜子编号不能为空', trigger: 'blur' }
        // ],
      },
      title: "信息列表",
      loading: false,
      loading1: false,
      pageParams: {
        maxCount: 0,//数据总条数
        currentPage: 0,//当前页(默认第一页)
        pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
        maxPage: 1,//总页数
        sort: "id",//排序字段
        order: "desc",//排序方式
        keyword: "",//通用查询条件
        keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
        orgCode: "",
      },
      pageParams1: {
        maxCount: 0,//数据总条数
        currentPage: 0,//当前页(默认第一页)
        pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
        maxPage: 1,//总页数
        sort: "id",//排序字段
        order: "desc",//排序方式
        keyword: "",//通用查询条件
        keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
        destroyFlag: "0",
      },
      pagesizeOpts: [20, 30, 50, 100],//分页数组
      pagesizeOpts1: [20, 30, 50, 100],
      tableData: [],
      batchDestroyauditData: [],
      talbeColumns: [
        {
          title: "档案名称",
          key: "fileName",
          align: "center",
          minWidth: 100,
          fixed: "left",
        },
        {
          title: "申请人",
          key: "applyUserName",
          align: "center",
          width: 100,
          fixed: "left",
        },
        {
          title: "申请时间",
          key: "applyTime",
          align: "center",
          width: 120,
        },
        {
          title: "审核人",
          key: "auditUserName",
          align: "center",
          width: 100,
        },
        {
          title: "审核时间",
          key: "auditTime",
          align: "center",
          width: 120,
        },
        {
          title: "销毁人",
          key: "destroyUserName",
          align: "center",
          width: 100,
        },
        {
          title: "销毁时间",
          key: "destroyTime",
          align: "center",
          width: 120,
        },
        {
          title: "状态",
          key: "status",
          align: "center",
          width: 95,
          render: (h, params) => {
            return h("div", this.convertObject("statusList", "value", "name", params.row.status + ""))
          },
        },
        {
          title: "备注",
          key: "reason",
          align: "center",
          minWidth: 80,
        },

        {
          title: "操作",
          slot: "action",
          width: 220,
          align: "center",
          fixed: "right",
          render: (h, params) => {
            return h("div",
              [
                h(
                  "Button",
                  {

                    class: "editDiv"
                    ,
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      margin: "0 3px",
                    },

                    on: {
                      click: () => {
                        this.processAudit(params.row.status, params.row.id, 1);
                      },
                    },
                  },
                  "通过"
                ),

                h(
                  "Button",
                  {

                    class: "editDiv"
                    ,
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      margin: "0 3px",
                    },

                    on: {
                      click: () => {
                        this.processAudit(params.row.status, params.row.id, 2);
                      },
                    },
                  },
                  "不通过"
                ),

                h(
                  "Button",
                  {
                    class: "deleteDiv"
                    ,
                    props: {
                      type: "error",
                      size: "small",
                    },
                    style: {
                      margin: "0 3px",
                    },
                    on: {
                      click: () => {
                        this.processAudit(params.row.status, params.row.id, 4);
                      },
                    },
                  },
                  "取消"
                ),
              ]);
          },
        },
      ],
      batchDestroyauditColumns: [
        // {
        //   type: 'selection',
        //   width: 60,
        //   fixed: "left",
        //   align: 'center'
        // },
        {
          title: "档案编号",
          key: "archiveNum",
          align: "center",
          width: 120,
        },
        {
          title: "档案名称",
          key: "fileName",
          align: "center",
          minWidth: 180,
        },
      ],
      right: {},
      saveLoading: false,
      infoType: "",
      infoTypeList: [],
      statusList: [],

      historyList: [],
      fileList: [],
      filePath: "",
      depts: [],
      deptid: [],
      formItems: {},
    };
  },
  created() {
    // this.orgCascader = orgCascader("dept");
    // this.deptid=this.orgCascader.convectCascaderList(this.$core.getBranchUserInfo("deptid"));
    // this.orgCascader.loadData(
    //   (depts) => {
    //     this.depts = depts;
    //   },this.deptid[this.deptid.length-1]);
  },
  mounted() {
    this.loadModuleInfo();
  },
  methods: {
    loadModuleInfo() {
      //读取栏目信息
      this.$get({
        url: "/gateway/api/manage/type/moduleinfo",
        data: {
          functionId: this.$core.getFunctionId(),
        },
        success: (res) => {
          if (res.code == "200" && res.data) {
            this.title = res.data.typename;
          }
        }
      });

      this.$core.getStyleParamList("destroyauditStatus", false, null, (res) => {
        this.statusList = res
      })


      //读取数据列表
      this.loadData(1);
      this.$core.getControlRight().then(res => {
        this.right = res;
      });
    },
    convertObject(target, inFeild, outFeild, value) {
      var object = this[target].find(item => value == item[inFeild])
      return object ? object[outFeild] : "无"
    },
    loadData(currentPage) {
      if (currentPage) {
        this.pageParams.currentPage = currentPage;
      } else {
        this.pageParams.currentPage = this.pageParams.currentPage + 1;
      }
      this.loading = true;
      this.$get({
        url: "/smartarchives/api/destroyaudit/queryDestroyaudit",
        data: this.pageParams,
        success: (res) => {
          this.tableData = []
          if (res.code == "200" && res.pageParams) {
            this.pageParams = { ...this.pageParams, ...res.pageParams }
            this.tableData = res.dataList
          } else {
            this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
          }
          this.loading = false;
        }
      })
    },

    loadData1(currentPage) {
      if (currentPage) {
        this.pageParams1.currentPage = currentPage;
      } else {
        this.pageParams1.currentPage = this.pageParams1.currentPage + 1;
      }
      this.loading1 = true;
      this.pageParams1.destroyFlag = "0";
      this.$get({
        url: "/smartarchives/api/archives/queryArchives",
        data: this.pageParams1,
        success: (res) => {
          this.batchDestroyauditData = []
          if (res.code == "200" && res.pageParams) {
            this.pageParams1 = { ...this.pageParams1, ...res.pageParams }
            this.batchDestroyauditData = res.dataList
          } else {
            this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
          }
          this.loading1 = false;
        }
      })
    },

    deleteData(row) {
      // if (this.right["D"] == false) {
      //   this.$Message.error({ background: true, content: "你没有删除权限" })
      //   return;
      // }
      // var ids = ""
      // if (row) {
      //   ids = row.id
      // } else {
      //   let selectedList = this.$refs.fncTable.getSelection();
      //   if (selectedList && selectedList.length > 0) {
      //     ids = selectedList.map(item => {
      //       return item.id;
      //     }).join(",")
      //   }
      // }
      // if (!ids) {
      //   this.$Message.error({ background: true, content: "并没有选择审核项" })
      //   return;
      // }

      // this.$Modal.confirm({
      //   title: '删除确认',
      //   content: row ? '您确认要删除“<span style="color:red"> ' + row.fileName + '</span>”吗？' : "确认要删除选中吗？",
      //   onOk: () => {
      //     this.$post({
      //       url: "/smartarchives/api/archives/delArchives", data: { ids: ids },
      //       success: (res) => {
      //         if (res.code == "200") {
      //           this.$Message.success({ background: true, content: row.fileName+"已移入回收站" })
      //           this.loadData(1);
      //         } else {
      //           this.$Message.error({ background: true, content: res.desc })
      //         }
      //       }
      //     });
      //   }
      // });
    },
    batchDestroyaudit() {
      this.$refs.batchDestroyauditContent.showModal();
      // this.loadData1(1);
    },
    processAudit(status, id, auditStatus) {
      if (status != 0) {
        this.$Message.error({ background: true, content: "不是审核中状态" });
        return;
      }
      if (id && this.right["E"] == true) {
        this.formItems.id = id;
        this.formItems.status = auditStatus;
        if (auditStatus == 1) {//通过
          this.$post({
            url: "/smartarchives/api/destroyaudit/updateDestroyaudit",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" });
                this.loadData(1);
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$refs.editModal.showModal();
          this.$refs.editContent.loadData(id, auditStatus);
        }
      }
    },
    closeModal() {
      this.$refs.editModal.hideModal();
    },
    submitData() {
      this.$refs.editContent.submitData((b, reload) => {
        this.saveLoading = b;
        if (reload == true) {
          this.loadData(1);
          this.closeModal();
        }
      });
    },
    submitData1() {
      // var archiveIdList = "";
      // let selectedList = this.$refs.batchDestroyauditTable.getSelection();
      // if (selectedList && selectedList.length > 0) {
      //   archiveIdList = selectedList.map(item => {
      //     return item.id;
      //   }).join(",")
      // }
      // this.formItems.selectedList = selectedList;
      this.$post({
        url: "/smartarchives/api/destroyaudit/addDestroyaudit",
        data: { ...this.formItems }
        , success: (res) => {
          if (res.code == "200") {
            callback(false, true);
            this.$Message.success({ background: true, content: "保存成功" })
          } else {
            this.$Message.error({ background: true, content: res.desc || "保存出错" })
          }
        }, complete: () => {
          callback(false)
        }
      });

    },
    closehistoryModal() {
      this.$refs.historyContent.hideModal();
    },
    historyDataWin(id) {
      // this.$get({
      //   url: "/smartarchives/api/archivesversion/queryArchivesversion", 
      //   data: {recordId:id,tableName:'tbarchives'}, success: (res) => {
      //     if (res.code == "200" && res.dataList) {
      //       this.historyList =res.dataList;
      //     }
      //   }
      // })
      // this.$refs.historyContent.showModal();
    },
    closefileListModal() {
      this.$refs.fileListContent.hideModal();
    },
    fileListWin(row) {
      // this.$get({
      //   url: "/smartarchives/api/archives/queryFileList", 
      //   data: {id:row.id,filesTotal:row.filesTotal}, success: (res) => {
      //     if (res.code == "200" && res.dataList) {
      //       this.fileList =res.dataList;
      //     }
      //   }
      // })
      // this.$refs.fileListContent.showModal();
    },

    deleteFile(row) {
      // if (this.right["D"] == false) {
      //   this.$Message.error({ background: true, content: "你没有删除权限" })
      //   return;
      // }
      // this.$Modal.confirm({
      //   title: '删除确认',
      //   content: '您确认要删除“<span style="color:red"> ' + row.fileName + '</span>”吗？',
      //   onOk: () => {
      //     this.$post({
      //       url: "/smartarchives/api/archives/delArchivesFile", data: { id: row.id },
      //       success: (res) => {
      //         if (res.code == "200") {
      //           this.$Message.success({ background: true, content: res.desc })
      //           this.closefileListModal();
      //           this.loadData(1);
      //         } else {
      //           this.$Message.error({ background: true, content: res.desc })
      //         }
      //       }
      //     });
      //   }
      // });
    },
    getArchiveParam(e) {
      for(var i = 0; i< this.archiveList.length; i++) {
        if(e.value = this.archiveList[i].id) {
          this.formItems.archiveId = e.value;
          // this.formItems.oldOrgCode = this.archiveList[i].orgCode;
          // this.formItems.oldWhId = this.archiveList[i].whId;
          // this.formItems.oldMcId = this.archiveList[i].mcId;
          break;
        }
      }
    },
    remoteMethod1 (query) {
      if (query !== ''||this.formItems.archiveId) {
        if(query == this.formItems.archiveId) {
          query ='';
        }
        this.loading1 = true;
        setTimeout(() => {
          this.loading1 = false;
          this.$get({
        url: "/smartarchives/api/archives/queryArchives", data: { pageSize: 50,destroyFlag: "0",id:this.formItems.archiveId,fileName: query}, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.archiveList = res.dataList
          }
        }
      })
          // this.options1 = this.archiveList.filter(item => item.fileName.toLowerCase().indexOf(query.toLowerCase()) > -1);
          }, 200);
      } else {
          this.archiveList = [];
      }
    },


  }
};
</script>

<style scoped lang='less'></style>