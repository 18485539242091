/**
 * 单页面配置
 */

module.exports = {
    website: {
        entry: 'src/views/website/main/main.js',
        title: '门户网站',
        template: 'public/index.html',
        filename: 'portal.html',
        chunks: ['chunk-vendors', 'chunk-common', 'website']
    },
}