import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//解决vueRouter 跳转相同路由报错重写push方法，添加catch
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

//files为指定route配置文件列表
var routes = (startPath) => {
  let routesConfig = [];
  var paths = [];
  paths = require("@/router/paths.js").default;
  startPath = startPath || [];
  paths.map(item => {
    if (!startPath||startPath.length==0||startPath.findIndex(s=>item.file.indexOf(s)==0)>= 0) {
      // console.log(item.file);
      routesConfig.push({
        path: item.path,
        name: item.path.substring(1),
        component: () => import(/*webpackChunkName: "fnc-[request]" */'@/' + item.file),
      });
    }
  });

  // routesConfig.push(
  //   {
  //     path: "/", // 默认页面重定向到主页
  //     redirect: "/", // 重定向 
  //   }
  //   );

  routesConfig.push({
    path: "/noRight",
    name: "noRight",
    component: () => import(/*webpackChunkName: "noright" */'@/portal/noright.vue'),
  });

  routesConfig.push({
    path: "/homepage",
    name: "homepage",
    component: () => import(/*webpackChunkName: "noright" */'@/portal/homepage.vue'),
  });


  return routesConfig;
};

const router = (startPath) => {
  var router = new VueRouter({
    mode: "hash",//hash则带#,history
    base: process.env.BASE_URL,
    routes: routes(startPath),
  });
  router.beforeEach((to, from, next) => {
    next();
  });
  return router;
}

export default router;
