var domain = function (param) {
  if (process.env.NODE_ENV == 'development') {
    return param.test;
  }
  if (process.env.NODE_ENV == 'production') {
    return param.prod;
  }
}

module.exports = {
  "/smartlife": {
    target: domain({
      test: "http://localhost:8007",//测试
      prod: "http://api.smartlife.891tech.com",//生产
    }), //

    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/smartlife": "/",//重写地址
    },
  },
  "/smartlifeh5": {
    target: domain({
      test: "https://h5.smartlife.891tech.com",//测试
      prod: "https://h5.smartlife.891tech.com",//生产
    }), //

    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/smartlifeh5": "/",//重写地址
    },
  },
}