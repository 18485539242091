<template>
    <div style="">
        <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
            <FormItem label="档案编号" prop="archiveNum">
                <Input v-model.trim="formItems.archiveNum" placeholder="档案编号" @on-blur="checkArchivesExist" @on-change="()=>{this.checkExist=false;}"></Input>
                <div style="color:red;" v-if="checkExist">提示：该档案编号({{ formItems.archiveNum }})已存在档案正式库中，整编入库时需要慎重</div>
            </FormItem>
            <FormItem label="文件名称" prop="fileName">
                <Input v-model.trim="formItems.fileName" placeholder="文件名称"></Input>
            </FormItem>
            <FormItem label="全宗号" prop="fullFileNum">
                <Input v-model.trim="formItems.fullFileNum" placeholder="全宗号"></Input>
            </FormItem>
            <FormItem label="全宗名称" prop="fullFileName">
                <Input v-model.trim="formItems.fullFileName" placeholder="全宗名称"></Input>
            </FormItem>
            <FormItem label="归档编号" prop="archivingNum">
                <Input v-model.trim="formItems.archivingNum" placeholder="归档编号"></Input>
            </FormItem>
            <FormItem label="绑定库房" prop="whId">
                <div style="width: 100%;display: flex;justify-content: flex-start;">
                    <Select v-model="formItems.whId" @on-change="getMachinecabinetByWh"
                        style="width: 180px;margin-right: 10px;">
                        <Option v-for="item in warehouseList" :value="item.id" :key="item.whName">{{ item.whName }}
                        </Option>
                    </Select>
                    <div style="color:#2db7f5;" v-if="formItems.batchNum && formItems.whName">
                        <Icon type="md-arrow-round-forward" /> 导入的库房名称：{{ formItems.whName }}
                    </div>
                </div>
            </FormItem>
            <FormItem label="绑定机柜" prop="mcId">
                <div style="width: 100%;display: flex;justify-content: flex-start;">
                    <Select v-model="formItems.mcId" style="width: 180px;float:left;margin-right: 10px;">
                        <Option v-for="item in machinecabinetList" :value="item.id" :key="item.mcName">{{ item.mcName }}
                        </Option>
                    </Select>
                    <div style="float:left;color:#2db7f5;" v-if="formItems.batchNum && formItems.mcName">
                        <Icon type="md-arrow-round-forward" /> 导入的机柜名称：{{ formItems.mcName }}
                    </div>
                </div>
            </FormItem>
            <FormItem label="所属科室" prop="orgCode">
                <div style="width: 100%;display: flex;justify-content: flex-start;">
                    <Cascader v-model="deptid" @on-change="checkOrgRight" placeholder="选择科室" change-on-select clearable
                        :data="depts" style="width: 300px;float:left;margin-right: 10px;" />
                    <div style="float:left;color:#2db7f5;" v-if="formItems.batchNum && formItems.orgName">
                        <Icon type="md-arrow-round-forward" /> 导入的科室名称：{{ formItems.orgName }}
                    </div>
                </div>
            </FormItem>
            <FormItem label="档案类型" prop="archiveType">
                <div style="width: 100%;display: flex;justify-content: flex-start;">
                    <Select v-model="formItems.archiveType" style="width: 180px;float:left;margin-right: 10px;">
                        <Option v-for="item in archiveTypeList" :value="item.value" :key="item.name">{{ item.name }}
                        </Option>
                    </Select>
                    <div style="float:left;color:#2db7f5;" v-if="formItems.batchNum && formItems.archiveName">
                        <Icon type="md-arrow-round-forward" /> 导入的档案类型：{{ formItems.archiveName }}
                    </div>
                </div>
            </FormItem>
            <FormItem label="密级" prop="secretLevel">
                <Select v-model="formItems.secretLevel" style="width: 180px;float:left;margin-right: 10px;">
                    <Option v-for="item in secretLevelList" :value="item.value" :key="item.name">{{ item.name }}
                    </Option>
                </Select>
            </FormItem>
            <FormItem label="档案年份" prop="archiveYear">
                <Input v-model.trim="formItems.archiveYear" placeholder="档案年份"></Input>
            </FormItem>
            <FormItem label="保管期限" prop="storageLife">
                <Input v-model.trim="formItems.storageLife" placeholder="保管期限"></Input>
            </FormItem>
            <FormItem label="存储位置" prop="storageLocation">
                <Input v-model.trim="formItems.storageLocation" placeholder="存储位置"></Input>
            </FormItem>
            <FormItem label="用地面积" prop="landArea">
                <Input v-model.trim="formItems.landArea" placeholder="用地面积"></Input>
            </FormItem>
            <FormItem label="地址" prop="address">
                <Input v-model.trim="formItems.address" placeholder="地址"></Input>
            </FormItem>
            <FormItem label="管理人员" prop="manager">
                <Input v-model.trim="formItems.manager" placeholder="管理人员"></Input>
            </FormItem>
        </Form>
    </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
import { orgCascader } from "@/utils/orgCascader"
import FNCUpload from "@/components/FNCUpload"
export default {
    components: {
        FNCModal,
        FNCUpload,
    },
    data() {
        return {
            id: "",
            checkExist: false,
            deptid: [],
            formItemsDefault: JSON.stringify({
                id: "",//ID
                branchId: "",//BranchId
                batchNum: "",//批次号
                whId: "",//绑定库房ID
                mcId: "",//绑定机柜ID
                mcName: "",//机柜名称（导入文件）
                whName: "",//库房名称（导入文件）
                fullFileNum: "",//全宗号
                fullFileName: "",//全宗名称
                fileName: "",//文件名称
                filesTotal: "",//文件总数量
                filesSizeTotal: "",//文件总大小
                archiveNum: "",//档案编号
                archivingNum: "",//归档编号
                archiveYear: "",//档案年份
                archiveType: "",//档案类型ID
                orgCode: "",//所属科室ID
                orgName: "",//科室名称（导入文件）
                archiveName: "",//档案类型名称（导入文件）
                secretLevel: "",//密级
                storageLife: "",//保管期限
                storageLocation: "",//存储位置
                landArea: "",//用地面积
                address: "",//地址
                manager: "",//管理人员
                importOrgCode: "",//入库科室（用于列表筛选）
                importUser: "",//入库人员
                importTime: "",//入库时间
                status: 1,//处理状态：待整编1，整编中2，整编完成3，整编失败4
                reason: "",//处理信息
                dealTime: "",//处理时间
                dealUser: "",//处理人员
            }),
            filesStorage: [],//文件列表
            formItems: {},
            ruleItems: {
                archiveNum: [{ required: true, message: "请填写档案编号", trigger: "blur" }],
                fileName: [{ required: true, message: "请填写文件名称", trigger: "blur" }],
                // whId: [{ required: true, message: "请绑定库房", trigger: "change" }],
                // mcId: [{ required: true, message: "请绑定机柜", trigger: "change" }],
                orgCode: [{ required: true, message: "请绑定所属科室", trigger: "change" }],
                archiveType: [{ required: true, message: "请绑定档案类型", trigger: "change" }],
                secretLevel: [{ required: true, message: "请绑定密级", trigger: "change" }],
            },

            batchNum: null,
            importData: {},
            machinecabinetList: [],
            archiveTypeList: [],
            warehouseList: [],
        }
    },
    created() {
        this.orgCascader = orgCascader("dept");
        this.deptid = this.orgCascader.convectCascaderList(this.$core.getBranchUserInfo("deptid"))
        this.orgCascader.loadData(
            depts => {
                this.depts = depts
            },
            this.deptid.length > 0 ? this.deptid[this.deptid.length - 1] : ""
        )
        this.loadBaseData();
    },
    mounted() {

    },
    methods: {
        loadBaseData() {
            this.$core.getStyleParamList("archiveType", false, "", res => {
                this.archiveTypeList = res || []
            })
            this.$core.getStyleParamList("secretLevel", false, "", res => {
                this.secretLevelList = res || []
            })

            this.$get({
                url: "/smartarchives/api/warehouse/client/queryWarehouse",
                data: {},
                success: res => {
                    if (res.code == "200") {
                        this.warehouseList = res.dataList
                    }
                }
            })

        },
        getMachinecabinetByWh(whId, mcId) {
            this.formItems.mcId = mcId;
            this.$get({
                url: "/smartarchives/api/machinecabinet/client/queryMachinecabinetByWh",
                data: { whId: whId },
                success: res => {
                    if (res.code == "200") {
                        this.machinecabinetList = res.dataList
                        this.$forceUpdate()
                    }
                }
            })
        },
        /**
        * 检测档案号是否存在
        */
        checkArchivesExist() {

            this.$Message.loading({ content: "正在检测档案编号是否存在", duration: 0 });
            this.$get({
                url: "/smartarchives/api/archivesstorage/checkarchivesexist",
                data: { archiveNum: this.formItems.archiveNum },
                success: res => {
                    if (res.code == "200") {
                        if (res.data > 0) {
                            this.checkExist = true;
                        } else {
                            this.checkExist = false;
                        }
                    }
                },
                complete: () => {
                    this.$Message.destroy()
                }
            })

        },
        /**
        * 获取数据
        */
        loadArchivesStorageData() {
            if (this.id) {
                this.$Message.loading({ content: "正在读取数据", duration: 0 });
                this.$get({
                    url: "/smartarchives/api/archivesstorage/archivesstoragedetail",
                    data: { id: this.id },
                    success: res => {
                        if (res.code == "200") {
                            this.formItems = res.data || {};
                            if (this.formItems.id) {
                                if (this.formItems.whId) {
                                    this.getMachinecabinetByWh(this.formItems.whId, this.formItems.mcId)
                                }
                                if (this.formItems.orgCode) this.deptid = this.orgCascader.convectCascaderList(this.formItems.orgCode)
                                this.checkArchivesExist();
                            }
                        }
                    },
                    complete: () => {
                        this.$Message.destroy()
                    }
                })
            } else {
                this.$Message.error({ background: true, content: "无效的档案文件信息" })
                this.$emit("closeModal");
            }
        },


        loadData(id) {
            this.id = id
            this.checkExist=false;
            this.formItems = JSON.parse(this.formItemsDefault)
            this.formItems.orgCode = this.deptid && this.deptid.length > 0 ? this.deptid[this.deptid.length - 1] : null;
            this.$nextTick(() => {
                this.loadArchivesStorageData()
            });
            this.$forceUpdate()
        },

        submitData(callback) {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    callback(3)
                    this.$post({
                        url: "/smartarchives/api/archivesstorage/reorganizesave",
                        data: { ...this.formItems },
                        header: { "Content-Type": "application/json" },
                        success: res => {
                            if (res.code == "200") {
                                callback(1, true)
                                this.$Message.success({ background: true, content: "保存成功" })
                            } else {
                                callback(1)
                                this.$Message.error({ background: true, content: res.desc || "保存出错" })
                            }
                        },
                        fail: () => {
                            callback(1)
                            this.$Message.error({ background: true, content: "保存出错" })
                        },

                    })
                } else {
                    this.$Message.error({ background: true, content: "部分必填项没填写" })
                }
            })
        },

        checkOrgRight(e) {
            this.deptid = this.orgCascader.checkOrgRight(e)
            this.formItems.orgCode = this.deptid[this.deptid.length - 1]
        },
    },
}
</script>
    
<style scoped lang='less'>
.file-upload-list {
    width: 100%;
    text-align: left;
    padding-bottom: 5px;
    overflow: hidden;
    position: relative;
    margin-right: 4px;
    color: #2b85e4;
}
</style>