<template>
    <FNCContent>
        <template v-slot:title>{{ title }}</template>
        <template v-slot:toolsbarLeft>
            <Select v-model="tableList.pageParams.status" style="width: 120px" placeholder="全部状态">
                <Option v-for="item in reorganizeStatus" :value="item.value" :key="item.name">{{ item.name }}</Option>
            </Select>
            <div style="width: 300px"><Input search enter-button="查询" clearable v-model="tableList.pageParams.keyword"
                    placeholder="输入批次号/档案编号/档案名称" @on-search="loadData(1)" /></div>
        </template>
        <template v-slot:toolsbarRight>
            <Button type="primary" class="addDiv" @click="() => {
                editData(1)
            }
                " v-if="right.A">
                <Icon type="md-cloud-upload" size="18" />
                创建档案
            </Button>
        </template>
        <template v-slot:contentArea>
            <FNCTable ref="fncTable" :loading="loading" :fixTable="true" :talbeColumns="talbeColumns"
                :tableData="tableList.dataList" :pageParams="tableList.pageParams" :pagesizeOpts="tableList.pagesizeOpts"
                @handleLoadData="loadData"></FNCTable>
            <FNCModal ref="editModal" title="创建档案" :fullscreen="false" width="800px" :height="height">
                <template v-slot:contentarea>
                    <Edit ref="editContent" @closeModal="closeModal"></Edit>
                </template>
                <template v-slot:toolsbar>
                    <Button style="width: 200px" type="primary" @click="submitData" :loading="saveLoading == 3">
                        <Icon type="md-checkmark-circle" />
                        {{ saveLoading == 3 ? "保存中..." : "保存" }}
                    </Button>
                </template>
            </FNCModal>
            <FNCModal ref="viewDetailModal" :title="viewDetailTitle" :fullscreen="true">
                <template v-slot:contentarea>
                    <FNCTable ref="viewTable" :talbeColumns="viewDetailColumns" :loading="viewLoading"
                        :tableData="detailList" :hidePage="true"></FNCTable>
                </template>
            </FNCModal>
            <FNCModal ref="viewModal" :title="viewTitle" :fullscreen="false" width="80%" height="750px">
                <template v-slot:contentarea>
                    <div style="margin:-10px;border-radius: 10px;">
                        <FNCOfficePreview :src="filePath" height="748px" :toolbar="true" :download="true" :print="true"
                            :isAuthorize="true" @error="() => { }" @rendered="() => { }"></FNCOfficePreview>
                    </div>
                </template>
            </FNCModal>
        </template>
    </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/archivesstorage
import FNCContent from "@/components/FNCContent"
import FNCTable from "@/components/FNCTable"
import FNCModal from "@/components/FNCModal"
import Edit from "./childrens/Edit"
import { pageList } from "@/utils/pageList"
import { orgCascader } from "@/utils/orgCascader"
import FNCOfficePreview from "@/components/FNCOfficePreview"
export default {
    components: {
        FNCTable,
        FNCContent,
        FNCModal,
        Edit,
        FNCOfficePreview,
    },

    data() {
        return {
            title: "信息列表",
            viewTitle: "档案文件",
            viewDetailTitle: "档案文件明细",
            loading: true,
            viewLoading: false,
            tableList: {},
            orgCascader: {},
            archiveTypeList: [],
            talbeColumns: [
                // {
                //   type: 'selection',
                //   width: 60,
                //   fixed: "left",
                //   align: 'center'
                // },

                {
                    title: "档案编号",
                    key: "archiveNum",
                    align: "center",
                    width: 200,
                },
                {
                    title: "全宗号",
                    key: "fullFileNum",
                    align: "center",
                    width: 100,
                },
                {
                    title: "全宗名称",
                    key: "fullFileName",
                    align: "center",
                    width: 120,
                },
                {
                    title: "档案类型",
                    key: "archiveName",
                    align: "center",
                    width: 200,
                    render: (h, params) => {
                        return h("span", this.covertStatus("archiveTypeList", params.row.archiveType) || params.row.archiveName)
                    },
                },
                {
                    title: "文件名称",
                    key: "fileName",
                    align: "center",
                    minWidth: 200,
                },
                {
                    title: "文件总数量",
                    key: "filesTotal",
                    align: "center",
                    width: 120,
                },
                {
                    title: "文件总大小",
                    key: "filesSizeTotal",
                    align: "center",
                    width: 120,
                    render: (h, params) => {
                        return h("span", this.convertSize(params.row.filesSizeTotal))
                    },
                },

                {
                    title: "所属科室",
                    key: "orgName",
                    align: "center",
                    width: 150,
                    render: (h, params) => {
                        return h("span", this.orgCascader.convectOrgName(params.row.orgCode) || params.row.orgName)
                    },
                },
                {
                    title: "批次号",
                    key: "batchNum",
                    align: "center",
                    width: 180,
                },
                {
                    title: "导入时间",
                    key: "importTime",
                    align: "center",
                    width: 200,
                },
                {
                    title: "状态",
                    key: "status",
                    align: "left",
                    fixed: "right",
                    width: 120,
                    render: (h, params) => {
                        return h("div",
                            {
                                style: { color: params.row.status == 3 ? "#19be6b" : params.row.status == 4 ? "#ed4014" : "#333333" },
                            },
                            params.row.reason ? [
                                h("Tooltip", {
                                    props: { transfer: true, placement: "left", content: this.covertStatus("reorganizeStatus", params.row.status) + "：" + params.row.reason, "max-width": "450" },
                                    style: { width: "100%" }
                                },
                                    [
                                        h("span",
                                            this.covertStatus("reorganizeStatus", params.row.status)
                                        ),
                                        h("Icon", {
                                            props: { type: "md-text", size: "18" },
                                        }),
                                    ])
                            ] : this.covertStatus("reorganizeStatus", params.row.status))
                    },
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 250,
                    align: "left",
                    fixed: "right",
                    render: (h, params) => {
                        return h("div", [

                            h(
                                "Button",
                                {
                                    class: "viewDiv",
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: {
                                        margin: "0 5px",
                                    },

                                    on: {
                                        click: () => {
                                            this.viewDetailList(params.row)
                                        },
                                    },
                                },
                                "文件明细"
                            ),

                            h(
                                "Button",
                                {
                                    class: "editDiv",
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: {
                                        display: params.row.status == 1 ? "" : "none",
                                        margin: "0 5px",
                                    },

                                    on: {
                                        click: () => {
                                            this.editData(1, params.row.id)
                                        },
                                    },
                                },
                                "修改"
                            ),


                            h(
                                "Button",
                                {
                                    class: "deleteDiv",
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    style: {
                                        display: params.row.status == 1 || params.row.status == 4 ? "" : "none",
                                        margin: "0 5px",
                                    },

                                    on: {
                                        click: () => {
                                            this.deleteData(params.row.id)
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],
            viewDetailColumns: [
                {
                    title: "顺序号",
                    key: "sortNum",
                    align: "center",
                    width: 100,
                },
                {
                    title: "文件名称",
                    key: "fileName",
                    align: "center",
                    minWidth: 250,
                },
                {
                    title: "文件格式",
                    key: "fileFormat",
                    align: "center",
                    width: 100,
                },
                {
                    title: "文件大小",
                    key: "fileSize",
                    align: "center",
                    width: 120,
                    render: (h, params) => {
                        return h("span", this.convertSize(params.row.fileSize))
                    },
                },
                {
                    title: "状态",
                    key: "status",
                    align: "center",
                    fixed: "right",
                    width: 100,
                    render: (h, params) => {
                        return h("span", this.covertStatus("reorganizeStatus", params.row.status))
                    },
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 150,
                    align: "center",
                    fixed: "right",
                    render: (h, params) => {
                        return h("div", [

                            h(
                                "Button",
                                {
                                    class: "viewDiv",
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: {
                                        width: "120px",
                                    },

                                    on: {
                                        click: () => {
                                            this.viewDetail(params.row)
                                        },
                                    },
                                },
                                "阅览"
                            ),

                        ])
                    },
                },
            ],
            detailList: [],
            right: {},
            saveLoading: 1,
            height: "750px",
            reorganizeStatus: [],
            timer: null,
            filePath: "",
        }
    },
    created() {
        this.orgCascader = orgCascader("dept")
        this.orgCascader.loadData();
        this.tableList = pageList(this.$get)
    },
    mounted() {
        this.loadModuleInfo()
    },
    beforeDestroy() {
    },
    methods: {
        loadModuleInfo() {
            //读取栏目信息
            this.$get({
                url: "/gateway/api/manage/type/moduleinfo",
                data: {
                    functionId: this.$core.getFunctionId(),
                },
                success: res => {
                    if (res.code == "200" && res.data) {
                        this.title = res.data.typename
                    }
                },
            })
            this.$core.getStyleParamList("reorganizeStatus", true, "全部状态", res => {
                this.reorganizeStatus = res || [{ name: "全部状态", value: "" }]
            })
            this.$core.getStyleParamList("archiveType", false, "", res => {
                this.archiveTypeList = res || []
            })
            //读取数据列表
            this.loadData(1)
            this.$core.getControlRight().then(res => {
                this.right = res
            })
        },
        covertStatus(type, value) {
            return (this[type].find(item => item.value == value) || {}).name
        },
        convertSize(size) {
            if (!size) {
                return "-";
            } else if (size < 1024) {
                return size + "B";
            } else if (size < 1024 * 1024) {
                return (size / (1024)).toFixed(3) + "KB";
            } else {
                return (size / (1024 * 1024)).toFixed(3) + "MB";
            }
        },
        closeModal() {
            this.$refs.editModal.hideModal()
        },

        loadData(currentPage, isTick) {
            if (!isTick) {
                this.loading = true;
            }
            var that = this;
            clearTimeout(that.timer);
            this.tableList.loadData({
                url: "/smartarchives/api/archivesstorage/archivesstoragelist",
                currentPage: currentPage,
                data: {},
                success: res => {
                    if (res.code != "200") {
                        this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
                    }
                },
                fail: () => {
                    this.loading = false;
                },
                complete: (res) => {
                    this.loading = false;
                }
            })
        },
        viewDetailList(row) {
            this.viewDetailTitle = (row.fileName || "") + "【" + row.archiveNum + "】" + "档案文件明细";
            this.viewLoading = true;
            this.detailList = [];
            this.$refs.viewDetailModal.showModal();
            this.$get({
                url: "/smartarchives/api/archivesstorage/filestoragelistbyid",
                data: { asId: row.id },
                success: (res) => {
                    if (res.code == "200") { }
                    this.detailList = res.dataList;
                },
                complete: () => {
                    this.viewLoading = false;
                }
            });
        },
        viewDetail(row) {
            this.viewTitle = (row.fileName || "");
            this.filePath = row.filePath;
            this.$refs.viewModal.showModal();
        },
        deleteData(id) {
            if (this.right["D"] == false) {
                this.$Message.error({ background: true, content: "你没有删除权限" })
                return
            }

            this.$Modal.confirm({
                title: "删除确认",
                content: "将删除该记录以及其相关联的文件，确认要删除选中吗？",
                onOk: () => {
                    this.$post({
                        url: "/smartarchives/api/archivesstorage/delete",
                        data: { ids: id },
                        success: res => {
                            if (res.code == "200") {
                                this.$Message.success({ background: true, content: "删除成功" })
                                this.loadData(1)
                            } else {
                                this.$Message.error({ background: true, content: res.desc })
                            }
                        },
                    })
                },
            })
        },
        editData(type, id) {
            if (this.right["A"] == true) {
                if (type == 2) {
                    this.height = "560px"
                } else {
                    this.height = "750px"
                }
                this.saveLoading = 1
                this.$refs.editModal.showModal();
                this.$refs.editContent.loadData(type, id);
            }
        },
        submitData() {
            this.$refs.editContent.submitData((b, c) => {
                if (c) {
                    this.loadData(1)
                    this.$refs.editModal.hideModal()
                }
                this.saveLoading = b
            })
        },
    },
}
</script>
  
<style scoped lang='less'></style>