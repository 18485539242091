<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="选择档案" prop="archiveId">
        <!-- <Select v-model="formItems.archiveId" @on-select="getArchiveParam">
          <Option v-for="item in archiveList" :value="item.id" :key="item.fileName">{{ item.fileName }}</Option>
        </Select> v-model="formItems.archiveId"-->
        <Select v-model="formItems.archiveId" @on-select="getArchiveParam" filterable :remote-method="remoteMethod1"
                :loading="remoteLoading">
          <Option v-for="(option, index) in archiveList" :value="option.id" :key="index">{{option.fileName}}</Option>
        </Select>
      </FormItem>
      <FormItem label="选择接收科室" prop="orgCode">
        <Cascader v-model="deptid" @on-change="checkOrgRight" placeholder="选择接收科室" change-on-select clearable :data="depts" />
      </FormItem>
      <FormItem label="移交原因" prop="transferReason">
        <!-- <textarea v-model="formItems.transferReason"></textarea> -->
        <Input type="textarea" v-model="formItems.transferReason" placeholder="移交原因" rows="4"></Input>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
import {orgCascader} from "@/utils/orgCascader";
export default {
  components: {
    FNCModal, 
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: 0,
        whName:"",
        whLocation:"",
      }),
      remoteLoading:false,
      formItems: {},
      ruleItems: {
        // whName: [
        //   { required: true, message: '库房名称不能为空', trigger: 'blur' }
        // ],
        // whLocation: [
        //   { required: true, message: '库房位置不能为空', trigger: 'blur' }
        // ],
        archiveId: [
          { required: true, message: '档案不能为空', trigger: 'blur' }
        ],
        transferReason:[
          { required: true, message: '移交原因不能为空', trigger: 'blur' }
        ]
      
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,
      orgList: [],
      archiveList: [],
      depts:[],
      deptid:[],
    };
  },
  created() {
    this.orgCascader = orgCascader("dept");
    this.deptid=this.orgCascader.convectCascaderList("");
    this.orgCascader.loadData(
      (depts) => {
        this.depts = depts;
      },this.deptid[this.deptid.length-1]);
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
     loadData(id) {
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.$get({
          url: "/smartarchives/api/transferaudit/queryTransferauditDetail",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
              this.deptid = this.orgCascader.convectCascaderList(res.data.orgCode);
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },

    /**
    * 获取选项数据
    */
    loadBaseData() {
      // this.$core.getStyleParamList("secretLevel", false, null, (res) => {
      //   this.secretLevelList = res 
      // })
      // this.$core.getStyleParamList("archiveType", false, null, (res) => {
      //   this.archiveTypeList = res 
      // })
      // this.$get({
      //   url: "/smartarchives/api/archives/queryArchives", data: { pageSize: 50,transferFlag: "0" }, success: (res) => {
      //     if (res.code == "200" && res.dataList) {
      //       this.archiveList = res.dataList
      //     }
      //   }
      // })
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          this.$post({
            url: this.formItems.id ? "/smartarchives/api/transferaudit/updateTransferaudit" : "/smartarchives/api/transferaudit/addTransferaudit",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    checkOrgRight(e){
      this.deptid=this.orgCascader.checkOrgRight(e);
      this.formItems.orgCode = this.deptid[this.deptid.length -1];
    },

    getArchiveParam(e) {
      for(var i = 0; i< this.archiveList.length; i++) {
        if(e.value = this.archiveList[i].id) {
          this.formItems.archiveId = e.value;
          this.formItems.oldOrgCode = this.archiveList[i].orgCode;
          this.formItems.oldWhId = this.archiveList[i].whId;
          this.formItems.oldMcId = this.archiveList[i].mcId;
          break;
        }
      }
    },
    remoteMethod1 (query) {
      
      if (query !== ''||this.formItems.archiveId) {
        if(query == this.formItems.archiveId) {
          query ='';
        }
        this.remoteLoading = true;
        setTimeout(() => {
          this.remoteLoading = false;
          this.$get({
        url: "/smartarchives/api/archives/queryArchives", data: { pageSize: 50,transferFlag: "0",id:this.formItems.archiveId,fileName: query}, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.archiveList = res.dataList
          }
        }
      })
          // this.options1 = this.archiveList.filter(item => item.fileName.toLowerCase().indexOf(query.toLowerCase()) > -1);
          }, 200);
      } else {
          this.archiveList = [];
      }
    },
  }
};
</script>
    
<style scoped lang='less'></style>