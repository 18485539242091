import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    baiduAk:"e8bPzXOTIiWMQrb4GkfsjqStIYMZkwxy",//百度AK
    tiandituAk:"ac1005cdd1699ee577937e793a93ee0b",//天地图AK
    portal:{},
  },
  mutations: {
    portalInfo(state,payload){
      state.portal = payload
    },
  },
  actions: {},
  modules: {},
});
