/**
 * 分页读取数据：创新一个分页对象
 * 引入依赖：import {pageList} from "@/utils/pageList";
 * 创建： this.orderList=pageList(this.$get);可多个，建议在 created()创建
 * 读取数据：this.orderList.loadData(option); 
 * 数据应用：this.orderList.dataList
 * 分页信息：this.orderList.pageParams
 */
export const pageList = (request) => {
    return {
        pageParams: {
            maxCount: 0,//数据总条数
            currentPage: 0,//当前页(默认第一页)
            pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
            maxPage: 1,//总页数
            sort: "id",//排序字段
            order: "desc",//排序方式
            keyword: "",//通用查询条件
        },
        pagesizeOpts: [20, 30, 50, 100],//分页数组
        dataList: [],//数据集
        loading: false,
        loadData(option = {
            url: "",
            currentPage: null,
            data: {},
            header: {},
            success: () => { },
            fail: () => { },
            complete: () => { }

        }) {
            if (this.loading) return;

            if (option.currentPage != null)
                this.pageParams.currentPage = option.currentPage;
            else
                this.pageParams.currentPage += 1;

            this.loading = true;
            request({
                url: option.url,
                data: { ...this.pageParams, ...option.data },
                success: (res) => {

                    if (res.code == "200") {
                        if (res.pageParams) this.pageParams = { ...this.pageParams, ...res.pageParams };
                        this.dataList = res.dataList;
                    }
                    if (option.success) option.success(res);
                },
                fail: () => {
                    if (option.fail) option.fail();
                },
                complete: () => {
                    this.loading = false;
                    if (option.complete) option.complete();
                }
            })
        },
    }
}