<template>
  <Card :bordered="false" v-if="reShow">
    <template #title>水位平均值：{{ waterLevelAvg }}米</template>
    <template #extra>
      <div
        style="width: 300px;overflow: hidden;top:-8px;position: relative;cursor: pointer;padding:4px;text-align: center;background-color: #fff;border-radius: 5px;"
        @click="showCalendar">
        <span style="float:left;font-size: 15px;">{{ statisticsDate || "选择监测日期" }}</span>
        <Icon type="md-calendar" size="20" style="float:right;" />
      </div>
      <div style="width: 300px;height:300px;z-index: 1000;position: absolute;" v-show="showCld">
        <Calendar ref="calendar" @hideCalendar="hideCalendar" @choseDay="onChangeStatisticsDate"
          @changeMonth="changeMonth" :markDate="markDate">
        </Calendar>
      </div>
    </template>
    <template>
      <div id="eChartsZoom" style="width:100%;height:450px;"></div>
    </template>
  </Card>
</template>

<script>
import * as echarts from 'echarts';
import Calendar from "../../calendar/calendar.vue"
export default {
  components: {
    Calendar
  },
  data() {
    return {
      id: 0,
      data: [],
      statisticsDate: "",
      waterLevelAvg: "-",
      showCld: false,
      markDate: [],
      reShow:false,
    };
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
    showChart(id, statisticsDate){
      this.reShow=false;
      this.$nextTick(()=>{
        this.reShow=true;
        this.loadData(id, statisticsDate);
      })
    },
    loadData(id, statisticsDate) {
      this.$Message.loading({
        content: '正在加载中...',
        duration: 0
      });
      this.id = id;
      this.statisticsDate = statisticsDate;
      if (id) {
        this.$get({
          url: "/smartcovers/object/queryObjectCover",
          data: { id: id, statisticsDate: statisticsDate, listFlag: "1" },
          success: (res) => {
            this.tableData = []
            if (res.code == "200" && res.dataList) {
              this.data = res.dataList[0]

              setTimeout(() => {
                var xAxis = [];
                var data = [];
                var data0 = [];
                var data1 = [];
                var data2 = [];
                var data3 = [];
                var waterLevelAvg = 0;
                var waterLevelCount = 0;
                this.data.coverStatistics && this.data.coverStatistics.map(ss => {
                  xAxis.push(ss.coverName)
                  var color = "#0D94AA"
                  var width = 4
                  if (((Number(ss.waterLevel1) || 0) >= (ss.depth || 200) - 30) || ((Number(ss.waterLevel2) || 0) >= (ss.depth || 200) - 30) || ((Number(ss.waterLevel3) || 0) >= (ss.depth || 200) - 30)) {
                    color = "#EA1A1A"
                    width = 10
                  }
                  if (ss.waterLevel1) {
                    waterLevelAvg += Number(ss.waterLevel1)
                    waterLevelCount++
                  }
                  if (ss.waterLevel2) {
                    waterLevelAvg += Number(ss.waterLevel2)
                    waterLevelCount++
                  }
                  if (ss.waterLevel3) {
                    waterLevelAvg += Number(ss.waterLevel3)
                    waterLevelCount++
                  }
                  data0.push({ value: Number(ss.depth) || "", })
                  data1.push({ value: Number(ss.waterLevel1) || "", })
                  data2.push({ value: Number(ss.waterLevel2) || "", })
                  data3.push({ value: Number(ss.waterLevel3) || "", })
                })
                data = [{
                  name: "井深", data: data0
                }, {
                  name: this.data.time1 ? this.data.time1 + "时" : "水位1", data: data1
                }, {
                  name: this.data.time2 ? this.data.time2 + "时" : "水位2", data: data2
                }, {
                  name: this.data.time3 ? this.data.time3 + "时" : "水位3", data: data3
                },]
                var avg = Math.ceil(waterLevelAvg / waterLevelCount);
                if (!isNaN(avg)) {
                  this.waterLevelAvg = avg
                } else {
                  this.waterLevelAvg = '-'
                }

                this.createChart('eChartsZoom', { xAxis: xAxis, data: data })
                this.$Message.destroy()
              }, 1000);
            } else {
              this.$Message.destroy()
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },
    /**
    * 获取选项数据
    */
    loadBaseData() {
    },
    submitData(callback) {
    },

    showCalendar() {
      this.showCld = !this.showCld;
      this.changeMonth(this.$core.formatDate(new Date(this.$refs.calendar.myDate), "yyyy-MM-dd"))
      event.stopPropagation();
    },
    hideCalendar() {
      this.showCld = false;
    },
    changeMonth(data) {
      var date = this.$core.formatDate(new Date(data), "yyyy-MM");
      console.log(date);
      this.markDate = []
      this.$get({
        url: "/smartcovers/object/queryDataExist",
        data: { id: this.id, statisticsMonth: date },
        success: (res) => {
          if (res.code = "200" && res.dataList) {
            this.markDate = res.dataList;
          }
        }
      });
      event.stopPropagation();
    },
    onChangeStatisticsDate(e) {
      this.showCld = false;
      this.loadData(this.id, e)
    },
    createChart(id, data) {
      let ec = echarts.init(document.getElementById(id))
      var datas = [];
      var colors = ["#5cadff", "#ed4014", "#19be6b", "#ff9900", "#2db7f5"]
      data && data.data && data.data.map((item, idx) => {
        datas.push({
          name: item.name,
          symbol: '',
          sampling: 'lttb',
          // itemStyle: {
          //   color: colors[idx % 5]
          // },
          data: item.data,
          smooth: true,
          type: 'line'
        })
      })
      let option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          //data:data.data.map(item=>{return item.name})
        },
        grid: {
          top: 40,
          bottom: 20,
          left: 40,
          right: 10,
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            show: true,
            interval: 0,
            rotate: -0,
          },
          data: data.xAxis
        },
        yAxis: {
          type: 'value'
        },
        dataZoom: [
          {
            type: "inside",
            show: true,
            realtime: true,
            start: 0,
            end: parseInt(8 * 100 / data.xAxis.length),
            xAxisIndex: [0],
            filterMode: "none",
          }, {
            type: "slider",
            brushSelect: false,
          }],
        // label: {
        //   show: true,
        //   position: 'top'
        // },
        series: datas
      };
      ec.setOption(option);
    },
  }
};
</script>
    
   
<style scoped lang='less'>
/deep/.ivu-card-head {
  border-bottom: 1px solid #e8eaec;
  background-color: #17233d;
  color: #ffffff;
  padding: 14px 16px;
  line-height: 1;
  transform: none;
}
</style>