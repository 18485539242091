<template>
    <div class="search-view">
        <Card class="search-view-content" :padding="0">
            <template #title><b>档案信息</b></template>
            <Form class="search-view-form" ref="form" :model="formItems" label-colon :rules="ruleItems" :label-width="150">
                <FormItem label="档案编号" prop="archiveNum">
                    {{ formItems.archiveNum }}
                </FormItem>
                <FormItem label="文件名称" prop="fileName">
                    {{ formItems.fileName }}
                </FormItem>
                <FormItem label="全宗号" prop="fullFileNum">
                    {{ formItems.fullFileNum }}
                </FormItem>
                <FormItem label="全宗名称" prop="fullFileName">
                    {{ formItems.fullFileName }}
                </FormItem>
                <FormItem label="归档编号" prop="archivingNum">
                    {{ formItems.archivingNum }}
                </FormItem>
                <FormItem label="文件总数" prop="filesTotal">
                    {{ formItems.filesTotal }}个
                </FormItem>
                <FormItem label="文件总大小" prop="filesSizeTotal">
                    {{ convertSize(formItems.filesSizeTotal) }}
                </FormItem>
                <FormItem label="绑定库房" prop="whId">
                    {{ formItems.whName }}
                </FormItem>
                <FormItem label="绑定机柜" prop="mcId">
                    {{ formItems.mcName }}

                </FormItem>
                <FormItem label="所属科室" prop="orgCode">
                    {{ orgCascader.convectOrgName(formItems.orgCode) }}

                </FormItem>
                <FormItem label="档案类型" prop="archiveType">
                    {{ convertName("archiveTypeList", formItems.archiveType) }}
                </FormItem>
                <FormItem label="密级" prop="secretLevel">
                    {{ convertName("secretLevelList", formItems.secretLevel) }}
                </FormItem>
                <FormItem label="档案年份" prop="archiveYear">
                    {{ formItems.archiveName }}
                </FormItem>
                <FormItem label="保管期限" prop="storageLife">
                    {{ formItems.archiveName }}
                </FormItem>
                <FormItem label="存储位置" prop="storageLocation">
                    {{ formItems.archiveName }}
                </FormItem>
                <FormItem label="用地面积" prop="landArea">
                    {{ formItems.archiveName }}
                </FormItem>
                <FormItem label="地址" prop="address">
                    {{ formItems.archiveName }}
                </FormItem>
                <FormItem label="管理人员" prop="manager">
                    {{ formItems.archiveName }}
                </FormItem>
            </Form>
        </Card>
        <Card class="seach-view-files" :padding="0">
            <template #title><b>文件列表</b></template>
            <div class="searcher-items">
                <div class="searcher-items-item" v-for="(item, index) in formItems.files" :key="'file' + index">
                    <div><span class="searcher-item-title" @click="openView(item)"><span
                                style="color:#888;margin-right:10px;">{{ index + 1 }}</span>{{ item.fileName }}</span></div>
                    <div class="searcher-item-desc">
                        <div class="searcher-desc-item">文件编号：{{ item.fileNum || "-" }}</div>
                        <div class="searcher-desc-item"><span style="color:#ccc;"> | </span>文件格式：{{ item.fileFormat }}</div>
                        <div class="searcher-desc-item"><span style="color:#ccc;"> | </span>文件大小：{{ convertSize(item.fileSize) }}</div>
                        <div class="searcher-desc-item"><span style="color:#ccc;"> | </span>页码范围：{{ item.pageRange || "-" }}
                        </div>
                        <div class="searcher-desc-item"><span style="color:#ccc;"> | </span>时间节点：{{ item.timeNode || "-" }}
                        </div>
                    </div>
                </div>
            </div>
        </Card>
        <FNCModal ref="viewModal" :title="viewTitle" :fullscreen="false" width="80%" height="750px">
                <template v-slot:contentarea>
                    <div style="margin:-10px;border-radius: 10px;">
                        <FNCOfficePreview :src="filePath" height="748px" :toolbar="true" :download="false" :print="true"
                            :isAuthorize="true" @error="() => { }" @rendered="() => { }"></FNCOfficePreview>
                    </div>
                </template>
            </FNCModal>
    </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
import { orgCascader } from "@/utils/orgCascader"
import FNCOfficePreview from "@/components/FNCOfficePreview"
export default {
    components: {
        FNCModal,FNCOfficePreview
    },
    props: {
        archiveTypeList: Array,
        secretLevelList: Array,
    },
    data() {
        return {
            id: "",
            formItems: {},
            filePath:null,
            viewTitle:"",
        }
    },
    created() {
        this.orgCascader = orgCascader("dept");
        this.orgCascader.loadData();
    },
    mounted() {

    },
    methods: {
        loadData(data) {
            this.formItems = data;
            this.$forceUpdate();
            this.buryingpoint(2,data.id,null);
        },
        convertName(target, value) {
            return (this[target].find(item => item.value == value) || { name: "-" }).name;
        },
        convertSize(size) {
            if (!size) {
                return "-";
            } else if (size < 1024) {
                return size + "B";
            } else if (size < 1024 * 1024) {
                return (size / (1024)).toFixed(3) + "KB";
            } else {
                return (size / (1024 * 1024)).toFixed(3) + "MB";
            }
        },
        openView(data){
            this.filePath = data.filePath;
            this.viewTitle=data.fileName;
            this.$refs.viewModal.showModal();
            this.buryingpoint(2,data.archiveId,data.id);
        },
        buryingpoint(bpType,archiveId,fileId){
            this.$post({
                url: "/smartarchives/api/search/buryingpoint",
                data: {
                    bpType: bpType,
                    archiveId: archiveId,
                    fileId: fileId,
                    terminal:"PC",
                    bpFrom: "管理端"
                }
            })
        },
    },
}
</script>
    
<style scoped lang='less'>
.search-view {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    background: #dcdee2;

    .search-view-content {
        width: 40%;
        margin: 10px;
        overflow: hidden;
    }

    .seach-view-files {
        width: 60%;
        height: calc(100% - 20px);
        margin: 10px;
        overflow: hidden;
    }

    .search-view-form {
        width: 100%;
        height: 630px;
        overflow: auto;
        padding: 20px 0;
    }


    .searcher-items {
        width: 100%;
        height: 630px;
        overflow: auto;
        padding: 20px;
        display: flex;
        flex-direction: column;

        .searcher-items-item {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin: 10px 0;

            .searcher-item-title {
                font-size: 16px;
                color: #2b85e4;
                cursor: pointer;

                &:hover {
                    color: #ed4014;
                }
            }

            .searcher-item-title-kw {
                font-size: 14px;
                color: #003064;
                cursor: pointer;
            }

            .searcher-item-desc {
                font-size: 14px;
                color: rgb(99, 117, 139);
                padding-left:18px;
                .searcher-desc-item {
                    float: left;
                    padding: 5px;
                }
            }
        }
    }
}
</style>