 <template>
  <!-- 样式组件 -->
  <div
    :class="
      'fnc-info-view'
    "
    v-if="!isParamComp"
  >
  <img src="/images/tabs.png"/>
  </div>
  <!-- 参数设置组件 -->
  <div class="fnc-info-params" v-else>
    <Form :label-width="100" ref="infoForm">
      <div style="margin:10px 20px;color:blue;">提示：页面可以通过传参数（lftabsCurrent=0,1,2...）来定位tab位置</div>
       <FormItem label="栏签栏名称">
        <Input
          v-model.trim="data.name"
        ></Input>
      </FormItem>
        <FormItem label="固定至顶部">
        <RadioGroup v-model="data.isFixed">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="阴影">
        <RadioGroup v-model="data.shadow">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
       <FormItem label="字体颜色">
          <ColorPicker
            v-model="data.textColor"
            recommend
             @on-active-change="(cl)=>{colorChange('textColor',cl)}"
          />
        </FormItem>
        <FormItem label="选中颜色">
          <ColorPicker
            v-model="data.sltTextColor"
            recommend
             @on-active-change="(cl)=>{colorChange('sltTextColor',cl)}"
          />
        </FormItem>
        <FormItem label="背景颜色">
          <ColorPicker
            v-model="data.backgroundColor"
            recommend
             @on-active-change="(cl)=>{colorChange('backgroundColor',cl)}"
          />
        </FormItem>
       
         <FormItem label="数据来源">
        <RadioGroup v-model="data.dataFrom">
          <Radio label="api">通过API或JSON数据获取</Radio>
          <Radio label="menu">直接获取当前栏目的子栏目列表</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="API地址" v-if="data.dataFrom != 'menu'">
        <Input
          v-model.trim="data.api"
          placeholder="api优先于固定JSON数据"
          type="textarea"
         :autosize="true"
        ></Input>
        <div style="color:blue">注：变量赋值参数的伪参数写法：abc=${def}</div>
      </FormItem>
     <FormItem label="响应参数互换" v-if="data.dataFrom != 'menu' && data.api">
        <Input
          v-model.trim="data.convertParams"
          type="textarea"
          rows="10"
         placeholder='响应参数互换是指：接口输出的参数与组件不一致，需要互换后才能在页面上正常显示。例：
{
  "新参数名称": "原参数名称，若参数不存在，则视为常量",
  "imageUrl":"image"
}
              '
        ></Input>
        <Button style="margin: 5px" type="success" @click="verifyJson('convertParams')"
          >格式化 / 验证JSON合法性</Button
        >
        <APIreadme/>
      </FormItem>
           <FormItem label="JSON数据"  v-if="data.dataFrom != 'menu' && !data.api">
        <Input
          v-model.trim="data.data"
          type="textarea"
          rows="15"
          placeholder='例：[
                {
                 title:标题
                 value:标题索引值
                 isJump:该tab为地址跳转模式
                 url:跳转模式需带URL参数
                 ...跳转模式其他参数，如menuCode
                }
              ]'
        ></Input>
        <Button style="margin: 5px" type="success" @click="verifyJson('data')"
          >格式化 / 验证JSON合法性</Button
        >
        <Button
          style="margin: 5px"
          type="info"
          @click="importDfData"
          >导入例子</Button
        >
      </FormItem>
    </Form>
  </div>
</template>

<script>
/**
 * import LFTabs from "./components/LFTabs";
 * 标签页组件
 */
import APIreadme from "./APIreadme";
export default {
  components: {APIreadme},
  props: {
    isParamComp: { type: Boolean, default: false }, //true为参数设置组件,false为样式组件
    data: {
      //组件数据，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
    target: {
      //组件对象，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      initData: {
        //初始化用的数据，每个组件需要具备
        type: "LFTabs",
        api: "",
        data: null,
        name: '',
        backgroundColor: '#ffffff',
        isFixed:false,
        dataFrom: "api",
        textColor:"#333333",
        sltTextColor:"#2D8CF0",
        shadow:false,
      },
      dfData: JSON.stringify(
        [{
          title:'',
          value:'',
          isJump:false,
          url:'',
        }],
        null,
        "\t"
      ),
    };
  },
  watch: {
    data: {   
      handler(item,oldItem){
       if(!item.name) item.name=this.$core.createID("SB");
        if(item.isFixed==null) item.isFixed=false;
      },
      immediate:true
    }
  },
  methods: {
    importDfData() {
      if (this.data.data) {
        this.$Modal.confirm({
          title: "提示",
          content: "编辑框已存在数据，是否覆盖？",
          onOk: (res) => {
            this.data.data = this.dfData;
          },
        });
      } else {
        this.data.data = this.dfData;
      }
    },
    verifyJson(target) {
      //验证JSON,每个组件需要具备
      try {
        if (!this.data[target]) {
          this.$Modal.warning({ title: "验证结果", content: "没有JSON数据。" });
        } else {
          var json = JSON.parse(this.data[target]);
          this.$Modal.success({ title: "验证结果", content: "JSON数据正确。" });
          this.data[target] = JSON.stringify(json, null, "\t");
        }
      } catch (e) {
        this.$Modal.error({
          title: "验证结果",
          content: "JSON数据有误，请检查。",
        });
      }
    },
    colorChange(tar,color) {
      this.data[tar] = color;
    },
  },
};
</script>
<style lang="less">
.fnc-info-view {
  width: 100%;
  margin: 5px 0;
  overflow: hidden;
  background-color: #eee;
  img{
    width: 100%;
  }
}
</style>