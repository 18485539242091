<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <!-- <FormItem label="档案名称" prop="fileName">
        <Input type="text" v-model="formItems.fileName" placeholder="档案名称"></Input>
      </FormItem> -->
      <FormItem label="备注" prop="reason">
        <Input type="text" v-model="formItems.reason" placeholder="备注"></Input>
      </FormItem>
      
    </Form>
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
export default {
  components: {
    FNCModal, 
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: 0,
        status:null,
        reason:"",
  
      }),
      formItems: {},
      ruleItems: {
      
      
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,
    };
  },
  created() {

  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
     loadData(id,auditStatus) {
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.formItems.id = id;
        this.formItems.status = auditStatus;
        // this.$get({
        //   url: "/smartarchives/api/destroyaudit/queryDestroyauditDetail",
        //   data: { id: id },
        //   success: (res) => {
        //     if (res.code == "200" && res.data) {
        //       this.formItems = res.data;
        //     } else {
        //       this.$Message.error(res.desc || "读取数据出错");
        //       this.$emit("closeModal");
        //     }
        //   }
        // });
      }
    },

    /**
    * 获取选项数据
    */
    loadBaseData() {
      
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          this.$post({
            url: "/smartarchives/api/destroyaudit/updateDestroyaudit",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    
  }
};
</script>
    
<style scoped lang='less'></style>