<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="手机号码" prop="mobile">
        <Input type="text" v-model.trim="formItems.mobile" @on-blur="loadDataByMobile" :disabled="id?true:false" placeholder="输入手机号码"></Input>
      </FormItem>
      <FormItem label="登录密码" prop="password">
        <Input type="text" v-model="formItems.password" :placeholder="(formItems.id ? '不修改密码则留空' : '输入8位或上密码')"></Input>
      </FormItem>
      <FormItem label="姓名" prop="realname">
        <Input type="text" v-model="formItems.realname" placeholder="输入姓名"></Input>
      </FormItem>
      <FormItem label="性别" prop="sex">
        <RadioGroup v-model="formItems.sex">
            <Radio label="男">
              男
            </Radio>
            <Radio label="女">
              女
            </Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="身分证号" prop="idcard">
        <Input type="text" v-model="formItems.idcard" placeholder="输入18位身分证号码"></Input>
      </FormItem>
      <FormItem label="联系地址" prop="address">
        <Input type="text" v-model="formItems.address" placeholder="输入联系地址"></Input>
      </FormItem>
      <FormItem label="部门" prop="dept">
        <Cascader v-model="dept" change-on-select clearable :data="depts" v-width="100" />
        
      </FormItem>
      <FormItem label="启用标识" prop="idisplay">
        <RadioGroup v-model="formItems.idisplay">
            <Radio :label="1">
              启用
            </Radio>
            <Radio :label="0">
              禁用
            </Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="绑定身份" prop="roleid">
        <CheckboxGroup v-model="roleid">
            <Checkbox v-for="item in roles" :label="item.value" :key="item.value">{{ item.name }}</Checkbox>
        </CheckboxGroup>
      </FormItem>
     
    </Form>
    
  </div>
</template>

<script>
export default {
  components: {
  },
  props:{
    depts:[]
  },
  data() {
    return {
      id:null,
      formItemsDefault: JSON.stringify({
        id: "",
        idisplay: "1",
        sex: "",
        address:"",
        branchid:"",
        deptid:"",
        password:"",
        email:"",
        globalid:"",
        mobile:"",
        realname:"",
        roleid:"",
        managelevel:0,
        username:"",
        idcard:"",
      }),
      roleid:[],
      formItems: {},
      ruleItems: {
        mobile: [
          { required: true, message: '请填写手机号码', trigger: 'blur' }
        ],
        realname: [
          { required: true, message: '请填写姓名', trigger: 'blur' }
        ],

      },
      roles: [],
      mobile:"",
      dept:[],
    };
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
     loadData(id) {
      this.id=id;
      this.mobile="";
      this.roleid=[]
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.$get({
          url: "/gateway/api/manage/users/view",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = {...this.formItems,...res.data,};
              this.mobile=this.formItems.mobil
              this.dept=this.convectCascader(this.formItems.deptid);
              var roleid=this.formItems.roleid.split(",");
              roleid.map(item=>{
                this.roleid.push(Number(item))
              })
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },
    //级联值转换
    convectCascader(deptid,i,depts){
      depts=depts||[];
      i=i||0;
      i=i+3;
      if(deptid.length>=i){
        depts.push(deptid.substring(0,i));
        if(deptid.length>=i+3){
          depts = this.convectCascader(deptid,i,depts)
        }
      }
      return depts;
    },
    loadDataByMobile() {
      if(this.formItems.mobile==this.mobile) return;
      if(this.id) return;
      this.mobile=this.formItems.mobile
      this.roleid=[]
      this.formItems.id="";
      this.formItems.globalid="";
      this.formItems.username="";
      if (this.formItems.mobile) {
        this.$get({
          url: "/gateway/api/manage/users/mgr/view",
          data: { mobile: this.formItems.mobile },
          success: (res) => {
            if (res.code == "200" && res.data&& res.data.id) {
              this.formItems = res.data;
              var roleid=this.formItems.roleid&&this.formItems.roleid.split(",")||[];
              roleid.map(item=>{
                this.roleid.push(Number(item))
              })
              this.$Message.success({content:"读取到用户信息，将基于现有信息修改。",duration:3})
            }
          }
        });
      }
    },
    /**
    * 获取选项数据
    */
     loadBaseData() {
      this.$get({
        url: "/gateway/api/manage/users/m/rolelist", success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.roles = res.dataList
          }
        }
      })
  
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let regMobile = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
          if (!regMobile.test(this.formItems.mobile)) {
            this.$Message.error({ background: true, content:"输入的手机号不正确"})
            return;
          }
          if(!this.formItems.id&&!this.formItems.password){
            this.$Message.error({ background: true, content:"请登入密码"})
            return;
          }
          if(!this.roleid||this.roleid.length==0){
            this.$Message.error({ background: true, content:"请绑定身份"})
            return;
          }
          this.formItems.roleid=this.roleid.join(",")
          this.formItems.deptid=this.dept&&this.dept.length==0?"":this.dept[this.dept.length-1];
          callback(true)
          this.$post({
            url: "/gateway/api/manage/users/m/save",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" +res.desc,duration:res.desc?5:2})
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
  }
};
</script>
    
<style scoped lang='less'></style>