<template>
    <div :class="maxBtn ? 'middleground-main-black' : 'middleground-main'">
        <div class="middleground">
            <div class="header">
                <div class="header-col-1">
                    <div class="header-col-4">实时监测：{{ nowdate }}</div>
                </div>
                <div class="header-col-1">
                    <div class="header-col-3">
                        <div class="header-col-3-title">{{ isFull ? title : "" }}</div>
                    </div>
                </div>
                <div class="header-col-1">
                    <div class="header-col-5">
                        <Icon :type="maxBtn ? 'md-contract' : 'md-expand'" size="30" @click="fullscreenHandle" style="cursor: pointer" />
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="content-items">
                    <div class="content-item">
                        <div class="content-item-title">实时服务工单</div>
                        <Carousel class="content-item-carousel content-item-carousel-content" :autoplay="false" loop :autoplay-speed="10000" arrow="never" dots="none">
                            <CarouselItem v-for="i in listData(1)" :key="'order' + i">
                                <List>
                                    <ListItem :class="'ivulistitem-' + ((index % 2) + 1)" v-for="(item, index) in listData(2, i)" :key="'order' + i + '-' + index">
                                        <ListItemMeta>
                                            <template #avatar></template>
                                            <template #title>
                                                <Icon type="ios-radio-button-on" :class="item.status == 1 ? 'ivulistitem-wram' : 'ivulistitem-green'" />
                                                <b>[{{ item.orderType == "2" ? "购买" : "维修" }}] {{ item.brandName }}</b>
                                            </template>
                                            <template #description>
                                                <div v-if="item.imei" style="line-height: 1.4em">
                                                    <b>IMEI：</b>
                                                    {{ item.imei }}
                                                </div>
                                                <div style="line-height: 1.4em">
                                                    <b>时间：</b>
                                                    {{ item.createTime }}
                                                </div>
                                            </template>
                                        </ListItemMeta>
                                        <template #action>
                                            <!-- <li><div :class="item.status==1?'items-content-online':'items-content-offline'" @click="gotoOrder">
                           {{ checkOrderStatus(item.status) }}
                          </div></li> -->

                                            <li>
                                                <div
                                                    :class="item.status == 1 ? 'items-content-online' : 'items-content-offline'"
                                                    @click="
                                                        () => {
                                                            gotoOrderDetail(item.id)
                                                        }
                                                    "
                                                >
                                                    {{ checkOrderStatus(item.status) }}
                                                </div>
                                            </li>
                                        </template>
                                    </ListItem>
                                </List>
                            </CarouselItem>
                        </Carousel>
                        <div class="content-charts-title">7天工单数汇总</div>
                        <div id="eCharts1" class="eCharts-small"></div>
                    </div>
                </div>
                <div class="content-items content-items-center">
                    <div class="content-item content-item-center">
                        <div id="tdtMap" class="map-view"></div>
                        <div class="center-total">
                            <div style="cursor: pointer" @click="gotoDevicestocks">
                                <i-circle class="i-circle" :size="180" :trail-width="8" :stroke-width="10" :percent="75" stroke-linecap="round" trail-color="#c5c8ce" stroke-color="#2db7f5">
                                    <div>
                                        <h2 class="i-circle-name">{{ totalData.deviceCount }}</h2>
                                        <p class="i-circle-name">设备总数</p>
                                    </div>
                                </i-circle>
                            </div>
                            <div style="cursor: pointer" @click="gotoDevicestocks">
                                <i-circle class="i-circle" :size="180" :trail-width="8" :stroke-width="10" :percent="60" stroke-linecap="round" trail-color="#c5c8ce" stroke-color="#19be6b">
                                    <h2 class="i-circle-name">{{ totalData.deviceBindCount }}</h2>
                                    <p class="i-circle-name">设备绑定数</p>
                                </i-circle>
                            </div>
                            <div
                                style="cursor: pointer"
                                @click="
                                    () => {
                                        gotoDevicepush('')
                                    }
                                "
                            >
                                <i-circle class="i-circle" :size="180" :trail-width="8" :stroke-width="10" :percent="80" stroke-linecap="round" trail-color="#c5c8ce" stroke-color="#ff9900">
                                    <h2 class="i-circle-name">{{ totalData.warnCount }}</h2>
                                    <p class="i-circle-name">报警总数</p>
                                </i-circle>
                            </div>

                            <div style="cursor: pointer" @click="gotoOrder">
                                <i-circle class="i-circle" :size="180" :trail-width="8" :stroke-width="10" :percent="50" stroke-linecap="round" trail-color="#c5c8ce" stroke-color="#ed4014">
                                    <h2 class="i-circle-name">{{ totalData.orderCount }}</h2>
                                    <p class="i-circle-name">工单总数</p>
                                </i-circle>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-items">
                    <div class="content-item">
                        <div class="content-item-title">实时报警记录</div>
                        <Carousel class="content-item-carousel content-item-carousel-content" :autoplay="false" loop :autoplay-speed="11000" arrow="never" dots="none">
                            <CarouselItem v-for="i in listData(3)" :key="'warm' + i">
                                <List>
                                    <ListItem :class="'ivulistitem-' + ((index % 2) + 1)" v-for="(item, index) in listData(4, i)" :key="'warm' + i + '-' + index">
                                        <ListItemMeta>
                                            <template #avatar></template>
                                            <template #title>
                                                <Icon
                                                    type="ios-radio-button-on"
                                                    :class="'ivulistitem-' + (!item.faultStatus && !item.dismantleStatus && !item.warnStatus && !item.lowbatteryStatus ? 'green' : 'wram')"
                                                />
                                                <b>{{ item.deviceName }}</b>
                                            </template>
                                            <template #description>
                                                <div
                                                    style="cursor: pointer"
                                                    @click="
                                                        () => {
                                                            gotoDevicepush(item.imei)
                                                        }
                                                    "
                                                >
                                                    <div style="clear: both">
                                                        <div style="float: left">
                                                            <b>IMEI：</b>
                                                            {{ item.imei }}
                                                        </div>
                                                        <div style="float: left; margin-left: 20px">
                                                            <b>时间：</b>
                                                            {{ item.pushTime }}
                                                        </div>
                                                    </div>
                                                    <div class="custom-foot-items-state">
                                                        <div class="items-content-online" v-if="item.faultStatus == 1">故障</div>
                                                        <div class="items-content-online" v-if="item.dismantleStatus == 1">拆卸</div>
                                                        <div class="items-content-online" v-if="item.warnStatus == 1">报警</div>
                                                        <div class="items-content-online" v-if="item.lowbatteryStatus == 1">电量低</div>
                                                        <div class="items-content-offline" v-if="!item.faultStatus && !item.dismantleStatus && !item.warnStatus && !item.lowbatteryStatus">
                                                            恢复正常
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </ListItemMeta>
                                    </ListItem>
                                </List>
                            </CarouselItem>
                        </Carousel>
                        <div class="content-charts-title">7天报警数汇总</div>
                        <div id="eCharts2" class="eCharts-small"></div>
                    </div>
                </div>
            </div>
        </div>

        <FNCModal ref="editModal" title="工单详情" :fullscreen="false" width="800px" height="600px">
            <template v-slot:contentarea>
                <Form ref="form" :label-width="150">
                    <FormItem label="品牌名称" prop="brandName">
                        <label>{{ formItems.brandName }}</label>
                    </FormItem>
                    <FormItem label="申请时间" prop="createTime">
                        <label>{{ formItems.createTime }}</label>
                    </FormItem>
                    <FormItem label="类型" prop="orderType">
                        <label>{{ formItems.orderTypeName }}</label>
                    </FormItem>
                    <FormItem label="工单备注" prop="orderRemark">
                        <label>{{ formItems.orderRemark }}</label>
                    </FormItem>
                    <FormItem label="工单状态" prop="status">
                        <Select v-model="formItems.status">
                            <Option v-for="item in orderStatus" :value="item.value" :key="'status' + item.name">{{ item.name }}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="处理备注" prop="serviceRemark">
                        <Input type="textarea" v-model="formItems.serviceRemark" placeholder="输入处理备注" rows="6"></Input>
                    </FormItem>
                </Form>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" style="margin-right: 8px" @click="closeModal">
                    <Icon type="md-close" />
                    取消
                </Button>
                <Button type="primary" @click="submitData" :loading="saveLoading">
                    <Icon type="md-checkmark-circle" />
                    保存
                </Button>
            </template>
        </FNCModal>
    </div>
</template>

<script>
//路由定义：@ROUTER=/smokemiddleground

import FNCModal from "@/components/FNCModal"
import * as echarts from "echarts"
export default {
    components: {
        FNCModal,
    },

    data() {
        return {
            title: "智慧烟感数据中台",
            center: { lat: 22.159479, lng: 111.807864 },
            BMap: null,
            map: null,
            userLocation: {},
            zoom: 16,
            activeed: {},
            dataList: [],
            pointsArr: [],
            maxBtn: false,
            nowdate: "",
            repairRecord: [],
            warmRecord: [],
            warm_i: 0,
            coverActived: {},
            totalData: {},
            objectCoverData: { i: 0, currObjectName: "", dataList: [] },
            orderStatus: [],
            dataSmokeList: [],
            isFull: false,

            saveLoading: false,
            formItemsDefault: JSON.stringify({
                id: null,
                orderType: "",
                orderRemark: "",
                status: "",
                branchId: null,
                orderTypeName: "",
            }),
            formItems: {},
            ruleItems: {
                // orderType: [
                //   { required: true, message: '类型不能为空', trigger: 'change' }
                // ],
            },
            selectedLocation: {},
            pageCenter: {},
            keyword: "",
            orderTypes: [],
        }
    },
    created() {
        // $(document).attr("title", this.title);
        setInterval(() => {
            this.nowdate = this.$core.formatDate(new Date(), "yyyy-MM-dd HH:mm:ss")
        }, 1000)
        this.loadBaseData()
    },
    mounted() {
        //地图加载
        this.initTiandituMap()
    },
    destroyed() {},
    methods: {
        async initTiandituMap() {
            //地图加载
            this.map = await this.$core.createTiandituMap("tdtMap", this.$store.state.tiandituAk, this.center, this.zoom)
            this.loadTimeOutData()
            // this.map.addEventListener("moveend", this.moveend)
            // this.moveend();
            // this.queryMapDeviceShow()
        },
        fullscreenHandle() {
            this.toggleFullScreen()
            this.maxBtn = !this.maxBtn
            if (this.maxBtn) {
                this.map.setStyle("indigo")
            } else {
                this.map.removeStyle()
            }
        },
        loadBaseData() {
            this.$core.getStyleParamList("smartsmoke_orderStatus", false, "", res => {
                this.orderStatus = res || []
            })
            this.$core.getStyleParamList("smartsmoke_orderType", false, null, res => {
                this.orderTypes = res || []
            })
        },
        listData(i, j) {
            if (i == 1) {
                return Math.ceil(this.repairRecord.length / (this.maxBtn ? 8 : 6))
            } else if (i == 2) {
                return this.repairRecord.slice((j - 1) * (this.maxBtn ? 8 : 6), j * (this.maxBtn ? 8 : 6))
            } else if (i == 3) {
                return Math.ceil(this.warmRecord.length / (this.maxBtn ? 6 : 5))
            } else if (i == 4) {
                return this.warmRecord.slice((j - 1) * (this.maxBtn ? 6 : 5), j * (this.maxBtn ? 6 : 5))
            }
        },
        loadTimeOutData() {
            this.loadData()
            setTimeout(this.loadTimeOutData, 60 * 1000)
        },
        loadData() {
            this.$get({
                url: "/smartsmoke/statistics/bigScreenStatistics",
                data: { pageSize: 30 },
                success: res => {
                    if (res.code == "200" && res.data) {
                        this.totalData = res.data
                        this.warmRecord = res.data.deviceRecordList
                        this.repairRecord = res.data.orderList
                        this.dataSmokeList = res.data.deviceAddressList
                        setTimeout(() => {
                            if (res.data.orderWeek) {
                                var xAxis = []
                                var data = []
                                res.data.orderWeek &&
                                    res.data.orderWeek.map(ss => {
                                        xAxis.push(ss.date && ss.date.length > 5 ? ss.date.substring(5) : ss.date)
                                        data.push(ss.num)
                                    })
                                this.createChart("eCharts1", "line", { xAxis: xAxis, data: data }, 0)
                            }
                            if (res.data.deviceRecordWeek) {
                                var xAxis = []
                                var data = []
                                res.data.deviceRecordWeek &&
                                    res.data.deviceRecordWeek.map(ss => {
                                        xAxis.push(ss.date && ss.date.length > 5 ? ss.date.substring(5) : ss.date)
                                        data.push(ss.num)
                                    })
                                this.createChart("eCharts2", "line", { xAxis: xAxis, data: data }, 0)
                            }
                        }, 1000)
                    }
                },
                complete: () => {
                    this.moveend()
                },
            })
        },
        queryMapDeviceShow() {
            if (this.dataSmokeList.length > 0) {
                var i = this.warm_i
                if (i > this.dataSmokeList.length - 1) i = 0
                var cover = this.dataSmokeList[i]
                // this.coverActived = cover.id
                this.center = { lat: cover.lat, lng: cover.lng }
                if (this.map) {
                    let lnglat = this.$core.convertBDToTianditu(this.center.lat, this.center.lng)
                    this.map.centerAndZoom(new T.LngLat(lnglat.lng, lnglat.lat))
                }
                this.warm_i = i + 1
                // setTimeout(this.queryMapDeviceShow, 10000);
            } else setTimeout(this.queryMapDeviceShow, 1000)
        },
        convertObject(target, inFeild, outFeild, value) {
            var object = this[target].find(item => value == item[inFeild])
            return object ? object[outFeild] : "无"
        },

        createChart(id, type, data, showType, param) {
            let ec = echarts.init(document.getElementById(id))
            let option = {
                grid: {
                    top: 20,
                    bottom: 40,
                    left: 40,
                    right: 20,
                    ...(param && param.grid),
                },
                xAxis: {
                    type: showType == 1 ? "value" : "category",
                    axisLabel: {
                        show: true,
                        interval: 0,
                        rotate: 0,
                        lineStyle: { color: "#888888" },
                        ...(param && param.xAxisLabel),
                    },
                    axisLine: {
                        lineStyle: { color: "#888888" },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#333333",
                            width: showType == 1 ? 1 : 0,
                        },
                    },
                    data: data.xAxis,
                },
                yAxis: {
                    type: showType == 1 ? "category" : "value",
                    axisLabel: {
                        show: true,
                        interval: 0,
                        rotate: 0,
                        ...(param && param.yAxisLabel),
                    },
                    axisLine: {
                        lineStyle: { color: "#888888" },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#888888",
                            width: showType == 1 ? 0 : 1,
                        },
                    },
                    data: data.xAxis,
                },
                series: [
                    {
                        symbol: "none",
                        sampling: "lttb",
                        itemStyle: {
                            color: "#ed4014",
                        },
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: "#ed4014",
                                },
                                {
                                    offset: 1,
                                    color: "#ff9900",
                                },
                            ]),
                        },
                        data: data.data,
                        smooth: true,
                        type: type,
                    },
                ],
            }
            ec.setOption(option)
        },

        moveend(e) {
            if (this.map) {
                this.map.clearOverLays()
                if (this.dataSmokeList) {
                    var maxLngLat = { lng: 0, lat: 0 }
                    var minLngLat = { lng: 180, lat: 90 }
                    var initCenter = { heartbeatTime: 0 }

                    this.coverActived=null;
                    this.coverActivedLabel=null;

                    this.dataSmokeList.map(item => {
                        try {
                            let lnglat = this.$core.convertBDToTianditu(item.lat, item.lng)
                            //最新的预警设备
                            if (this.checkStatus(item) == 2 && (item.heartbeatTime||0)  - (initCenter.heartbeatTime||0) >= 0) {
                                initCenter = { ...lnglat, heartbeatTime: item.heartbeatTime||0 }
                            }

                            //获取最大最小经纬度
                            minLngLat = { lng: Math.min(lnglat.lng || 0, maxLngLat.lng), lat: Math.min(lnglat.lat || 0, maxLngLat.lat) }
                            maxLngLat = { lng: Math.max(lnglat.lng || 0, maxLngLat.lng), lat: Math.max(lnglat.lat || 0, maxLngLat.lat) }

                            var label = new T.Label({
                                text: this.getMapText(item),
                                position: new T.LngLat(Number(lnglat.lng), Number(lnglat.lat)),
                                offset: new T.Point(0, 0),
                            })
                            label.addEventListener("click", e => {
                                //修改之前的标签
                                var oldItem = this.dataSmokeList.find(item2 => item2.id == this.coverActived)
                                this.coverActived = item.id
                                // console.log(this.coverActived,oldItem);
                                if (oldItem && this.coverActivedLabel) {
                                    this.coverActivedLabel.setZindex(this.checkStatus(oldItem) == 2 ? 1002 : 1000)
                                    $(this.coverActivedLabel.Ow).empty()
                                    $(this.coverActivedLabel.Ow).append($(this.getMapText(oldItem)))
                                }
                                //更新当前的标签
                                this.coverActivedLabel = label
                                this.coverActivedLabel.setZindex(1003)
                                $(this.coverActivedLabel.Ow).empty()
                                $(this.coverActivedLabel.Ow).append($(this.getMapText(item)))
                            })
                            this.map.addOverLay(label)
                            //让层居顶显示
                            // if (this.coverActived == item.id) {
                            //     label.setZindex(1003)
                            // } else {
                            //     label.setZindex(this.checkStatus(item) == 2 ? 1002 : 1000)
                            // }
                            label.setZindex(this.checkStatus(item) == 2 ? 1002 : 1000)

                        } catch (ex) {}
                    })

                    // var initCenter = {
                    //     lng: (maxLngLat.lng + minLngLat.lng) / 2,
                    //     lat: (maxLngLat.lat + minLngLat.lat) / 2,
                    //     zoom: this.getZoom(maxLngLat.lng, minLngLat.lng, maxLngLat.lat, minLngLat.lat),
                    // }
                    // // console.log(initCenter);
                    // this.map.centerAndZoom(new T.LngLat(initCenter.lng || 0, initCenter.lat || 0), initCenter.zoom)

                    if (!initCenter.lng) {
                        initCenter = this.$core.convertBDToTianditu(this.dataSmokeList[0].lat, this.dataSmokeList[0].lng)
                    }
                    this.map.centerAndZoom(new T.LngLat(initCenter.lng || 0, initCenter.lat || 0))
                }
            } else {
                setTimeout(this.moveend, 1000)
            }

            // if (e && e.target) this.zoom = e.target.getZoom()
            // if (this.map) {
            //     this.map.clearOverLays()
            //     var bounds = this.map.getBounds() //获取地图可视区域
            //     var sw = bounds.getSouthWest() //获取西南角的经纬度(左下端点)
            //     var ne = bounds.getNorthEast() //获取东北角的经纬度(右上端点)
            //     if (this.dataSmokeList) {
            //         this.dataSmokeList.map(item => {
            //             try {
            //                 let lnglat = this.$core.convertBDToTianditu(item.lat, item.lng)
            //                 if (Number(lnglat.lng) >= sw.lng && Number(lnglat.lat) >= sw.lat && Number(lnglat.lng) <= ne.lng && Number(lnglat.lat) <= ne.lat) {
            //                     var label = new T.Label({
            //                         text:
            //                             '<div class="marker-div"><img class="' +
            //                             (this.coverActived == item.id ? "marker-img-active" : "marker-img") +
            //                             '" src="' +
            //                             (this.checkStatus(item)==1 ? "./images/map-overlay-offline.png" :(this.checkStatus(item)==2 ? "./images/map-overlay-warm.png":"./images/map-overlay.png")) +
            //                             '"><div class="' +
            //                             (this.coverActived == item.id ? (this.checkStatus(item)==1 ? "marker-name" :(this.checkStatus(item)==2 ? "marker-name-active":"marker-name-green")) : (this.checkStatus(item)==2 ? "marker-name-active" :"marker-name-hide")) +
            //                             '">IMEI：' +
            //                             item.imei +
            //                             "<br>店铺：" +
            //                             (item.shopName||"-") +
            //                             "<br>地址："+
            //                             item.address+
            //                             "</div></div>",
            //                         position: new T.LngLat(Number(lnglat.lng), Number(lnglat.lat)),
            //                         offset: new T.Point(0, 0),
            //                     })
            //                     this.map.addOverLay(label)
            //                     //让层居顶显示
            //                     if (this.coverActived == item.id) {
            //                         $(label.Ow).css("z-index", "2")
            //                     } else {
            //                         $(label.Ow).css("z-index", "1")
            //                     }
            //                 }
            //             } catch (ex) {}
            //         })
            //     }
            // }
        },
        getMapText(item) {
            return (
                '<div class="marker-div"><img class="' +
                (this.coverActived == item.id ? "marker-img-active" : "marker-img") +
                '" src="' +
                (this.checkStatus(item) == 1 ? "./images/map-overlay-offline.png" : this.checkStatus(item) == 2 ? "./images/map-overlay-warm.png" : "./images/map-overlay.png") +
                '"><div class="' +
                (this.coverActived == item.id
                    ? this.checkStatus(item) == 1
                        ? "marker-name"
                        : this.checkStatus(item) == 2
                        ? "marker-name-active"
                        : "marker-name-green"
                    : this.checkStatus(item) == 2
                    ? "marker-name-active"
                    : "marker-name-hide") +
                '">IMEI：' +
                item.imei +
                "<br>店铺：" +
                (item.shopName || "-") +
                "<br>地址：" +
                item.address +
                "</div></div>"
            )
        },
        getZoom(maxJ, minJ, maxW, minW) {
            if (maxJ == minJ && maxW == minW) return 10
            var diff = maxJ - minJ
            if (diff < (maxW - minW) * 2.1) diff = (maxW - minW) * 2.1
            diff = parseInt(10000 * diff) / 10000

            var zoomArr = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13)
            var diffArr = new Array(180, 90, 45, 22, 11, 5.5, 2.75, 1.37, 0.68, 0.34, 0.17, 0.08, 0.04)

            for (var i = 0; i < diffArr.length; i++) {
                if (diff - diffArr[i] >= 0) {
                    return zoomArr[i]
                }
            }
            return 14
        },
        // 在全屏与非全屏之间来回切换
        toggleFullScreen(d) {
            if (this.isFullScreen()) {
                this.isFull = false
                this.exitFullScreen()
            } else {
                this.isFull = true
                this.fullScreen()
            }
        },

        /**实现F11全屏效果*/
        fullScreen() {
            if (this.isFullScreen()) return
            var docElm = document.documentElement
            /*W3C*/
            if (docElm.requestFullscreen) {
                docElm.requestFullscreen()
            } /*FireFox */ else if (docElm.mozRequestFullScreen) {
                docElm.mozRequestFullScreen()
            } /*Chrome等 */ else if (docElm.webkitRequestFullScreen) {
                docElm.webkitRequestFullScreen()
            } /*IE11*/ else if (docElm.msRequestFullscreen) {
                docElm.msRequestFullscreen()
            }
        },
        /**退出F11全屏*/
        exitFullScreen() {
            if (!this.isFullScreen()) return
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen()
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
            }
        },
        /**判断是否全屏*/
        isFullScreen() {
            return document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement ? true : false
        },
        /**判断全屏模式是否是可用*/
        isFullscreenEnabled() {
            return document.fullscreenEnabled || document.mozFullScreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled || false
        },
        /**判断整个页面被一个标签铺满*/
        isFullscreenForNoScroll() {
            var explorer = window.navigator.userAgent.toLowerCase()
            if (explorer.indexOf("chrome") > -1) {
                /*webkit*/
                return document.body.scrollHeight === window.screen.height && document.body.scrollWidth === window.screen.width
            } else {
                /*IE 9+  fireFox*/
                return window.outerHeight === window.screen.height && window.outerWidth === window.screen.width
            }
        },
        /**实现局部div、dom元素全屏*/
        fullScreenForDOM(sel) {
            typeof sel == "string" && (sel = document.querySelector(sel))
            /**el是具体的dom元素*/ var rfs = sel.requestFullScreen || sel.webkitRequestFullScreen || sel.mozRequestFullScreen || sel.msRequestFullScreen,
                wscript
            if (typeof rfs != "undefined" && rfs) {
                rfs.call(sel)
                return
            }
            if (typeof window.ActiveXObject != "undefined") {
                wscript = new ActiveXObject("WScript.Shell")
                if (wscript) {
                    wscript.SendKeys("{F11}")
                }
            }
        },
        exitFullScreenForDOM(sel) {
            typeof sel == "string" && (sel = document.querySelector(sel))
            /**el是具体的dom元素*/ var el = document,
                cfs = sel.cancelFullScreen || sel.webkitCancelFullScreen || sel.mozCancelFullScreen || sel.exitFullScreen,
                wscript
            if (typeof cfs != "undefined" && cfs) {
                cfs.call(el)
                return
            }
            if (typeof window.ActiveXObject != "undefined") {
                wscript = new ActiveXObject("WScript.Shell")
                if (wscript != null) {
                    wscript.SendKeys("{F11}")
                }
            }
        },
        checkStatus(item) {
            if (!item.preStatus) {
                return 1
            }
            if (item.faultStatus == 1 || item.dismantleStatus == 1 || item.warnStatus == 1 || item.lowbatteryStatus == 1) {
                return 2
            } else {
                return 3
            }
        },
        checkOrderStatus(value) {
            var object = this.orderStatus.find(item => value == item.value)
            return object ? object.name : "无"
        },
        gotoOrder() {
            top.vue.goto("/serviceorder?functionid=0503")
        },
        gotoOrderDetail(id) {
            this.formItems = JSON.parse(this.formItemsDefault)
            if (id) {
                this.$get({
                    url: "/smartsmoke/serviceorder/queryServiceorderDetail",
                    data: { id: id },
                    success: res => {
                        if (res.code == "200" && res.data) {
                            this.formItems = res.data
                            this.formItems.status = res.data.status + ""

                            if (this.formItems.orderType == "1") {
                                this.formItems.orderTypeName = "维修"
                            } else if (this.formItems.orderType == "2") {
                                this.formItems.orderTypeName = "新购"
                            }
                        } else {
                            this.$Message.error(res.desc || "读取数据出错")
                            this.$emit("closeModal")
                        }
                    },
                })
            }

            this.$refs.editModal.showModal()
        },
        gotoDevicepush(imei) {
            top.vue.goto("/devicepush?functionid=0504&keyword=" + imei)
        },
        gotoDevicestocks() {
            top.vue.goto("/devicestocks?functionid=0502")
        },

        closeModal() {
            this.$refs.editModal.hideModal()
        },

        submitData() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    this.$post({
                        url: "/smartsmoke/serviceorder/createOrUpdateServiceorder",
                        data: { ...this.formItems },
                        success: res => {
                            if (res.code == "200") {
                                this.$Message.success({ background: true, content: "保存成功" })
                                this.closeModal()
                            } else {
                                this.$Message.error({ background: true, content: res.desc || "保存出错" })
                            }
                        },
                        complete: () => {},
                    })
                } else {
                    this.$Message.error({ background: true, content: "部分必填项没填写" })
                }
            })
        },
    },
}
</script>
    
<style scoped lang='less'>
.middleground-main {
    width: 100%;
    min-width: 1820px;
    height: 100vh;
    font-size: 16px;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;

    .middleground {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .header {
            position: fixed;
            z-index: 11;
            width: 100%;
            height: 66px;
            background-color: #eef9ff;
            box-shadow: inset 0px -7px 7px -7px #99caff;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .header-col-1 {
                padding: 0 20px;
                display: flex;
                font-size: 18px;
            }

            .header-col-2 {
            }

            .header-col-3 {
                font-size: 30px;
                padding: 10px 20px;
                letter-spacing: 0.3em;
                display: flex;
                text-align: center;
                flex-direction: column;

                .header-col-3-title {
                    height: 45px;
                }

                .header-col-3-img {
                    position: relative;
                    margin-bottom: -41px;
                }
            }

            .header-col-4 {
                width: 300px;
            }

            .header-col-5 {
                width: 300px;
                text-align: right;
            }
        }

        .content {
            margin-top: 60px;
            width: 100%;
            height: calc(100% - 105px);
            min-height: 830px;

            .content-items {
                width: 450px;
                height: 100%;
                margin: 30px 10px 0 10px;
                float: left;
                display: flex;
                flex-direction: column;

                .content-item {
                    margin: 18px 0;
                    width: 100%;
                    height: 100%;
                    background-color: #f4faff;
                    color: #333;
                    box-shadow: inset 0px 0px 7px 0px #99caff;
                    border-radius: 5px;

                    .content-item-icon {
                        position: relative;
                        margin-top: -32px;
                        z-index: 0;
                    }

                    .content-charts-title {
                        position: relative;
                        margin: -15px 0 0 15px;
                        z-index: 1;
                        font-size: 16px;
                        color: #2d8cf0;
                        padding: 5px 0;
                        font-weight: bold;
                    }

                    .content-item-title {
                        width: 150px;
                        text-align: center;
                        font-weight: bold;
                        background-color: #2d8cf0;
                        color: #fff;
                        padding: 6px;
                        position: relative;
                        margin: -20px 0 0 15px;
                        z-index: 1;
                        border-radius: 5px;
                    }

                    .content-item-carousel {
                        width: calc(100% -40px);
                        margin: 10px 20px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        overflow: hidden;

                        .ivulistitem-1 {
                            background-color: #e2ebf4;
                            padding: 10px 10px;
                            color: #333;
                            border-radius: 4px;
                        }

                        .ivulistitem-2 {
                            background-color: #eff8ff;
                            padding: 10px 10px;
                            color: #333;
                            border-radius: 4px;
                            margin: 2px 0;
                        }

                        .ivulistitem-wram {
                            font-size: 18px;
                            color: #ff451a;
                            padding-right: 10px;
                        }

                        .ivulistitem-green {
                            font-size: 18px;
                            color: #00d2a4;
                            padding-right: 10px;
                        }

                        /deep/.ivu-list-split .ivu-list-item {
                            border-bottom: 0px;
                        }

                        /deep/.ivu-list-item-meta-description {
                            color: #333;
                            font-size: 14px;
                            line-height: 22px;
                        }

                        /deep/.ivu-list-item-meta-title {
                            font-weight: 500;
                            margin-bottom: 4px;
                            color: #333;
                            font-size: 16px;
                            line-height: 22px;
                        }

                        /deep/.ivu-list-item-action > li {
                            position: relative;
                            display: inline-block;
                            padding: 0 8px;
                            color: #333;
                            font-size: 16px;
                            line-height: 22px;
                            text-align: center;
                            cursor: pointer;
                        }
                    }

                    .content-item-carousel-small {
                        height: 280px;
                    }

                    .content-item-carousel-content {
                        height: calc(100% - 280px);
                    }
                }

                .content-item-center {
                    width: 100%;
                    height: 952px;

                    .center-total {
                        margin: 10px 0;
                        width: 100%;
                        display: flex;
                        justify-content: center;

                        .i-circle {
                            margin: 20px;

                            .i-circle-name {
                                line-height: 1.8em;
                            }
                        }
                    }
                }
            }

            .content-items-center {
                width: calc(100% - 960px);
            }
        }

        .eCharts-small {
            width: 100%;
            height: 230px;
            position: relative;
            top: 0px;
        }

        .eCharts-big {
            width: 100%;
            height: 440px;
            position: relative;
        }
    }

    .map-view {
        height: 550px;
        width: calc(100% - 8px);
        margin: 4px;
        z-index: 1;
        border-radius: 4px;

        /deep/.tdt-label {
            background: transparent;
            border: 0px;
            box-shadow: 0px 0px 0px #999;
            padding: 0px;
            margin: 10px 0 0 -10px;

            .marker-div {
                position: relative;
                display: flex;
                align-items: center;
                left: -20px;
                top: -36px;
                cursor: pointer;
                .marker-name-hide {
                    display: none;
                }
                .marker-name {
                    color: #fff;
                    background: #555;
                    border-radius: 2px;
                    padding: 5px 5px;
                    line-height: 1em;
                    white-space: nowrap;
                    font-size: 12px;
                    opacity: 0.9;
                    box-shadow: 1px 1px 2px #88888888;
                }
                .marker-name-active {
                    color: #fff;
                    background: #ea1e1e;
                    border-radius: 2px;
                    padding: 5px 5px;
                    line-height: 1em;
                    white-space: nowrap;
                    font-size: 12px;
                    opacity: 0.9;
                    box-shadow: 1px 1px 2px #a1000088;
                }
                .marker-name-green {
                    color: #fff;
                    background: #0c991f;
                    border-radius: 2px;
                    padding: 5px 5px;
                    line-height: 1em;
                    white-space: nowrap;
                    font-size: 12px;
                    opacity: 0.9;
                    box-shadow: 1px 1px 2px #0c991f88;
                }

                .marker-img {
                    width: 40px;
                    height: 40px;
                    transition: all 0.4s;
                }

                .marker-img-active {
                    width: 65px;
                    height: 65px;
                    transition: all 0.4s;
                    margin-left: -18px;
                    margin-top: -25px;
                }
            }
        }
    }

    .items-content-online {
        float: right;
        color: #fff;
        background-color: #fd0000;
        font-size: 12px;
        padding: 2px 10px;
        border-radius: 4px;
        margin-left: 3px;
    }

    .items-content-offline {
        float: right;
        color: #ffffff;
        background-color: #00c2b1;
        font-size: 12px;
        padding: 2px 10px;
        border-radius: 4px;
        margin-left: 3px;
    }

    .custom-foot-items-state {
        clear: both;
        font-size: 14px;
        color: #888;
        padding-top: 6px;
    }
}
//black
.middleground-main-black {
    width: 100%;
    min-width: 1820px;
    height: 100vh;
    font-size: 16px;
    color: #ffffff;
    background-color: #00133c;
    display: flex;
    justify-content: center;
    align-items: center;

    .middleground {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .header {
            position: fixed;
            z-index: 11;
            width: 100%;
            height: 66px;
            background-color: #000716;
            box-shadow: inset 0px -7px 7px -7px #00e4ff;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .header-col-1 {
                padding: 0 20px;
                display: flex;
                font-size: 18px;
            }

            .header-col-2 {
            }

            .header-col-3 {
                font-size: 30px;
                padding: 10px 20px;
                letter-spacing: 0.3em;
                display: flex;
                text-align: center;
                flex-direction: column;

                .header-col-3-title {
                    height: 45px;
                }

                .header-col-3-img {
                    position: relative;
                    margin-bottom: -41px;
                }
            }

            .header-col-4 {
                width: 300px;
            }

            .header-col-5 {
                width: 300px;
                text-align: right;
            }
        }

        .content {
            margin-top: 60px;
            width: 100%;
            height: calc(100% - 105px);
            min-height: 830px;

            .content-items {
                width: 450px;
                height: 100%;
                margin: 30px 10px 0 10px;
                float: left;
                display: flex;
                flex-direction: column;

                .content-item {
                    margin: 18px 0;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    background-color: #000716;
                    box-shadow: inset 0px 0px 7px 0px #00e4ff;
                    border-radius: 5px;

                    .content-item-icon {
                        position: relative;
                        margin-top: -32px;
                        z-index: 0;
                    }

                    .content-charts-title {
                        position: relative;
                        margin: -10px 0 0 15px;
                        z-index: 1;
                        font-size: 16px;
                        color: #2d8cf0;
                        padding: 5px 0;
                        font-weight: bold;
                    }

                    .content-item-title {
                        width: 150px;
                        text-align: center;
                        font-weight: bold;
                        box-shadow: inset 0px 0px 7px 0px #00e4ff;
                        background-color: #061941;
                        color: #fff;
                        padding: 6px;
                        position: relative;
                        margin: -20px 0 0 15px;
                        z-index: 1;
                        border-radius: 5px;
                    }

                    .content-item-carousel {
                        width: calc(100% -40px);
                        margin: 10px 20px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        overflow: hidden;

                        .ivulistitem-1 {
                            background-color: #000a32;
                            padding: 10px 10px;
                        }

                        .ivulistitem-2 {
                            background-color: #021a54;
                            padding: 10px 10px;
                        }

                        .ivulistitem-wram {
                            font-size: 12px;
                            color: #ff451a;
                            padding-right: 10px;
                        }

                        .ivulistitem-green {
                            font-size: 12px;
                            color: #5ff4d4;
                            padding-right: 10px;
                        }

                        /deep/.ivu-list-split .ivu-list-item {
                            border-bottom: 0px;
                        }

                        /deep/.ivu-list-item-meta-description {
                            color: rgb(210, 210, 210);
                            font-size: 14px;
                            line-height: 22px;
                        }

                        /deep/.ivu-list-item-meta-title {
                            font-weight: 500;
                            margin-bottom: 4px;
                            color: rgb(255, 255, 255);
                            font-size: 16px;
                            line-height: 22px;
                        }

                        /deep/.ivu-list-item-action > li {
                            position: relative;
                            display: inline-block;
                            padding: 0 8px;
                            color: rgb(255, 255, 255);
                            font-size: 16px;
                            line-height: 22px;
                            text-align: center;
                            cursor: pointer;
                        }
                    }

                    .content-item-carousel-small {
                        height: 280px;
                    }

                    .content-item-carousel-content {
                        height: calc(100% - 280px);
                    }
                }

                .content-item-center {
                    width: 100%;
                    height: 952px;

                    .center-total {
                        margin: 10px 0;
                        width: 100%;
                        display: flex;
                        justify-content: center;

                        .i-circle {
                            margin: 20px;

                            .i-circle-name {
                                line-height: 1.8em;
                            }
                        }
                    }
                }
            }

            .content-items-center {
                width: calc(100% - 960px);
            }
        }

        .eCharts-small {
            width: 100%;
            height: 220px;
            position: relative;
            top: 0px;
        }

        .eCharts-big {
            width: 100%;
            height: 440px;
            position: relative;
        }
    }

    .map-view {
        height: 700px;
        width: calc(100% - 8px);
        margin: 4px;
        z-index: 1;
        border-radius: 4px;

        /deep/.tdt-label {
            background: transparent;
            border: 0px;
            box-shadow: 0px 0px 0px #999;
            padding: 0px;
            margin: 10px 0 0 -10px;

            .marker-div {
                position: relative;
                display: flex;
                align-items: center;
                left: -20px;
                top: -36px;
                cursor: pointer;
                .marker-name-hide {
                    display: none;
                }
                .marker-name {
                    color: #555;
                    background: #eee;
                    border-radius: 2px;
                    padding: 5px 5px;
                    line-height: 1em;
                    white-space: nowrap;
                    font-size: 12px;
                    opacity: 0.9;
                    box-shadow: 1px 1px 2px #88888888;
                }
                .marker-name-active {
                    color: #fff;
                    background: #ea1e1e;
                    border-radius: 2px;
                    padding: 5px 5px;
                    line-height: 1em;
                    white-space: nowrap;
                    font-size: 12px;
                    opacity: 0.9;
                    box-shadow: 1px 1px 2px #a1000088;
                }
                .marker-name-green {
                    color: #fff;
                    background: #0c991f;
                    border-radius: 2px;
                    padding: 5px 5px;
                    line-height: 1em;
                    white-space: nowrap;
                    font-size: 12px;
                    opacity: 0.9;
                    box-shadow: 1px 1px 2px #0c991f88;
                }
                .marker-img {
                    width: 40px;
                    height: 40px;
                    transition: all 0.4s;
                }

                .marker-img-active {
                    width: 65px;
                    height: 65px;
                    transition: all 0.4s;
                    margin-left: -18px;
                    margin-top: -25px;
                }
            }
        }
    }

    .items-content-online {
        float: right;
        color: #fff;
        background-color: #fd0000;
        font-size: 12px;
        padding: 2px 10px;
        border-radius: 4px;
        margin-left: 3px;
    }

    .items-content-offline {
        float: right;
        color: #ffffff;
        background-color: #00c2b1;
        font-size: 12px;
        padding: 2px 10px;
        border-radius: 4px;
        margin-left: 3px;
    }

    .custom-foot-items-state {
        clear: both;
        font-size: 14px;
        color: #888;
        padding-top: 6px;
    }
}

/deep/.BMap_cpyCtrl {
    display: none !important;
}

/deep/.anchorBL {
    display: none !important;
}
</style>