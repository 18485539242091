<template>
    <FNCModal ref="mapModal" class="map" :fullscreen="false" width="80%" height="550px" title="选择经纬度">
        <template v-slot:contentarea>
            <div  v-if="isPickup"
                style="width:400px;position: absolute;margin:10px;z-index:90000;display:flex;background:#ffffff;padding:10px;border-radius:10px;">
                <Input v-model="keyword" style="width:100%;margin-right:5px;" placeholder="请输入地名"></Input><Button
                    type="primary" @click="setPlace(keyword)">查询</Button>
            </div>
            <div id="tdtMap" style="width: 100%; height: 470px; flex: 1"></div>
        </template>
        <template #toolsbar>
            <Button type="info" style="margin-right: 10px" @click="closeModal" >{{isPickup?"取消":"关闭"}}</Button>
            <Button type="primary" @click="selectMap" v-if="isPickup">确定</Button>
        </template>
    </FNCModal>
</template>
<script>
/**
 * import FNCMapCoordinatePickup from '@/components/FNCMapCoordinatePickup';
 * 渲染：<FNCMapCoordinatePickup ref="coordinatePickup" @close="onClose"></FNCMapCoordinatePickup>
 * 调用：this.$refs.coordinatePickup.open(oldLnglat, address,isPickup)
 * 需要引入注解@close="onClose"接收数据;onClose(lnglat, address){}
 */
import FNCModal from "@/components/FNCModal";
export default {
    components: {
        FNCModal,
    },
    data() {
        return {
            selectedLocation: {},
            pageCenter: { lat: 23.112223, lng: 113.331084 },
            keyword: "",
            zoom: 16,
            map: null,
            isPickup: true,//是否取坐标
        }
    },
    created() {
    },
    mounted() { },
    methods: {
        async initTiandituMap() {
            //地图加载
            if (!this.map) {
                this.map = await this.$core.createTiandituMap("tdtMap", this.$store.state.tiandituAk, this.pageCenter, this.zoom);
                if (this.isPickup == true) this.map.addEventListener("click", this.clickMap);
            }
        },  // 点击了地图
        clickMap(e) {
            var geocode = new T.Geocoder();
            geocode.getLocation(e.lnglat, (ee) => {
                this.makerCenter({ lng: e.lnglat.lng, lat: e.lnglat.lat, address: ee ? ee.formatted_address : null })
            });

        },
        makerCenter(point) {
            if (this.map) {
                this.selectedLocation = point;
                this.map.clearOverLays();
                var marker = new T.Marker(new T.LngLat(Number(point.lng), Number(point.lat)));
                this.map.addOverLay(marker)
            }
        },
       /**
         * 弹开地图拾取器
         * oldLnglat 原来的经纬度
         * address 原来的地址
         * isPickup 是否获取坐标值，默认获取
         */
        async open(oldLnglat, address, isPickup = true) {
            this.isPickup = isPickup;
            this.$refs.mapModal.showModal();
            await this.initTiandituMap();
            if (oldLnglat.lng) {
                this.keyword = "";
                this.pageCenter = oldLnglat;
                this.map.centerAndZoom(new T.LngLat(Number(this.pageCenter.lng), Number(this.pageCenter.lat)));
                this.makerCenter(this.pageCenter);
            } else if (address) {
                this.keyword = address;
                this.setPlace(address);
            }
        },
        closeModal() {
            this.$refs.mapModal.hideModal();
        },
        selectMap() {
            this.$emit("close", { lat: this.selectedLocation.lat, lng: this.selectedLocation.lng }, this.selectedLocation.address);
            this.$refs.mapModal.hideModal();
        },
        setPlace(myValue) {
            if (!myValue) return;
            var that = this
            var local = new T.LocalSearch(this.map, {
                onSearchComplete: (res) => {
                    try {
                        var pois = res.getPois();
                        if (pois && pois.length > 0 && pois[0]) {
                            var pp = pois[0].lonlat.split(",");
                            that.pageCenter = { lng: Number(pp[0]), lat: Number(pp[1]), address: pois[0].address }
                            that.map.centerAndZoom(new T.LngLat(that.pageCenter.lng, that.pageCenter.lat));
                            that.makerCenter(that.pageCenter)
                        }
                    } catch (e) { }
                },
            })
            local.search(myValue)
        },
    }
}
</script>