<template>
  <FNCContent>
    <template v-slot:title> {{ title }} </template>
    <template v-slot:toolsbarLeft>
      <!-- <Select v-model="pageParams.objectNum" style="width: 180px" placeholder="全部管线">
          <Option v-for="item in projects" :value="item.objectNum" :key="item.objectName">{{ item.objectName }}</Option>
        </Select> -->
        <Cascader v-model="deptid" @on-change="checkOrgRight" placeholder="全部服务主体" change-on-select clearable :data="depts" style="width:300px;"/>
        <Select v-model="pageParams.warmType" style="width: 120px" placeholder="全部状态">
          <Option v-for="item in warmType" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      <div style="width:250px;"><Input search enter-button="查询" clearable v-model="pageParams.keyword"
          placeholder="输入井盖名称/编号/地址" @on-search="loadData(1)" /></div>
    </template>
    <template v-slot:toolsbarRight>
      <!-- <div style="width:250px;">总报警状态量:{{pageParams.maxCount  }}</div> -->
      <div style="width:250px;font-size: 18px;color:#ff0000;margin-top:5px;">总报警状态量:{{count }}</div>
      <!-- <Button type="info" class="addDiv" @click="() => { editData() }" v-if="right.A">
        <Icon type="md-add-circle" size="18" />添加
      </Button> -->
<!-- 
      <Button type="warning" class="deleteDiv" @click="() => { deleteData() }" v-if="right.D">
        <Icon type="md-trash" size="18" />删除
      </Button> -->
      <Button type="primary" class="addDiv" @click="() => { exportDataWin() }" v-if="right.A">
        <Icon type="ios-download-outline" size="18" />导出
      </Button>

    </template>
    <template v-slot:contentArea>
      <FNCTable ref="fncTable" :loading="loading" :fixTable="true" :talbeColumns="talbeColumns" :tableData="tableData"
        :pageParams="pageParams" :pagesizeOpts="pagesizeOpts" @handleLoadData="loadData"></FNCTable>
      <FNCModal ref="editModal" title="编辑窗" :fullscreen="false" width="800px" height="600px">
        <template v-slot:contentarea>
          <Edit ref="editContent" @closeModal="closeModal"></Edit>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closeModal">
            <Icon type="md-close" />取消
          </Button>
          <Button type="primary" @click="submitData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />保存
          </Button>
        </template>
      </FNCModal>
      <FNCModal ref="captchaContent" title="导出验证" :fullscreen="false" width="250px" height="250px">
        <template v-slot:contentarea>

          <div style="display: flex;flex-direction: column; align-items: center; justify-content: center;">
              <span style="color:red">为确保数据安全，导出前请先输入验证码!</span>
              <img style="margin:20px 0;width: 200px; height: 50px;  cursor: pointer; border: 1px solid #ddd; border-radius: 2px"
              title="点击刷新" @click="randCode" :src="randcodeAction" />
              <Input placeholder="输入验证码" style="width: 200px" size="large" v-model="captcha" @on-enter="exportData"></Input>
          </div>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closecaptchaModal">
            <Icon type="md-close" />取消
          </Button>
          <Button type="primary" @click="exportData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />导出
          </Button>
        </template>
      </FNCModal>
    </template>
  </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/coverwarm

import FNCContent from "@/components/FNCContent";
import FNCTable from "@/components/FNCTable";
import FNCModal from "@/components/FNCModal";
import Edit from "./childrens/Edit";
import {orgCascader} from "@/utils/orgCascader";
export default {
  components: {
    FNCTable,
    FNCContent,
    FNCModal,
    Edit,
  },

  data() {
    return {
      title: "信息列表",
      loading: false,
      keyword:'',
      pageParams: {
        maxCount: 0,//数据总条数
        currentPage: 0,//当前页(默认第一页)
        pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
        maxPage: 1,//总页数
        sort: "id",//排序字段
        order: "desc",//排序方式
        keyword: "",//通用查询条件
        keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
        objectNum:"",
        orgCode:"",
      },
      pagesizeOpts: [20, 30, 50, 100],//分页数组
      tableData: [],
      talbeColumns: [
        // {
        //   type: 'selection',
        //   width: 60,
        //   fixed: "left",
        //   align: 'center'
        // },
        {
          title: "服务主体",
          key: "orgCode",
          align: "center",
          width: 200,
          render: (h, params) => {
            return h("div", this.orgCascader.convectCascaderName(params.row.orgCode)
            )
          },
        },
        {
          title: "井盖号",
          key: "coverOutNum",
          align: "center",
          width: 180,
        },
        {
          title: "井盖名称",
          key: "coverName",
          align: "center",
          width: 200,
        },
        {
          title: "地址",
          key: "address",
          align: "center",
          minWidth: 200,
        },
        {
          title: "报警时间",
          key: "createTime",
          align: "center",
          width: 180,
        },
        {
          title: "状态",
          key: "warmTypeName",
          align: "center",
          minWidth: 200,
          // render: (h, params) => {
          //   return h("div",this.convertObject("warmType","value","name",params.row.warmType))},
        },
        // {
        //   title: "所属管线",
        //   key: "objectNum",
        //   align: "center",
        //   width: 200,
        //   render: (h, params) => {
        //     return h("div",this.convertObject("projects","objectNum","objectName",params.row.objectNum))},
        // }, 
        // {
        //   title: "维修状态",
        //   key: "manholeCoverWarmStatusName",
        //   align: "center",
        //   width: 200,
        // },
        {
          title: "操作",
          slot: "action",
          width: 100,
          align: "center",
          render: (h, params) => {
            return h("div",
              [
                h(
                  "Button",
                  {

                    class: "editDiv"
                    ,
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      margin: "0 5px",
                    },

                    on: {
                      click: () => {
                        this.editData(params.row.id);
                      },
                    },
                  },
                  "去处理"
                ),
              ]);
          },
        },
      ],
      right: {},
      saveLoading: false,
      infoType: "",
      infoTypeList: [],
      manholeCoverStatus:[],
      projects:[],
      warmType:[],
      count:0,
      depts:[],
      deptid:[],
      captcha:"",
      randcodeAction: "./images/nopic.png", //验证码IMG base64
      captchaId: "", //验证码captchaId
    };
  },
  created() {
    this.orgCascader = orgCascader("dept");
    this.deptid=this.orgCascader.convectCascaderList(this.$core.getBranchUserInfo("deptid"));
    this.orgCascader.loadData(
      (depts) => {
        this.depts = depts;
      },this.deptid[this.deptid.length-1]);
    this.pageParams.orgCode = this.deptid[this.deptid.length -1];

    this.keyword=this.$core.getUrlParam("keyword");
    if(this.keyword){
      this.pageParams.keyword=this.keyword;
    }
  },
  mounted() {
    this.loadModuleInfo();
  },
  methods: {
     loadModuleInfo() {
      //读取栏目信息
       this.$get({
        url: "/gateway/api/manage/type/moduleinfo",
        data: {
          functionId: this.$core.getFunctionId(),
        },
        success: (res) => {
          if (res.code == "200" && res.data) {
            this.title = res.data.typename;
          }
        }
      });
      this.$core.getStyleParamList("manholeCoverStatus", true, "全部状态", (res) => {
        this.manholeCoverStatus = res || [{ name: "全部状态", value: "" }]
      })
      this.$core.getStyleParamList("warmType", true, "全部状态", (res) => {
        this.warmType = res || [{ name: "全部状态", value: "" }]
      })
      // this.$get({
      //   url: "/smartcovers/cover/queryCoverWardRecord", data: { pageSize: 1000 }, success: (res) => {
      //     if (res.code == "200" && res.dataList) {
      //       this.projects =[{ objectName: "全部管线", objectNum: "" }].concat(res.dataList);
      //     }
      //   }
      // })
      //读取数据列表
      this.loadData(1);
      this.loadCount();
      this.$core.getControlRight().then(res => {
        this.right = res;
      });
    },
    convertObject(target,inFeild,outFeild,value){
      var object=this[target].find(item=>value==item[inFeild])
      return object?object[outFeild]:"无"
    },
    loadData(currentPage) {
      if (currentPage) {
        this.pageParams.currentPage = currentPage;
      } else {
        this.pageParams.currentPage = this.pageParams.currentPage + 1;
      }
      this.loading = true;
      this.$get({
        url: "/smartcovers/cover/queryCoverWardRecord",
        data: this.pageParams,
        success: (res) => {
          this.tableData = []
          if (res.code == "200" && res.pageParams) {
            this.pageParams = {...this.pageParams,...res.pageParams}
            this.tableData = res.dataList
          } else {
            this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
          }
          this.loading = false;
        }
      })
    },
    loadCount() {
      this.$get({
        url: "/smartcovers/cover/queryCoverWardRecord",
        success: (res) => {
          if (res.code == "200" && res.pageParams) {
            this.count = res.pageParams.maxCount
          } 
        }
      })
    },
   
    editData(id) {
      if (id && this.right["E"] == true || !id && this.right["A"] == true) {
        this.$refs.editModal.showModal();
        this.$refs.editContent.loadData(id);
      }
    },
    closeModal() {
      this.$refs.editModal.hideModal();
    },
    submitData() {
      this.$refs.editContent.submitData((b, reload) => {
        this.saveLoading = b;
        if (reload == true) {
          this.loadData(1);
          this.closeModal();
        }
      });
    },
    getSelectedOrg(e) {
      var length = e.length
      this.pageParams.orgCode = e[length -1]
    },
    closecaptchaModal() {
      this.$refs.captchaContent.hideModal();
    },
    exportDataWin() {
      this.randCode();
      this.$refs.captchaContent.showModal();
    },

    exportData() {
      if(typeof this.pageParams.warmType === 'undefined') {
        this.pageParams.warmType ='';
      }
      top.location.href = this.$getProxy("/smartcovers") + "/cover/coverwarmExport?randKey=" + this.captchaId + "&randCode="+this.captcha+"&keyword=" + this.pageParams.keyword+"&warmType="+this.pageParams.warmType+"&orgCode="+this.pageParams.orgCode;
      this.$refs.captchaContent.hideModal();
    },

  randCode() {
      this.randcodeAction= "./images/nopic.png"; //验证码IMG base64
      this.captchaId="";
      this.$get({ url: "/gateway/oauth/captchaImage" }).then(res => {
        if (res && res.data && res.data != null) {
          this.captchaId=res.data.randKey;
          this.randcodeAction=res.data.image;
        }
      })
    },

    checkOrgRight(e){
      this.deptid=this.orgCascader.checkOrgRight(e);
      this.pageParams.orgCode = this.deptid[this.deptid.length -1]
    },
  }
};
</script>
    
<style scoped lang='less'></style>