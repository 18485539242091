<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="选择库房" prop="whId">
        <div style="width: 100%;display: flex;justify-content: flex-start;">
          <Select v-model="formItems.whId" @on-change="getMachinecabinetByWh" style="width: 180px;margin-right: 10px;">
            <Option v-for="item in warehouseList" :value="item.id" :key="item.whName">{{ item.whName }}
            </Option>
          </Select>
        </div>
      </FormItem>
      <FormItem label="选择机柜" prop="mcId">
        <div style="width: 100%;display: flex;justify-content: flex-start;">
          <Select v-model="formItems.mcId" style="width: 180px;float:left;margin-right: 10px;">
            <Option v-for="item in machinecabinetList" :value="item.id" :key="item.mcName">{{ item.mcName }}
            </Option>
          </Select>
        </div>
      </FormItem>
      <FormItem label="备注" prop="reason" >
          <Input type="textarea" v-model="formItems.reason" placeholder="退回原因" rows="4"></Input>
      </FormItem>
      <FormItem label="上传凭证" prop="receiveVoucher">
        <FNCUpload type="image" style="float:left" :showView="true" :defaultList="convertImages(formItems.receiveVoucher)"
          :showDel="true" @success="upLogoImage" multiple="true" fileLength="8">
        </FNCUpload>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
import FNCUpload from '@/components/FNCUpload';
export default {
  components: {
    FNCModal,
    FNCUpload,
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: 0,
      }),
      formItems: {},
      ruleItems: {
        // whId: [{ required: true, message: "请选择库房", trigger: "change" }],
        // mcId: [{ required: true, message: "请选择机柜", trigger: "change" }],
      },
      pageCenter: {},
      keyword: "",
      zoom: 16,
      machinecabinetList: [],
      warehouseList: [],
    };
  },
  created() {
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
    // loadData(id,status) {
    //   this.formItems = JSON.parse(this.formItemsDefault);
    //   if (id) {
    //     this.formItems.id = id;
    //     this.formItems.status = status;
    //   }
    // },
    loadData(row,status) {
      this.formItems = JSON.parse(this.formItemsDefault);
      if (row.id) {
        this.formItems.id = row.id;
        this.formItems.status = status;
        this.formItems.orgCode = row.orgCode;
        this.formItems.archiveId = row.archiveId;
        this.formItems.oldMcId = row.oldMcId;
      }
    },

    /**
    * 获取选项数据
    */
    loadBaseData() {
      // this.$get({
      //   url: "/smartarchives/api/archives/queryArchives", data: { pageSize: 1000, transferFlag: "0" }, success: (res) => {
      //     if (res.code == "200" && res.dataList) {
      //       this.archiveList = res.dataList
      //     }
      //   }
      // })
      this.$get({
        url: "/smartarchives/api/warehouse/client/queryWarehouse",
        data: {},
        success: res => {
          if (res.code == "200") {
            this.warehouseList = res.dataList
          }
        }
      })

    },
    getMachinecabinetByWh(whId, mcId) {
      this.formItems.mcId = mcId;
      this.$get({
        url: "/smartarchives/api/machinecabinet/client/queryMachinecabinetByWh",
        data: { whId: whId },
        success: res => {
          if (res.code == "200") {
            this.machinecabinetList = res.dataList
            this.$forceUpdate()
          }
        }
      })
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // if(this.formItems.status == 1) {
          //   if(this.formItems.whId == undefined ||this.formItems.mcId == undefined ) {
          //     this.$Message.error({ background: true, content: "请选择库房" })
          //     return false
          //   }
          // }
          // if(this.formItems.oldMcId == this.formItems.mcId) {
          //   this.$Message.error({ background: true, content: "机柜不能与原机柜相同" })
          //   return false
          // }
          callback(true)
          this.$post({
            url: "/smartarchives/api/transferauditReceive/updateTransferauditReceive",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    upLogoImage(file) {
      this.formItems.receiveVoucher = file.url;
    },
    convertImages(value) {
      try {
        return JSON.parse(value || "[]")
      } catch (e) {
        return []
      }
    },
  }
};
</script>
    
<style scoped lang='less'></style>