 <template>
  <!-- 参数设置组件 -->
  <div class="fnc-tabbar-params">
    <Form :label-width="100" ref="bannerForm">
      <FormItem label="API地址">
        <Input
          v-model.trim="data.api"
          placeholder="api优先于固定JSON数据"
          type="textarea"
          :autosize="true"
        ></Input>
        <div style="color:blue">注：变量赋值参数的伪参数写法：abc=${def}</div>
      </FormItem>
      <FormItem label="响应参数互换" v-if="data.api">
        <Input
          v-model.trim="data.convertParams"
          type="textarea"
          rows="10"
          placeholder='响应参数互换是指：接口输出的参数与组件不一致，需要互换后才能在页面上正常显示。例：
{
  "新参数名称": "原参数名称，若参数不存在，则视为常量",
  "imageUrl":"image"
}
              '
        ></Input>
        <Button
          style="margin: 5px"
          type="success"
          @click="verifyJson('convertParams')"
          >格式化 / 验证JSON合法性</Button
        >
        <APIreadme />
      </FormItem>
      <FormItem label="JSON数据" v-else>
        <Input
          v-model.trim="data.data"
          type="textarea"
          rows="22"
          placeholder='例：{
            "custom": false,
            "color": "#000000",
            "selectedColor": "#c62c24",
            "borderStyle": "#e2e2e5",
            "backgroundColor": "#f7f7fa",
            "list": [
              { 
                "text": "名称",
                "pagePath": "跳转地址，支持伪代码${}" ,
                menuCode:"页面定制的Code，根据需要可选填",
                "iconPath": "图标",
                "selectedIconPath": "选中图标",
                "openType":"switchTab"
              }
            ]
          }
          openType为打开方式：
            switchTab：跳转TAB，
            weappSwitchTab：跳转小程序TAB,一般指H5
            reLaunch:重LOAD
            navigateTo:弹开新页面
            redirectTo：重定向页面
            weappReLaunch:小程序重LOAD
            tabClick:当前页面的点击事件'
        ></Input>
        <Button style="margin: 5px" type="success" @click="verifyJson('data')"
          >格式化 / 验证JSON合法性</Button
        >
        <Button style="margin: 5px" type="info" @click="importDfData"
          >导入例子</Button
        >
      </FormItem>
      <FormItem label="颜色参考工具">
        <ColorPicker recommend v-model="color1" />
      </FormItem>
    </Form>
  </div>
</template>

<script>
/**
 * import Tabbar from "./components/Tabbar";
 * 底部导航
 */
import APIreadme from "./APIreadme";
export default {
  components: { APIreadme },
  props: {
    data: {
      //组件数据，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
    target: {
      //组件对象，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      color1: "#c62c24",
      dfData: JSON.stringify(
        {
          color: "#000000",
          selectedColor: "#c62c24",
          borderStyle: "#e2e2e5",
          backgroundColor: "#f7f7fa",
          list: [
            {
              text: "首页",
              pagePath: "apps/suiyue/pages/index/main",
              menuCode: "",
              iconPath: "apps/suiyue/images/suiyue.png",
              selectedIconPath: "apps/suiyue/images/suiyueActive.png",
              openType: "switchTab",
            },
            {
              text: "主页",
              pagePath: "apps/suiyue/pages/index/myhome",
              menuCode: "",
              iconPath: "apps/suiyue/images/myhome.png",
              selectedIconPath: "apps/suiyue/images/myhomeActive.png",
              openType: "switchTab",
            },
          ],
        },
        null,
        "\t"
      ),
    };
  },
  created() {},
  methods: {
    importDfData() {
      if (this.data.data) {
        this.$Modal.confirm({
          title: "提示",
          content: "编辑框已存在数据，是否覆盖？",
          onOk: (res) => {
            this.data.data = this.dfData;
          },
        });
      } else {
        this.data.data = this.dfData;
      }
    },
    verifyJson(target) {
      //验证JSON,每个组件需要具备
      try {
        if (!this.data[target]) {
          this.$Modal.warning({ title: "验证结果", content: "没有JSON数据。" });
        } else {
          var json = JSON.parse(this.data[target]);
          this.$Modal.success({ title: "验证结果", content: "JSON数据正确。" });
          this.data[target] = JSON.stringify(json, null, "\t");
        }
      } catch (e) {
        this.$Modal.error({
          title: "验证结果",
          content: "JSON数据有误，请检查。",
        });
      }
    },
  },
};
</script>
<style lang="less">
.fnc-tabbar-params {
  width: 100%;
  margin: 5px 0;
}
</style>