 <template>
  <!-- 头部主页面风格组件 -->
  <div class="fnc-navbar-params">
    <Form :label-width="150" ref="bannerForm">
       <FormItem label="显示标题">
        <RadioGroup v-model="data.showTitle">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
         <FormItem label="标题位置">
        <RadioGroup v-model="data.textAlign">
          <Radio label="left">居左</Radio>
          <Radio label="center">居中</Radio>
           <Radio label="right">居右</Radio>
        </RadioGroup>
      </FormItem>
       <FormItem label="组件在顶部固定">
        <RadioGroup v-model="data.compFixed">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
       <FormItem label="顶部以圆角结束">
        <RadioGroup v-model="data.radius">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="沉浸式圆角" v-if="data.radius">
        <RadioGroup v-model="data.radiusImmersion">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="沉浸式状态栏">
        <RadioGroup v-model="data.statusBarImmersion">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
    </Form>
  </div>
</template>

<script>
/**
 * import Header from "./components/Header";
 * 头部主页面风格
 */
export default {
  components: {},
  props: {
    data: {
      //组件数据，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
    target: {
      //组件对象，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
    };
  },
  created() {},
  methods: {
  },
};
</script>
<style lang="less">
.fnc-navbar-params {
  width: 100%;
  margin: 5px 0;
}
</style>