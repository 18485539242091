<template>
    <div class="search-body">
        <Button class="searcher-to-top" @click="goTop" size="large" icon="md-arrow-round-up" type="info"
            shape="circle"></Button>
        <div class="searcher">
            <div class="searcher-title">档案检索</div>
            <Input v-model="pageParams.keyword" @on-search="search" @on-clear="search" size="large" class="searcher-input"
                search autofocus clearable enter-button="   检索   " placeholder="输入档案关键字" />
        </div>
        <div class="searcher-result" v-if="hostorys.length > 0 || searchResults.length > 0 || keywords.length > 0">
            <div class="searcher-result-list" v-if="searchKey">
                <div class="searcher-result-title">
                    <Icon type="md-search" size="23" />检索结果
                </div>
                <div class="searcher-result-nothing" v-if="searchResults.length == 0">
                    系统检索不到关于“<span style="color:#ff0000cc;">{{ searchKey }}</span>”的相关档案
                </div>
                <div class="searcher-items" v-else>
                    <div class="searcher-items-item" v-for="(item, index) in searchResults" :key="'hostory' + index">
                        <div><span class="searcher-item-title" @click="openView(item)">{{ item.fileName ? "【" +
                            item.archiveNum + "】" + item.fileName :
                            item.archiveNum }}</span></div>
                        <div class="searcher-item-desc">
                            <div class="searcher-desc-item">位置：{{ item.whName }}/{{ item.mcName }}</div>
                            <div class="searcher-desc-item"><span style="color:#ccc;"> | </span>类型：{{
                                convertType(item.archiveType, "archiveTypeList") }}</div>
                            <div class="searcher-desc-item"><span style="color:#ccc;"> | </span>密级：{{
                                convertType(item.secretLevel, "secretLevelList") }}</div>
                            <div class="searcher-desc-item"><span style="color:#ccc;"> | </span>文件数：{{ item.filesTotal }}个
                            </div>
                        </div>
                    </div>
                    <Page style="float:right;margin:10px;" @on-change="currentPage" :current="pageParams.currentPage"
                        :page-size="pageParams.pageSize" :total="pageParams.maxCount"
                        v-if="searchResults.length < pageParams.maxCount" />
                </div>
            </div>
            <div class="searcher-hostory" v-else>
                <div class="searcher-result-title">
                    <Icon type="ios-timer" size="23" />您曾经看过的档案
                </div>
                <div class="searcher-nothing" v-if="hostorys.length == 0">
                    暂无历史阅览档案
                </div>
                <div class="searcher-items" v-else>
                    <div class="searcher-items-item" v-for="(item, index) in hostorys" :key="'hostory' + index">
                        <div><span class="searcher-item-title" @click="openView(item)">{{ item.fileName ? "【" +
                            item.archiveNum + "】" + item.fileName :
                            item.archiveNum }}</span></div>
                        <div class="searcher-item-desc">
                            <div class="searcher-desc-item">阅览次数：{{ item.readCount}}</div>
                            <div class="searcher-desc-item"><span style="color:#ccc;"> | </span>位置：{{ item.whName }}/{{ item.mcName }}</div>
                            <div class="searcher-desc-item"><span style="color:#ccc;"> | </span>类型：{{
                                convertType(item.archiveType, "archiveTypeList") }}</div>
                            <div class="searcher-desc-item"><span style="color:#ccc;"> | </span>密级：{{
                                convertType(item.secretLevel, "secretLevelList") }}</div>
                            <div class="searcher-desc-item"><span style="color:#ccc;"> | </span>文件数：{{ item.filesTotal }}个
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="searcher-sp">
            </div>
            <div class="searcher-keyword">
                <div class="searcher-result-title">
                    <Icon type="md-clock" size="23" />您最近检索关键字
                </div>
                <div class="searcher-nothing" v-if="keywords.length == 0">
                    暂无历史检索关键字
                </div>
                <div class="searcher-items" v-else>
                    <div class="searcher-items-item" style="margin: 5px 0;" v-for="(item, index) in keywords"
                        :key="'keywords' + index">
                        <div><span class="searcher-item-title searcher-item-title-kw" @click="search(item)"><span
                                    style="color:#999;padding-right:10px;">{{ (index + 1) }}</span>{{ item }}</span></div>
                    </div>
                </div>
            </div>
        </div>
        <FNCModal ref="viewModal" :title="viewTitle" :fullscreen="false" width="80%" height="700px">
            <template v-slot:contentarea>
                <div style="margin:-10px;border-radius: 10px;">
                <SearchView ref="viewArchiveModal" :archiveTypeList="archiveTypeList" :secretLevelList="secretLevelList"></SearchView>
            </div>
            </template>
        </FNCModal>
    </div>
</template>

<script>
//路由定义：@ROUTER=/search/index
import FNCModal from "@/components/FNCModal"
import SearchView from "./childrens/SearchView"
export default {
    components: {
        FNCModal,SearchView
    },

    data() {
        return {
            viewTitle: "",
            searchKey: "",
            hostorys: [],
            searchResults: [],
            keywords: [],
            archiveTypeList: [],
            secretLevelList: [],
            pageParams: {
                maxCount: 0,//数据总条数
                currentPage: 1,//当前页(默认第一页)
                pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
                maxPage: 1,//总页数
                keyword: "",//通用查询条件
            }
        }
    },
    created() {
        this.loadBaseData()
    },
    mounted() {
        this.setKeywords();
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $(".searcher-to-top").fadeIn();
            } else {
                $(".searcher-to-top").fadeOut();
            }
        });
    },
    beforeDestroy() {
    },
    methods: {
        goTop() {
            $('html, body').animate({ scrollTop: '0px' }, 100);
        },
        loadBaseData() {
            this.$core.getStyleParamList("archiveType", false, "", res => {
                this.archiveTypeList = res || []
            })
            this.$core.getStyleParamList("secretLevel", false, "", res => {
                this.secretLevelList = res || []
            })
        },
        convertType(value, name) {
            return (this[name].find(item => item.value == value) || {}).name;
        },
        search(e) {
            this.$Message.loading({
                content: "正在搜索...",
                duration: 0,
            });
            this.pageParams.keyword = e;
            this.$get({
                url: "/smartarchives/api/search/search",
                data: this.pageParams,
                success: (res) => {
                    if (res.code == "200") {
                        this.pageParams = { ...this.pageParams, ...res.pageParams }
                        this.searchResults = res.dataList
                    }
                },
                complete: () => {
                    this.goTop();
                    this.$Message.destroy();
                    this.searchKey = this.pageParams.keyword
                    this.setKeywords();
                }
            })
        },
        currentPage(e) {
            this.pageParams.currentPage = e;
            this.search(this.searchKey);
        },
        /**
         * 搜索历史
         */
        searchHostory() {
            this.hostorys = []
            this.$get({
                url: "/smartarchives/api/search/searchhostory",
                success: (res) => {
                    if (res.code == "200" && res.dataList) {
                        this.hostorys = res.dataList
                    }
                }
            })
        },
        openView(data) {
            this.viewTitle=data.fileName;
            this.$refs.viewModal.showModal();
            this.$refs.viewArchiveModal.loadData(data);
        },
        setKeywords() {
            var keywords = window.localStorage.searchKeywords;
            if (keywords) {
                keywords = JSON.parse(keywords);
            }
            keywords = keywords || [];
            if (this.searchKey) {
                keywords = keywords.filter(item => item != this.searchKey) || [];
                keywords = [this.searchKey, ...keywords];
                if (keywords.length > 15) keywords.length = 15;
                window.localStorage.setItem("searchKeywords", JSON.stringify(keywords));
            } else {
                this.searchHostory();
            }
            this.keywords = keywords;
        }
    },
}
</script>
  
<style scoped lang='less'>
.search-body {
    width: 100%;
    min-height: 100%;
    background: linear-gradient(to bottom right, rgb(210, 218, 228) 50%, rgb(226, 229, 238) 50%);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;

    .searcher {
        width: 100%;
        margin: 50px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .searcher-title {
            font-family: "Microsoft YaHei";
            font-weight: bold;
            font-size: 32px;
            color: rgb(99, 117, 139);
            letter-spacing: 5px;
        }

        .searcher-input {
            width: 650px;
            margin-top: 20px;
        }

    }

    .searcher-result {
        width: 80%;
        min-width: 1000px;
        min-height: 500px;
        padding: 20px;
        display: flex;
        flex-direction: row;
        background: #fff;
        border-radius: 16px;
        overflow: hidden;

        .searcher-result-title {
            font-size: 15px;
            color: #546996;
            font-weight: bold;
        }

        .searcher-nothing {
            font-size: 20px;
            color: #54699644;
            margin-top: 30px;
            text-align: left;
        }

        .searcher-result-nothing {
            font-size: 16px;
            color: #546996;
            margin-top: 50px;
            text-align: center;
        }

        .searcher-hostory {
            width: calc(100% - 500px);
            color: rgb(99, 117, 139);
        }

        .searcher-sp {
            width: 100px;
            overflow: hidden;
        }

        .searcher-keyword {
            width: 400px;
            color: rgb(99, 117, 139);


        }

        .searcher-result-list {
            width: calc(100% - 500px);
            color: rgb(99, 117, 139);
        }

        .searcher-items {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin: 10px 0;

            .searcher-items-item {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin: 10px 0;

                .searcher-item-title {
                    font-size: 18px;
                    color: #2b85e4;
                    cursor: pointer;

                    &:hover {
                        color: #ed4014;
                    }
                }

                .searcher-item-title-kw {
                    font-size: 14px;
                    color: #003064;
                    cursor: pointer;
                }

                .searcher-item-desc {
                    font-size: 14px;
                    color: rgb(99, 117, 139);

                    .searcher-desc-item {
                        float: left;
                        padding: 5px;
                    }
                }
            }
        }
    }

    .searcher-to-top {
        position: fixed;
        right: 20px;
        bottom: 50px;
        display: none;
    }
}
</style>