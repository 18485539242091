<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <!-- <FormItem label="imei" prop="imei">
        <Input type="text" v-model="formItems.imei" disabled style="width: 480px"></Input>
      </FormItem> -->
      <FormItem label="品牌名称" prop="brandName">
        <label>{{ formItems.brandName }}</label>
      </FormItem>
      <FormItem label="申请时间" prop="createTime">
        <label>{{ formItems.createTime }}</label>
        <!-- <Input type="text" v-model="formItems.createTime" disabled ></Input> -->
      </FormItem>
      <FormItem label="类型" prop="orderType">
        <label>{{ formItems.orderTypeName }}</label>
        <!-- <Select v-model="formItems.orderType" disabled>
          <Option v-for="item in orderTypes" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select> -->
      </FormItem>
      <FormItem label="工单备注" prop="orderRemark">
        <label>{{ formItems.orderRemark }}</label>
        <!-- <Input  type="textarea" v-model="formItems.orderRemark" placeholder="输入工单备注" rows="6" disabled></Input> -->
      </FormItem>
      <FormItem label="工单状态" prop="status">
        <Select v-model="formItems.status">
          <Option v-for="item in statusList" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="处理备注" prop="serviceRemark">
        <Input  type="textarea" v-model="formItems.serviceRemark" placeholder="输入处理备注" rows="6"></Input>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
export default {
  components: {
    FNCModal,
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: null,
        orderType:"",
        orderRemark:"",
        status:"",
        branchId:null,
        orderTypeName:"",
      }),
      formItems: {},
      ruleItems: {
        // orderType: [
        //   { required: true, message: '类型不能为空', trigger: 'change' }
        // ],
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,
      orderTypes: [],
      statusList: [],
    };
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
     loadData(id) {
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.$get({
          url: "/smartsmoke/serviceorder/queryServiceorderDetail",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
              this.formItems.status = res.data.status + "";

              if(this.formItems.orderType == '1') {
                this.formItems.orderTypeName = '维修';
              } else if(this.formItems.orderType == '2') {
                this.formItems.orderTypeName = '新购';
              }


            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },
    /**
    * 获取选项数据
    */
    loadBaseData() {
      this.$core.getStyleParamList("smartsmoke_orderType", false, null, (res) => {
        this.orderTypes = res || []
      })
      this.$core.getStyleParamList("smartsmoke_orderStatus", false, null, (res) => {
        this.statusList = res || []
      })

    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          this.$post({
            url: "/smartsmoke/serviceorder/createOrUpdateServiceorder",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    
    classModal() {
      this.$refs.mapModal.hideModal();
    },
    
  }
};
</script>
    
<style scoped lang='less'></style>