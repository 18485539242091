<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="模板名称" prop="tempName">
        <Input type="text" v-model="formItems.tempName" placeholder="请输入模板名称" ></Input>
      </FormItem>
      <FormItem label="模板代码" prop="tempId">
        <Input type="text" v-model="formItems.tempId" placeholder="请输入模板代码" ></Input>
        <!-- <Select v-model="formItems.tempId">
          <Option v-for="item in tempIdList" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select> -->
      </FormItem>
      <FormItem label="微信" prop="weixinTemp">
        <Input type="textarea" v-model="formItems.weixinTemp" placeholder="微信模板"  rows="14"></Input>
      </FormItem>
      <FormItem label="短信" prop="smsTemp">
        <Input type="textarea" v-model="formItems.smsTemp" placeholder="短信模板"  rows="3"></Input>
      </FormItem>
      <FormItem label="参数说明">
        <span>${touser}：消息接收人</span><br/>
        <span>${deviceName}：设备名称</span><br/>
        <span>${imei}：IMEI</span><br/>
        <span>${msgTime}：消息发送时间</span><br/>
        <span>${address}：设备绑定地址</span><br/>
        <span>${shopName}：店铺名称</span><br/>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
export default {
  components: {
    FNCModal,
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: null,
        brandId:"",
        clientId:"",
      }),
      formItems: {},
      ruleItems: {
        tempName: [
          { required: true, message: '模板名称不能为空', trigger: 'blur' }
        ],
        tempId: [
          { required: true, message: '模板代码不能为空', trigger: 'change' }
        ],
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,
      brands:[],
      tempIdList:[],
    };
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
     loadData(id) {
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.$get({
          url: "/gateway/api/messagepush/config/view",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
              this.formItems.clientId = this.$core.getPortalConfig("clientId")
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },
    /**
    * 获取选项数据
    */
    loadBaseData() {
      // this.$core.getStyleParamList("smartsmoke_messagepushTempId", false, null, (res) => {
      //   this.tempIdList = res || []
      // })

    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          this.formItems.clientId = this.$core.getPortalConfig("client_id")
          this.$post({
            url: "/gateway/api/messagepush/config/save",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    
    classModal() {
      this.$refs.mapModal.hideModal();
    },
    
  }
};
</script>
    
<style scoped lang='less'></style>