var domain = function (param) {
  if (process.env.NODE_ENV == 'development') {
    return param.test;
  }
  if (process.env.NODE_ENV == 'production') {
    return param.prod;
  }
}

//各子项目的接口配置
var paths={};
try{
const files = require.context("/src/views", true, /\/api\/proxy.js$/);
files.keys().map(item => {
  try {
    let path = require("@/views/" + item.substring(2));
    if (path) {
      paths = {...paths, ...path}
    } 
  } catch (e) { }
});
} catch (e) { }

module.exports = {
  "/gateway": {
    target: domain({
      test: "https://test.oauth.891tech.com",//测试
      // test: "https://test.oauth.891tech.com",//测试
      prod: "https://test.oauth.891tech.com",//生产
    }), //

    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/gateway": "/",//重写地址
    },
  },
  "/uploader": {
    target: domain({
      test: "http://localhost:8000/api/uploader",//测试
      // test: "https://test.oauth.891tech.com/api/uploader",//测试
      prod: "https://test.oauth.891tech.com/api/uploader",//生产
    }), //

    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/uploader": "/",//重写地址
    },
  },
  "/custom": {
    target: domain({
      test: "https://h5.smartsmoke.891tech.com",//测试
      prod: "https://h5.smartsmoke.891tech.com",//生产
    }), //

    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/custom": "/",//重写地址
    },
  },
  ...paths
}