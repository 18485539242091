<template>
  <FNCContent>
    <template v-slot:title> {{ title }} </template>
    <template v-slot:toolsbarLeft>
      <div style="width:220px;"><Input search enter-button="查询" clearable v-model="pageParams.keyword"
          placeholder="输入终端号(IMEI)" @on-search="loadData(1)" /></div>

      <Select style="width: 250px;margin:0 10px;" placeholder="选择排序" @on-change="handleChange">
        <Option v-for="item in sortList" :value="item.value" :key="item.name">{{ item.name }}</Option>
      </Select>

    </template>
    <template v-slot:toolsbarRight>
      <Button type="info" class="addDiv" @click="() => { editData() }" v-if="right.A">
        <Icon type="md-add-circle" size="18" />新增设备
      </Button>

      <!-- <Button type="primary" class="addDiv" @click="() => { exportData() }" v-if="right.A">
        <Icon type="ios-download-outline" size="18" />导出
      </Button> -->

      <Button type="primary" class="addDiv" @click="() => { exportDataWin() }" v-if="right.A">
        <Icon type="ios-download-outline" size="18" />导出
      </Button>

      <!-- <Button type="warning" class="deleteDiv" @click="() => { deleteData() }" v-if="right.D">
        <Icon type="md-trash" size="18" />删除
      </Button> -->

    </template>
    <template v-slot:contentArea>
      <FNCTable ref="fncTable" :loading="loading" :fixTable="true" :talbeColumns="talbeColumns" :tableData="tableData"
        :pageParams="pageParams" :pagesizeOpts="pagesizeOpts" @handleLoadData="loadData"></FNCTable>
      <FNCModal ref="editModal" title="设备编辑" :fullscreen="false" width="800px" height="600px">
        <template v-slot:contentarea>
          <Edit ref="editContent" @closeModal="closeModal"></Edit>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closeModal">
            <Icon type="md-close" />取消
          </Button>
          <Button type="primary" @click="submitData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />保存
          </Button>
        </template>
      </FNCModal>

      <FNCModal ref="captchaContent" title="导出验证" :fullscreen="false" width="250px" height="250px">
        <template v-slot:contentarea>

          <div style="display: flex;flex-direction: column; align-items: center; justify-content: center;">
              <span style="color:red">为确保数据安全，导出前请先输入验证码!</span>
              <img style="margin:20px 0;width: 200px; height: 50px;  cursor: pointer; border: 1px solid #ddd; border-radius: 2px"
              title="点击刷新" @click="randCode" :src="randcodeAction" />
              <Input placeholder="输入验证码" style="width: 200px" size="large" v-model="captcha" @on-enter="exportData"></Input>
          </div>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closecaptchaModal">
            <Icon type="md-close" />取消
          </Button>
          <Button type="primary" @click="exportData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />导出
          </Button>
        </template>
      </FNCModal>
    </template>
  </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/devicestocks
import FNCContent from "@/components/FNCContent";
import FNCTable from "@/components/FNCTable";
import FNCModal from "@/components/FNCModal";
import Edit from "./childrens/Edit";
export default {
  components: {
    FNCTable,
    FNCContent,
    FNCModal,
    Edit,
  },

  data() {
    return {
      title: "信息列表",
      loading: false,
      pageParams: {
        maxCount: 0,//数据总条数
        currentPage: 0,//当前页(默认第一页)
        pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
        maxPage: 1,//总页数
        sort: "id",//排序字段
        order: "desc",//排序方式
        keyword: "",//通用查询条件
        keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
      },
      pagesizeOpts: [20, 30, 50, 100],//分页数组
      tableData: [],
      talbeColumns: [
        // {
        //   type: 'selection',
        //   width: 60,
        //   fixed: "left",
        //   align: 'center'
        // },
        {
          title: "终端号(IMEI)",
          key: "imei",
          align: "center",
          minWidth: 160,
          fixed: "left",
        },
        {
          title: "设备名称",
          key: "deviceName",
          align: "center",
          width: 200,
          fixed: "left",
        },
        {
          title: "品牌名称",
          key: "brandName",
          align: "center",
          width: 200,
        },
        {
          title: "品牌型号",
          key: "brandModel",
          align: "center",
          width: 150,
        },

        {
          title: "店铺名称",
          key: "shopName",
          align: "center",
          width: 200,
        },
        {
          title: "地址",
          key: "address",
          align: "center",
          width: 160,
        },
        {
          title: "经纬度",
          key: "lat",
          align: "center",
          width: 160,
          render: (h, params) => {
            var lng = "";
            var lat = "";
            if (params.row.lng != undefined && params.row.lat != undefined) {
              return h("div", params.row.lng + ' , ' + params.row.lat);
            } else {
              return h("div", "");
            }
          },
        },

        {
          title: "绑定人名称",
          key: "fullname",
          align: "center",
          width: 120,
        },
        {
          title: "绑定人手机号",
          key: "mobile",
          align: "center",
          width: 150,
        },
        {
          title: "第一联系人",
          key: "firstContactFullName",
          align: "center",
          minWidth: 190,
          render: (h, params) => {
            var firstContactFullName = "";
            var firstContactMobile = "";
            if (params.row.firstContactFullName != undefined) {
              firstContactFullName = params.row.firstContactFullName
            }
            if (params.row.firstContactMobile != undefined) {
              firstContactMobile = params.row.firstContactMobile
            }
            return h("div", firstContactFullName + ' ' + firstContactMobile);
          },
        },
        {
          title: "第二联系人",
          key: "secondaryContactFullName",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            var secondaryContactFullName = "";
            var secondaryContactMobile = "";
            if (params.row.secondaryContactFullName != undefined) {
              secondaryContactFullName = params.row.secondaryContactFullName
            }
            if (params.row.secondaryContactMobile != undefined) {
              secondaryContactMobile = params.row.secondaryContactMobile
            }
            return h("div", secondaryContactFullName + ' ' + secondaryContactMobile);
          },
        },
        {
          title: "绑定时间",
          key: "bindingTime",
          align: "center",
          width: 200,
        },
        {
          title: "绑定状态",
          key: "bindStatus",
          align: "center",
          fixed: "right",
          width: 100,
          render: (h, params) => {
            if (params.row.bindStatus != null && params.row.bindStatus != '' && params.row.bindStatus != '0') {
              // return h("div", "已绑定");
              return h("span", {
                style: {
                  color: 'black'
                }
              }
                , '已绑定',
              )
            } else {
              //return h("div", "未绑定");
              return h("span", {
                style: {
                  color: '#999999'
                }
              }
                , '未绑定',
              )
            }
          },
        },
        {
          title: "运行状态",
          key: "status",
          align: "center",
          minWidth: 260,
          fixed: "right",
          render: (h, params) => {

            return h("div", [
              h("span", {
                style: {
                  color: params.row.preStatus == 1 ? 'green' : 'red',
                  margin: "0 5px",
                }
              }
                , params.row.preStatus == 1 ? '在线' : '离线',
              ),
              h("span", {
                style: {
                  color: params.row.warnStatus == 1 ? 'red' : 'lightgrey',
                  margin: "0 5px",
                }
              }
                , "报警"
              ),
              h("span", {
                style: {
                  color: params.row.dismantleStatus == 1 ? 'red' : 'lightgrey',
                  margin: "0 5px",
                }
              }
                , "拆卸"
              ),
              h("span", {
                style: {
                  color: params.row.lowbatteryStatus == 1 ? 'red' : 'lightgrey',
                  margin: "0 5px",
                }
              }
                , "电池低压"
              ),
              h("span", {
                style: {
                  color: params.row.faultStatus == 1 ? 'red' : 'lightgrey',
                  margin: "0 5px",
                }
              }
                , "故障"
              ),
            ]
            )
          },
        },
        {
          title: "操作",
          slot: "action",
          width: 200,
          align: "center",
          fixed: "right",
          render: (h, params) => {
            // params.row.bindStatus = null;
            if (params.row.bindStatus != null && params.row.bindStatus != '' && params.row.bindStatus != '0') {
              return h("div",
                [
                  h(
                    "Button",
                    {

                      class: "editDiv"
                      ,
                      props: {
                        type: "primary",
                        size: "small",
                      },
                      style: {
                        margin: "0 5px",
                      },

                      on: {
                        click: () => {
                          layer.open({
                            title:"工单记录",
                            type: 2,
                            area: ['90%', '90%'],
                            content: '/page.html#/serviceorder?functionid=' + this.$core.getFunctionId() + '&imei=' + params.row.imei
                          });
                        },
                      },
                    },
                    "工单记录"
                  ),
                  h(
                    "Button",
                    {
                      class: "deleteDiv"
                      ,
                      props: {
                        type: "info",
                        size: "small",
                      },
                      on: {
                        click: () => {
                          this.deleteData(params.row);
                        },
                      },
                    },
                    "解绑"
                  ),
                ]);
            } else {
              return h("div",
                [
                  h(
                    "Button",
                    {

                      class: "editDiv"
                      ,
                      props: {
                        type: "primary",
                        size: "small",
                      },
                      style: {
                        margin: "0 5px",
                      },

                      on: {
                        click: () => {
                          //  this.$router.push({ path: '/serviceorder?functionid=0503&keyword=1111' })
                          layer.open({
                            title:"工单记录",
                            type: 2,
                            area: ['90%', '90%'],
                            content: '/page.html#/serviceorder?functionid=' + this.$core.getFunctionId() + '&imei=' + params.row.imei
                          });
                        },
                      },
                    },
                    "工单记录"
                  ),
                  h(
                    "Button",
                    {
                      class: "deleteDiv"
                      ,
                      props: {
                        type: "error",
                        size: "small",
                      },
                      on: {
                        click: () => {
                          this.deleteDevicestockData(params.row, '0');
                        },
                      },
                    },
                    "删除设备"
                  ),
                ]);
            }
            // else {
            //   return h(
            //       "Button",
            //       {

            //         class: "editDiv"
            //         ,
            //         props: {
            //           type: "primary",
            //           size: "small",
            //         },
            //         style: {
            //           margin: "0 5px",
            //         },

            //         on: {
            //           click: () => {
            //             // this.editData(params.row.id);
            //           },
            //         },
            //       },
            //       "绑定"
            //     )
            // }
          },
        },
      ],
      right: {},
      captcha:"",
      randcodeAction: "./images/nopic.png", //验证码IMG base64
      captchaId: "", //验证码captchaId
      saveLoading: false,
      sortList: [{ "value": { sort: "t1.id", order: "desc" }, "name": "根据入库时间排序" }, { "value": { sort: "t3.heartbeatTime", order: "desc" }, "name": "根据在线排序" }, { "value": { sort: "t3.heartbeatTime", order: "asc" }, "name": "根据离线排序" }, { "value": { sort: "t3.warnStatus", order: "desc" }, "name": "根据报警状态排序" },
      { "value": { sort: "t3.bindingTime", order: "desc" }, "name": "根据绑定状态排序" }, { "value": { sort: "t3.dismantleStatus", order: "desc" }, "name": "根据拆卸状态排序" }, { "value": { sort: "t3.lowbatteryStatus", order: "desc" }, "name": "根据电池电压排序" }, { "value": { sort: "t3.faultStatus", order: "desc" }, "name": "根据故障状态排序" },]

    };
  },
  created() {

  },
  mounted() {
    this.loadModuleInfo();
  },
  methods: {
     loadModuleInfo() {
      //读取栏目信息
       this.$get({
        url: "/gateway/api/manage/type/moduleinfo",
        data: {
          functionId: this.$core.getFunctionId(),
        },
        success: (res) => {
          if (res.code == "200" && res.data) {
            this.title = res.data.typename;
          }
        }
      });
      //读取数据列表
      this.loadData(1);
      this.$core.getControlRight().then(res => {
        this.right = res;
      });
    },
    handleChange(e) {
      if (e) {
        this.pageParams = { ...this.pageParams, ...e }
        this.loadData(1)
      }
    },
    loadData(currentPage) {
      if (currentPage) {
        this.pageParams.currentPage = currentPage;
      } else {
        this.pageParams.currentPage = this.pageParams.currentPage + 1;
      }
      this.loading = true;
      this.$get({
        url: "/smartsmoke/devicestocks/queryDevicestocks",
        data: this.pageParams,
        success: (res) => {
          this.tableData = []
          if (res.code == "200" && res.pageParams) {
            this.pageParams = { ...this.pageParams, ...res.pageParams }
            this.tableData = res.dataList
          } else {
            this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
          }
          this.loading = false;
        }
      })
    },

    deleteData(row) {
      if (this.right["D"] == false) {
        this.$Message.error({ background: true, content: "你没有删除权限" })
        return;
      }
      var ids = ""
      if (row) {
        ids = row.id
      } else {
        let selectedList = this.$refs.fncTable.getSelection();
        if (selectedList && selectedList.length > 0) {
          ids = selectedList.map(item => {
            return item.id;
          }).join(",")
        }
      }
      if (!ids) {
        this.$Message.error({ background: true, content: "并没有选择审核项" })
        return;
      }

      this.$Modal.confirm({
        title: '解绑确认',
        content: row ? '您确认要解绑“<span style="color:red"> ' + row.imei + '</span>”吗？' : "确认要解绑选中吗？",
        onOk: () => {
          this.$post({
            url: "/smartsmoke/devicestocks/updateDevice", data: { imei: row.imei, stockUser: '' },
            success: (res) => {
              if (res.code == "200") {
                this.$Message.success({ background: true, content: res.desc })
                this.loadData(1);
              } else {
                this.$Message.error({ background: true, content: res.desc })
              }
            }
          });
        }
      });
    },

    deleteDevicestockData(row, delFlag) {
      if (this.right["D"] == false) {
        this.$Message.error({ background: true, content: "你没有删除权限" })
        return;
      }
      // if(delFlag == '1') {
      //   this.$Message.error({ background: true, content: "设备被绑定不能删除" })
      //   return;
      // }

      this.$Modal.confirm({
        title: '删除设备确认',
        content: row ? '您确认要删除“<span style="color:red"> ' + row.imei + '</span>”吗？' : "确认要删除选中吗？",
        onOk: () => {
          this.$post({
            url: "/smartsmoke/devicestocks/delDevicestocks", data: { imeis: row.imei },
            success: (res) => {
              if (res.code == "200") {
                this.$Message.success({ background: true, content: res.desc })
                this.loadData(1);
              } else {
                this.$Message.error({ background: true, content: res.desc })
              }
            }
          });
        }
      });
    },

    editData(id) {
      if (id && this.right["E"] == true || !id && this.right["A"] == true) {
        this.$refs.editModal.showModal();
        this.$refs.editContent.loadData(id);
      }
    },
    closeModal() {
      this.$refs.editModal.hideModal();
    },
    submitData() {
      this.$refs.editContent.submitData((b, reload) => {
        this.saveLoading = b;
        if (reload == true) {
          this.loadData(1);
          this.closeModal();
        }
      });
    },

    closecaptchaModal() {
      this.$refs.captchaContent.hideModal();
    },
    exportDataWin() {
      this.randCode();
      this.$refs.captchaContent.showModal();
    },

    exportData() {
      top.location.href = this.$getProxy("/smartsmoke") + "/devicestocks/devicestocksExport?randKey=" + this.captchaId + "&randCode="+this.captcha+"&keyword=" + this.pageParams.keyword;
      this.$refs.captchaContent.hideModal();
    },


    randCode() {
      this.randcodeAction= "./images/nopic.png"; //验证码IMG base64
      this.captchaId="";
      this.$get({ url: "/gateway/oauth/captchaImage" }).then(res => {
        if (res && res.data && res.data != null) {
          this.captchaId=res.data.randKey;
          this.randcodeAction=res.data.image;
        }
      })
    },
  }
};
</script>
    
<style scoped lang='less'></style>