<template>
  <!-- 文本组件 -->
  <div :class="
    'fnc-RichText-view'
  " v-if="!isParamComp">
    <img src="/images/editor.png" />
  </div>
  <!-- 文本组件 -->
  <div class="fnc-RichText-params" v-else>
    <Form :label-width="150" ref="lineForm">
      <FormItem label="文本样式">
        <RadioGroup v-model="data.txtType" @on-change="changeTxtType">
          <Radio label="1">富文本</Radio>
          <Radio label="2">纯文本</Radio>
          <Radio label="3">API接口</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="文本区高度">
        <Input v-model.trim="data.height" @on-blur="handleData"></Input>
      </FormItem>
      <FormItem label="圆角大小">
        <Input v-model.trim="data.radius" @on-blur="handleData" placeholder="上右下左：10px 10px 10px 10px"></Input>
      </FormItem>
      <FormItem label="边距">
        <Input v-model.trim="data.margin" @on-blur="handleData" placeholder="上右下左：10px 10px 10px 10px"></Input>
      </FormItem>
  
      <template v-if="data.txtType == 2">
      <FormItem label="文本背景">
        <ColorPicker v-model="data.backgroundColor" recommend @on-active-change="bgcolorChange" />
      </FormItem>
      <FormItem label="文本字色">
        <ColorPicker v-model="data.fontColor" recommend @on-active-change="colorChange" />
      </FormItem>
      <FormItem label="字体大小">
        <Input v-model.trim="data.fontSize" @on-blur="handleData"></Input>
      </FormItem>
     
      <FormItem label="纯文本">
        <Input type="textarea" v-model.trim="data.data"  @input="handleData"></Input>
      </FormItem>
    </template>
    <template v-if="data.txtType == 3">
    <FormItem label="标签栏搜索">
        <RadioGroup v-model="data.isLFTabs">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
        <FormItem label="绑定标签栏名称" v-if="data.isLFTabs">
        <Input
          placeholder="注：从对应的标签栏组件设置中找到"
          v-model.trim="data.LFTabsName"
        ></Input>
      </FormItem>
      <FormItem label="搜索字段名" v-if="data.isLFTabs">
        <Input
          placeholder="注：API中参与标签栏搜索的字段名"
          v-model.trim="data.lftabsKeyword"
        ></Input>
      </FormItem>
      <FormItem label="API地址">
        <Input
          v-model.trim="data.api"
          placeholder="api接口地址"
          type="textarea"
          :autosize="true"
        ></Input>
        <div style="color:blue">注：变量赋值参数的伪参数写法：abc=${def}</div>
      </FormItem>

    </template>
      <iframe v-show="data.txtType == 1" name="uRichText1" id="uRichText1" src="/mobileeditor/#/index?titlename=富文本"
        @load="setContent" style="width:100%;height:500px;border:0px;"></iframe>
    </Form>
  </div>
</template>

<script>
import G from 'glob';

/**
 * import RichText from "./components/RichText";
 * 文本
 */
export default {
  components: {},
  props: {
    isParamComp: { type: Boolean, default: false }, //true为参数设置组件,false为样式组件
    data: {
      //组件数据，每个组件需要具备
      type: Object,
      default: () => {
        return {
        };
      },
    },
    target: {
      //组件对象，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      initData: {
        //初始化用的数据，每个组件需要具备
        type: "RichText",
        height: 'auto',
        margin: '',
        radius: '',
        txtType: "1",
        data: '',
        backgroundColor: "transparent",
        fontColor:"#333333",
        fontSize:"14px",
        isLFTabs:false,
        LFTabsName:"",
        lftabsKeyword:"",
        api:"",
      },
    };
  },
  mounted() {
    window.sessionStorage.setItem("NODEENV", process.env.NODE_ENV);
    if (this.isParamComp) this.handleData();
    //初始化编辑富文本后的事件
    top.editorCallback = this.editorCallback;
  },
  methods: {
    editorCallback() {
      var v_Obj = document.getElementById("uRichText1").contentWindow;//获取iframe对象
      this.data.data = v_Obj.getContent();
      this.handleData();
    },
    setContent() {
      var v_Obj = document.getElementById("uRichText1").contentWindow;//获取iframe对象
      v_Obj.setContent(this.data.data);
    },
    changeTxtType(e){
      if(this.data.txtType==1){
        this.setContent();
      }
      this.handleData();
    },
    colorChange(color) {
      this.data.fontColor = color;
      this.handleData();
    },
    bgcolorChange(color) {
      this.data.backgroundColor = color;
      this.handleData();
    },
    handleData() {
      var that = this.$(this.target);
      that.empty();
      that.css("height", this.data.height);
      that.css("border-radius", this.data.radius);
       if(this.data.txtType==3){
        this.data.data="";
        that.html("<div style='text-align:center;padding:20px;'>API接口...</div>")
      }else{
        this.data.isLFTabs=false;
        this.data.LFTabsName="";
        this.data.lftabsKeyword="";
        this.data.api="";
        if(this.data.txtType==1){
          that.html(this.data.data.replace(/class="component"/g,'class="component-text"'))
      }else
          {
            that.text(this.data.data)
          }
      }
      
      var data=this.data;
      if(!this.data) data=this.initData;
      that.css("width", "calc(100% - " + data.marginLeft + " - " + data.marginRight + ")");
      that.css("min-height", "20px");
      that.css("overflow", "auto");
      that.css("marginLeft", data.marginLeft);
      that.css("marginRight", data.marginRight);
      that.css("marginTop", data.marginTop);
      that.css("marginBottom", data.marginBottom);
      that.css("backgroundColor", data.backgroundColor);
      that.css("color", data.fontColor);
      that.css("fontSize", data.fontSize);
    
    }
  },
};
</script>
<style lang="less">
.fnc-RichText-params {
  width: 100%;
  margin: 5px 0;
}

.fnc-RichText-view {
  width: 100%;
  margin: 5px 0;
  overflow: hidden;
  background-color: transparent;

  img {
    width: 100%;
  }
}
</style>