<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="选择档案" prop="archiveId">
        <Select v-model="formItems.archiveId" @on-select="getArchiveParam" filterable :remote-method="remoteMethod1"
                :loading="remoteLoading">
          <Option v-for="(option, index) in archiveList" :value="option.id" :key="index">{{option.fileName}}</Option>
        </Select>
      </FormItem>
      <FormItem label="预计借阅时间" prop="expectedBorrowTime">
        <DatePicker type="date" :editable="false" placeholder="预计借阅时间" :value="formItems.expectedBorrowTimeStr" @on-change="formItems.expectedBorrowTimeStr = $event" style="width: 200px"></DatePicker>
      </FormItem>
      <FormItem label="预计归还时间" prop="expectedReturnTime">
        <DatePicker type="date" :editable="false" placeholder="预计归还时间" :value="formItems.expectedReturnTimeStr" @on-change="formItems.expectedReturnTimeStr = $event" style="width: 200px"></DatePicker>
        <!-- <Input v-model.trim="formItems.expectedReturnTime" placeholder="预计归还时间"></Input> -->
      </FormItem>
      <FormItem label="备注" prop="remarks">
        <Input type="textarea" v-model.trim="formItems.remarks" placeholder="备注"></Input>
      </FormItem>
    
    </Form>
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
import FNCUpload from "@/components/FNCUpload"
import { orgCascader } from "@/utils/orgCascader";
export default {
  components: {
    FNCModal,
    FNCUpload,
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: "",//ID
        branchId: "",//BranchId
        whId: "",//绑定库房ID
        mcId: "",//绑定机柜ID
        mcName: "",//机柜名称（导入文件）
        whName: "",//库房名称（导入文件）
        fullFileNum: "",//全宗号
        fullFileName: "",//全宗名称
        fileName: "",//文件名称
        filesTotal: "",//文件总数量
        filesSizeTotal: "",//文件总大小
        archiveNum: "",//档案编号
        archivingNum: "",//归档编号
        archiveYear: "",//档案年份
        archiveType: "",//档案类型ID
        orgCode: "",//所属科室ID
        orgName: "",//科室名称（导入文件）
        archiveName: "",//档案类型名称（导入文件）
        secretLevel: "",//密级
        storageLife: "",//保管期限
        storageLocation: "",//存储位置
        landArea: "",//用地面积
        address: "",//地址
        dealTime: "",//处理时间
        dealUser: "",//处理人员
        // filesStorage: [],//文件列表
      }),
      remoteLoading:false,
      // filesStorage: [],//文件列表
      deptid: [],
      formItems: {},
      ruleItems: {
        // archiveNum: [{ required: true, message: "请填写档案编号", trigger: "blur" }],
        // fileName: [{ required: true, message: "请填写文件名称", trigger: "blur" }],

      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,
      archiveList:[],
      
    };
  },
  created() {
    // this.orgCascader = orgCascader("dept");
    // this.deptid = this.orgCascader.convectCascaderList(this.$core.getBranchUserInfo("deptid"))
    // this.orgCascader.loadData(
    //   depts => {
    //     this.depts = depts
    //   },
    //   this.deptid.length > 0 ? this.deptid[this.deptid.length - 1] : ""
    // )
    // this.loadBaseData();
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
    loadData(id) {
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.$get({
          url: "/smartarchives/api/archives/queryArchivesDetail",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
              if (this.formItems.whId) {
                this.getMachinecabinetByWh(this.formItems.whId, this.formItems.mcId)
              }
              this.formItems.orgName = this.orgCascader.convectOrgName(this.formItems.orgCode);
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },

    /**
    * 获取选项数据
    */
    loadBaseData() {
      // this.$core.getStyleParamList("secretLevel", false, null, (res) => {
      //   this.secretLevelList = res
      // })
      // this.$core.getStyleParamList("archiveType", false, null, (res) => {
      //   this.archiveTypeList = res
      // })
     
    },
    // getMachinecabinetByWh(whId, mcId) {
    //   this.formItems.mcId = mcId;
    //   this.$get({
    //     url: "/smartarchives/api/machinecabinet/client/queryMachinecabinetByWh",
    //     data: { whId: whId },
    //     success: res => {
    //       if (res.code == "200") {
    //         this.machinecabinetList = res.dataList
    //         this.$forceUpdate()
    //       }
    //     }
    //   })
    // },
    // getMcList(e) {
    //   this.mcList = []
    //   this.$get({
    //     url: "/smartarchives/api/machinecabinet/queryMachinecabinet", data: { pageSize: 1000, whId: e }, success: (res) => {
    //       if (res.code == "200" && res.dataList) {
    //         this.machinecabinetList = res.dataList;
    //       }
    //     }
    //   })
    // },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          console.log(this.formItems)
          this.$post({
            url: "/smartarchives/api/borrow/addBorrow",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    
    checkOrgRight(e) {
      this.deptid = this.orgCascader.checkOrgRight(e)
      this.formItems.orgCode = this.deptid[this.deptid.length - 1]
    },

    getArchiveParam(e) {
      for(var i = 0; i< this.archiveList.length; i++) {
        if(e.value = this.archiveList[i].id) {
          this.formItems.archiveId = e.value;
          // this.formItems.oldOrgCode = this.archiveList[i].orgCode;
          // this.formItems.oldWhId = this.archiveList[i].whId;
          // this.formItems.oldMcId = this.archiveList[i].mcId;
          break;
        }
      }
    },
    remoteMethod1 (query) {
      
      if (query !== ''||this.formItems.archiveId) {
        this.remoteLoading = true;
        setTimeout(() => {
          this.remoteLoading = false;
          this.$get({
        url: "/smartarchives/api/archives/queryArchives", data: { pageSize: 50,borrowFlag: "0",fileName: query}, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.archiveList = res.dataList
          }
        }
      })
          // this.options1 = this.archiveList.filter(item => item.fileName.toLowerCase().indexOf(query.toLowerCase()) > -1);
          }, 200);
      } else {
          this.archiveList = [];
      }
    },

    changeExpectedBorrowTime(value){
      this.formItems.expectedBorrowTime = new Date(value)
    },

    changeExpectedReturnTime(value){
      this.formItems.expectedReturnTime = new Date(value)
    },
  
  }
};
</script>
    
<style scoped lang='less'>
</style>