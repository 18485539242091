<template>
    <div style="overflow-y: auto; padding: 10px; height: 600px">
        <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
            <FormItem label="题库类型" prop="questionBankId">
                <Select clearable v-model="formItems.questionBankId" style="width: 300px" placeholder="选择题库类型">
                    <Option v-for="(item, idx) in questionBank" :value="item.id" :key="'en' + idx">{{ item.questionBank }}</Option>
                </Select>
            </FormItem>

            <FormItem label="标题" prop="title">
                <Input type="text" v-model="formItems.title" placeholder="答题活动标题"></Input>
            </FormItem>
            <FormItem label="背景颜色">
                <ColorPicker v-model="formItems.bgColor" recommend />
            </FormItem>
            <FormItem label="背景图片" prop="bgImg">
                <FNCUpload type="image" :showView="true" :defaultList="formItems.bgImg" :showDel="true" @success="upLogoImage"></FNCUpload>
            </FormItem>
            <FormItem label="答题时段">
                <DatePicker type="datetimerange" :value="getDate()" placeholder="选择日期和时间" style="width: 400px" @on-change="dataChange" />
            </FormItem>

            <FormItem label="答题模式" prop="module">
                <RadioGroup v-model="formItems.module">
                    <Radio label="1">闯关模式</Radio>
                    <Radio label="2">计分模式</Radio>
                    <Radio label="3">考试模式</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="答题数量" prop="questionLimit">
                <Input type="number" v-model.number="formItems.questionLimit" placeholder="答题数量" style="width: 250px"></Input>
            </FormItem>
            <FormItem label="答题时长" prop="answerDuration">
                <Input type="number" v-model.number="formItems.answerDuration" :placeholder="'答题时长：'+(formItems.module==3?'考试总时长':'单题限时')" style="width: 250px">
                    <template #append>
                        <span>秒；{{ formItems.module==3?'考试总时长':'单题限时' }}</span>
                    </template>
                </Input>
            </FormItem>
            <FormItem label="单题分数" prop="questionScore" v-if="formItems.module == '2' || formItems.module == '3'">
                <Input type="number" v-model.number="formItems.questionScore" placeholder="单题分数" style="width: 250px"></Input>
            </FormItem>
            <FormItem label="通关数" prop="passQuestionNum" v-if="formItems.module == '1'">
                <Input type="number" v-model.number="formItems.passQuestionNum" placeholder="答对数量即通关，<=答题数量" style="width: 250px"></Input>
            </FormItem>
            <FormItem label="总分数" prop="scoreTotal" v-if="formItems.module == '2' || formItems.module == '3'">
                <div style="color: #cc0000">{{ formItems.questionScore * formItems.questionLimit }}</div>
            </FormItem>
            <FormItem label="挑战次数限额" prop="userJoinLimit">
                <Input type="number" v-model.number="formItems.userJoinLimit" placeholder="每人挑战最大次数，0为无限次" style="width: 250px"></Input>
            </FormItem>
            <FormItem label="参与者人数限额" prop="participantsNumber">
                <Input type="number" v-model.number="formItems.participantsNumber" placeholder="参与者人数限额，0为无限人数" style="width: 250px"></Input>
            </FormItem>

            <FormItem label="启禁用" prop="idisplay">
                <RadioGroup v-model="formItems.idisplay">
                    <Radio :label="1">启用</Radio>
                    <Radio :label="0">禁用</Radio>
                </RadioGroup>
            </FormItem>
            <div style="padding-left: 80px">
                <iframe
                    name="ueditor"
                    id="ueditor"
                    src="/mobileeditor/#/index?titlename=答题规则（注：为保证应用端页面美观，内容尽量简洁）"
                    style="width: 100%; height: 500px; border: 0px"
                    @load="setContent"
                ></iframe>
            </div>
        </Form>
    </div>
</template>

<script>
import FNCUpload from "@/components/FNCUpload"
export default {
    components: {
        FNCUpload,
    },
    props: {
        questionBank: [],
    },

    data() {
        return {
            formItemsDefault: {
                id: 0,
                questionBankId: "",
                title: "",
                bgColor: "#1FBB7E",
                bgImg: "",
                module: "1",
                questionLimit: "",
                answerDuration: "",
                questionScore: "",
                passQuestionNum: "",
                participantsNumber: "",
                userJoinLimit: "",
                startTime: "",
                endTime: "",
                content: "",
                idisplay: 1,
                branchId: 0,
            },
            formItems: {},
            ruleItems: {
                questionBankId: [{ type: "number", required: true, message: "题库类型不能为空", trigger: "change" }],
                title: [{ required: true, message: "题目不能为空", trigger: "blur" }],
                module: [{ required: true, message: "答题模式必选", trigger: "change" }],
                questionLimit: [{ type: "number", required: true, message: "答题数量不能为空", trigger: "blur" }],
                answerDuration: [{ type: "number", required: true, message: "答题时长不能为空", trigger: "blur" }],
                questionScore: [{ type: "number", required: true, message: "单题分数不能为空", trigger: "blur" }],
                passQuestionNum: [{ type: "number", required: true, message: "通关数不能为空", trigger: "blur" }],
            },
        }
    },
    mounted() {},
    methods: {
        loadData(id, questionBankId) {
            this.formItems = { ...this.formItemsDefault }
            this.formItems.questionBankId = questionBankId || ""
            if (id > 0) {
                this.$Message.loading({ content: "读取中...", duration: 0 })
                this.$get({
                    url: "/smartlife/api/answer/view",
                    data: { id },
                    success: res => {
                        if (res.code == "200" && res.data) {
                            this.formItems = { ...this.formItems, ...res.data }
                            this.setContent()
                            this.$Message.destroy()
                        } else {
                            this.$Message.error(res.desc || "读取数据出错")
                            this.$emit("closeModal")
                        }
                    },
                    fail: () => {
                        this.$Message.destroy()
                    },
                })
            } else {
                this.setContent()
            }
        },
        getDate() {
            return [this.formItems.startTime, this.formItems.endTime]
        },
        dataChange(e) {
            if (e && e.length == 2) {
                this.formItems.startTime = e[0]
                this.formItems.endTime = e[1]
            }
        },
        upLogoImage(file) {
            this.formItems.bgImg = file.url
        },
        setContent() {
            var v_Obj = document.getElementById("ueditor").contentWindow //获取iframe对象
            try {
                v_Obj.setContent(this.formItems.content) //写入编辑器富文本内容
            } catch (e) {}
        },
        editorCallback() {
            var v_Obj = document.getElementById("ueditor").contentWindow //获取iframe对象
        },
        submitData(callback) {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    if (!this.formItems.startTime || !this.formItems.endTime) {
                        this.$Message.error({ background: true, content: "请选择答题时段" })
                        return
                    }

                    var v_Obj = document.getElementById("ueditor").contentWindow //获取iframe对象
                    this.formItems.content = v_Obj.getContent()

                    callback(true)
                    this.$post({
                        url: "/smartlife/api/answer/save",
                        data: { ...this.formItems },
                        success: res => {
                            if (res.code == "200") {
                                callback(false, true)
                                this.$Message.success({ background: true, content: "保存成功" })
                            } else {
                                this.$Message.error({ background: true, content: res.desc || "保存出错" })
                            }
                        },
                        complete: () => {
                            callback(false)
                        },
                        fail: () => {
                            callback(false)
                        },
                    })
                } else {
                    this.$Message.error({ background: true, content: "部分必填项没填写" })
                }
            })
        },
    },
}
</script>

<style scoped lang="less"></style>
