<template>
    <div style="height: 500px; overflow-y: auto; padding: 10px">
        <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="200">
            <FormItem label="系统代码" prop="systemCode">
                <Input type="text" v-model="formItems.systemCode" placeholder="查看服务中的命名"></Input>
            </FormItem>
            <FormItem label="环境代码" prop="activeCode">
                <Input type="text" v-model="formItems.activeCode" placeholder="查看服务中的命名：dev or prod"></Input>
            </FormItem>
            <FormItem label="数据库编号" prop="databaseCode">
                <Input type="text" v-model="formItems.databaseCode" placeholder="查看服务中的命名，主库为：primary"></Input>
            </FormItem>
            <FormItem label="数据库地址" prop="jdbcUrl">
                <Input type="text" v-model="formItems.jdbcUrl" placeholder="数据库地址"></Input>
            </FormItem>
            <FormItem label="数据库用户" prop="username">
                <Input type="text" v-model="formItems.username" placeholder="数据库用户"></Input>
            </FormItem>
            <FormItem label="数据库密码" prop="password">
                <Input type="text" v-model="formItems.password" placeholder="数据库密码"></Input>
            </FormItem>
            <FormItem label="数据库驱动" prop="driverClassName">
                <Input type="text" v-model="formItems.driverClassName" placeholder="数据库驱动"></Input>
            </FormItem>
            <FormItem label="Redis地址" prop="redisHost">
                <Input type="text" v-model="formItems.redisHost" placeholder="Redis地址"></Input>
            </FormItem>
            <FormItem label="Redis端口" prop="redisPort">
                <Input type="text" v-model="formItems.redisPort" placeholder="Redis端口"></Input>
            </FormItem>
            <FormItem label="Redis密码" prop="redisPassword">
                <Input type="text" v-model="formItems.redisPassword" placeholder="Redis密码"></Input>
            </FormItem>
            <FormItem label="RedisID" prop="redisDatabaseId">
                <Input type="number" v-model="formItems.redisDatabaseId" placeholder="RedisID,默认0" style="width: 150px"></Input>
            </FormItem>
            <FormItem label="Redis超时" prop="redisTimeout">
                <Input type="number" v-model="formItems.redisTimeout" placeholder="Redis超时,默认5000" style="width: 150px"></Input>
            </FormItem>
            <FormItem label="最大空闲连接" prop="redisMaxIdle">
                <Input type="number" v-model="formItems.redisMaxIdle" placeholder="最大空闲连接,默认20" style="width: 150px"></Input>
            </FormItem>
            <FormItem label="最小空闲连接" prop="redisMinIdle">
                <Input type="number" v-model="formItems.redisMinIdle" placeholder="最小空闲连接,默认8" style="width: 150px"></Input>
            </FormItem>
            <FormItem label="最大阻塞等待时间" prop="redisMaxWait">
                <Input type="number" v-model="formItems.redisMaxWait" placeholder="最大阻塞等待时间,默认30000" style="width: 150px"></Input>
            </FormItem>
            <FormItem label="最大连接数" prop="redisMaxActive">
                <Input type="number" v-model="formItems.redisMaxActive" placeholder="最大连接数,默认100" style="width: 150px"></Input>
            </FormItem>

            <FormItem label="启禁用" prop="idisplay">
                <RadioGroup v-model="formItems.idisplay">
                    <Radio :label="1">启用</Radio>
                    <Radio :label="0">禁用</Radio>
                </RadioGroup>
            </FormItem>
        </Form>
    </div>
</template>

<script>
import FNCUpload from "@/components/FNCUpload"
export default {
    components: {
        FNCUpload,
    },
    props: {},

    data() {
        return {
            formItemsDefault: {
                activeCode: "",
                databaseCode: "",
                driverClassName: "com.mysql.cj.jdbc.Driver",
                id: 0,
                idisplay: 1,
                jdbcUrl: "",
                password: "",
                redisDatabaseId: 0,
                redisHost: "",
                redisMaxActive: 100,
                redisMaxIdle: 20,
                redisMaxWait: 30000,
                redisMinIdle: 8,
                redisPassword: "",
                redisPort: 6379,
                redisTimeout: 5000,
                systemCode: "",
                username: "",
            },
            formItems: {},
            ruleItems: {
                systemCode: [{ required: true, message: "系统代码不能为空", trigger: "blur" }],
                databaseCode: [{ required: true, message: "环境代码不能为空", trigger: "blur" }],
                activeCode: [{ required: true, message: "数据库编号不能为空", trigger: "blur" }],
                jdbcUrl: [{ required: true, message: "数据库地址不能为空", trigger: "blur" }],
                username: [{ required: true, message: "数据库用户名不能为空", trigger: "blur" }],
                password: [{ required: true, message: "数据库密码不能为空", trigger: "blur" }],
            },
        }
    },
    mounted() {},
    methods: {
        loadData(id) {
            this.formItems = {...this.formItemsDefault};
            if (id) {
                this.$get({
                    url: "/gateway/api/manage/platform/dbconfig/view",
                    data: { id },
                    success: res => {
                        if (res.code == "200" && res.data) {
                            this.formItems = { ...this.formItems, ...res.data }
                        } else {
                            this.$Message.error(res.desc || "读取数据出错")
                            this.$emit("closeModal")
                        }
                    },
                })
            }
        },
        submitData(callback) {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    callback(true)
                    this.$post({
                        url: "/gateway/api/manage/platform/dbconfig/save",
                        data: this.formItems,
                        success: res => {
                            if (res.code == "200") {
                                callback(false, true)
                                this.$Message.success({ background: true, content: "保存成功" })
                            } else {
                                this.$Message.error({ background: true, content: res.desc || "保存出错" })
                            }
                        },
                        complete: () => {
                            callback(false)
                        },
                    })
                } else {
                    this.$Message.error({ background: true, content: "部分必填项没填写" })
                }
            })
        },
    },
}
</script>
  
<style scoped lang='less'></style>