<template>
    <div style="">
        <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
            <FormItem label="整编结果" prop="status">
                <RadioGroup v-model="formItems.status">
                    <Radio label="update" :border="true" v-if="checkExist"><span
                            style="color:red;">检测到档案（编号）已存在，覆盖原有的档案</span></Radio>
                    <Radio label="insert" :border="true" v-else>整编成功，正式导入档案库</Radio>
                    <p style="margin-top:10px;">
                        <Radio label="skip" :border="true">整编失败，不入库</Radio>
                    </p>
                </RadioGroup>
            </FormItem>
            <FormItem label="整编描述" prop="reason">
                <Input type="textarea" :rows="8" maxlength="200" show-word-limit v-model.trim="formItems.reason"
                    placeholder="整编描述"></Input>
            </FormItem>

        </Form>
    </div>
</template>

<script>
export default {
    components: {

    },
    data() {
        return {
            id: "",
            archiveNum: "",
            checkExist: false,
            formItems: {},
            ruleItems: {
                status: [{ required: true, message: "请选择整编结果", trigger: "change" }],
            },
        }
    },
    created() {
        this.loadBaseData();
    },
    mounted() {

    },
    methods: {
        loadBaseData() {

        },

        /**
        * 检测档案号是否存在
        */
        checkArchivesExist() {

            this.$Message.loading({ content: "正在检测档案编号是否存在", duration: 0 });
            this.$get({
                url: "/smartarchives/api/archivesstorage/checkarchivesexist",
                data: { archiveNum: this.archiveNum },
                success: res => {
                    if (res.code == "200") {
                        if (res.data > 0) {
                            this.checkExist = true;
                        } else {
                            this.checkExist = false;
                        }
                    }
                },
                complete: () => {
                    this.$Message.destroy()
                }
            })

        },


        loadData(id, archiveNum) {
            this.id = id
            this.archiveNum = archiveNum
            this.checkExist=false;
            this.formItems = { id: id, status: "", reason: "" }
            if (this.archiveNum) {
                this.$nextTick(() => {
                    this.checkArchivesExist()
                });
                this.$forceUpdate()
            } else {
                this.$Message.error({ background: true, content: "无效的档案信息" })
                this.$emit("closeModal");
            }
        },

        submitData(callback) {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    callback(3)
                    this.$post({
                        url: "/smartarchives/api/archivesstorage/reorganizecomplete",
                        data: { ...this.formItems },
                        success: res => {
                            if (res.code == "200") {
                                callback(1, true)
                                this.$Message.success({ background: true, content: "整编完成" })
                            } else {
                                callback(1)
                                this.$Message.error({ background: true, content: res.desc || "保存出错" })
                            }
                        },
                        fail: () => {
                            callback(1)
                            this.$Message.error({ background: true, content: "保存出错" })
                        },

                    })
                } else {
                    this.$Message.error({ background: true, content: "部分必填项没填写" })
                }
            })
        },

    },
}
</script>
    
<style scoped lang='less'>
.file-upload-list {
    width: 100%;
    text-align: left;
    padding-bottom: 5px;
    overflow: hidden;
    position: relative;
    margin-right: 4px;
    color: #2b85e4;
}
</style>