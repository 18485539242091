<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="井盖编号" prop="coverOutNum">
        <Input type="text" v-model="formItems.coverOutNum" disabled></Input>
      </FormItem>
      <FormItem label="井盖名称" prop="coverName">
        <Input type="text" v-model="formItems.coverName" disabled></Input>
      </FormItem>
      <FormItem label="具体地址" prop="address">
        <Input type="textarea" v-model="formItems.address" disabled></Input>
      </FormItem>
      <FormItem label="经纬度" prop="longitude">
        <Input v-model.trim="formItems.longitude" placeholder="经度" style="width: 120px; margin-right: 20px" disabled></Input>
        <Input v-model.trim="formItems.latitude" placeholder="纬度" style="width: 120px; margin-right: 20px" disabled></Input>
        <Button @click="mapFn">查看地图</Button>
      </FormItem>
      <FormItem label="所属管线" prop="objectNum">
        <Select v-model="formItems.objectNum" style="width: 180px" disabled>
          <Option v-for="item in projects" :value="item.objectNum" :key="item.objectName">{{ item.objectName }}</Option>
        </Select>
      </FormItem>
      <FormItem label="报警类型" prop="warmType">
        <label>{{ formItems.warmTypeName }}</label>
        <!-- <Select v-model="formItems.warmType" style="width: 180px" disabled>
          <Option v-for="item in warmTypes" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select> -->
      </FormItem>
      <FormItem label="报警时间" prop="createTime">
        <DatePicker type="datetime" v-model="formItems.createTime" disabled/>
      </FormItem>
      <FormItem label="事件处理方式" prop="deleted">
        <RadioGroup v-model="formItems.deleted">
            <Radio label="1" disabled>
               消除报警
            </Radio>
            <Radio label="0" disabled>
               生成维修单
            </Radio>
        </RadioGroup>
      </FormItem>
      <template v-if="formItems.deleted == 1">
        <FormItem label="原因" prop="deletedReason" >
        <Select v-model="formItems.deletedReason" style="width: 180px">
          <Option v-for="item in deletedReasons" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="备注" prop="remarks">
        <Input type="textarea" v-model="formItems.remarks" placeholder="补充消除报警原因" rows="4"></Input>
      </FormItem>
      </template>
      <template v-else>
        <FormItem label="维修事项" prop="repairRemark">
          <Input type="textarea" v-model="formItems.repairRemark" placeholder="维修事项" rows="4" readonly></Input>
        </FormItem>
        <FormItem label="指派人员" prop="appoint">
          <RadioGroup v-model="formItems.appoint">
            <Radio :label="1" disabled>
               是
            </Radio>
            <Radio :label="0" disabled>
               否
            </Radio>
        </RadioGroup>
        </FormItem>
        <FormItem label="选择维修员" prop="dealRepairId" v-if="formItems.appoint==1">
          <Select v-model="formItems.dealRepairId" style="width: 180px">
            <Option v-for="item in userList" :value="item.globalid" :key="item.globalid" disabled>{{ "["+item.orgName+"] "+item.realname }}</Option>
          </Select>
        </FormItem>
        <FormItem label="维修状态" prop="manholeCoverWarmStatus" v-if="formItems.coverRepairId">
          <Select v-model="formItems.manholeCoverWarmStatus" style="width: 180px">
            <Option v-for="item in manholeCoverWarmStatusList" :value="item.value" :key="item.name">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="维修记录" prop="repairRecord" v-if="formItems.coverRepairId">
          <Input type="textarea" v-model="formItems.repairRecord" placeholder="记录维修过程" rows="4"></Input>
        </FormItem>
        <FormItem label="维修图片" prop="repairPicture" v-if="formItems.coverRepairId">
          <FNCUpload type="image" style="float:left" :showView="true" :defaultList="formItems.repairPicture" :showDel="true"
          @success="upLogoImage" multiple="true" fileLength="8">
        </FNCUpload>
        </FormItem>

      </template>
    
    </Form>
    <FNCMapCoordinatePickup ref="coordinatePickup" @close="onClose"></FNCMapCoordinatePickup>
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
import FNCUpload from '@/components/FNCUpload';
import FNCMapCoordinatePickup from '@/components/FNCMapCoordinatePickup';
export default {
  components: {
    FNCUpload,
    FNCModal, 
    FNCMapCoordinatePickup,
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: 0,
        deviceId: 0,
        createBy: "",
        createTime: "",
        updateTime: null,
        updateBy: null,
        objectNum: "",
        coverNum: "",
        coverOutNum: "",
        coverName: null,
        address: null,
        longitude: null,
        latitude: null,
        remarks: null,
        warmType:"",
        createTime:"",
        deleted:"",
        deletedReason:"",
        remarks:"",
        repairRemark:"",
        appoint:0,
        dealRepairId:null,
      }),
      formItems: {},
      ruleItems: {
        // coverOutNum: [
        //   { required: true, message: '井盖编号不能为空', trigger: 'blur' }
        // ],
        // coverName: [
        //   { required: true, message: '井盖名称不能为空', trigger: 'blur' }
        // ],
        // longitude: [
        //   { required: true, message: '定位不能为空', trigger: 'blur' }
        // ],
        // objectNum: [
        //   { required: true, message: '必须绑定管线', trigger: 'blur' }
        // ],
        // deviceId: [
        //   { type:"number", required: true, message: '必须绑定设备', trigger: 'blur' }
        // ],
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,

      devices: [],
      projects: [],
      flowths: [],
      warmTypes: [],
      waterStates: [],
      deletedReasons: [],
      userList: [],
      manholeCoverWarmStatusList: [],
      deptid:null,
    };
  },
  mounted() {
    this.deptid=this.$core.getBranchUserInfo("deptid")||null;
    this.loadBaseData();
    this.coverId = this.$core.getUrlParam("coverId") || "";
    this.coverWarmId = this.$core.getUrlParam("coverWarmId") || "";
    this.coverRepairId = this.$core.getUrlParam("coverRepairId") || "";
    this.loadData();
  },
  methods: {
     loadData(id) {
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.$get({
          url: "/smartcovers/cover/queryMoreCoverDetail",
          data: { coverRepairId: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              if (!res.data.deleted) res.data.deleted = 0;
              // if (!res.data.appoint) res.data.appoint = 0;
              if (res.data.deletedReason == null) res.data.deletedReason = "";
              if (res.data.dealRepairId == null||res.data.dealRepairId == "") {
                res.data.dealRepairId = "";
                res.data.appoint=0;
              } else {
                res.data.appoint=1;
              }

            res.data.repairPicture = this.convertImages(res.data.repairPicture);
              this.formItems = { ...this.formItems, ...res.data }
              // this.formItems = res.data;
              console.log(this.formItems)
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      } 
    },
    /**
    * 获取选项数据
    */
    loadBaseData() {
      this.$core.getStyleParamList("flowth", false, null, (res) => {
        this.flowths = res || []
      })
      this.$core.getStyleParamList("waterState", false, null, (res) => {
        this.waterStates = res || []
      })
      this.$core.getStyleParamList("warmType", false, null, (res) => {
        this.warmTypes = res || []
      })
      this.$core.getStyleParamList("manholeCoverWarmDelReason", false, null, (res) => {
        this.deletedReasons = res || []
      })
      this.$core.getStyleParamList("manholeCoverWarmStatus", false, null, (res) => {
        this.manholeCoverWarmStatusList = res || []
      })
      // this.$get({
      //   url: "/smartcovers/cover/queryCoverDevice", data: { pageSize: 1000 }, success: (res) => {
      //     if (res.code == "200" && res.dataList) {
      //       this.devices = res.dataList
      //     }
      //   }
      // })
      this.$get({
        url: "/smartcovers/object/queryObject", data: { pageSize: 1000 }, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.projects = res.dataList
          }
        }
      })
      //获取维修人员名单
      this.$get({
        url: "/gateway/api/manage/users/roleuserlist", data: { deptid: this.deptid }, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.userList = res.dataList
          }
        }
      })
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          this.$post({
            url: "/smartcovers/cover/moreCoverDetailSave",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    upLogoImage(file) {
      this.formItems.repairPicture = file.url;
    },
   
    mapFn() {
      this.$refs.coordinatePickup.open({ lng: this.formItems.longitude, lat: this.formItems.latitude }, this.formItems.address, false);
    },
    onClose(lnglat, address) {
      // this.formItems.longitude = lnglat.lng;
      // this.formItems.latitude = lnglat.lat;
      // if (!this.formItems.address) {
      //   this.formItems.address = address;
      // }
    },
    
    handleRadio(name, value) {
      this.formItems[name] = value
    },
    convertImages(value) {
      try {
        return JSON.parse(value || "[]")
      } catch (e) {
        return []
      }
    },
  }
};
</script>
    
<style scoped lang='less'></style>