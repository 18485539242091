<template>
  <div style="height:500px;overflow-y: auto;padding:10px;">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="200">
      <FormItem label="ClientID" prop="clientId">
        <Input type="text" v-model="formItems.clientId" :disabled="formItems.id>0?true:false" placeholder="字母或数字"></Input>
      </FormItem>
      <FormItem label="ClientSecret" prop="password">
        <Input type="text" v-model="formItems.password" placeholder="字母或数字"></Input>
      </FormItem>
      <FormItem label="Scope" prop="scope">
        <RadioGroup v-model="formItems.scope">
          <Radio label="all"></Radio>
          <Radio label="read"></Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="GrantType" prop="grantType">
        <RadioGroup v-model="formItems.grantType">
          <Radio label="password">密码式</Radio>
          <Radio label="authorization-code">授权码</Radio>
          <Radio label="implicit">隐藏式</Radio>
          <Radio label="client_credentials">凭证式</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="系统名称" prop="systemName">
        <Input type="text" v-model="formItems.systemName"></Input>
      </FormItem>
      <FormItem label="租户绑定的域名" prop="domain2">
        <Input type="textarea" :autosize="{ minRows: 3}" v-model="formItems.domain2" placeholder="每行填写一个域名，多个域名换行填写"></Input>
      </FormItem>
      <FormItem label="系统首页地址" prop="sysHomePage">
        <Input type="text" v-model="formItems.sysHomePage"></Input>
      </FormItem>
      <FormItem label="允许注册" prop="allowReg">
        <RadioGroup v-model="formItems.allowReg">
          <Radio :label="1">允许</Radio>
          <Radio :label="0">禁止</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="允许取回密码" prop="allowForgot">
        <RadioGroup v-model="formItems.allowForgot">
          <Radio :label="1">允许</Radio>
          <Radio :label="0">禁止</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="系统LOGO" prop="loginLogo">
        <FNCUpload type="image" :showView="true" :defaultList="formItems.loginLogo" :showDel="true" @success="upLogoImage">
        </FNCUpload>
      </FormItem>
      <FormItem label="首页滚动图片" prop="bgimages">
        <FNCUpload type="image" :multiple="true" :fileLength="10" :showView="true" :showDel="true" :defaultList="formItems.bgimages"
          @success="upBgImages"></FNCUpload>
      </FormItem>
      <FormItem label="版权信息" prop="copyright">
        <Input type="textarea" :autosize="{ minRows: 2}" v-model="formItems.copyright"></Input>
      </FormItem>

      <FormItem label="登录主色" prop="loginColor">
        <ColorPicker v-model="formItems.loginColor" @on-active-change="handleColorChage($event, 'loginColor')"
          recommend />
      </FormItem>
      <FormItem label="门户表头背景颜色" prop="headerBgcolor">
        <ColorPicker v-model="formItems.headerBgcolor" @on-active-change="handleColorChage($event, 'headerBgcolor')"
          recommend />
      </FormItem>
      <FormItem label="门户表头字体颜色" prop="headerFontcolor">
        <ColorPicker v-model="formItems.headerFontcolor" @on-active-change="handleColorChage($event, 'headerFontcolor')"
          recommend />
      </FormItem>
      <FormItem label="门户表头选中背景颜色" prop="headerSelectedBgcolor">
        <ColorPicker v-model="formItems.headerSelectedBgcolor"
          @on-active-change="handleColorChage($event, 'headerSelectedBgcolor')" recommend />
      </FormItem>
      <FormItem label="门户表头选中字体颜色" prop="headerSelectedFontcolor">
        <ColorPicker v-model="formItems.headerSelectedFontcolor"
          @on-active-change="handleColorChage($event, 'headerSelectedFontcolor')" recommend />
      </FormItem>
      <FormItem label="门户左侧菜单颜色" prop="leftMenuBgcolor">
        <ColorPicker v-model="formItems.leftMenuBgcolor" @on-active-change="handleColorChage($event, 'leftMenuBgcolor')"
          recommend />
      </FormItem>
      <FormItem label="门户左侧菜单字体颜色" prop="leftMenuFontcolor">
        <ColorPicker v-model="formItems.leftMenuFontcolor"
          @on-active-change="handleColorChage($event, 'leftMenuFontcolor')" recommend />
      </FormItem>
      <FormItem label="门户左侧菜单选中字体颜色" prop="leftMenuSelecteFontcolor">
        <ColorPicker v-model="formItems.leftMenuSelecteFontcolor"
          @on-active-change="handleColorChage($event, 'leftMenuSelecteFontcolor')" recommend />
      </FormItem>
      <FormItem label="门户左侧菜单选中颜色" prop="leftMenuSelectedBgcolor">
        <ColorPicker v-model="formItems.leftMenuSelectedBgcolor"
          @on-active-change="handleColorChage($event, 'leftMenuSelectedBgcolor')" recommend />
      </FormItem>
      <FormItem label="门户左侧菜单子项颜色" prop="leftMenuitemBgcolor">
        <ColorPicker v-model="formItems.leftMenuitemBgcolor"
          @on-active-change="handleColorChage($event, 'leftMenuitemBgcolor')" recommend />
      </FormItem>
      <FormItem label="启禁用" prop="idisplay">
        <RadioGroup v-model="formItems.idisplay">
          <Radio :label="1">启用</Radio>
          <Radio :label="0">禁用</Radio>
        </RadioGroup>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import FNCUpload from '@/components/FNCUpload';
export default {
  components: {
    FNCUpload,
  },
  props: {
  },

  data() {
    return {
      formItemsDefault: {
        id:0,
        clientId: '',
        clientSecret: '',
        password:"",
        scope: 'all',
        grantType: 'password',
        systemName: '',
        sysHomePage: '',
        domain: [],
        domain2:'',
        loginLogo: '',
        copyright: '',
        bgimages: '',
        idisplay: 0,
        loginColor: '#17233d',
        headerBgcolor: '#00B5FF',
        headerFontcolor: '#ffffff',
        headerSelectedBgcolor: '#ffffff',
        headerSelectedFontcolor: '#2D8CF0',
        leftMenuBgcolor: '#17233D',
        leftMenuFontcolor: '#ffffff99',
        leftMenuSelecteFontcolor: '#ffffff',
        leftMenuSelectedBgcolor: '#293551',
        leftMenuitemBgcolor: '#1F2C48',
        allowForgot:0,
        allowReg:0,
      },
      formItems:{},
      ruleItems: {
        clientId: [
          { required: true, message: '租户ID不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '租户密码不能为空', trigger: 'blur' }
        ],
        scope: [
          { required: true, message: 'scope不能为空', trigger: 'blur' }
        ],
        grantType: [
          { required: true, message: 'grantType不能为空', trigger: 'blur' }
        ],
        domain2: [
          { required: true, message: '租户绑定的域名不能为空', trigger: 'blur' }
        ],
        systemName: [
          { required: true, message: '平台名称不能为空', trigger: 'blur' }
        ],
      }
    };
  },
  mounted() {

  },
  methods: {
    loadData(clientId) {
      this.formItems = {...this.formItemsDefault};
      if (clientId) {
        this.$get({
          url: "/gateway/api/manage/platform/view",
          data: { clientId },
          success: (res) => {
            if (res.code == "200" && res.data) {
              var formItems=res.data;
             if(formItems.domain) formItems.domain2=formItems.domain.join('\n');
             else formItems.domain2="";
             this.formItems=formItems;
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.formItems.domain=this.formItems.domain2.split("\n");
          callback(true)
          this.$post({url:"/gateway/api/manage/platform/save",
          data:this.formItems
          ,success:(res)=>{
            if(res.code=="200"){
              callback(false,true);
              this.$Message.success({background:true,content:"保存成功"})
            }else{
              this.$Message.error({background:true,content:res.desc||"保存出错"})
            }
          },complete:()=>{
            callback(false)
          }
        });
        } else{
          this.$Message.error({background:true,content:"部分必填项没填写"})
        }
      })
    },
    upLogoImage(file) {
      this.formItems.loginLogo = file.url;
    },
    upBgImages(file) {
      var bgimageArray=[];
      file.map(item=>{
        bgimageArray.push(item.url)
      })
      this.formItems.bgimages = JSON.stringify(bgimageArray);
    },
    handleColorChage(e, target) {
      this.formItems[target] = e;
    },
  }
};
</script>
    
<style scoped lang='less'></style>