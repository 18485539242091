<template>
    <div style="overflow-y: auto; padding: 10px">
        <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="100">
            <FormItem label="名称" prop="questionBank">
                <Input type="text" v-model="formItems.questionBank" placeholder="中文名称"></Input>
            </FormItem>
           
            <FormItem label="描述" prop="content">
                <Input
                    type="textarea"
                    :autosize="{ minRows: 3 }"
                    v-model="formItems.content"
                    placeholder="描述"
                ></Input>
            </FormItem>
        </Form>
    </div>
</template>

<script>
import FNCUpload from "@/components/FNCUpload"
export default {
    components: {
        FNCUpload,
    },
    props: {},

    data() {
        return {
            formItemsDefault: {
                id: 0,
                questionBank: "",
                content: "",
            },
            formItems: {},
            ruleItems: {
                questionBank: [{ required: true, message: "名称不能为空", trigger: "blur" }],
            },
        }
    },
    mounted() {},
    methods: {
        loadData(id) {
            this.formItems = {...this.formItemsDefault};
            if (id>0) {
                this.$get({
                    url: "/smartlife/api/questions/bankview",
                    data: { id },
                    success: res => {
                        if (res.code == "200" && res.data) {
                            this.formItems = {...this.formItems,...res.data}
                        } else {
                            this.$Message.error(res.desc || "读取数据出错")
                            this.$emit("closeModal")
                        }
                    },
                })
            }
        },
        submitData(callback) {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    callback(true)
                    this.$post({
                        url: "/smartlife/api/questions/savebank",
                        data: this.formItems,
                        success: res => {
                            if (res.code == "200") {
                                callback(false, true)
                                this.$Message.success({ background: true, content: "保存成功" })
                            } else {
                                this.$Message.error({ background: true, content: res.desc || "保存出错" })
                            }
                        },
                        complete: () => {
                            callback(false)
                        },
                    })
                } else {
                    this.$Message.error({ background: true, content: "部分必填项没填写" })
                }
            })
        },
    },
}
</script>
    
<style scoped lang='less'></style>