<template>
    <div style="">
        <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
            <FormItem label="顺序号" prop="sortNum">
                <Input type="number" v-model.number="formItems.sortNum" placeholder="顺序号：1~1000"></Input>
            </FormItem>
            <FormItem label="文件名称" prop="fileName">
                <Input v-model.trim="formItems.fileName" placeholder="文件名称"></Input>
            </FormItem>
            <FormItem label="文件编号" prop="fileNum">
                <Input v-model.trim="formItems.fileNum" placeholder="文件编号"></Input>
            </FormItem>
            <FormItem label="页码范围" prop="pageRange">
                <Input v-model.trim="formItems.pageRange" placeholder="页码范围"></Input>
            </FormItem>
            <FormItem label="时间节点" prop="timeNode">
                <Input v-model.trim="formItems.timeNode" placeholder="时间节点"></Input>
            </FormItem>
            <FormItem label="档案文件" prop="filePath">
                <FNCUpload :defaultList="filesStorage" ref="fileUploader" type="file"
                    :action="$getProxy('/smartarchives') + '/api/filemanager/upload'" :hashMaxSize="500" accept="*/*"
                    :showView="true" :showDel="true" @success="upFile"></FNCUpload>
                <input type="hidden" v-model="formItems.filePath" />
            </FormItem>
        </Form>
    </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
import FNCUpload from "@/components/FNCUpload"
export default {
    components: {
        FNCModal,
        FNCUpload,
    },
    data() {
        return {
            id: "",
            deptid: [],
            filesStorage: [],//文件列表
            formItems: {},
            ruleItems: {
                fileName: [{ required: true, message: "请填写文件名称", trigger: "blur" }],
                filePath: [{ required: true, message: "请上传文件", trigger: "change" }],
                sortNum: [{ type: "number", required: true, min: 1, message: "请填写顺序号：1~1000", trigger: "blur" }],
            },
        }
    },
    created() {
        this.loadBaseData();
    },
    mounted() {

    },
    methods: {
        loadBaseData() {

        },

        loadData(file) {
            if (file && file.id) {
                this.formItems = file
                this.filesStorage = [{ name: file.fileName, url: file.filePath }]
            } else {
                this.$Message.error({ background: true, content: "无效的档案文件信息" })
                this.$emit("closeModal");
            }
            this.$forceUpdate()
        },
        submitData(callback) {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    callback(3)
                    this.$post({
                        url: "/smartarchives/api/archivesstorage/filesave",
                        data: { ...this.formItems },
                        success: res => {
                            if (res.code == "200") {
                                callback(1, true)
                                this.$Message.success({ background: true, content: "保存成功" })
                            } else {
                                callback(1)
                                this.$Message.error({ background: true, content: res.desc || "保存出错" })
                            }
                        },
                        fail: () => {
                            callback(1)
                            this.$Message.error({ background: true, content: "保存出错" })
                        },

                    })
                } else {
                    this.$Message.error({ background: true, content: "部分必填项没填写" })
                }
            })
        },
        upFile(file) {
            // console.log(file);
            if (file && file.url) {
                this.filesStorage = [file];
                this.formItems.filePath = file.url;
                this.formItems.fileSize=file.size;
                this.formItems.fileName=file.name;
                this.formItems.fileFormat=(file.ext||"").toUpperCase();
            } else {
                this.filesStorage = [];
                this.formItems.filePath = "";
            }
        },

    },
}
</script>
    
<style scoped lang='less'>
.file-upload-list {
    width: 100%;
    text-align: left;
    padding-bottom: 5px;
    overflow: hidden;
    position: relative;
    margin-right: 4px;
    color: #2b85e4;
}
</style>