<template>
  <FNCContent>
    <template v-slot:title> {{ title }} </template>
    <template v-slot:toolsbarLeft>
      <!-- <Cascader v-model="deptid" @on-change="checkOrgRight" placeholder="科室" change-on-select clearable :data="depts"
        style="width:300px;" />
      <Select v-model="pageParams.whId" style="width: 180px" placeholder="库房" @on-change="getMcList">
        <Option v-for="item in whList" :value="item.id" :key="item.whName">{{ item.whName }}</Option>
      </Select>
      <Select v-model="pageParams.mcId" style="width: 180px" placeholder="机柜">
        <Option v-for="item in mcList" :value="item.id" :key="item.mcName">{{ item.mcName }}</Option>
      </Select>
      <Select v-model="pageParams.secretLevel" style="width: 120px" placeholder="密级">
        <Option v-for="item in secretLevelList" :value="item.value" :key="item.name">{{ item.name }}</Option>
      </Select> -->

      <div style="width:250px;"><Input search enter-button="查询" clearable v-model="pageParams.keyword"
          placeholder="输入档案名称" @on-search="loadData(1)" /></div>
    </template>
    <template v-slot:toolsbarRight>

      <Button type="info" class="addDiv" @click="() => { editData() }" v-if="right.A">
        <Icon type="md-add-circle" size="18" />创建借阅单
      </Button>
      <!-- <Button type="warning" class="deleteDiv" @click="() => { deleteData() }" v-if="right.D">
        <Icon type="md-trash" size="18" />删除
      </Button> -->

    </template>
    <template v-slot:contentArea>
      <FNCTable ref="fncTable" :loading="loading" :fixTable="true" :talbeColumns="talbeColumns" :tableData="tableData"
        :pageParams="pageParams" :pagesizeOpts="pagesizeOpts" @handleLoadData="loadData"></FNCTable>
      <FNCModal ref="editModal" title="档案编辑" :fullscreen="false" width="800px" height="750px">
        <template v-slot:contentarea>
          <Edit ref="editContent" @closeModal="closeModal"></Edit>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closeModal">
            <Icon type="md-close" />取消
          </Button>
          <Button type="primary" @click="submitData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />保存
          </Button>
        </template>
      </FNCModal>
      
    </template>
  </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/borrow
import FNCContent from "@/components/FNCContent";
import FNCTable from "@/components/FNCTable";
import FNCModal from "@/components/FNCModal";
import Edit from "./childrens/Edit";
import FNCOfficePreview from "@/components/FNCOfficePreview";
import { orgCascader } from "@/utils/orgCascader";
export default {
  components: {
    FNCTable,
    FNCContent,
    FNCModal,
    Edit,
    FNCOfficePreview,
  },

  data() {
    return {
      contentModalFlag:false,
      title: "信息列表",
      loading: false,
      pageParams: {
        maxCount: 0,//数据总条数
        currentPage: 0,//当前页(默认第一页)
        pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
        maxPage: 1,//总页数
        sort: "id",//排序字段
        order: "desc",//排序方式
        keyword: "",//通用查询条件
        keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
        orgCode: "",
      },
      pagesizeOpts: [20, 30, 50, 100],//分页数组
      tableData: [],
      talbeColumns: [
        // {
        //   type: 'selection',
        //   width: 60,
        //   fixed: "left",
        //   align: 'center'
        // },
        {
          title: "文件名称",
          key: "fileName",
          align: "center",
          minWidth: 200,
        },
        {
          title: "预计借阅时间",
          key: "expectedBorrowTime",
          align: "center",
          width: 200,
        },
        {
          title: "预计归还时间",
          key: "expectedReturnTime",
          align: "center",
          width: 100,
        },
        {
          title: "申请时间",
          key: "applyTime",
          align: "center",
          width: 120,
        },
        {
          title: "审核人",
          key: "auditUser",
          align: "center",
          width: 120,
        },
        {
          title: "审核时间",
          key: "auditTime",
          align: "center",
          width: 120,
        },

        {
          title: "领取交接人",
          key: "destroyUser",
          align: "center",
          width: 120,
        },
        {
          title: "领取时间",
          key: "destroyTime",
          align: "center",
          width: 120,
        },

        {
          title: "归还交接人",
          key: "returnUser",
          align: "center",
          width: 120,
        },
        {
          title: "归还时间",
          key: "returnTime",
          align: "center",
          width: 120,
        },


        {
          title: "状态",
          key: "status",
          align: "center",
          width: 100,
          render: (h, params) => {
            return h("div", this.convertObject("borrowStatusList", "value", "name", params.row.status+""))
          },
        },
        
        // {
        //   title: "操作",
        //   slot: "action",
        //   width: 300,
        //   align: "center",
        //   fixed: "right",
        //   render: (h, params) => {
        //     return h("div",
        //       [
        //         h(
        //           "Button",
        //           {

        //             class: "editDiv"
        //             ,
        //             props: {
        //               type: "primary",
        //               size: "small",
        //             },
        //             style: {
        //               margin: "0 3px",
        //             },

        //             on: {
        //               click: () => {
        //                 this.fileListWin(params.row);
        //               },
        //             },
        //           },
        //           "文件明细"
        //         ),
        //       ]);
        //   },
        // },
      ],
      right: {},
      saveLoading: false,
      infoType: "",
      infoTypeList: [],
      borrowStatusList: [],
    };
  },
  created() {
    // this.orgCascader = orgCascader("dept");
    // this.deptid = this.orgCascader.convectCascaderList(this.$core.getBranchUserInfo("deptid"));
    // this.orgCascader.loadData(
    //   (depts) => {
    //     this.depts = depts;
    //   }, this.deptid[this.deptid.length - 1]);
  },
  mounted() {
    this.loadModuleInfo();
  },
  methods: {
    loadModuleInfo() {
      //读取栏目信息
      this.$get({
        url: "/gateway/api/manage/type/moduleinfo",
        data: {
          functionId: this.$core.getFunctionId(),
        },
        success: (res) => {
          if (res.code == "200" && res.data) {
            this.title = res.data.typename;
          }
        }
      });
      this.$core.getStyleParamList("borrowStatus", false, "", res => {
        this.archiveTypeList = res || []
      })
      this.$core.getStyleParamList("borrowStatus", true, "全部状态", res => {
        this.borrowStatusList = res || [{ name: "全部状态", value: "" }]
      })
      
      //读取数据列表
      this.loadData(1);
      this.$core.getControlRight().then(res => {
        this.right = res;
      });
    },
    convertObject(target, inFeild, outFeild, value) {
      var object = this[target].find(item => value == item[inFeild])
      return object ? object[outFeild] : "无"
    },
    loadData(currentPage) {
      if (currentPage) {
        this.pageParams.currentPage = currentPage;
      } else {
        this.pageParams.currentPage = this.pageParams.currentPage + 1;
      }
      this.loading = true;
      this.$get({
        url: "/smartarchives/api/borrow/queryBorrow",
        data: this.pageParams,
        success: (res) => {
          this.tableData = []
          if (res.code == "200" && res.pageParams) {
            this.pageParams = { ...this.pageParams, ...res.pageParams }
            this.tableData = res.dataList
          } else {
            this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
          }
          this.loading = false;
        }
      })
    },
    
    
    editData(id) {
      if (id && this.right["E"] == true || !id && this.right["A"] == true) {
        this.$refs.editModal.showModal();
        this.$refs.editContent.loadData(id);
      }
    },
    closeModal() {
      this.$refs.editModal.hideModal();
    },
    submitData() {
      this.$refs.editContent.submitData((b, reload) => {
        this.saveLoading = b;
        if (reload == true) {
          this.loadData(1);
          // this.closeModal();
          this.$refs.editModal.hideModal()
        }
      });
    },
   
    
    checkOrgRight(e) {
      this.deptid = this.orgCascader.checkOrgRight(e);
      this.pageParams.orgCode = this.deptid[this.deptid.length - 1]
    },
  
  
    convertSize(size) {
      if (!size) {
        return "-";
      } else if (size < 1024) {
        return size + "B";
      } else if (size < 1024 * 1024) {
        return (size / (1024)).toFixed(3) + "KB";
      } else {
        return (size / (1024 * 1024)).toFixed(3) + "MB";
      }
    },
    
  }
};
</script>
    
<style scoped></style>
<style scoped lang='less'></style>