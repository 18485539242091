<template>
    <div style="height: 500px; overflow-y: auto; padding: 10px">
        <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="200">
            <FormItem label="商户应用" prop="branchId">
                <Select v-model="formItems.branchId" style="width: 180px; float: left; margin-right: 10px">
                    <Option v-for="item in branchInfo" :value="item.id" :key="'Branch' + item.id">{{ item.branchname }}</Option>
                </Select>
            </FormItem>
            <FormItem label="应用类型" prop="smsPlatform">
                <Select v-model="formItems.smsPlatform" style="width: 180px; float: left; margin-right: 10px" @on-change="initTemplateModel">
                    <Option v-for="item in smsPlatform" :value="item.value" :key="item.value">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="短信签名" prop="smsSignature">
                <Input type="text" v-model="formItems.smsSignature" placeholder="短信签名"></Input>
            </FormItem>
            <FormItem label="Appid" prop="appId">
                <Input type="text" v-model="formItems.appId" placeholder="Appid"></Input>
            </FormItem>
            <FormItem label="SecretId" prop="secretId">
                <Input type="text" v-model="formItems.secretId" placeholder="SecretId"></Input>
            </FormItem>
            <FormItem label="SecretKey" prop="secretKey">
                <Input type="text" v-model="formItems.secretKey" placeholder="SecretKey"></Input>
            </FormItem>
            <FormItem label="模板JSON" prop="templateJson">
                <Input type="textarea" :autosize="{ minRows: 5 }" v-model="formItems.templateJson"></Input>
                <Button style="margin: 5px" type="success" @click="verifyJson">格式化 / 验证JSON合法性</Button>
            </FormItem>
        </Form>
    </div>
</template>

<script>
import FNCUpload from "@/components/FNCUpload"
export default {
    components: {
        FNCUpload,
    },
    props: {
        smsPlatform: {
            type: Array,
            default: () => {
                return []
            },
        },
        branchInfo: {
            type: Array,
            default: () => {
                return []
            },
        },
    },

    data() {
        return {
            formItemsDefault: {
                branchId: "",
                smsSignature: "",
                smsPlatform: "",
                id: 0,
                appId: "",
                secretId: "",
                secretKey: "",
                templateJson: "",
            },
            formItems: {},
            ruleItems: {
                branchId: [{ type: "number", required: true, message: "商户应用不能为空", trigger: "change" }],
                smsPlatform: [{ required: true, message: "短信平台不能为空", trigger: "change" }],
                smsSignature: [{ required: true, message: "短信签名不能为空", trigger: "blur" }],
                appId: [{ required: true, message: "appId不能为空", trigger: "blur" }],
                templateJson: [{ required: true, message: "模板JSON不能为空", trigger: "blur" }],
            },
            templateModel: {
                bluecat: { register: "注册验证码：${0}", forget: "取回密码验证码：${0}", login: "登录验证码：${0}", general: "${0}" },
                tencent: { register: "注册模板ID", forget: "取回密码模板ID", login: "登录模板ID" },
            },
        }
    },
    mounted() {},
    methods: {
        loadData(id) {
            this.formItems = {...this.formItemsDefault};
            if (id) {
                this.$get({
                    url: "/gateway/api/manage/platform/smsconfig/view",
                    data: { id },
                    success: res => {
                        if (res.code == "200" && res.data) {
                            this.formItems = { ...this.formItems, ...res.data }
                        } else {
                            this.$Message.error(res.desc || "读取数据出错")
                            this.$emit("closeModal")
                        }
                    },
                })
            }
        },
        submitData(callback) {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    callback(true)
                    this.$post({
                        url: "/gateway/api/manage/platform/smsconfig/save",
                        data: this.formItems,
                        success: res => {
                            if (res.code == "200") {
                                callback(false, true)
                                this.$Message.success({ background: true, content: "保存成功" })
                            } else {
                                this.$Message.error({ background: true, content: res.desc || "保存出错" })
                            }
                        },
                        complete: () => {
                            callback(false)
                        },
                    })
                } else {
                    this.$Message.error({ background: true, content: "部分必填项没填写" })
                }
            })
        },
        initTemplateModel(e) {
            if (this.templateModel[e]) {
                this.formItems.templateJson = JSON.stringify(this.templateModel[e], null, "\t")
            }
        },
        verifyJson() {
            //验证JSON
            try {
                if (!this.formItems.templateJson) {
                    this.$Modal.warning({ title: "验证结果", content: "没有JSON数据。" })
                } else {
                    var json = JSON.parse(this.formItems.templateJson)
                    this.$Modal.success({ title: "验证结果", content: "JSON数据正确。" })
                    this.formItems.templateJson = JSON.stringify(json, null, "\t")
                }
            } catch (e) {
                this.$Modal.error({
                    title: "验证结果",
                    content: "JSON数据有误，请检查。",
                })
            }
        },
    },
}
</script>

<style scoped lang='less'></style>