<template>
    <div style="padding: 0px; height: 600px;">
        <FNCTable
            ref="fncTable"
            :loading="tableList.loading"
            :fixTable="false"
            :height="600"
            :talbeColumns="talbeColumns"
            :tableData="tableList.dataList"
            :pageParams="tableList.pageParams"
            :pagesizeOpts="tableList.pagesizeOpts"
            @handleLoadData="loadPageData"
        ></FNCTable>
    </div>
</template>

<script>
import FNCTable from "@/components/FNCTable"
import { pageList } from "@/utils/pageList"
import { render } from "less"
export default {
    components: {
        FNCTable,
    },

    data() {
        return {
            id: 0,
            tableList: {},
            talbeColumns: [
                {
                    title: "排名",
                    key: "rank",
                    width: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("span", { style: params.row.rank < 4 ? "color:red" : "" }, params.row.rank)
                    },
                },
                {
                    title: "用户",
                    key: "userName",
                    align: "center",
                    minWidth: 200,
                },
                {
                    title: "得分",
                    key: "score",
                    align: "center",
                    width: 200,
                },
                {
                    title: "时长（秒）",
                    key: "timeTotal",
                    align: "center",
                    width: 200,
                },
                {
                    title: "对题数",
                    key: "successTotal",
                    align: "center",
                    width: 120,
                },
                {
                    title: "错题数",
                    key: "failTotal",
                    align: "center",
                    width: 120,
                },
            ],
        }
    },
    created() {
        this.tableList = pageList(this.$get)
        this.tableList.pageParams.sort = "rownum"
        this.tableList.pageParams.order = "asc"
        this.tableList.pageParams.pageSize = 10
    },
    methods: {
        loadData(id, module) {
            this.tableList.dataList=[]
            if (id > 0) {
                this.id = id
                this.module = module
                this.loadPageData(1)
            } else {
                this.$Message.error("无效的答题信息")
            }
        },
        loadPageData(currentPage) {
            this.tableList.loadData({
                url: "/smartlife/api/answer/selectAnswerRanking",
                currentPage: currentPage,
                data: { answerId: this.id, module: this.module },
                success: res => {
                    if (res.code != "200") {
                        this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
                    }
                },
            })
        },
    },
}
</script>

<style scoped lang="less"></style>
