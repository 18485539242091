<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="品牌名称" prop="brandName">
        <Input type="text" v-model="formItems.brandName" placeholder="输入品牌名称"></Input>
      </FormItem>
      <FormItem label="品牌编码" prop="brandCode">
        <Input type="text" v-model="formItems.brandCode" placeholder="输入品牌编码"></Input>
      </FormItem>
      <FormItem label="品牌型号" prop="brandModel">
        <Input type="text" v-model="formItems.brandModel" placeholder="输入品牌型号"></Input>
      </FormItem>
      <FormItem label="通讯方式" prop="commModel">
        <Input type="text" v-model="formItems.commModel" placeholder="输入通讯方式"></Input>
      </FormItem>
      <FormItem label="厂商名称" prop="manufacturer">
        <Input type="text" v-model="formItems.manufacturer" placeholder="输入厂商名称"></Input>
      </FormItem>
      <FormItem label="品牌图片" prop="brandPicture">
          <FNCUpload type="image" style="float:left" :showView="true" :defaultList="formItems.brandPicture" :showDel="true"
          @success="upLogoImage">
        </FNCUpload>
        </FormItem>
    </Form>
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
import FNCUpload from '@/components/FNCUpload'
export default {
  components: {
    FNCUpload,
    FNCModal,
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: null,
        brandName:"",
        brandCode:"",
        brandModel:"",
        commModel:"",
        manufacturer:"",
        globalUserId:"",
        createTime:null,
        branchId:0,
      }),
      formItems: {},
      ruleItems: {
        brandCode: [
          { required: true, message: '品牌编码不能为空', trigger: 'blur' }
        ],
        brandName: [
          { required: true, message: '品牌名称不能为空', trigger: 'blur' }
        ],
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,
    };
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
     loadData(id) {
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.$get({
          url: "/smartsmoke/brandinfo/queryBrandinfoDetail",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },
    /**
    * 获取选项数据
    */
    loadBaseData() {
      
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          this.$post({
            url: "/smartsmoke/brandinfo/createOrUpdateBrandinfo",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    
    classModal() {
      this.$refs.mapModal.hideModal();
    },
    upLogoImage(file) {
      this.formItems.brandPicture = file.url;
    },
    
  }
};
</script>
    
<style scoped lang='less'></style>