<template>
    <FNCContent>
        <template v-slot:title>{{ title }}</template>
        <template v-slot:toolsbarLeft>
            <Select v-model="tableList.pageParams.status" style="width: 120px" placeholder="全部状态">
                <Option v-for="item in importStatus" :value="item.value" :key="item.name">{{ item.name }}</Option>
            </Select>
            <div style="width: 250px"><Input search enter-button="查询" clearable v-model="tableList.pageParams.keyword"
                    placeholder="输入批次号/文件名称" @on-search="loadData(1)" /></div>
        </template>
        <template v-slot:toolsbarRight>
            <Button type="primary" class="addDiv" @click="() => {
                editData(2)
            }
                " v-if="right.A">
                <Icon type="md-cloud-upload" size="18" />
                批量导入
            </Button>
        </template>
        <template v-slot:contentArea>
            <FNCTable ref="fncTable" :loading="loading" :fixTable="true" :talbeColumns="talbeColumns"
                :tableData="tableList.dataList" :pageParams="tableList.pageParams" :pagesizeOpts="tableList.pagesizeOpts"
                @handleLoadData="loadData"></FNCTable>
            <FNCModal ref="editModal" title="导入档案" :fullscreen="false" width="800px" :height="height">
                <template v-slot:contentarea>
                    <Edit ref="editContent"></Edit>
                </template>
                <template v-slot:toolsbar>
                    <Button style="width: 200px" type="primary" @click="submitData" :loading="saveLoading == 3">
                        <Icon type="md-checkmark-circle" />
                        {{ saveLoading == 2 ? "后台执行" : saveLoading == 4 ? "关闭" : "保存" }}
                    </Button>
                </template>
            </FNCModal>
        </template>
    </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/importstorage
import FNCContent from "@/components/FNCContent"
import FNCTable from "@/components/FNCTable"
import FNCModal from "@/components/FNCModal"
import Edit from "./childrens/Edit"
import { pageList } from "@/utils/pageList"
export default {
    components: {
        FNCTable,
        FNCContent,
        FNCModal,
        Edit,
    },

    data() {
        return {
            title: "信息列表",
            loading: true,
            tableList: {},
            talbeColumns: [
                // {
                //   type: 'selection',
                //   width: 60,
                //   fixed: "left",
                //   align: 'center'
                // },
                {
                    title: "批次号",
                    key: "id",
                    align: "center",
                    width: 180,
                },
                {
                    title: "Excel文件名称",
                    key: "excelName",
                    align: "center",
                    width: 250,
                },
                {
                    title: "Excel大小",
                    key: "excelSize",
                    align: "center",
                    width: 120,
                    render: (h, params) => {
                        return h("span", this.convertSize(params.row.excelSize))
                    },
                },
                {
                    title: "档案数",
                    key: "excelCount",
                    align: "center",
                    minWidth: 120,
                },
                {
                    title: "ZIP包名称",
                    key: "zipName",
                    align: "center",
                    width: 250,
                },
                {
                    title: "ZIP大小",
                    key: "zipSize",
                    align: "center",
                    width: 120,
                    render: (h, params) => {
                        return h("span", this.convertSize(params.row.zipSize))
                    },
                },
                {
                    title: "文件数",
                    key: "zipCount",
                    align: "center",
                    minWidth: 120,
                },
                {
                    title: "存在时的执行方式",
                    key: "exitsAction",
                    align: "center",
                    width: 200,
                    render: (h, params) => {
                        return h("span", params.row.exitsAction == "insert" ? "继续入库" : "跳过不入库")
                    }
                },
                {
                    title: "没有文件时的执行方式",
                    key: "noFilesAction",
                    align: "center",
                    width: 200,
                    render: (h, params) => {
                        return h("span", params.row.noFilesAction == "all" ? "全部入库" : "文件存在时入库")
                    }
                },
                {
                    title: "导入时间",
                    key: "importTime",
                    align: "center",
                    width: 200,
                },


                {
                    title: "状态",
                    key: "reason",
                    align: "center",
                    fixed: "right",
                    minWidth: 500,
                    align: "left",
                    render: (h, params) => {
                        return h("div",
                            [h("Tooltip", {
                                props: { transfer: true, placement: "left", content: params.row.reason, "max-width": "450" },
                                style: { width: "100%" }
                            },
                                [
                                    h("Icon", {
                                        props: { type: "md-text", size: "18" },
                                    }),
                                    h("span",
                                        params.row.reason && params.row.reason.length > 36 ? params.row.reason.substring(0, 36) + "..." : params.row.reason
                                    ),
                                    h("Progress",
                                        {
                                            props: {
                                                status: params.row.status == 3 ? "wrong" : params.row.status == 2 ? "success" : "active",
                                                percent: !params.row.status ? 0 : params.row.status == 1 || params.row.status == 3 ? Math.ceil(100 * params.row.importCount / params.row.excelCount) : 100,
                                            }
                                        }
                                    )
                                ])
                            ]
                        )
                    },
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 180,
                    align: "right",
                    fixed: "right",
                    render: (h, params) => {
                        return h("div", [

                            h(
                                "Button",
                                {
                                    class: "editDiv",
                                    props: {
                                        type: params.row.status == 3 ? "primary" : params.row.status == 2 ? "dashed" : "warning",
                                        size: "small",
                                    },
                                    style: {
                                        margin: "0 5px",
                                        width: "80px",
                                    },

                                    on: {
                                        click: () => {
                                            if (params.row.status == 3)
                                                this.reImportData(params.row.id)
                                            else if (params.row.status == 1)
                                                this.stopImportData(params.row.id)
                                        },
                                    },
                                },
                                params.row.status == 3 ? "重新导入" : params.row.status == 2 ? "已完成" : "终止导入"
                            ),

                            h(
                                "Button",
                                {
                                    class: "deleteDiv",
                                    props: {
                                        type: params.row.status == 1 ? "dashed" : "error",
                                        size: "small",
                                    },
                                    on: {
                                        click: () => {
                                            if (params.row.status == 1) {
                                                this.$Message.error({ background: true, content: "正在导入中，请选终止导入，再删除", duration: 3 })
                                            } else {
                                                this.deleteData(params.row.id)
                                            }
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],
            right: {},
            saveLoading: 1,
            height: "750px",
            importStatus: [],
            timer: null,
        }
    },
    created() {
        this.tableList = pageList(this.$get)
    },
    mounted() {
        this.loadModuleInfo()
    },
    beforeDestroy() {
        //销毁前清除定时器
        clearTimeout(this.timer);
        this.timer = -1021;
    },
    methods: {
        loadModuleInfo() {
            //读取栏目信息
            this.$get({
                url: "/gateway/api/manage/type/moduleinfo",
                data: {
                    functionId: this.$core.getFunctionId(),
                },
                success: res => {
                    if (res.code == "200" && res.data) {
                        this.title = res.data.typename
                    }
                },
            })
            this.$core.getStyleParamList("importStatus", true, "全部状态", res => {
                this.importStatus = res || [{ name: "全部状态", value: "" }]
            })
            //读取数据列表
            this.loadData(1)
            this.$core.getControlRight().then(res => {
                this.right = res
            })
        },
        convertSize(size) {
            if (!size) {
                return "-";
            } else if (size < 1024) {
                return size + "B";
            } else if (size < 1024 * 1024) {
                return (size / (1024)).toFixed(3) + "KB";
            } else {
                return (size / (1024 * 1024)).toFixed(3) + "MB";
            }
        },
        loadData(currentPage, isTick) {
            if (!isTick) {
                this.loading = true;
            }
            var that = this;
            clearTimeout(that.timer);
            this.tableList.loadData({
                url: "/smartarchives/api/archivesstorage/batchlist",
                currentPage: currentPage,
                data: {},
                success: res => {
                    if (res.code != "200") {
                        this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
                    }
                    if (this.timer > -1021 && res.dataList && res.dataList.findIndex(item => item.status == 1) > -1) {
                        that.timer = setTimeout(() => {
                            that.loadData(that.tableList.pageParams.currentPage, true);
                        }, 5000);
                    }
                },
                fail: () => {
                    this.loading = false;
                },
                complete: (res) => {
                    this.loading = false;
                }
            })
        },
        deleteData(id) {
            if (this.right["D"] == false) {
                this.$Message.error({ background: true, content: "你没有删除权限" })
                return
            }

            this.$Modal.confirm({
                title: "删除确认",
                content: "删除批次记录，同时岀除同批次未整编的入库档案记录，确认要删除选中吗？",
                onOk: () => {
                    this.$post({
                        url: "/smartarchives/api/archivesstorage/deleteimport",
                        data: { id: id },
                        success: res => {
                            if (res.code == "200") {
                                this.$Message.success({ background: true, content: res.desc })
                                this.loadData(1)
                            } else {
                                this.$Message.error({ background: true, content: res.desc })
                            }
                        },
                    })
                },
            })
        },
        editData(type) {
            if (this.right["A"] == true) {
                if (type == 2) {
                    this.height = "560px"
                } else {
                    this.height = "750px"
                }
                this.saveLoading = 1
                this.$refs.editModal.showModal();
                this.$refs.editContent.loadData(type);
            }
        },
        stopImportData(id) {
            if (this.right["A"] == false && this.right["E"] == false) {
                this.$Message.error({ background: true, content: "你没有终止导入权限" })
                return
            }
            this.$Modal.confirm({
                title: "终止确认",
                content: "终止导入将删除该批次已导入的数据，是否继续？",
                onOk: () => {
                    this.$Message.loading({ content: "正在执行终止导入工作", duration: 0 });
                    this.$post({
                        url: "/smartarchives/api/archivesstorage/stopimport",
                        data: { id },
                        success: res => {
                            this.$Message.destroy()
                            if (res.code == "200") {
                                this.loadData(this.tableList.pageParams.currentPage);
                                this.$Message.success({ background: true, content: "该批次导入已终止。" })
                            } else {
                                this.$Message.error({ background: true, content: res.desc || "执行出错" })
                            }
                        },
                        fail: () => {
                            this.$Message.destroy()
                            this.$Message.error({ background: true, content: "执行出错" })
                        }, complete: () => {
                        }
                    })
                },
            })
        },
        reImportData(id) {
            if (this.right["A"] == false && this.right["E"] == false) {
                this.$Message.error({ background: true, content: "你没有重新导入权限" })
                return
            }
            this.$Modal.confirm({
                title: "重新导入确认",
                content: "重新导入会按原来设定的“存在时”、“没有文件时”的执行规则导入数据，但不会覆盖该批次之前导入的数据，您确认要重新导入吗？",
                onOk: () => {
                    this.$Message.loading({ content: "正在执行导入工作", duration: 0 });
                    this.$post({
                        url: "/smartarchives/api/archivesstorage/reimportsave",
                        data: { id },
                        success: res => {
                            this.$Message.destroy()
                            if (res.code == "200") {
                                this.loadData(this.tableList.pageParams.currentPage);
                                this.$Message.success({ background: true, content: "正在导入中，导入可能需要较长时间，请稍候" })
                            } else {
                                this.$Message.error({ background: true, content: res.desc || "导入出错" })
                            }
                        },
                        fail: () => {
                            this.$Message.destroy()
                            this.$Message.error({ background: true, content: "导入出错" })
                        }, complete: () => {
                        }
                    })
                },
            })
        },
        submitData() {
            this.$refs.editContent.submitData((b, c) => {
                if (c) {
                    this.loadData(1)
                    this.$refs.editModal.hideModal()
                }
                this.saveLoading = b
            })
        },
    },
}
</script>
    
<style scoped lang='less'></style>