<template>
  <FNCContent>
      <template v-slot:title>{{ title }}</template>
      <template v-slot:toolsbarLeft></template>
      <template v-slot:toolsbarRight>
          <Button
              type="info"
              class="addDiv"
              @click="
                  () => {
                      editData()
                  }
              "   v-if="right.A"
          >
              <Icon type="md-add-circle" />
              添加
          </Button>
      </template>
      <template v-slot:contentArea>
          <FNCTable
              :loading="tableList.loading"
              :fixTable="true"
              :talbeColumns="talbeColumns"
              :tableData="tableList.dataList"
              :pageParams="tableList.pageParams"
              :pagesizeOpts="tableList.pagesizeOpts"
              @handleLoadData="loadData"
          ></FNCTable>
          <FNCModal ref="editModal" title="编辑窗" width="800px">
              <template v-slot:contentarea>
                  <Edit ref="editContent" @closeModal="closeModal" :branchInfo="branchInfo" :smsPlatform="smsPlatform"></Edit>
              </template>
              <template v-slot:toolsbar>
                  <Button type="info" style="margin-right: 8px" @click="closeModal">
                      <Icon type="md-close" />
                      取消
                  </Button>
                  <Button type="primary" @click="submitData" :loading="saveLoading">
                      <Icon type="md-checkmark-circle" />
                      保存
                  </Button>
              </template>
          </FNCModal>
      </template>
  </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/smsconfig
import FNCContent from "@/components/FNCContent"
import FNCTable from "@/components/FNCTable"
import FNCModal from "@/components/FNCModal"
import Edit from "./childrens/SmsEdit"
import { pageList } from "@/utils/pageList"
export default {
  components: {
      FNCTable,
      FNCContent,
      FNCModal,
      Edit,
  },

  data() {
      return {
          title:"短信平台配置",
          tableList: {},
          talbeColumns: [
              {
                  title: "商户应用",
                  key: "branchId",
                  align: "center",
                  width: 300,
                  render: (h, params) => {
                    return h("span",
                        this.getBranchName(params.row.branchId)
                    )
                  },
              },
              {
                  title: "短信平台",
                  key: "smsPlatform",
                  align: "center",
                  width: 200,
                  render: (h, params) => {
                    return h("span",
                        this.getSmsPlatformName(params.row.smsPlatform)
                    )
                  },
              },
              {
                  title: "短信签名",
                  key: "smsSignature",
                  align: "center",
                  width: 200,
               
              },
              {
                  title: "AppId",
                  key: "appId",
                  align: "center",
                  width: 200,
               
              },
              {
                  title: "secretId",
                  key: "secretId",
                  align: "center",
                  minWidth: 300,
               
              },
              {
                  title: "操作",
                  slot: "action",
                  fixed: "right",
                  width: 150,
                  align: "center",
                  render: (h, params) => {
                      return h("div", [
                                h(
                                    "Button",
                                    {
                                        class: "editDiv",
                                        props: {
                                            type: "primary",
                                            size: "small",
                                        },
                                        style: {
                                            margin: "0 5px",
                                        },

                                        on: {
                                            click: () => {
                                                this.editData(params.row.id)
                                            },
                                        },
                                    },
                                    "修改"
                                ),

                                h(
                                    "Button",
                                    {
                                        class: "deleteDiv",
                                        props: {
                                            type: "error",
                                            size: "small",
                                        },
                                        on: {
                                            click: () => {
                                                this.deleteData(params.row)
                                            },
                                        },
                                    },
                                    "删除"
                                ),
                            ])
                  },
              },
          ],
          right: {},
          saveLoading: false,
          smsPlatform:[],
          branchInfo:[]
      }
  },
  mounted() {
      this.tableList = pageList(this.$get)
      this.loadModuleInfo()
  },
  methods: {
    loadModuleInfo() {
          //读取栏目信息
          this.$get({
              url: "/gateway/api/manage/type/moduleinfo",
              data: {
                  functionId: this.$core.getFunctionId(),
              },
              success: res => {
                  if (res.code == "200" && res.data) {
                      this.title = res.data.typename
                  }
              },
          })
          
          //读取商户应用信息
          this.$get({
              url: "/gateway/api/manage/branch/list",
              data: {
                  pagesize: 100000,
                  order: "asc",
                  sort:"branchname",
                  keyword:""
              },
              success: res => {
                  if (res.rows) {
                      this.branchInfo = res.rows
                  }
              },
          })
          
          //读取微信应用类型
          this.$core.getStyleParamList("smsPlatform", true, "", res => {
                this.smsPlatform = res || []
            })

          //读取数据列表
          this.loadData(1)
          this.$core.getControlRight().then(res => {
              this.right = res
          })
      },
      loadData(currentPage) {
          this.tableList.loadData({
              url: "/gateway/api/manage/platform/smsconfig/list",
              currentPage: currentPage,
              data: {},
              success: res => {
                  if (res.code != "200") {
                      this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
                  }
              }
          })
      },
      
      deleteData(row) {
        if (this.right["D"] == false) {
                this.$Message.error({ background: true, content: "你没有删除权限" })
                return
            }
          this.$Modal.confirm({
              title: "删除确认",
              content: '您确认要<span style="color:red"> ' + row.smsSignature + " </span>吗？",
              onOk: () => {
                  this.$post({
                      url: "/gateway/api/manage/platform/smsconfig/delete",
                      data: { ids: row.id },
                      success: res => {
                          if (res.code == "200") {
                              this.$Message.success({ background: true, content: res.desc })
                              this.loadData(1)
                          } else {
                              this.$Message.error({ background: true, content: res.desc })
                          }
                      },
                  })
              },
          })
      },
      editData(id) {
          if ((id && this.right["E"] == true) || (!id && this.right["A"] == true)) {
              this.$refs.editModal.showModal()
              this.$refs.editContent.loadData(id)
          }
      },
      closeModal() {
          this.$refs.editModal.hideModal()
      },
      submitData() {
          this.$refs.editContent.submitData((b, reload) => {
              this.saveLoading = b
              if (reload == true) {
                  this.loadData(1)
                  this.closeModal()
              }
          })
      },
      getBranchName(branchId){
        for(let i=0;i<this.branchInfo.length;i++){
            if(this.branchInfo[i].id==branchId){
                return this.branchInfo[i].branchname
            }
        }
      },
      getSmsPlatformName(smsPlatform){
        for(let i=0;i<this.smsPlatform.length;i++){
            if(this.smsPlatform[i].value==smsPlatform){
                return this.smsPlatform[i].name
            }
        }
      },
  },
}
</script>
  
<style scoped lang='less'></style>