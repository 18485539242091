/**
 * 各类地图坐标转换
*/
//转换常数
var x_pi = 3.14159265358979324 * 3000.0 / 180.0;
var pi = 3.14159265358979324;
var a = 6378245.0;
var ee = 0.00669342162296594323;

function transformLon(x, y) {
    var ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x));
    ret += (20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0 / 3.0;
    ret += (20.0 * Math.sin(x * pi) + 40.0 * Math.sin(x / 3.0 * pi)) * 2.0 / 3.0;
    ret += (150.0 * Math.sin(x / 12.0 * pi) + 300.0 * Math.sin(x / 30.0 * pi)) * 2.0 / 3.0;
    return ret;
};

function transformLat(x, y) {
    var ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x));
    ret += (20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0 / 3.0;
    ret += (20.0 * Math.sin(y * pi) + 40.0 * Math.sin(y / 3.0 * pi)) * 2.0 / 3.0;
    ret += (160.0 * Math.sin(y / 12.0 * pi) + 320 * Math.sin(y * pi / 30.0)) * 2.0 / 3.0;
    return ret;
}

function outOfChina(lat, lng) {
    if (lng < 72.004 || lng > 137.8347)
        return true;
    if (lat < 0.8293 || lat > 55.8271)
        return true;
    return false;
}

/*
* WGS-84：是国际标准，GPS坐标（Google Earth使用、或者GPS模块、天地图）
* GCJ-02：中国坐标偏移标准，Google Map、高德、腾讯使用
* BD-09：百度坐标偏移标准，Baidu Map使用
*/

/**
 * wgLat 纬度
 * wgLon 经度
 * WGS-84 到 GCJ-02 的转换（即 GPS 加偏）
 * */
function wgs_gcj_encrypts(wgLat, wgLon) {
    var point = {};
    if (outOfChina(wgLat, wgLon)) {
        point.lat = wgLat;
        point.lng = wgLon;
        return point;
    }
    var dLat = transformLat(wgLon - 105.0, wgLat - 35.0);
    var dLon = transformLon(wgLon - 105.0, wgLat - 35.0);
    var radLat = wgLat / 180.0 * pi;
    var magic = Math.sin(radLat);
    magic = 1 - ee * magic * magic;
    var sqrtMagic = Math.sqrt(magic);
    dLat = (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * pi);
    dLon = (dLon * 180.0) / (a / sqrtMagic * Math.cos(radLat) * pi);
    var lat = wgLat + dLat;
    var lng = wgLon + dLon;
    point.lat = lat;
    point.lng = lng;
    return point;
};


/**
 * wgLat 纬度
 * wgLon 经度
 * BD-09转换GCJ-02
 * 百度转google
 * */
function bd_google_encrypt(bd_lat, bd_lon) {
    var point = {};
    var x = bd_lon - 0.0065;
    var y = bd_lat - 0.006;
    var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
    var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
    var gg_lon = z * Math.cos(theta);
    var gg_lat = z * Math.sin(theta);
    point.lat = gg_lat;
    point.lng = gg_lon;
    return point;
};


/**
 * gg_lat 纬度
 * gg_lon 经度
 * GCJ-02转换BD-09
 * Google地图经纬度转百度地图经纬度
 * */
function google_bd_encrypt(gg_lat, gg_lon) {
    var point = {};
    var x = gg_lon;
    var y = gg_lat;
    var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
    var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
    var bd_lon = z * Math.cos(theta) + 0.0065;
    var bd_lat = z * Math.sin(theta) + 0.006;
    point.lat = bd_lat;
    point.lng = bd_lon;
    return point;
};


/**
 * wgLat 纬度
 * wgLon 经度
 * GCJ-02 到 WGS-84 的转换
 * */
function gcj_wgs_encrypts(wgLat, wgLon) {
    var point = {};
    if (outOfChina(wgLat, wgLon)) {
        point.lat = wgLat;
        point.lng = wgLon;
        return point;
    }
    var dLat = transformLat(wgLon - 105.0, wgLat - 35.0);
    var dLon = transformLon(wgLon - 105.0, wgLat - 35.0);
    var radLat = wgLat / 180.0 * pi;
    var magic = Math.sin(radLat);
    magic = 1 - ee * magic * magic;
    var sqrtMagic = Math.sqrt(magic);
    dLat = (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * pi);
    dLon = (dLon * 180.0) / (a / sqrtMagic * Math.cos(radLat) * pi);
    var lat = wgLat - dLat;
    var lng = wgLon - dLon;
    point.lat = lat;
    point.lng = lng;
    return point;
};

/**
* @Description 天地图转腾讯(高德)地图
* @Param lat, lng
* @return {}
**/
export function convertTDTToWX(lat, lng) {
    return wgs_gcj_encrypts(lat, lng);
};

/**
* @Description 腾讯(高德)地图转天地图
* @Param lat, lng
* @return {}
**/
export function convertWXToTDT(lat, lng) {
    return gcj_wgs_encrypts(lat, lng);
};

/**
* @Description 百度地图转腾讯(高德)地图
* @Param lat, lng
* @return {}
**/
export function convertBDToWX(lat, lng) {
    return bd_google_encrypt(lat, lng);
};
/**
* @Description 腾讯(高德)地图转百度地图
* @Param lat, lng
* @return {}
**/
export function convertWXToBD(lat, lng) {
    return google_bd_encrypt(lat, lng);
};
/**
  * @Description 天地图(google、GPS模块)地图转百度地图：WGS-84 => GCJ-02 => BD-09
  * 政府官方免费，天地图：http://lbs.tianditu.gov.cn/home.html
* @Param lat, lng
* @return {}
  * */
export function convertTiandituToBD(lat, lng) {
    var point = wgs_gcj_encrypts(lat, lng);
    point = google_bd_encrypt(point.lat, point.lng)
    return point;
};


/**
 * @Description 百度地图转天地图(google、GPS模块)地图：BD-09 => GCJ-02 => WGS-84
 * @Param lat, lng
 * @return {}
* */
export function convertBDToTdt(lat, lng) {
    var point = bd_google_encrypt(lat, lng);
    point = gcj_wgs_encrypts(point.lat, point.lng)
    return point;
};

/**
 * @Description 天地图地图加载，适用H5和web端
* */
export async function createTiandituMap(mapDiv, ak, center, zoom) {
    var head = document.head;
    //检查MAP链是否存在
    var hasMap = false;
    for (let i = 0; i < head.children.length; i++) {
        if (head.children[i].id == "tiandituMap") {
            hasMap = true;
            break;
        }
    }
    //不存在
    if (hasMap == false) {
        var script = document.createElement('script');
        script.type = "text/javascript";
        script.id = "tiandituMap";
        script.setAttribute("src", "https://api.tianditu.gov.cn/api?v=4.0&tk=" + ak);
        head.appendChild(script);
    }
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            var map = new T.Map(mapDiv);
            if (!center) center = { lat: 23.112223, lng: 113.331084 };
            map.centerAndZoom(new T.LngLat(center.lng, center.lat), zoom || 10);
            resolve(map);
        }, 1000);
    });
};