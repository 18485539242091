import { render, staticRenderFns } from "./WxEdit.vue?vue&type=template&id=740a3c15&scoped=true"
import script from "./WxEdit.vue?vue&type=script&lang=js"
export * from "./WxEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "740a3c15",
  null
  
)

export default component.exports