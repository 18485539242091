<template>
  <div class="fnc-website-infomation-list">
    <div v-if="dataList && dataList.length > 0">
      <List item-layout="vertical" :loading="loading" size="large">
        <ListItem v-for="item in dataList" :key="item.title">
          <a :href="convertTo('/information/view?functionId='+ item.typeId + '&id=' + item.id)" target="_blank">
            <ListItemMeta :title="item.infoTitle" :description="item.publishtime" />
          </a>
          <template #action>
            <li> 来源：{{ item.fromIn || $core.getBranchInfo('branchname') }}</li>
            <li> {{ item.label || "" }}</li>
          </template>
          <template #extra>
            <a :href="convertTo('/information/view?functionId=' + item.typeId + '&id=' + item.id)" target="_blank">
            <img :src="item.infoPicture || $core.getBranchInfo('nopic')"
              class="extra-img" :style="width>800?'':'width:100%;height:auto;'">
            </a>
          </template>
        </ListItem>
      </List>
      <div style="margin:20px 5px;">
        <Page prev-text="上一页" next-text="下一页" size="small" :total="pageParams.maxCount"
          style="font-size: 18px;float:right;" :page-size="pageParams.pageSize" @on-change="loadDataList"
          :current="pageParams.currentPage" />
      </div>
    </div>
    <div class="fnc-website-loadding" v-else>
      {{ dataList == null ? "" : "暂无内容" }}
    </div>
  </div>
</template>
<script>
//路由定义：@ROUTER=/information/list
import "./index.less"
export default {
  data() {
    return {
      functionId: "",
      dataList: null,
      pageParams: {
        maxCount: 0,//数据总条数
        currentPage: 0,//当前页(默认第一页)
        pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
        maxPage: 1,//总页数
        sort: "id",//排序字段
        order: "desc",//排序方式
        keyword: "",//通用查询条件
        keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
      },
      loading: false,
      width:0,
    };
  },
 created(){
  this.width = $(window).width();
    $(window).resize(() => {
      this.width = $(window).width();
    })
 },
   mounted() {
    this.functionId = this.$core.getUrlParam("functionId");
    this.loadDataList(1);
  },
  methods: {
    convertTo(url){
      var lurl=location.href;
      lurl=lurl.substring(0,lurl.indexOf("#/")+1);
      return lurl+url;
    },
    loadDataList(currentPage) {
      if (currentPage) {
        this.pageParams.currentPage = currentPage;
      } else {
        this.pageParams.currentPage = this.pageParams.currentPage + 1;
      }
      this.$Message.config({ top: 300 });
      this.$Message.loading({ content: "加载中...", duration: 0 })
      this.loading = true;
      this.$get({
        url: "/gateway/api/information/loadListByFunctionId",
        data: { functionId: this.functionId, ...this.pageParams },
        success: (res) => {
          this.dataList = [];
          if (res.code == '200' && res.dataList)
            this.pageParams = { ...this.pageParams, ...res.pageParams }
          this.dataList = res.dataList;
        }, complete: () => {
          this.loading = false;
          this.$Message.destroy();
        }
      })
    }
  },
};
</script>