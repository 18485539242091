<template>
    <div style="height: 500px; overflow-y: auto; padding: 10px">
        <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="200">
            <FormItem label="平台租户" prop="clientId">
                <Select v-model="formItems.clientId" style="width: 180px; float: left; margin-right: 10px">
                    <Option v-for="item in clientInfo" :value="item.clientId" :key="item.clientId">{{ item.systemName }}</Option>
                </Select>
            </FormItem>
            <FormItem label="应用类型" prop="appType">
                <Select v-model="formItems.appType" style="width: 180px; float: left; margin-right: 10px">
                    <Option v-for="item in wxAppType" :value="item.value" :key="item.value">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="Appid" prop="appid">
                <Input type="text" v-model="formItems.appid" placeholder="Appid"></Input>
            </FormItem>
            <FormItem label="Secret" prop="secret">
                <Input type="text" v-model="formItems.secret" placeholder="secret"></Input>
            </FormItem>
            <FormItem label="ApiUrl" prop="apiUrl">
                <Input type="text" v-model="formItems.apiUrl" placeholder="apiUrl"></Input>
            </FormItem>
            <FormItem label="公众号二维码" prop="qrcode">
                <FNCUpload type="image" style="float: left" :showView="true" :defaultList="formItems.qrcode" :showDel="true" @success="upLogoImage"></FNCUpload>
            </FormItem>
            <FormItem label="启禁用" prop="status">
                <RadioGroup v-model="formItems.status">
                    <Radio :label="1">启用</Radio>
                    <Radio :label="0">禁用</Radio>
                </RadioGroup>
            </FormItem>
        </Form>
    </div>
</template>

<script>
import FNCUpload from "@/components/FNCUpload"
export default {
    components: {
        FNCUpload,
    },
    props: {
        wxAppType: {
            type: Array,
            default: () => {
                return []
            },
        },
        clientInfo: {
            type: Array,
            default: () => {
                return []
            },
        },
    },

    data() {
        return {
            formItemsDefault: {
                clientId: "",
                appType: "",
                appid: "",
                id: 0,
                status: 1,
                secret: "",
                apiUrl: "https://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential",
                qrcode: "",
            },
            formItems: {},
            ruleItems: {
                clientId: [{ required: true, message: "平台租户不能为空", trigger: "change" }],
                appType: [{ required: true, message: "微信应用类型不能为空", trigger: "change" }],
                appid: [{ required: true, message: "AppId不能为空", trigger: "blur" }],
                apiUrl: [{ required: true, message: "Api地址不能为空", trigger: "blur" }],
                secret: [{ required: true, message: "Secret不能为空", trigger: "blur" }],
            },
        }
    },
    mounted() {},
    methods: {
        loadData(id) {
            this.formItems = {...this.formItemsDefault};
            if (id) {
                this.$get({
                    url: "/gateway/api/manage/platform/wxconfig/view",
                    data: { id },
                    success: res => {
                        if (res.code == "200" && res.data) {
                            this.formItems = { ...this.formItems, ...res.data }
                        } else {
                            this.$Message.error(res.desc || "读取数据出错")
                            this.$emit("closeModal")
                        }
                    },
                })
            }
        },
        submitData(callback) {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    callback(true)
                    this.$post({
                        url: "/gateway/api/manage/platform/wxconfig/save",
                        data: this.formItems,
                        success: res => {
                            if (res.code == "200") {
                                callback(false, true)
                                this.$Message.success({ background: true, content: "保存成功" })
                            } else {
                                this.$Message.error({ background: true, content: res.desc || "保存出错" })
                            }
                        },
                        complete: () => {
                            callback(false)
                        },
                    })
                } else {
                    this.$Message.error({ background: true, content: "部分必填项没填写" })
                }
            })
        },
        upLogoImage(file) {
      this.formItems.qrcode = file.url;
    },
    },
}
</script>

<style scoped lang='less'></style>