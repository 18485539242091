<template>
  <FNCContent>
    <template v-slot:title> {{ title }} </template>
    <template v-slot:toolsbarLeft>
      <Cascader v-model="deptid" @on-change="checkOrgRight" placeholder="全部服务主体" change-on-select clearable :data="depts" style="width:300px;"/>
      <div style="width:250px;"><Input search enter-button="查询" clearable v-model="pageParams.keyword"
          placeholder="输入管线名称" @on-search="loadData(1)" /></div>
    </template>
    <template v-slot:toolsbarRight>
      <Button type="primary" class="addDiv" @click="() => { addCoverClass() }" v-if="right.A">
        <Icon type="md-flag" size="18" />添加品类
      </Button>
      <Button type="info" class="addDiv" @click="() => { editData() }" v-if="right.A">
        <Icon type="md-add-circle" size="18" />添加管线
      </Button>
<!-- 
      <Button type="warning" class="deleteDiv" @click="() => { deleteData() }" v-if="right.D">
        <Icon type="md-trash" size="18" />删除
      </Button> -->

    </template>
    <template v-slot:contentArea>
      <FNCTable ref="fncTable" :loading="loading" :fixTable="true" :talbeColumns="talbeColumns" :tableData="tableData"
        :pageParams="pageParams" :pagesizeOpts="pagesizeOpts" @handleLoadData="loadData"></FNCTable>
      <FNCModal ref="editModal" title="编辑窗" :fullscreen="false" width="800px" height="600px">
        <template v-slot:contentarea>
          <Edit ref="editContent" @closeModal="closeModal"></Edit>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closeModal">
            <Icon type="md-close" />取消
          </Button>
          <Button type="primary" @click="submitData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />保存
          </Button>
        </template>
      </FNCModal>
    </template>
  </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/object
import FNCContent from "@/components/FNCContent";
import FNCTable from "@/components/FNCTable";
import FNCModal from "@/components/FNCModal";
import Edit from "./childrens/Edit";
import {orgCascader} from "@/utils/orgCascader";
export default {
  components: {
    FNCTable,
    FNCContent,
    FNCModal,
    Edit,
  },

  data() {
    return {
      title: "信息列表",
      loading: false,
      pageParams: {
        maxCount: 0,//数据总条数
        currentPage: 0,//当前页(默认第一页)
        pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
        maxPage: 1,//总页数
        sort: "id",//排序字段
        order: "desc",//排序方式
        keyword: "",//通用查询条件
        keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
        objectNum:"",
      },
      pagesizeOpts: [20, 30, 50, 100],//分页数组
      tableData: [],
      talbeColumns: [
        // {
        //   type: 'selection',
        //   width: 60,
        //   fixed: "left",
        //   align: 'center'
        // },
        {
          title: "服务主体",
          key: "orgCode",
          align: "center",
          fixed: "left",
          minWidth: 250,
          render: (h, params) => {
            return h("div", this.orgCascader.convectCascaderName(params.row.orgCode)
            )
          },
        },
        {
          title: "管线名称",
          key: "objectName",
          align: "center",
          fixed: "left",
          width: 350,
        },
        {
          title: "管线地址",
          key: "address",
          align: "center",
          minWidth: 300,
        },
        {
          title: "操作",
          slot: "action",
          width: 200,
          align: "center",
          fixed: "right",
          render: (h, params) => {
            return h("div",
              [
                h(
                  "Button",
                  {

                    class: "editDiv"
                    ,
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      margin: "0 5px",
                    },

                    on: {
                      click: () => {
                        this.editData(params.row.id);
                      },
                    },
                  },
                  "修改"
                ),

                h(
                  "Button",
                  {
                    class: "deleteDiv"
                    ,
                    props: {
                      type: "error",
                      size: "small",
                    },
                    on: {
                      click: () => {
                        this.deleteData(params.row);
                      },
                    },
                  },
                  "删除"
                ),
              ]);
          },
        },
      ],
      right: {},
      saveLoading: false,
      depts:[],
      deptid:[],
    };
  },
  created() {
    this.orgCascader = orgCascader("dept");
    this.deptid=this.orgCascader.convectCascaderList(this.$core.getBranchUserInfo("deptid"));
    this.orgCascader.loadData(
      (depts) => {
        this.depts = depts;
      },this.deptid[this.deptid.length-1]);
  },
  mounted() {
    this.loadModuleInfo();
  },
  methods: {
     loadModuleInfo() {
      //读取栏目信息
       this.$get({
        url: "/gateway/api/manage/type/moduleinfo",
        data: {
          functionId: this.$core.getFunctionId(),
        },
        success: (res) => {
          if (res.code == "200" && res.data) {
            this.title = res.data.typename;
          }
        }
      });

      //读取数据列表
      this.loadData(1);
      this.$core.getControlRight().then(res => {
        this.right = res;
      });
    },
    loadData(currentPage) {
      if (currentPage) {
        this.pageParams.currentPage = currentPage;
      } else {
        this.pageParams.currentPage = this.pageParams.currentPage + 1;
      }
      this.loading = true;
      this.$get({
        url: "/smartcovers/object/queryObject",
        data: this.pageParams,
        success: (res) => {
          this.tableData = []
          if (res.code == "200" && res.pageParams) {
            this.pageParams = {...this.pageParams,...res.pageParams}
            this.tableData = res.dataList
          } else {
            this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
          }
          this.loading = false;
        }
      })
    },
    deleteData(row) {
      if (this.right["D"] == false) {
        this.$Message.error({ background: true, content: "你没有删除权限" })
        return;
      }
      var ids = ""
      if (row) {
        ids = row.id
      } else {
        let selectedList = this.$refs.fncTable.getSelection();
        if (selectedList && selectedList.length > 0) {
          ids = selectedList.map(item => {
            return item.id;
          }).join(",")
        }
      }
      if (!ids) {
        this.$Message.error({ background: true, content: "并没有选择审核项" })
        return;
      }

      this.$Modal.confirm({
        title: '删除确认',
        content: row ? '您确认要删除“<span style="color:red"> ' + row.objectName + '</span>”吗？' : "确认要删除选中吗？",
        onOk: () => {
          this.$post({
            url: "/smartcovers/object/delObject", data: { id: ids },
            success: (res) => {
              if (res.code == "200") {
                this.$Message.success({ background: true, content: res.desc })
                this.loadData(1);
              } else {
                this.$Message.error({ background: true, content: res.desc })
              }
            }
          });
        }
      });
    },
    editData(id) {
      if (id && this.right["E"] == true || !id && this.right["A"] == true) {
        this.$refs.editModal.showModal();
        this.$refs.editContent.loadData(id);
      }
    },
    //跳转页面  /nativeapp/platform/stylelist.html?styleen=coverClass&functionid=0104&stylename=井盖分类
    addCoverClass() {
      layer.open({
        title:"管道品类",
        type: 2,
        area: ['90%', '90%'],
        content: '/nativeapp/platform/stylelist.html?styleen=coverClass&stylename=井盖分类&functionid=' + this.$core.getFunctionId(),
        end: function () {
            //在这里写关闭后需要执行的操作，例如刷新页面
            location.reload();
        } 
      });
    },
    closeModal() {
      this.$refs.editModal.hideModal();
    },
    submitData() {
      this.$refs.editContent.submitData((b, reload) => {
        this.saveLoading = b;
        if (reload == true) {
          this.loadData(1);
          this.closeModal();
        }
      });
    },
    checkOrgRight(e){
      this.deptid=this.orgCascader.checkOrgRight(e);
      this.pageParams.orgCode = this.deptid[this.deptid.length -1]
    },
  }
};
</script>
    
<style scoped lang='less'></style>