<template>
    <div style="height: 500px; overflow-y: auto; padding: 10px">
        <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="200">
            <FormItem label="任务代码" prop="apicode">
                <Input type="text" v-model="formItems.apicode" :disabled="formItems.id > 0 ? true : false" placeholder="字母或数字"></Input>
            </FormItem>
            <FormItem label="任务名称" prop="apiname">
                <Input type="text" v-model="formItems.apiname" placeholder="中文描述"></Input>
            </FormItem>
            <FormItem label="执行类名" prop="apiThreadClass">
                <Input type="text" v-model="formItems.apiThreadClass" placeholder="任务线程类名"></Input>
                <div>第三方任务使用“com.fnc.fncplatform.task.httpPushThread.HttpPushThread” + URL</div>
            </FormItem>
            <FormItem label="第三方任务URL" prop="url" v-if="formItems.apiThreadClass == 'com.fnc.fncplatform.task.httpPushThread.HttpPushThread'">
                <Input type="text" v-model="formItems.url" placeholder="第三方任务URL"></Input>
            </FormItem>
            <FormItem label="服务代码" prop="servicecode" v-if="formItems.apiThreadClass == 'com.fnc.fncplatform.task.httpPushThread.HttpPushThread'">
                <Input type="text" v-model="formItems.servicecode" placeholder="第三方任务URL的服务代码"></Input>
            </FormItem>
            <FormItem label="服务密钥" prop="servicekey" v-if="formItems.apiThreadClass == 'com.fnc.fncplatform.task.httpPushThread.HttpPushThread'">
                <Input type="text" v-model="formItems.servicekey" placeholder="第三方任务URL的服务密钥"></Input>
            </FormItem>
            <FormItem label="运行周期" prop="runround">
                <Input type="text" v-model.number="formItems.runround" placeholder="运行周期，单位为：分钟，可填小数" style="width: 150px">
                    <template #append>
                        <span>分钟</span>
                    </template>
                </Input>
            </FormItem>
            <FormItem label="限时时段" prop="starthour">
                <Select v-model="formItems.starthour" style="width: 150px">
                    <Option v-for="(item, idx) in 24" :value="idx" :key="'st' + idx">{{ idx }}时</Option>
                </Select>
                ~
                <Select v-model="formItems.endhour" style="width: 150px">
                    <Option v-for="(item, idx) in 24" :value="idx" :key="'en' + idx">{{ idx }}时</Option>
                </Select>
            </FormItem>
            <FormItem label="任务白名单" prop="addressip">
                <Input
                    type="textarea"
                    :autosize="{ minRows: 3 }"
                    v-model="formItems.addressip"
                    placeholder="填写服务器IP或Docker容器ID（CONTAINER ID）白名单，多个换行填写，不填写则表示所有服务均可执行"
                ></Input>
            </FormItem>
            <FormItem label="读取数据个数" prop="datacount">
                <Input type="number" v-model="formItems.datacount" placeholder="每次读取数据个数，根据实际需要填写"  style="width: 150px"></Input>
            </FormItem>
            <FormItem label="执行失败重试次数" prop="pushcount">
                <Input type="number" v-model="formItems.pushcount" placeholder="执行失败重试次数，根据实际需要填写"  style="width: 150px"></Input>
            </FormItem>

            <FormItem label="启禁用" prop="istate">
                <RadioGroup v-model="formItems.istate">
                    <Radio :label="1">启用</Radio>
                    <Radio :label="0">禁用</Radio>
                </RadioGroup>
            </FormItem>
        </Form>
    </div>
</template>

<script>
import FNCUpload from "@/components/FNCUpload"
export default {
    components: {
        FNCUpload,
    },
    props: {},

    data() {
        return {
            formItemsDefault: {
                id: 0,
                apiThreadClass: "",
                apicode: "",
                apiname: "",
                url: "",
                servicecode: "",
                servicekey: "",
                datacount: 1000,
                endhour: 0,
                istate: 1,
                pushcount: 3,
                runround: 0,
                starthour: 0,
                addressip: "",
            },
            formItems: {},
            ruleItems: {
                apicode: [{ required: true, message: "任务代码不能为空", trigger: "blur" }],
                apiThreadClass: [{ required: true, message: "任务线程类名不能为空", trigger: "blur" }],
                apiname: [{ required: true, message: "任务名称不能为空", trigger: "blur" }],
                runround: [{ type: "number", required: true, message: "运行周期不能为空", trigger: "blur" }],
            },
        }
    },
    mounted() {},
    methods: {
        loadData(id) {
            this.formItems = {...this.formItemsDefault};
            if (id) {
                this.$get({
                    url: "/gateway/api/manage/platform/taskconfig/view",
                    data: { id },
                    success: res => {
                        if (res.code == "200" && res.data) {
                            this.formItems = {...this.formItems,...res.data}
                        } else {
                            this.$Message.error(res.desc || "读取数据出错")
                            this.$emit("closeModal")
                        }
                    },
                })
            }
        },
        submitData(callback) {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    callback(true)
                    this.$post({
                        url: "/gateway/api/manage/platform/taskconfig/save",
                        data: this.formItems,
                        success: res => {
                            if (res.code == "200") {
                                callback(false, true)
                                this.$Message.success({ background: true, content: "保存成功" })
                            } else {
                                this.$Message.error({ background: true, content: res.desc || "保存出错" })
                            }
                        },
                        complete: () => {
                            callback(false)
                        },
                    })
                } else {
                    this.$Message.error({ background: true, content: "部分必填项没填写" })
                }
            })
        },
    },
}
</script>
    
<style scoped lang='less'></style>