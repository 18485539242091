<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem  label="服务主体">
        <Cascader v-model="deptid" @on-change="checkOrgRight" placeholder="全部服务主体" change-on-select clearable :data="depts" style="width:300px;"/>
      </FormItem>
      <FormItem label="管线名称" prop="objectName">
        <Input type="text" v-model="formItems.objectName" placeholder="输入管线名称"></Input>
      </FormItem>
      <FormItem label="品类" prop="coverClass">
        <Select v-model="formItems.coverClass" style="width: 180px">
          <Option v-for="item in coverClassList" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="省/市/区" prop="county">
        <FNCAreaSelectList startParent="440000" ref="areaSlt" @onChange="onAreaChange"></FNCAreaSelectList>
      </FormItem>
      <FormItem label="管线地址" prop="address">
        <Input type="textarea" v-model="formItems.address"></Input>
      </FormItem>
      <FormItem label="测量水位时间1" prop="amount">
        <Input type="number" placeholder="范围0到23" style="width: 168px" v-model.trim="formItems.time1" @keyup.native="checkNumber" @on-blur="checkNumber"/>
      </FormItem>
      <FormItem label="测量水位时间2" prop="amount">
        <Input type="number" placeholder="范围0到23" style="width: 168px" v-model="formItems.time2" @keyup.native="checkNumber" @on-blur="checkNumber"/>
      </FormItem>
      <FormItem label="测量水位时间3" prop="amount">
        <Input type="number" placeholder="范围0到23" style="width: 168px" v-model="formItems.time3" @keyup.native="checkNumber" @on-blur="checkNumber"/>
      </FormItem>
    </Form>
    
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
import { TrackOpTypes } from "vue";
import {orgCascader} from "@/utils/orgCascader";
import FNCAreaSelectList from '@/components/FNCAreaSelectList';
export default {
  components: {
    FNCModal, FNCAreaSelectList
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: 0,
        objectName:"",
        province:"",
        city:"",
        county:"",
        address:"",
        amount:"",
        signAmount:"",
        receivedAmount:"",
        merchantName:"",
        linkman:"",
        linkmanPhone:"",
        businessMan:"",
        businessManPhone:"",
        signTime:"",
        startTime:"",
        expectTime:"",
        lengths:"",
        coverNum:"",
        area:[],
        time1:null,
        time2:null,
        time3:null,
        coverClassList:[],
      }),
      formItems: {},
      ruleItems: {
        objectName: [
          { required: true, message: '管线名称不能为空', trigger: 'blur' }
        ],
        // longitude: [
        //   { required: true, message: '定位不能为空', trigger: 'blur' }
        // ],
        address: [
          { required: true, message: '管线具体地址不能为空', trigger: 'blur' }
        ],
        // merchantName: [
        //   { required: true, message: '商户名称不能为空', trigger: 'blur' }
        //],
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,

      // devices: [],
      // projects: [],
      // flowths: [],
      // waterStates: [],
      depts:[],
      deptid:[],
    };
  },
  created() {
    this.orgCascader = orgCascader("dept");
    this.deptid=this.orgCascader.convectCascaderList(this.$core.getBranchUserInfo("deptid"));
    this.orgCascader.loadData(
      (depts) => {
        this.depts = depts;
      },this.deptid[this.deptid.length-1]);
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
     loadData(id) {
      this.$refs.areaSlt.initData([]);
      this.deptid=[]
      this.formItems = JSON.parse(this.formItemsDefault);
      this.formItems.orgCode=this.deptid&&this.deptid.length>0?this.deptid[this.deptid.length-1]:null;
      if (id) {
        this.$get({
          url: "/smartcovers/object/queryObjectDetail",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
              let a=[res.data.province,res.data.city,res.data.county]
              this.$refs.areaSlt.initData(a);
              this.deptid = this.orgCascader.convectCascaderList(res.data.orgCode);
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },
    /**
    * 获取选项数据
    */
     loadBaseData() {
     this.orgCascader = orgCascader("dept");
     this.deptid=this.orgCascader.convectCascaderList(this.$core.getBranchUserInfo("deptid"));
     this.orgCascader.loadData(
      (depts) => {
        this.depts = depts;
      },this.deptid[this.deptid.length-1]);

      this.$core.getStyleParamList("coverClass", false, null, (res) => {
        this.coverClassList = res || []
      })
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          this.$post({
            url: this.formItems.id ? "/smartcovers/object/updateObject" : "/smartcovers/object/addObject",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    onChangesignTime(e){
      this.formItems.signTime=e
    },
    onChangeStarttime(e){
      this.formItems.startTime=e
    },
    onAreaChange(e){
      if (e.length >= 3) {
        this.formItems.province = e[0];
        this.formItems.city = e[1];
        this.formItems.county = e[2];
      }
      // console.log( this.formItems.province ,this.formItems.city, this.formItems.county);
    },
    checkNumber(e) {
      var value = e.target.value;
      var reg = /^(\d{1}|1[0-9]|2[0-3])$/;
      if(!reg.test(value)) {
        this.$Message.error({ background: true, content: "数字不在0到23之间" })
        return;
      }
    },
    checkOrgRight(e){
      this.deptid=this.orgCascader.checkOrgRight(e);
      this.formItems.orgCode = this.deptid[this.deptid.length -1]
    },
  }
};
</script>
    
<style scoped lang='less'></style>