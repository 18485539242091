var domain = function (param) {
  if (process.env.NODE_ENV == 'development') {
    return param.test;
  }
  if (process.env.NODE_ENV == 'production') {
    return param.prod;
  }
}

module.exports = {
  "/smartcovers": {
    target: domain({
      test: "http://localhost:8001",//测试
      prod: "https://test.smartcover.api.891tech.com",//生产
    }), //

    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/smartcovers": "/",//重写地址
    },
  },
}