<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="档案编号" prop="archiveNum">
        <Input v-model.trim="formItems.archiveNum" placeholder="档案编号"></Input>
      </FormItem>
      <FormItem label="文件名称" prop="fileName">
        <Input v-model.trim="formItems.fileName" placeholder="文件名称"></Input>
      </FormItem>
      <FormItem label="全宗号" prop="fullFileNum">
        <Input v-model.trim="formItems.fullFileNum" placeholder="全宗号"></Input>
      </FormItem>
      <FormItem label="全宗名称" prop="fullFileName">
        <Input v-model.trim="formItems.fullFileName" placeholder="全宗名称"></Input>
      </FormItem>
      <FormItem label="归档编号" prop="archivingNum">
        <Input v-model.trim="formItems.archivingNum" placeholder="归档编号"></Input>
      </FormItem>
      <FormItem label="绑定库房" prop="whId">
        <div style="width: 100%;display: flex;justify-content: flex-start;">
          <Select v-model="formItems.whId" @on-change="getMachinecabinetByWh" style="width: 180px;margin-right: 10px;">
            <Option v-for="item in warehouseList" :value="item.id" :key="item.whName">{{ item.whName }}
            </Option>
          </Select>
        </div>
      </FormItem>
      <FormItem label="绑定机柜" prop="mcId">
        <div style="width: 100%;display: flex;justify-content: flex-start;">
          <Select v-model="formItems.mcId" style="width: 180px;float:left;margin-right: 10px;">
            <Option v-for="item in machinecabinetList" :value="item.id" :key="item.mcName">{{ item.mcName }}
            </Option>
          </Select>
        </div>
      </FormItem>
      <FormItem label="所属科室" prop="orgCode">
        <div style="width: 100%;display: flex;justify-content: flex-start;">
          <Cascader v-model="deptid" @on-change="checkOrgRight" placeholder="选择科室" change-on-select clearable
            :data="depts" style="width: 300px;float:left;margin-right: 10px;" />
        </div>
      </FormItem>
      <FormItem label="归档年份" prop="archiveYear">
        <Input type="text" v-model="formItems.archiveYear" placeholder="归档年份"></Input>
      </FormItem>
      <FormItem label="归档类型" prop="archiveType">
        <Select v-model="formItems.archiveType">
          <Option v-for="item in archiveTypeList" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="密级" prop="secretLevel">
        <Select v-model="formItems.secretLevel">
          <Option v-for="item in secretLevelList" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="保管期限" prop="storageLife">
        <Input type="text" v-model="formItems.storageLife" placeholder="保存期限"></Input>
      </FormItem>
      <FormItem label="存储位置" prop="storageLocation">
        <Input type="text" v-model="formItems.storageLocation" placeholder="存储位置"></Input>
      </FormItem>
      <FormItem label="用地面积" prop="landArea">
        <Input type="text" v-model="formItems.landArea" placeholder="使用面积"></Input>
      </FormItem>
      <FormItem label="地址" prop="address">
        <Input type="text" v-model="formItems.address" placeholder="地址"></Input>
      </FormItem>
      <FormItem label="归属人" prop="manager">
        <Input type="text" v-model="formItems.manager" placeholder="归属人"></Input>
      </FormItem>
      <FormItem label="整编人员" prop="dealUser">
        <Input type="text" v-model="formItems.dealUser" placeholder="整编人员"></Input>
      </FormItem>
      <FormItem label="整编时间" prop="dealTime">
        <Input type="text" v-model="formItems.dealTime" placeholder="整编时间"></Input>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
import FNCUpload from "@/components/FNCUpload"
import { orgCascader } from "@/utils/orgCascader";
export default {
  components: {
    FNCModal,
    FNCUpload,
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: "",//ID
        branchId: "",//BranchId
        whId: "",//绑定库房ID
        mcId: "",//绑定机柜ID
        mcName: "",//机柜名称（导入文件）
        whName: "",//库房名称（导入文件）
        fullFileNum: "",//全宗号
        fullFileName: "",//全宗名称
        fileName: "",//文件名称
        filesTotal: "",//文件总数量
        filesSizeTotal: "",//文件总大小
        archiveNum: "",//档案编号
        archivingNum: "",//归档编号
        archiveYear: "",//档案年份
        archiveType: "",//档案类型ID
        orgCode: "",//所属科室ID
        orgName: "",//科室名称（导入文件）
        archiveName: "",//档案类型名称（导入文件）
        secretLevel: "",//密级
        storageLife: "",//保管期限
        storageLocation: "",//存储位置
        landArea: "",//用地面积
        address: "",//地址
        dealTime: "",//处理时间
        dealUser: "",//处理人员
      }),
      deptid: [],
      formItems: {},
      ruleItems: {
        archiveNum: [{ required: true, message: "请填写档案编号", trigger: "blur" }],
        fileName: [{ required: true, message: "请填写文件名称", trigger: "blur" }],
        whId: [{ required: true, message: "请绑定库房", trigger: "change" }],
        mcId: [{ required: true, message: "请绑定机柜", trigger: "change" }],
        orgCode: [{ required: true, message: "请绑定所属科室", trigger: "change" }],
        filesStorage: [{ type: "array", required: true, message: "请上传文件", trigger: "change" }],

      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,
      secretLevelList: [],
      archiveTypeList: [],
      machinecabinetList: [],
      warehouseList: [],
    };
  },
  created() {
    this.orgCascader = orgCascader("dept");
    this.deptid = this.orgCascader.convectCascaderList(this.$core.getBranchUserInfo("deptid"))
    this.orgCascader.loadData(
      depts => {
        this.depts = depts
      },
      this.deptid.length > 0 ? this.deptid[this.deptid.length - 1] : ""
    )
    this.loadBaseData();
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
    loadData(id,filesTotal) {
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.$get({
          url: "/smartarchives/api/archives/queryArchivesDetail",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
              if (this.formItems.whId) {
                this.getMachinecabinetByWh(this.formItems.whId, this.formItems.mcId)
              }
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }

      

    },

  

    /**
    * 获取选项数据
    */
    loadBaseData() {
      this.$core.getStyleParamList("secretLevel", false, null, (res) => {
        this.secretLevelList = res
      })
      this.$core.getStyleParamList("archiveType", false, null, (res) => {
        this.archiveTypeList = res
      })
      this.$get({
        url: "/smartarchives/api/warehouse/client/queryWarehouse",
        data: {},
        success: res => {
          if (res.code == "200") {
            this.warehouseList = res.dataList
          }
        }
      })
      
    },
    getMachinecabinetByWh(whId, mcId) {
      this.formItems.mcId = mcId;
      this.$get({
        url: "/smartarchives/api/machinecabinet/client/queryMachinecabinetByWh",
        data: { whId: whId },
        success: res => {
          if (res.code == "200") {
            this.machinecabinetList = res.dataList
            this.$forceUpdate()
          }
        }
      })
    },
    getMcList(e) {
      this.mcList = []
      this.$get({
        url: "/smartarchives/api/machinecabinet/queryMachinecabinet", data: { pageSize: 1000, whId: e }, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.machinecabinetList = res.dataList;
          }
        }
      })
    },
    submitData(callback) {
    },
    checkOrgRight(e) {
      this.deptid = this.orgCascader.checkOrgRight(e)
      this.formItems.orgCode = this.deptid[this.deptid.length - 1]
    },

  }
};
</script>
    
<style scoped lang='less'>
</style>