<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="所属库房名称" prop="whId">
        <Select v-model="formItems.whId" style="width: 180px">
          <Option v-for="item in warehouseList" :value="item.id" :key="item.whName">{{ item.whName }}</Option>
        </Select>
      </FormItem>
      <FormItem label="柜子名称" prop="mcName">
        <Input type="text" v-model="formItems.mcName" placeholder="柜子名称"></Input>
      </FormItem>
      <FormItem label="柜子编号" prop="mcCode">
        <Input type="text" v-model="formItems.mcCode" placeholder="柜子编号"></Input>
      </FormItem>
      <FormItem label="是否智能柜" prop="isIntelligent">
        <!-- <Input type="text" v-model="formItems.isIntelligent" placeholder="是否智能柜"></Input> -->
        <Select v-model="formItems.isIntelligent">
          <Option v-for="item in isIntelligentList" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="规格" prop="speci">
        <Input type="text" v-model="formItems.speci" placeholder="规格"></Input>
      </FormItem>
      <FormItem label="档案编号范围" prop="snRange">
        <Input type="text" v-model="formItems.snRange" placeholder="档案编号范围"></Input>
      </FormItem>
      <FormItem label="柜子备注" prop="remarks">
        <Input type="text" v-model="formItems.remarks" placeholder="柜子备注"></Input>
      </FormItem>
        <!-- <RadioGroup v-model="formItems.locationTag">
            <Radio :label="true" checked>
               支持
            </Radio>
            <Radio :label="false">
             不支持
            </Radio>
        </RadioGroup> -->
    </Form>
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
export default {
  components: {
    FNCModal, 
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: 0,
        mcName:"",
        mcCode:"",
        isIntelligent:"",
        speci:"",
        snRange:"",
        remarks:"",
  
      }),
      formItems: {},
      ruleItems: {
        whId: [
          { required: true, message: '库房不能为空', trigger: 'blur' }
        ],
        mcName: [
          { required: true, message: '柜子名称不能为空', trigger: 'blur' }
        ],
        mcCode: [
          { required: true, message: '柜子编号不能为空', trigger: 'blur' }
        ],
      
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,
      warehouseList:[],
      isIntelligentList: [],
    };
  },
  created() {

  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
     loadData(id) {
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.$get({
          url: "/smartarchives/api/machinecabinet/queryMachinecabinetDetail",
          data: { id: id },
          success: (res) => {
            console.log(res, "res");
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
              this.formItems.isIntelligent = res.data.isIntelligent + ''
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },

    /**
    * 获取选项数据
    */
    loadBaseData() {
      // this.$core.getStyleParamList("flowth", false, null, (res) => {
      //   this.flowths = res || []
      // })
      // this.$core.getStyleParamList("waterState", false, null, (res) => {
      //   this.waterStates = res || []
      // })
      this.$core.getStyleParamList("isIntelligent", false, null, (res) => {
        this.isIntelligentList = res || []
      })

      this.$get({
        url: "/smartarchives/api/warehouse/queryWarehouse", data: { pageSize: 1000 }, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.warehouseList = res.dataList
          }
        }
      })
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          this.$post({
            url: this.formItems.id ? "/smartarchives/api/machinecabinet/updateMachinecabinet" : "/smartarchives/api/machinecabinet/addMachinecabinet",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    
  }
};
</script>
    
<style scoped lang='less'></style>