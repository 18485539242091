<template>
  <div style="">
    <Form ref="form" :model="globalUserId" :rules="ruleItems" :label-width="150">
      <!-- <FormItem label="报警推送运营人员" prop="globalUserId">
        <Select v-model="formItems.globalUserId">
          <Option v-for="item in userList" :value="item" :key="item.realname">{{ item.realname }}</Option>
        </Select>
      </FormItem> -->
      <FormItem label="告警推送运营人员" prop="globalUserId">
        <Select filterable @on-query-change="remoteMethod" :loading="loading" multiple
          @on-change="selectUser" placeholder="请选择运营人员，支持姓名搜索, 支持多选" :size="5">
          <Option v-for="(option, index) in options" :value="option" :key="option.id">{{ option.realname}}</Option>
        </Select>
      </FormItem>
   
    </Form>
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
export default {
  components: {
    FNCModal,
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: null,
        globalUserId: "",
        clientId: "",
        selectedUsers:"",
      }),
      formItems: {},
      ruleItems: {
        // globalUserId: [
        //   { required: true, message: '报警推送运营人员不能为空', trigger: 'change' }
        // ],
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,
      options: [],
      loading: false,
    };
  },
  mounted() {
     this.loadBaseData("");
  },
  methods: {
     loadData(id) {
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.$get({
          url: "/smartsmoke/warmpushuser/queryWarmpushuserDetail",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },
    /**
    * 获取选项数据
    */
    loadBaseData(keyword) {
      this.$get({
        url: "/smartsmoke/warmpushuser/selectUser", data:{keyword:keyword},success: (res) => {
          if (res.code == "200" && res.data) {
            this.options=res.data
          }
        }
      })

    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          // if(this.formItems.globalUserId =='') {
          //   this.$Message.error({ background: true, content: "请选择运营人员" })
          //   return false
          // }
          this.$post({
            url: "/smartsmoke/warmpushuser/createOrUpdateWarmpushuser",
            data: { ...this.formItems }
            // data: { "userList":JSON.stringfy(this.selectedUsers)}
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },

    classModal() {
      this.$refs.mapModal.hideModal();
    },
    remoteMethod(query) {
      var that=this
      that.keyword=query;
     setTimeout(() => {
      if(that.keyword==query){
        that.loadBaseData(query)
      }
     }, 1000);
    },
    selectUser(user) {
      // if(user != undefined) {
      //   this.formItems.globalUserId = user.globalId
      //   this.formItems.username = user.realname
      //   this.formItems.branchId = user.branchid
      // }
      this.formItems.selectedUsers = JSON.stringify(user)
    }

  }
};
</script>
    
<style scoped lang='less'></style>