<template>
  <FNCContent>
    <template v-slot:title> {{ title }} </template>
    <template v-slot:toolsbarLeft>
      <Cascader v-model="deptid" @on-change="checkOrgRight" placeholder="科室" change-on-select clearable :data="depts"
        style="width:300px;" />
      <Select v-model="pageParams.whId" style="width: 180px" placeholder="库房" @on-change="getMcList">
        <Option v-for="item in whList" :value="item.id" :key="item.whName">{{ item.whName }}</Option>
      </Select>
      <Select v-model="pageParams.mcId" style="width: 180px" placeholder="机柜">
        <Option v-for="item in mcList" :value="item.id" :key="item.mcName">{{ item.mcName }}</Option>
      </Select>
      <Select v-model="pageParams.secretLevel" style="width: 120px" placeholder="密级">
        <Option v-for="item in secretLevelList" :value="item.value" :key="item.name">{{ item.name }}</Option>
      </Select>

      <div style="width:250px;"><Input search enter-button="查询" clearable v-model="pageParams.keyword"
          placeholder="输入档案名称" @on-search="loadData(1)" /></div>
    </template>
    <template v-slot:toolsbarRight>

      <!-- <Button type="info" class="addDiv" @click="() => { editData() }" v-if="right.A">
        <Icon type="md-add-circle" size="18" />修改
      </Button> -->
      <!-- <Button type="warning" class="deleteDiv" @click="() => { deleteData() }" v-if="right.D">
        <Icon type="md-trash" size="18" />删除
      </Button> -->
      <Button type="warning" class="deleteDiv" @click="() => { historyDataWin() }" v-if="right.D">
        <Icon type="md-trash" size="18" />历史版本
      </Button>

    </template>
    <template v-slot:contentArea>
      <FNCTable ref="fncTable" :loading="loading" :fixTable="true" :talbeColumns="talbeColumns" :tableData="tableData"
        :pageParams="pageParams" :pagesizeOpts="pagesizeOpts" @handleLoadData="loadData"></FNCTable>
      <FNCModal ref="editModal" title="档案编辑" :fullscreen="false" width="800px" height="750px">
        <template v-slot:contentarea>
          <Edit ref="editContent" @closeModal="closeModal"></Edit>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closeModal">
            <Icon type="md-close" />取消
          </Button>
          <Button type="primary" @click="submitData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />保存
          </Button>
        </template>
      </FNCModal>
      <FNCModal ref="historyContent" title="历史版本" :fullscreen="false" width="1000px" height="600px">
        <template v-slot:contentarea>
          <!-- <Table :columns="versionColumns" :data="historyList"></Table> -->
          <FNCTable ref="versionTable" :loading="loading1" :fixTable="true" :talbeColumns="versionColumns" :tableData="historyList"
        :pageParams="pageParams1" :pagesizeOpts="pagesizeOpts1" @handleLoadData="loadData1(1,null)"></FNCTable>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closehistoryModal">
            <Icon type="md-close" />取消
          </Button>
        </template>
      </FNCModal>
      <FNCModal ref="fileListContent" :title="viewDetailTitle" :fullscreen="true">
        <template v-slot:contentarea>
          <!-- <Table :columns="fileColumns" :data="fileList"></Table> -->
          <FNCTable ref="fileListTable" :talbeColumns="fileColumns" :loading="viewLoading" :tableData="fileList"
            :hidePage="true"></FNCTable>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closefileListModal">
            <Icon type="md-close" />取消
          </Button>
        </template>
      </FNCModal>
      <FNCModal ref="viewModal" :title="viewTitle" :fullscreen="false" width="80%" height="750px">
        <template v-slot:contentarea>
          <div style="margin:-10px;border-radius: 10px;">
            <FNCOfficePreview :src="filePath" height="748px" :toolbar="true" :download="true" :isAuthorize="true" :print="true"
              @error="() => { }" @rendered="() => { }"></FNCOfficePreview>
          </div>
        </template>
      </FNCModal>
      <FNCModal ref="contentModal" :title="历史版本" :fullscreen="false" width="90%" height="500px">
        <template v-slot:contentarea>
          <!-- <div v-html="beautifyJson(jsonData)">
          </div> @on-ok="ok" @on-cancel="cancel"-->
          <Modal v-model="contentModalFlag" title="内容"  :fullscreen="false" width="800px">
            <div style="padding:20px;height:600px;overflow: auto;" v-html="beautifyJson(jsonData)"></div>
          </Modal>
        </template>
      </FNCModal>
      <FNCModal ref="editFileModal" title="文件修改" :fullscreen="false" width="800px" height="550px">
                <template v-slot:contentarea>
                    <FileEdit ref="editFileContent" @closeModal="closeModal('editFileModal')"></FileEdit>
                </template>
                <template v-slot:toolsbar>
                    <Button style="width: 150px" type="primary" @click="submitFileData" :loading="saveLoading == 3">
                        <Icon type="md-checkmark-circle" />
                        保存
                    </Button>
                </template>
            </FNCModal>
    </template>
  </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/archives
import FNCContent from "@/components/FNCContent";
import FNCTable from "@/components/FNCTable";
import FNCModal from "@/components/FNCModal";
import Edit from "./childrens/Edit";
import FileEdit from "./childrens/FileEdit"
import FNCOfficePreview from "@/components/FNCOfficePreview";
import { orgCascader } from "@/utils/orgCascader";
export default {
  components: {
    FNCTable,
    FNCContent,
    FNCModal,
    Edit,
    FileEdit,
    FNCOfficePreview,
  },

  data() {
    return {
      contentModalFlag:false,
      viewTitle: '档案文件',
      title: "信息列表",
      viewDetailTitle: "档案文件明细",
      loading: false,
      loading1: false,
      viewLoading: false,
      pageParams: {
        maxCount: 0,//数据总条数
        currentPage: 0,//当前页(默认第一页)
        pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
        maxPage: 1,//总页数
        sort: "id",//排序字段
        order: "desc",//排序方式
        keyword: "",//通用查询条件
        keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
        orgCode: "",
      },
      pageParams1: {
        maxCount: 0,//数据总条数
        currentPage: 0,//当前页(默认第一页)
        pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
        maxPage: 1,//总页数
        sort: "id",//排序字段
        order: "desc",//排序方式
        keyword: "",//通用查询条件
        keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
        orgCode: "",
      },
      pagesizeOpts: [20, 30, 50, 100],//分页数组
      pagesizeOpts1: [20, 30, 50, 100],//分页数组
      tableData: [],
      talbeColumns: [
        // {
        //   type: 'selection',
        //   width: 60,
        //   fixed: "left",
        //   align: 'center'
        // },
        {
          title: "档案编号",
          key: "archiveNum",
          align: "center",
          width: 200,
        },
        {
          title: "全宗号",
          key: "fullFileNum",
          align: "center",
          width: 100,
        },
        {
          title: "全宗名称",
          key: "fullFileName",
          align: "center",
          width: 120,
        },
        {
          title: "档案类型",
          key: "archiveType",
          align: "center",
          width: 100,
          render: (h, params) => {
            return h("div", this.convertObject("archiveTypeList", "value", "name", params.row.archiveType))
          },
        },
        {
          title: "文件名称",
          key: "fileName",
          align: "center",
          minWidth: 200,
        },
        {
          title: "文件总数量",
          key: "filesTotal",
          align: "center",
          width: 120,
        },
        {
          title: "文件总大小",
          key: "filesSizeTotal",
          align: "center",
          width: 120,
          render: (h, params) => {
            return h("span", this.convertSize(params.row.filesSizeTotal))
          },
        },
        // {
        //   title: "所属科室",
        //   key: "orgCode",
        //   align: "center",
        //   width: 100,
        // },
        {
          title: "所属科室",
          key: "orgCode",
          align: "center",
          width: 150,
          render: (h, params) => {
            return h("span", this.orgCascader.convectOrgName(params.row.orgCode))
          },
        },
        {
          title: "操作",
          slot: "action",
          width: 300,
          align: "center",
          fixed: "right",
          render: (h, params) => {
            return h("div",
              [
                h(
                  "Button",
                  {

                    class: "editDiv"
                    ,
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      margin: "0 3px",
                    },

                    on: {
                      click: () => {
                        this.fileListWin(params.row);
                      },
                    },
                  },
                  "文件明细"
                ),

                h(
                  "Button",
                  {

                    class: "editDiv"
                    ,
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      margin: "0 3px",
                    },

                    on: {
                      click: () => {
                        this.editData(params.row.id);
                      },
                    },
                  },
                  "修改"
                ),

                h(
                  "Button",
                  {
                    class: "deleteDiv"
                    ,
                    props: {
                      type: "error",
                      size: "small",
                    },
                    style: {
                      margin: "0 3px",
                    },
                    on: {
                      click: () => {
                        this.deleteData(params.row);
                      },
                    },
                  },
                  "删除"
                ),

                h(
                  "Button",
                  {
                    class: "deleteDiv"
                    ,
                    props: {
                      type: "error",
                      size: "small",
                    },
                    style: {
                      margin: "0 3px",
                    },
                    on: {
                      click: () => {
                        // this.deleteData(params.row);
                        this.historyDataWin(params.row.id);
                        // this.loadData1(1,params.row.id);
                      },
                    },
                  },
                  "历史版本"
                ),

                // h(
                //   "Button",
                //   {
                //     class: "deleteDiv"
                //     ,
                //     props: {
                //       type: "error",
                //       size: "small",
                //     },
                //     on: {
                //       click: () => {
                //         this.historyDataWin(params.row.id);
                //       },
                //     },
                //   },
                //   "上传文件"
                // ),
              ]);
          },
        },
      ],
      right: {},
      saveLoading: false,
      infoType: "",
      infoTypeList: [],
      archiveTypeList: [],
      secretLevelList: [],
      historyList: [],
      fileList: [],
      filePath: "",
      whList: [],
      mcList: [],
      depts: [],
      deptid: [],
      jsonData: "",
      versionColumns: [
        {
          title: "id",
          key: "recordId",
          align: "center",
          width: 100,
        },
        {
          title: "版本",
          key: "version",
          align: "center",
          width: 100,
        },
        {
          title: "动作",
          key: "actionName",
          align: "center",
          width: 100,
        },
        // {
        //   title: "内容",
        //   key: "content",
        //   align: "center",
        //   width: 200,
        // },
        {
          title: "备注",
          key: "remarks",
          align: "center",
          minWidth: 100,
        },
        {
          title: "变更人员",
          key: "operationUser",
          align: "center",
          width: 100,
        },
        {
          title: "变更时间",
          key: "operationTime",
          align: "center",
          width: 100,
        },
        {
          title: "操作",
          slot: "action",
          minWidth: 200,
          align: "center",
          render: (h, params) => {
            return h("div",
              [
                h(
                  "Button",
                  {
                    class: "editDiv",
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      margin: "0 5px",
                    },

                    on: {
                      click: () => {
                        // this.$Modal.info({
                        //   title: "内容",
                        //   content: JSON.stringify(JSON.parse(params.row.content), null, "\t"),
                        //   width:1100
                        // });
                        this.showContent(params.row);
                      },
                    },
                  },
                  "查看内容"
                ),
              ])
          }
        },
      ],
      fileColumns: [
        {
          title: "顺序号",
          key: "sortNum",
          align: "center",
          width: 100,
        },
        // {
        //   title: "档案id",
        //   key: "archiveId",
        //   align: "center",
        //   width: 100,
        // },
        // {
        //   title: "文件编号",
        //   key: "fileNum",
        //   align: "center",
        //   width: 100,
        // },
        {
          title: "文件名称",
          key: "fileName",
          align: "center",
          minWidth: 250,
        },
        {
          title: "文件格式",
          key: "fileFormat",
          align: "center",
          width: 100,
        },
        {
          title: "文件大小",
          key: "fileSize",
          align: "center",
          width: 120,
          render: (h, params) => {
            return h("span", this.convertSize(params.row.fileSize))
          },
        },
        {
          title: "操作",
          slot: "action",
          width: 200,
          align: "center",
          fixed: "right",
          render: (h, params) => {
            return h("div",
              [
                h(
                  "Button",
                  {
                    class: "editDiv",
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      margin: "0 5px",
                    },

                    on: {
                      click: () => {
                        this.viewDetail(params.row)
                      },
                    },
                  },
                  "阅览"
                ),

                h(
                  "Button",
                  {

                    class: "editDiv"
                    ,
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      margin: "0 5px",
                    },

                    on: {
                      click: () => {
                        //this.editData(params.row.id);
                        this.editFileData(params.row);
                      },
                    },
                  },
                  "修改"
                ),

                h(
                  "Button",
                  {
                    class: "deleteDiv"
                    ,
                    props: {
                      type: "error",
                      size: "small",
                    },
                    on: {
                      click: () => {
                        this.deleteFile(params.row);
                      },
                    },
                  },
                  "删除"
                ),
              ]);
          },
        },
      ],
    };
  },
  created() {
    this.orgCascader = orgCascader("dept");
    this.deptid = this.orgCascader.convectCascaderList(this.$core.getBranchUserInfo("deptid"));
    this.orgCascader.loadData(
      (depts) => {
        this.depts = depts;
      }, this.deptid[this.deptid.length - 1]);
  },
  mounted() {
    this.loadModuleInfo();
  },
  methods: {
    loadModuleInfo() {
      //读取栏目信息
      this.$get({
        url: "/gateway/api/manage/type/moduleinfo",
        data: {
          functionId: this.$core.getFunctionId(),
        },
        success: (res) => {
          if (res.code == "200" && res.data) {
            this.title = res.data.typename;
          }
        }
      });
      this.$core.getStyleParamList("archiveType", false, "", res => {
        this.archiveTypeList = res || []
      })
      this.$core.getStyleParamList("secretLevel", true, "全部密级", res => {
        this.secretLevelList = res || [{ name: "全部密级", value: "" }]
      })
      this.$get({
        url: "/smartarchives/api/warehouse/queryWarehouse", data: { pageSize: 1000, orgCode: this.pageParams.orgCode }, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.whList = [{ whName: "全部库房", id: "" }].concat(res.dataList);
          }
        }
      })
      //读取数据列表
      this.loadData(1);
      this.$core.getControlRight().then(res => {
        this.right = res;
      });
    },
    convertObject(target, inFeild, outFeild, value) {
      var object = this[target].find(item => value == item[inFeild])
      return object ? object[outFeild] : "无"
    },
    loadData(currentPage) {
      if (currentPage) {
        this.pageParams.currentPage = currentPage;
      } else {
        this.pageParams.currentPage = this.pageParams.currentPage + 1;
      }
      this.loading = true;
      this.$get({
        url: "/smartarchives/api/archives/queryArchives",
        data: this.pageParams,
        success: (res) => {
          this.tableData = []
          if (res.code == "200" && res.pageParams) {
            this.pageParams = { ...this.pageParams, ...res.pageParams }
            this.tableData = res.dataList
          } else {
            this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
          }
          this.loading = false;
        }
      })
    },
    loadData1(currentPage,id) {
      if (currentPage) {
        this.pageParams1.currentPage = currentPage;
      } else {
        this.pageParams1.currentPage = this.pageParams1.currentPage + 1;
      }
      this.loading1 = true;
      this.pageParams1.recordId = id;
      this.pageParams1.tableName = 'tbarchives';
      
      this.$get({
        url: "/smartarchives/api/archivesversion/queryArchivesversion",
        data: this.pageParams1, success: (res) => {
          this.historyList = [];
          if (res.code == "200" && res.pageParams) {
            this.pageParams1 = { ...this.pageParams1, ...res.pageParams }
            this.historyList = res.dataList;
          }else {
            this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
          }
          this.loading1 = false;
        }
      })
      // this.$refs.historyContent.showModal();
    },
    deleteData(row) {
      if (this.right["D"] == false) {
        this.$Message.error({ background: true, content: "你没有删除权限" })
        return;
      }
      var ids = ""
      if (row) {
        ids = row.id
      } else {
        let selectedList = this.$refs.fncTable.getSelection();
        if (selectedList && selectedList.length > 0) {
          ids = selectedList.map(item => {
            return item.id;
          }).join(",")
        }
      }
      if (!ids) {
        this.$Message.error({ background: true, content: "并没有选择审核项" })
        return;
      }

      this.$Modal.confirm({
        title: '删除确认',
        content: row ? '您确认要删除“<span style="color:red"> ' + row.fileName + '</span>”吗？' : "确认要删除选中吗？",
        onOk: () => {
          this.$post({
            url: "/smartarchives/api/archives/delArchives", data: { ids: ids },
            success: (res) => {
              if (res.code == "200") {
                this.$Message.success({ background: true, content: row.fileName + "已移入回收站" })
                this.loadData(1);
              } else {
                this.$Message.error({ background: true, content: res.desc })
              }
            }
          });
        }
      });
    },
    editData(id) {
      if (id && this.right["E"] == true || !id && this.right["A"] == true) {
        this.$refs.editModal.showModal();
        this.$refs.editContent.loadData(id);
      }
    },
    closeModal() {
      this.$refs.editModal.hideModal();
    },
    submitData() {
      this.$refs.editContent.submitData((b, reload) => {
        this.saveLoading = b;
        if (reload == true) {
          this.loadData(1);
          // this.closeModal();
          this.$refs.editModal.hideModal()
        }
      });
    },
    closehistoryModal() {
      this.$refs.historyContent.hideModal();
    },
    historyDataWin(id) {
      // this.$get({
      //   url: "/smartarchives/api/archivesversion/queryArchivesversion",
      //   data: { recordId: id, tableName: 'tbarchives' }, success: (res) => {
      //     if (res.code == "200" && res.dataList) {
      //       this.historyList = res.dataList;
      //     }
      //   }
      // })
      this.loadData1(1,id);
      this.$refs.historyContent.showModal();
    },
    closefileListModal() {
      this.$refs.fileListContent.hideModal();
    },
    fileListWin(row) {
      this.editFile = row;
      this.viewDetailTitle = (row.fileName || "") + "【" + row.archiveNum + "】" + "档案文件明细";
      this.viewLoading = true;
      this.fileList = [];
      this.$refs.fileListContent.showModal();
      this.$get({
        url: "/smartarchives/api/archives/queryFileList",
        data: { id: row.id, filesTotal: row.filesTotal }, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.fileList = res.dataList;
          }
        },
        complete: () => {
          this.viewLoading = false;
        }
      })
      
    },
    viewDetail(row) {
      this.viewTitle = (row.fileName || "");
      this.filePath = row.filePath;
      this.$refs.viewModal.showModal();
    },
    deleteFile(row) {
      if (this.right["D"] == false) {
        this.$Message.error({ background: true, content: "你没有删除权限" })
        return;
      }
      this.$Modal.confirm({
        title: '删除确认',
        content: '您确认要删除“<span style="color:red"> ' + row.fileName + '</span>”吗？',
        onOk: () => {
          this.$post({
            url: "/smartarchives/api/archives/delArchivesFile", data: { id: row.id },
            success: (res) => {
              if (res.code == "200") {
                this.$Message.success({ background: true, content: res.desc })
                this.closefileListModal();
                this.loadData(1);
                this.fileListWin(this.editFile);
              } else {
                this.$Message.error({ background: true, content: res.desc })
              }
            }
          });
        }
      });
    },
    checkOrgRight(e) {
      this.deptid = this.orgCascader.checkOrgRight(e);
      this.pageParams.orgCode = this.deptid[this.deptid.length - 1]
    },
    getMcList(e) {
      this.mcList = []
      this.$get({
        url: "/smartarchives/api/machinecabinet/queryMachinecabinet", data: { pageSize: 1000, whId: e }, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.mcList = res.dataList;
          }
        }
      })
    },
    showContent(row) {
      if (row.content != undefined && row.content != null && row.content != '') {
        this.jsonData = JSON.stringify(JSON.parse(row.content), null, "\t")
        this.contentModalFlag = true;
        //this.$refs.contentModal.showModal();

        

      } else {
        this.$Message.error({ background: true, content: "查无内容" })
      }
    },
    beautifyJson(json) {
      if (typeof json != 'string') {
        json = JSON.stringify(json, undefined, 2);
      }
      json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>').replaceAll('{', '{<br>').replaceAll('}', '<br>}').replaceAll(',', ',<br>');
      return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        let cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null/.test(match)) {
          cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
      });
    },
    convertSize(size) {
      if (!size) {
        return "-";
      } else if (size < 1024) {
        return size + "B";
      } else if (size < 1024 * 1024) {
        return (size / (1024)).toFixed(3) + "KB";
      } else {
        return (size / (1024 * 1024)).toFixed(3) + "MB";
      }
    },
    editFileData(file) {
            if (this.right["A"] == true) {
                this.saveLoading = 1
                this.$refs.editFileModal.showModal();
                this.$refs.editFileContent.loadData(JSON.parse(JSON.stringify(file)));
            }
        },
        submitFileData() {
            this.$refs.editFileContent.submitData((b, c) => {
                if (c) {
                    this.loadData(1)
                    this.fileListWin(this.editFile);
                    this.$refs.editFileModal.hideModal()
                }
                this.saveLoading = b
            })
        },
  }
};
</script>
    
<style scoped>
.line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/deep/ .string {
  color: green;
}

/deep/ .number {
  color: darkorange;
}

/deep/ .boolean {
  color: blue;
}

/deep/ .null {
  color: magenta;
}

/deep/ .key {
  color: red;
}
</style>
<style scoped lang='less'></style>