<template>
    <FNCContent>
        <template v-slot:title>{{ title }}</template>
        <template v-slot:toolsbarLeft>
            <div style="width: 600px; display: flex" v-if="tableList.pageParams">
                <Select clearable v-model="questionBankId" style="width: 300px" placeholder="所有题库类型">
                    <Option v-for="(item, idx) in questionBank" :value="item.id" :key="'en' + idx">{{ item.questionBank }}</Option>
                </Select>
                <Input search enter-button="查询" clearable v-model="tableList.pageParams.keyword" placeholder="输入标题关键字" @on-search="loadData(1)" />
            </div>
        </template>
        <template v-slot:toolsbarRight>
            <Button
                type="info"
                class="addDiv"
                @click="
                    () => {
                        editData()
                    }
                "
                v-if="right.A"
            >
                <Icon type="md-add-circle" />
                添加
            </Button>

            <Button
                type="warning"
                class="passedDiv"
                @click="
                    () => {
                        passedData()
                    }
                "
                v-if="right.D"
            >
                <Icon type="md-eye" />
                启禁用选中
            </Button>
            <Button
                type="error"
                class="delDiv"
                @click="
                    () => {
                        deleteData()
                    }
                "
                v-if="right.D"
            >
                <Icon type="md-close-circle" />
                删除选中
            </Button>
        </template>
        <template v-slot:contentArea>
            <FNCTable
                ref="fncTable"
                :loading="tableList.loading"
                :fixTable="true"
                :talbeColumns="talbeColumns"
                :tableData="tableList.dataList"
                :pageParams="tableList.pageParams"
                :pagesizeOpts="tableList.pagesizeOpts"
                @handleLoadData="loadData"
            ></FNCTable>
            <FNCModal ref="editModal" title="编辑窗" width="1000px">
                <template v-slot:contentarea>
                    <Edit ref="editContent" @closeModal="closeModal" :questionBank="questionBank"></Edit>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" style="margin-right: 8px" @click="closeModal">
                        <Icon type="md-close" />
                        取消
                    </Button>
                    <Button type="primary" @click="submitData" :loading="saveLoading">
                        <Icon type="md-checkmark-circle" />
                        保存
                    </Button>
                </template>
            </FNCModal>
            <FNCModal ref="rankModal" :title="rankTitle" width="1000px">
                <template v-slot:contentarea>
                    <Rank ref="rankList"></Rank>
                </template>
            </FNCModal>
            <FNCModal ref="shareModal" title="分享码" :fullscreen="false" width="420px">
                <template v-slot:contentarea>
                    <div style="display: flex; width: 100%">
                        <div v-if="shareUrl"><img :src="shareUrl" style="width: 400px; height: 400px" @error="errorImg" /></div>
                        <div class="qrcode" id="qrcode" v-else></div>
                    </div>
                    <div style="display: flex; width: 100%; margin: 20px 0; font-size: 16px">
                        <div style="width: 400px; text-align: center" v-if="shareUrl">小程序码</div>
                        <div style="width: 400px; text-align: center" v-else>H5二维码</div>
                    </div>
                </template>
            </FNCModal>
        </template>
    </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/answer
import FNCContent from "@/components/FNCContent"
import FNCTable from "@/components/FNCTable"
import FNCModal from "@/components/FNCModal"
import Edit from "./childrens/AnswerEdit"
import Rank from "./childrens/Rank"
import { pageList } from "@/utils/pageList"
import QRCode from "qrcodejs2"
export default {
    components: {
        FNCTable,
        FNCContent,
        FNCModal,
        Edit,
        Rank,
    },

    data() {
        return {
            title: "答题活动",
            rankTitle:"排行榜",
            tableList: {},
            shareUrl: "",
            talbeColumns: [
                {
                    type: "selection",
                    width: 60,
                    fixed: "left",
                    align: "center",
                },
                {
                    title: "题库类型",
                    key: "questionBankName",
                    align: "left",
                    width: 200,
                },
                {
                    title: "标题",
                    key: "title",
                    align: "left",
                    minWidth: 400,
                },
                {
                    title: "答题模式",
                    key: "module",
                    align: "center",
                    width: 150,
                    render: (h, params) => {
                        return h("div", params.row.module == "1" ? "闯关模式" : params.row.module == "2" ? "计分模式" : params.row.module == "3" ? "考试模式" : "-")
                    },
                },
                {
                    title: "开始时间",
                    key: "startTime",
                    align: "center",
                    minWidth: 180,
                },
                {
                    title: "结束时间",
                    key: "endTime",
                    align: "center",
                    minWidth: 180,
                },
                {
                    title: "启禁用",
                    key: "idisplay",
                    fixed: "right",
                    align: "center",
                    width: 80,
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                class: "passedDiv",
                            },
                            [
                                h(
                                    "i-switch",
                                    {
                                        props: {
                                            value: params.row.idisplay == 1 ? true : false,
                                            "before-change": () => {
                                                return new Promise(resolve => {
                                                    this.passedData(params.row, resolve)
                                                })
                                            },
                                        },
                                        on: {
                                            "on-change": e => {
                                                this.tableList.dataList[params.index].idisplay = e ? 1 : 0
                                            },
                                        },
                                    },
                                    [h("span", { slot: "open" }, "启"), h("span", { slot: "close" }, "禁")]
                                ),
                            ]
                        )
                    },
                },
                {
                    title: "操作",
                    slot: "action",
                    fixed: "right",
                    width: 280,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    class: "editDiv",
                                    props: {
                                        type: "success",
                                        size: "small",
                                    },
                                    style: {
                                        margin: "0 2px",
                                    },

                                    on: {
                                        click: () => {
                                            this.rankData(params.row)
                                        },
                                    },
                                },
                                "排行榜"
                            ),
                            h(
                                "Button",
                                {
                                    class: "editDiv",
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        margin: "0 2px",
                                    },

                                    on: {
                                        click: () => {
                                            this.shareData(params.row)
                                        },
                                    },
                                },
                                "分享码"
                            ),

                            h(
                                "Button",
                                {
                                    class: "editDiv",
                                    props: {
                                        type: "default",
                                        size: "small",
                                    },
                                    style: {
                                        margin: "0 2px",
                                    },

                                    on: {
                                        click: () => {
                                            this.editData(params.row.id)
                                        },
                                    },
                                },
                                "修改"
                            ),

                            h(
                                "Button",
                                {
                                    class: "deleteDiv",
                                    props: {
                                        type: "default",
                                        size: "small",
                                    },
                                    style: {
                                        margin: "0 2px",
                                    },
                                    on: {
                                        click: () => {
                                            this.deleteData(params.row)
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],
            right: {},
            saveLoading: false,
            questionBank: [],
            questionBankId: "",
        }
    },
    mounted() {
        this.tableList = pageList(this.$get)
        this.loadModuleInfo()
    },
    methods: {
        loadModuleInfo() {
            //读取栏目信息
            this.$get({
                url: "/gateway/api/manage/type/moduleinfo",
                data: {
                    functionId: this.$core.getFunctionId(),
                },
                success: res => {
                    if (res.code == "200" && res.data) {
                        this.title = res.data.typename
                    }
                },
            })
            //读取题库类型
            this.$get({
                url: "/smartlife/api/questions/banklist",
                data: {
                    pagesize: 100000,
                    order: "asc",
                    sort: "questionBank",
                },
                success: res => {
                    if (res.code == "200" && res.dataList) {
                        this.questionBank = res.dataList
                    }
                },
            })

            //读取数据列表
            this.loadData(1)
            this.$core.getControlRight().then(res => {
                this.right = res
            })
        },
        loadData(currentPage) {
            this.tableList.loadData({
                url: "/smartlife/api/answer/list",
                currentPage: currentPage,
                data: { questionBankId: this.questionBankId },
                success: res => {
                    if (res.code != "200") {
                        this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
                    }
                },
            })
        },
        passedData(row, resolve) {
            if (this.right["P"] == false) {
                this.$Message.error({ background: true, content: "你没有启禁用权限" })
                return
            }
            var ids = ""
            if (row) {
                ids = row.id
            } else {
                let selectedList = this.$refs.fncTable.getSelection()
                if (selectedList && selectedList.length > 0) {
                    ids = selectedList
                        .map(item => {
                            return item.id
                        })
                        .join(",")
                }
            }
            if (!ids) {
                this.$Message.error({ background: true, content: "并没有选择审核项" })
                return
            }

            this.$Modal.confirm({
                title: "启禁确认",
                content: row ? '您确认要<span style="color:red"> ' + (row.idisplay == 1 ? "禁用" : "启用") + " </span>" + row.title + "吗？" : "您确认要启禁用选中？",
                onOk: () => {
                    this.$post({
                        url: "/smartlife/api/answer/passed",
                        data: { ids: ids },
                        success: res => {
                            if (res.code == "200") {
                                if (resolve) resolve()
                                this.$Message.success({ background: true, content: res.desc })
                                this.loadData(1)
                            } else {
                                this.$Message.error({ background: true, content: res.desc })
                            }
                        },
                    })
                },
            })
        },
        deleteData(row) {
            if (this.right["D"] == false) {
                this.$Message.error({ background: true, content: "你没有删除权限" })
                return
            }
            var ids = ""
            if (row) {
                ids = row.id
            } else {
                let selectedList = this.$refs.fncTable.getSelection()
                if (selectedList && selectedList.length > 0) {
                    ids = selectedList
                        .map(item => {
                            return item.id
                        })
                        .join(",")
                }
            }
            if (!ids) {
                this.$Message.error({ background: true, content: "并没有选择审核项" })
                return
            }

            this.$Modal.confirm({
                title: "删除确认",
                content: row ? '您确认要删除“<span style="color:red"> ' + row.title + "</span>”吗？" : "确认要删除选中吗？",
                onOk: () => {
                    this.$post({
                        url: "/smartlife/api/answer/delete",
                        data: { ids: ids },
                        success: res => {
                            if (res.code == "200") {
                                this.$Message.success({ background: true, content: res.desc })
                                this.loadData(1)
                            } else {
                                this.$Message.error({ background: true, content: res.desc })
                            }
                        },
                    })
                },
            })
        },
        editData(id) {
            if ((id && this.right["E"] == true) || (!id && this.right["A"] == true)) {
                this.$refs.editModal.showModal()
                this.$refs.editContent.loadData(id, this.questionBankId)
            }
        },
        closeModal() {
            this.$refs.editModal.hideModal()
        },
        submitData() {
            this.$refs.editContent.submitData((b, reload) => {
                this.saveLoading = b
                if (reload == true) {
                    this.loadData(1)
                    this.closeModal()
                }
            })
        },
        errorImg(e) {
            e.target.src = "./images/nopic.png"
        },
        rankData(row){
            this.$refs.rankModal.showModal()
            this.rankTitle="《"+row.title+"》"+"排行榜";
            this.$refs.rankList.loadData(row.id,row.module)
        },
        shareData(row) {
            this.$refs.shareModal.showModal()
            this.shareUrl = "./images/nopic.png"
            this.$get({
                url: "/gateway/api/weixinservice/getUnlimitedQRCode",
                data: {
                    clientId: this.$core.getPortalConfig("client_id"),
                    url: "apps/smartlife/pages/answer/index",
                    scene: row.id,
                },
                success: res => {
                    if (res.code == "200" && res.data) {
                        this.shareUrl = res.data
                        // console.log(this.shareUrl)
                    } else {
                        this.$("#qrcode").html("")
                        new QRCode("qrcode", {
                            text: this.$getProxy("/smartlifeh5") + "/apps/smartlife/pages/answer/index?id=" + row.id,
                            width: 400,
                            height: 400,
                        })
                    }
                },
            })
        },
    },
}
</script>

<style scoped lang="less"></style>
