<template>
    <div style="overflow-y: auto; padding: 10px; height: 500px">
        <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
            <FormItem label="题库类型" prop="questionBankId">
                <Select clearable v-model="formItems.questionBankId" style="width: 300px" placeholder="选择题库类型">
                    <Option v-for="(item, idx) in questionBank" :value="item.id" :key="'en' + idx">{{ item.questionBank }}</Option>
                </Select>
            </FormItem>

            <FormItem label="题目" prop="title">
                <Input type="text" v-model="formItems.title" placeholder="中文题目"></Input>
            </FormItem>
            <FormItem label="选项类型" prop="questionType">
                <RadioGroup v-model="formItems.questionType" @on-change="typeChange">
                    <Radio label="SINGLE">单选</Radio>
                    <Radio label="MULTIPLE">多选</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="选项" prop="questionoptions">
                <Table :border="true" :columns="optionsColumns" :data="optionsData" size="small" ref="optionsTable" v-if="optionsData && optionsData.length > 0"></Table>
                <Button type="default" style="margin: 10px 0; width: 200px" @click="addColumn">
                    <Icon type="md-add-circle" />
                    增加一行
                </Button>
            </FormItem>
            <FormItem label="启禁用" prop="idisplay">
                <RadioGroup v-model="formItems.idisplay">
                    <Radio :label="1">启用</Radio>
                    <Radio :label="0">禁用</Radio>
                </RadioGroup>
            </FormItem>
        </Form>
    </div>
</template>

<script>
import FNCUpload from "@/components/FNCUpload"
export default {
    components: {
        FNCUpload,
    },
    props: {
        questionBank: [],
    },

    data() {
        return {
            formItemsDefault: {
                id: 0,
                questionBankId: "",
                title: "",
                questionType: "SINGLE",
                idisplay: 1,
                branchId: 0,
            },
            formItems: {},
            ruleItems: {
                questionBankId: [{ type: "number", required: true, message: "题库类型不能为空", trigger: "change" }],
                title: [{ required: true, message: "题目不能为空", trigger: "change" }],
            },
            optionsColumns: [
                {
                    title: "选项",
                    key: "content",
                    align: "center",
                    minWidth: 500,
                    render: (h, params) => {
                        return h("Input", {
                            style: "width:100%;",
                            props: {
                                value: params.row.content,
                                type: "text",
                                placeholder: "请输入选项内容",
                            },
                            on: {
                                input: val => {
                                    this.optionsData[params.index].content = val
                                },
                            },
                        })
                    },
                },
                {
                    title: "是否答案",
                    key: "answer",
                    align: "center",
                    width: 120,
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "i-switch",
                                {
                                    props: {
                                        value: params.row.answer == 1 ? true : false,
                                        "before-change": () => {
                                            this.typeChange()
                                        },
                                    },
                                    on: {
                                        "on-change": e => {
                                            this.optionsData[params.index].answer = e ? 1 : 0
                                        },
                                    },
                                },
                                [h("span", { slot: "open" }, "是"), h("span", { slot: "close" }, "否")]
                            ),
                        ])
                    },
                },
                {
                    title: " ",
                    slot: "action",
                    width: 100,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "Button",
                            {
                                style: "color:#cc0000;width:100%;",
                                props: {
                                    type: "text",
                                    size: "small",
                                },
                                on: {
                                    click: () => {
                                        this.optionsData.splice(params.index, 1)
                                    },
                                },
                            },
                            "删除"
                        )
                    },
                },
            ],
            optionsData: [
                { content: "", answer: 0 },
                { content: "", answer: 0 },
            ],
        }
    },
    mounted() {},
    methods: {
        loadData(id,questionBankId) {
            this.formItems = { ...this.formItemsDefault }
            this.optionsData = [
                { content: "", answer: 0 },
                { content: "", answer: 0 },
            ]
            this.formItems.questionBankId=questionBankId || "";
            if (id > 0) {
                this.$Message.loading({ content: "读取中...",duration:0 })
                this.$get({
                    url: "/smartlife/api/questions/questionview",
                    data: { id },
                    success: res => {
                        if (res.code == "200" && res.data) {
                            this.formItems = { ...this.formItems, ...res.data }
                            if (this.formItems && this.formItems.options) {
                                this.optionsData = this.formItems.options
                            }
                            this.$Message.destroy()
                        } else {
                            this.$Message.error(res.desc || "读取数据出错")
                            this.$emit("closeModal")
                        }
                    },fail:() => {
                        this.$Message.destroy()
                    }
                })
            }
        },
        addColumn() {
            this.optionsData.push({ content: "", answer: 0 })
        },
        typeChange(e) {
            if (this.formItems.questionType != "MULTIPLE") {
                this.optionsData.forEach(item => {
                    item.answer = 0
                })
            }
        },
        submitData(callback) {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    var options = JSON.stringify(this.optionsData)
                    var count = 0
                    this.optionsData.forEach(item => {
                        if (item.answer == 1) {
                            count++
                        }
                    })
                    if (this.formItems.questionType == "MULTIPLE") {
                        if (count < 2) {
                            this.$Message.error({ background: true, content: "至少选择两个答案" })
                            return
                        }
                    } else {
                        if (count != 1) {
                            this.$Message.error({ background: true, content: "请选择一个答案" })
                            return
                        }
                    }
                    callback(true)
                    this.$post({
                        url: "/smartlife/api/questions/savequestion",
                        data: { ...this.formItems, options },
                        success: res => {
                            if (res.code == "200") {
                                callback(false, true)
                                this.$Message.success({ background: true, content: "保存成功" })
                            } else {
                                this.$Message.error({ background: true, content: res.desc || "保存出错" })
                            }
                        },
                        complete: () => {
                            callback(false)
                        },
                        fail: () => {
                            callback(false)
                        },
                    })
                } else {
                    this.$Message.error({ background: true, content: "部分必填项没填写" })
                }
            })
        },
    },
}
</script>
    
<style scoped lang='less'></style>