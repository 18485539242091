<template>
  <FNCContent>
    <template v-slot:title> {{ title }} </template>
    <template v-slot:toolsbarLeft>
      <Select v-model="pageParams.status" style="width: 120px" placeholder="移交状态">
        <Option value="">全部</Option>
        <Option v-for="item in statusList" :value="item.value" :key="item.name">{{ item.name }}</Option>
      </Select>
      <div style="width:250px;"><Input search enter-button="查询" clearable v-model="pageParams.keyword"
          placeholder="输入档案名称" @on-search="loadData(1)" /></div>
    </template>
    <template v-slot:toolsbarRight>

      <Button type="info" class="addDiv" @click="() => { editData() }" v-if="right.A">
        <Icon type="md-add-circle" size="18" />新建
      </Button>
      <!-- <Button type="warning" class="deleteDiv" @click="() => { deleteData() }" v-if="right.D">
        <Icon type="md-trash" size="18" />删除
      </Button> -->

    </template>
    <template v-slot:contentArea>
      <FNCTable ref="fncTable" :loading="loading" :fixTable="true" :talbeColumns="talbeColumns" :tableData="tableData"
        :pageParams="pageParams" :pagesizeOpts="pagesizeOpts" @handleLoadData="loadData"></FNCTable>
      <FNCModal ref="editModal" title="移交编辑" :fullscreen="false" width="800px" height="720px">
        <template v-slot:contentarea>
          <Edit ref="editContent" @closeModal="closeModal"></Edit>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closeModal">
            <Icon type="md-close" />取消
          </Button>
          <Button type="primary" @click="submitData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />移交
          </Button>
        </template>
      </FNCModal>
    </template>
  </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/transferaudit
import FNCContent from "@/components/FNCContent";
import FNCTable from "@/components/FNCTable";
import FNCModal from "@/components/FNCModal";
import Edit from "./childrens/Edit";
import {orgCascader} from "@/utils/orgCascader";
export default {
  components: {
    FNCTable,
    FNCContent,
    FNCModal,
    Edit,
  },

  data() {
    return {
      title: "信息列表",
      loading: false,
      pageParams: {
        maxCount: 0,//数据总条数
        currentPage: 0,//当前页(默认第一页)
        pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
        maxPage: 1,//总页数
        sort: "id",//排序字段
        order: "desc",//排序方式
        keyword: "",//通用查询条件
        keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
      },
      pagesizeOpts: [20, 30, 50, 100],//分页数组
      tableData: [],
      talbeColumns: [
        // {
        //   type: 'selection',
        //   width: 60,
        //   fixed: "left",
        //   align: 'center'
        // },
        {
          title: "档案名称",
          key: "fileName",
          align: "center",
          width: 100,
          fixed: "left",
        },
        {
          title: "原科室",
          key: "oldOrgCode",
          align: "center",
          width: 150,
          render: (h, params) => {
            // return h("div", this.orgCascader.convectCascaderName(params.row.oldOrgCode)
            return h("span", this.orgCascader.convectOrgName(params.row.oldOrgCode)
            )
          },
        },
        {
          title: "原库房",
          key: "oldWhName",
          align: "center",
          width: 100,
        },
        {
          title: "原机柜",
          key: "oldMcName",
          align: "center",
          width: 100,
        },
        {
          title: "现科室",
          key: "orgCode",
          align: "center",
          width: 150,
          render: (h, params) => {
            // return h("div", this.orgCascader.convectCascaderName(params.row.orgCode)
            return h("span", this.orgCascader.convectOrgName(params.row.orgCode)
            )
          },
        },
        {
          title: "现库房",
          key: "whName",
          align: "center",
          width: 100,
        },
        {
          title: "现机柜",
          key: "mcName",
          align: "center",
          width: 100,
        },
        {
          title: "提交人",
          key: "applyUserName",
          align: "center",
          width: 80,
        },
        {
          title: "提交时间",
          key: "applyTime",
          align: "center",
          width: 100,
        },
        {
          title: "接收人",
          key: "receiverUserName",
          align: "center",
          width: 100,
        },
        {
          title: "接收时间",
          key: "receiveTime",
          align: "center",
          width: 100,
        },
        {
          title: "移交状态",
          key: "status",
          align: "center",
          width: 80,
          render: (h, params) => {
          return h("div",this.convertObject("statusList","value","name",params.row.status+''))},
        },
        {
          title: "备注",
          key: "reason",
          align: "center",
          midWidth: 100,
        },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
          fixed: "right",
          render: (h, params) => {
            return h("div",
              [
                h(
                  "Button",
                  {

                    class: "editDiv"
                    ,
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      display: params.row.status != 1 ? "" : "none",
                      margin: "0 3px",
                    },

                    on: {
                      click: () => {
                        this.editData(params.row);
                      },
                    },
                  },
                  "修改"
                ),

                h(
                  "Button",
                  {
                    class: "deleteDiv"
                    ,
                    props: {
                      type: "error",
                      size: "small",
                    },
                    style: {
                      display: params.row.status != 1 ? "" : "none",
                      margin: "0 3px",
                    },
                    on: {
                      click: () => {
                        this.deleteData(params.row);
                      },
                    },
                  },
                  "删除"
                ),
              ]);
          },
        },
      ],
      right: {},
      saveLoading: false,
      infoType: "",
      infoTypeList: [],
      statusList: [],
      depts:[],
      deptid:[],
    };
  },
  created() {
    this.orgCascader = orgCascader("dept");
    this.deptid=this.orgCascader.convectCascaderList(this.$core.getBranchUserInfo("deptid"));
    this.orgCascader.loadData(
      (depts) => {
        this.depts = depts;
      },this.deptid[this.deptid.length-1]);
  },
  mounted() {
    this.loadModuleInfo();
  },
  methods: {
     loadModuleInfo() {
      //读取栏目信息
       this.$get({
        url: "/gateway/api/manage/type/moduleinfo",
        data: {
          functionId: this.$core.getFunctionId(),
        },
        success: (res) => {
          if (res.code == "200" && res.data) {
            this.title = res.data.typename;
          }
        }
      });
      this.$core.getStyleParamList("transferauditStatus", false, null, (res) => {
        this.statusList = res
      })
      //读取数据列表
      this.loadData(1);
      this.$core.getControlRight().then(res => {
        this.right = res;
      });
    },
    convertObject(target,inFeild,outFeild,value){
      var object=this[target].find(item=>value==item[inFeild])
      return object?object[outFeild]:"无"
    },
    loadData(currentPage) {
      if (currentPage) {
        this.pageParams.currentPage = currentPage;
      } else {
        this.pageParams.currentPage = this.pageParams.currentPage + 1;
      }
      this.loading = true;
      this.$get({
        url: "/smartarchives/api/transferaudit/queryTransferaudit",
        data: this.pageParams,
        success: (res) => {
          this.tableData = []
          if (res.code == "200" && res.pageParams) {
            this.pageParams = {...this.pageParams,...res.pageParams}
            this.tableData = res.dataList
          } else {
            this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
          }
          this.loading = false;
        }
      })
    },
    deleteData(row) {
      if (this.right["D"] == false) {
        this.$Message.error({ background: true, content: "你没有删除权限" })
        return;
      }
      // if (row.status == 1) {
      //   this.$Message.error({ background: true, content: "该档案已接收，不能删除" })
      //   return;
      // }
      var ids = ""
      if (row) {
        ids = row.id
      } else {
        let selectedList = this.$refs.fncTable.getSelection();
        if (selectedList && selectedList.length > 0) {
          ids = selectedList.map(item => {
            return item.id;
          }).join(",")
        }
      }
      if (!ids) {
        this.$Message.error({ background: true, content: "并没有选择审核项" })
        return;
      }

      this.$Modal.confirm({
        title: '删除确认',
        content: "确认要删除选中吗？",
        onOk: () => {
          this.$post({
            url: "/smartarchives/api/transferaudit/delTransferaudit", data: { ids: ids },
            success: (res) => {
              if (res.code == "200") {
                this.$Message.success({ background: true, content: res.desc })
                this.loadData(1);
              } else {
                this.$Message.error({ background: true, content: res.desc })
              }
            }
          });
        }
      });
    },
    editData(row) {
      if (row!= undefined && row.id && this.right["E"] == true) {
        // if(row.status == 1) {
        //   this.$Message.error({ background: true, content: "该档案已接收，不能修改" });
        //   return;
        // }
        this.$refs.editModal.showModal();
        this.$refs.editContent.loadData(row.id);
      }
      if (row==undefined && this.right["A"] == true) {
        this.$refs.editModal.showModal();
        this.$refs.editContent.loadData();
      }
    },
    closeModal() {
      this.$refs.editModal.hideModal();
    },
    submitData() {
      this.$refs.editContent.submitData((b, reload) => {
        this.saveLoading = b;
        if (reload == true) {
          this.loadData(1);
          this.closeModal();
        }
      });
    },
    closehistoryModal() {
      this.$refs.historyContent.hideModal();
    },
  }
};
</script>
    
<style scoped lang='less'></style>