/**
 * 字符串加密传输
 * 说明:
 * 1. key, iv值由后台提供
 */
import CryptoJS from 'crypto-js'
const skey="1862093078710210"
const siv="1862093078710210"
export const encrypt = src => {
  let key = skey
  key = CryptoJS.enc.Utf8.parse(key)
  const iv = CryptoJS.enc.Utf8.parse(siv)
  const enc = CryptoJS.AES.encrypt(src, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  const enced = enc.ciphertext.toString(CryptoJS.enc.Base64)
  // console.warn('加密:', enced)
  return enced
}

export const decrypt = src => {
  let key = skey
  key = CryptoJS.enc.Utf8.parse(key)
  const iv = CryptoJS.enc.Utf8.parse(siv)
  const dec = CryptoJS.AES.decrypt(src, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  // console.log('解密:', CryptoJS.enc.Utf8.stringify(dec))
  return CryptoJS.enc.Utf8.stringify(dec)
}
