<template>
    <FNCContent>
        <template v-slot:title>{{ title }}</template>
        <template v-slot:toolsbarLeft>
            <div style="width: 600px; display: flex" v-if="tableList.pageParams">
                <Select clearable v-model="questionBankId" style="width: 300px" placeholder="所有题库类型">
                    <Option v-for="(item, idx) in questionBank" :value="item.id" :key="'en' + idx">{{ item.questionBank }}</Option>
                </Select>
                <Input search enter-button="查询" clearable v-model="tableList.pageParams.keyword" placeholder="输入标题关键字" @on-search="loadData(1)" />
            </div>
        </template>
        <template v-slot:toolsbarRight>
            <Button
                type="info"
                class="addDiv"
                @click="
                    () => {
                        editData()
                    }
                "
                v-if="right.A"
            >
                <Icon type="md-add-circle" />
                添加
            </Button>
          
            <Button
                type="warning"
                class="passedDiv"
                @click="
                    () => {
                        passedData()
                    }
                "
                v-if="right.D"
            >
            <Icon type="md-eye" />
                启禁用选中
            </Button>
            <Button
                type="error"
                class="delDiv"
                @click="
                    () => {
                        deleteData()
                    }
                "
                v-if="right.D"
            >
                <Icon type="md-close-circle" />
                删除选中
            </Button>
        </template>
        <template v-slot:contentArea>
            <FNCTable
                ref="fncTable"
                :loading="tableList.loading"
                :fixTable="true"
                :talbeColumns="talbeColumns"
                :tableData="tableList.dataList"
                :pageParams="tableList.pageParams"
                :pagesizeOpts="tableList.pagesizeOpts"
                @handleLoadData="loadData"
            ></FNCTable>
            <FNCModal ref="editModal" title="编辑窗" width="1000px">
                <template v-slot:contentarea>
                    <Edit ref="editContent" @closeModal="closeModal" :questionBank="questionBank"></Edit>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" style="margin-right: 8px" @click="closeModal">
                        <Icon type="md-close" />
                        取消
                    </Button>
                    <Button type="primary" @click="submitData" :loading="saveLoading">
                        <Icon type="md-checkmark-circle" />
                        保存
                    </Button>
                </template>
            </FNCModal>
        </template>
    </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/question
import FNCContent from "@/components/FNCContent"
import FNCTable from "@/components/FNCTable"
import FNCModal from "@/components/FNCModal"
import Edit from "./childrens/QuestionEdit"
import { pageList } from "@/utils/pageList"
export default {
    components: {
        FNCTable,
        FNCContent,
        FNCModal,
        Edit,
    },

    data() {
        return {
            title: "题库",
            tableList: {},
            talbeColumns: [
                {
                    type: "selection",
                    width: 60,
                    fixed: "left",
                    align: "center",
                },
                {
                    title: "题库类型",
                    key: "questionBankName",
                    align: "left",
                    width: 300,
                },
                {
                    title: "题目",
                    key: "title",
                    align: "left",
                    minWidth: 400,
                },
                {
                    title: "选项",
                    key: "questionType",
                    align: "center",
                    width: 120,
                    render: (h, params) => {
                        return h("div", params.row.questionType == "MULTIPLE" ? "多选" : "单选")
                    },
                },

                {
                    title: "启禁用",
                    key: "idisplay",
                    fixed: "right",
                    align: "center",
                    width: 80,
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                class: "passedDiv",
                            },
                            [
                                h(
                                    "i-switch",
                                    {
                                        props: {
                                            value: params.row.idisplay == 1 ? true : false,
                                            "before-change": () => {
                                                return new Promise(resolve => {
                                                    this.passedData(params.row, resolve)
                                                })
                                            },
                                        },
                                        on: {
                                            "on-change": e => {
                                                this.tableList.dataList[params.index].idisplay = e ? 1 : 0
                                            },
                                        },
                                    },
                                    [h("span", { slot: "open" }, "启"), h("span", { slot: "close" }, "禁")]
                                ),
                            ]
                        )
                    },
                },
                {
                    title: "操作",
                    slot: "action",
                    fixed: "right",
                    width: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    class: "editDiv",
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: {
                                        margin: "0 5px",
                                    },

                                    on: {
                                        click: () => {
                                            this.editData(params.row.id)
                                        },
                                    },
                                },
                                "修改"
                            ),

                            h(
                                "Button",
                                {
                                    class: "deleteDiv",
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    on: {
                                        click: () => {
                                            this.deleteData(params.row)
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],
            right: {},
            saveLoading: false,
            questionBank:[],
            questionBankId:"",
        }
    },
    mounted() {
        this.tableList = pageList(this.$get)
        this.loadModuleInfo()
    },
    methods: {
        loadModuleInfo() {
            //读取栏目信息
            this.$get({
                url: "/gateway/api/manage/type/moduleinfo",
                data: {
                    functionId: this.$core.getFunctionId(),
                },
                success: res => {
                    if (res.code == "200" && res.data) {
                        this.title = res.data.typename
                    }
                },
            })
            //读取题库类型
            this.$get({
                url: "/smartlife/api/questions/banklist",
                data: {
                    pagesize: 100000,
                    order: "asc",
                    sort: "questionBank",
                },
                success: res => {
                    if (res.code == "200" && res.dataList) {
                        this.questionBank = res.dataList
                    }
                },
            })

            //读取数据列表
            this.loadData(1)
            this.$core.getControlRight().then(res => {
                this.right = res
            })
        },
        loadData(currentPage) {
            this.tableList.loadData({
                url: "/smartlife/api/questions/questionlist",
                currentPage: currentPage,
                data: {questionBankId:this.questionBankId},
                success: res => {
                    if (res.code != "200") {
                        this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
                    }
                },
            })
        },
        passedData(row, resolve) {
            if (this.right["P"] == false) {
                this.$Message.error({ background: true, content: "你没有启禁用权限" })
                return
            }
            var ids = ""
            if (row) {
                ids = row.id
            } else {
                let selectedList = this.$refs.fncTable.getSelection()
                if (selectedList && selectedList.length > 0) {
                    ids = selectedList
                        .map(item => {
                            return item.id
                        })
                        .join(",")
                }
            }
            if (!ids) {
                this.$Message.error({ background: true, content: "并没有选择审核项" })
                return
            }

            this.$Modal.confirm({
                title: "启禁确认",
                content: row ? '您确认要<span style="color:red"> ' + (row.idisplay == 1 ? "禁用" : "启用") + " </span>" + row.title + "吗？" : "您确认要启禁用选中？",
                onOk: () => {
                    this.$post({
                        url: "/smartlife/api/questions/questionpassed",
                        data: { ids: ids },
                        success: res => {
                            if (res.code == "200") {
                                if (resolve) resolve()
                                this.$Message.success({ background: true, content: res.desc })
                                this.loadData(1)
                            } else {
                                this.$Message.error({ background: true, content: res.desc })
                            }
                        },
                    })
                },
            })
        },
        deleteData(row) {
            if (this.right["D"] == false) {
                this.$Message.error({ background: true, content: "你没有删除权限" })
                return
            }
            var ids = ""
            if (row) {
                ids = row.id
            } else {
                let selectedList = this.$refs.fncTable.getSelection()
                if (selectedList && selectedList.length > 0) {
                    ids = selectedList
                        .map(item => {
                            return item.id
                        })
                        .join(",")
                }
            }
            if (!ids) {
                this.$Message.error({ background: true, content: "并没有选择审核项" })
                return
            }

            this.$Modal.confirm({
                title: "删除确认",
                content: row ? '您确认要删除“<span style="color:red"> ' + row.title + "</span>”吗？" : "确认要删除选中吗？",
                onOk: () => {
                    this.$post({
                        url: "/smartlife/api/questions/questiondelete",
                        data: { ids: ids },
                        success: res => {
                            if (res.code == "200") {
                                this.$Message.success({ background: true, content: res.desc })
                                this.loadData(1)
                            } else {
                                this.$Message.error({ background: true, content: res.desc })
                            }
                        },
                    })
                },
            })
        },
        editData(id) {
            if ((id && this.right["E"] == true) || (!id && this.right["A"] == true)) {
                this.$refs.editModal.showModal()
                this.$refs.editContent.loadData(id,this.questionBankId)
            }
        },
        closeModal() {
            this.$refs.editModal.hideModal()
        },
        submitData() {
            this.$refs.editContent.submitData((b, reload) => {
                this.saveLoading = b
                if (reload == true) {
                    this.loadData(1)
                    this.closeModal()
                }
            })
        },
    },
}
</script>
    
<style scoped lang='less'></style>