<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="库房名称" prop="whName">
        <Input type="text" v-model="formItems.whName" placeholder="输入库房名称"></Input>
      </FormItem>
      <FormItem label="库房位置" prop="whLocation">
        <Input type="text" v-model="formItems.whLocation" placeholder="库房位置"></Input>
      </FormItem>
      <FormItem label="机柜数量" prop="cabinetNum">
        <Input type="text" v-model="formItems.cabinetNum" placeholder=""></Input>
      </FormItem>
      <FormItem label="库房面积" prop="whArea">
        <Input type="text" v-model="formItems.whArea" placeholder="库房面积"></Input>
      </FormItem>
      <FormItem label="负责人" prop="responsiblePerson">
        <Input type="text" v-model="formItems.responsiblePerson" placeholder="负责人"></Input>
      </FormItem>
      <FormItem label="文件册数" prop="filesNum">
        <Input type="text" v-model="formItems.filesNum" placeholder="文件册数"></Input>
      </FormItem>
      <FormItem label="文件页数" prop="filePagesNum">
        <Input type="text" v-model="formItems.filePagesNum" placeholder="文件页数"></Input>
      </FormItem>
      <FormItem label="启用时间" prop="enableTime">
        <!-- <Input type="text" v-model="formItems.enableTime" placeholder="启用时间"></Input> -->
        <DatePicker type="date" v-model="formItems.enableTime"/>
      </FormItem>
      <FormItem label="监控条件" prop="monitoringCondition">
        <Input type="text" v-model="formItems.monitoringCondition" placeholder="监控条件"></Input>
      </FormItem>
      <FormItem label="保密条件" prop="secrecyCondition">
        <Input type="text" v-model="formItems.secrecyCondition" placeholder="保密条件"></Input>
      </FormItem>
      <FormItem label="消防条件" prop="fireCondition">
        <Input type="text" v-model="formItems.fireCondition" placeholder="消防条件"></Input>
      </FormItem>
      <FormItem label="环境条件" prop="environmentCondition">
        <Input type="text" v-model="formItems.environmentCondition" placeholder="环境条件"></Input>
      </FormItem>
      <FormItem label="库房状态" prop="whState">
        <Select v-model="formItems.whState" style="width: 180px">
          <Option v-for="item in whStateList" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      
        <!-- <RadioGroup v-model="formItems.locationTag">
            <Radio :label="true" checked>
               支持
            </Radio>
            <Radio :label="false">
             不支持
            </Radio>
        </RadioGroup> -->
    </Form>
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
export default {
  components: {
    FNCModal, 
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: 0,
        whName:"",
        whLocation:"",
  
      }),
      formItems: {},
      ruleItems: {
        whName: [
          { required: true, message: '库房名称不能为空', trigger: 'blur' }
        ],
        whLocation: [
          { required: true, message: '库房位置不能为空', trigger: 'blur' }
        ],
      
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,
      whStateList:[],
    };
  },
  created() {

  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
     loadData(id) {
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.$get({
          url: "/smartarchives/api/warehouse/queryWarehouseDetail",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },

    /**
    * 获取选项数据
    */
    loadBaseData() {
      // this.$core.getStyleParamList("flowth", false, null, (res) => {
      //   this.flowths = res || []
      // })
      // this.$core.getStyleParamList("waterState", false, null, (res) => {
      //   this.waterStates = res || []
      // })

      this.$core.getStyleParamList("coverClass", false, null, (res) => {
        this.coverClassList = res || []
      })
      this.$core.getStyleParamList("manholeCoverType", false, null, (res) => {
        this.manholeCoverTypeList = res || []
      })
      this.$core.getStyleParamList("whState", false, null, (res) => {
        this.whStateList = res
      })
      // this.$get({
      //   url: "/smartcovers/object/queryObject", data: { pageSize: 1000 }, success: (res) => {
      //     if (res.code == "200" && res.dataList) {
      //       this.projects = res.dataList
      //     }
      //   }
      // })
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          this.$post({
            url: this.formItems.id ? "/smartarchives/api/warehouse/updateWarehouse" : "/smartarchives/api/warehouse/addWarehouse",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    
  }
};
</script>
    
<style scoped lang='less'></style>