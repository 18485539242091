<template>
  <FNCContent>
    <template v-slot:title> 服务工单管理 </template>
    <template v-slot:toolsbarLeft>
      <div style="width:500px;"><Input search enter-button="查询" clearable v-model="pageParams.keyword"
          placeholder="输入用户名称/手机号/终端号(IMEI)" @on-search="loadData(1)" /></div>
    </template>
    <template v-slot:toolsbarRight>
      <!-- <Button type="info" class="addDiv" @click="() => { editData() }" v-if="right.A">
        <Icon type="md-add-circle" size="18" />添加
      </Button>

      <Button type="warning" class="deleteDiv" @click="() => { deleteData() }" v-if="right.D">
        <Icon type="md-trash" size="18" />删除
      </Button> -->
      <Button type="info" class="addDiv" @click="() => { exportDataWin() }" v-if="right.A">
        <Icon type="ios-download-outline" size="18" />导出
      </Button>

    </template>
    <template v-slot:contentArea>
      <FNCTable ref="fncTable" :loading="loading" :fixTable="true" :talbeColumns="talbeColumns" :tableData="tableData"
        :pageParams="pageParams" :pagesizeOpts="pagesizeOpts" @handleLoadData="loadData"></FNCTable>
      <FNCModal ref="editModal" title="工单编辑" :fullscreen="false" width="800px" height="600px">
        <template v-slot:contentarea>
          <Edit ref="editContent" @closeModal="closeModal"></Edit>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closeModal">
            <Icon type="md-close" />取消
          </Button>
          <Button type="primary" @click="submitData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />保存
          </Button>
        </template>
      </FNCModal>
      <FNCModal ref="captchaContent" title="导出验证" :fullscreen="false" width="250px" height="250px">
        <template v-slot:contentarea>

          <div style="display: flex;flex-direction: column; align-items: center; justify-content: center;">
              <span style="color:red">为确保数据安全，导出前请先输入验证码!</span>
              <img style="margin:20px 0;width: 200px; height: 50px;  cursor: pointer; border: 1px solid #ddd; border-radius: 2px"
              title="点击刷新" @click="randCode" :src="randcodeAction" />
              <Input placeholder="输入验证码" style="width: 200px" size="large" v-model="captcha" @on-enter="exportData"></Input>
          </div>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closecaptchaModal">
            <Icon type="md-close" />取消
          </Button>
          <Button type="primary" @click="exportData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />导出
          </Button>
        </template>
      </FNCModal>

    </template>
  </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/serviceorder
import FNCContent from "@/components/FNCContent";
import FNCTable from "@/components/FNCTable";
import FNCModal from "@/components/FNCModal";
import Edit from "./childrens/Edit";
export default {
  components: {
    FNCTable,
    FNCContent,
    FNCModal,
    Edit,
  },

  data() {
    return {
      title: "信息列表",
      loading: false,
      pageParams: {
        maxCount: 0,//数据总条数
        currentPage: 0,//当前页(默认第一页)
        pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
        maxPage: 1,//总页数
        sort: "id",//排序字段
        order: "desc",//排序方式
        keyword: "",//通用查询条件
        keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
      },
      pagesizeOpts: [20, 30, 50, 100],//分页数组
      tableData: [],
      talbeColumns: [
        // {
        //   type: 'selection',
        //   width: 60,
        //   fixed: "left",
        //   align: 'center'
        // },
        {
          title: "类型",
          key: "orderType",
          align: "center",
          width: 100,
          render: (h, params) => {
            return h("div",this.convertObject("orderTypes","value","name",params.row.orderType))},
        },
        {
          title: "品牌名称",
          key: "brandName",
          align: "center",
          minWidth: 200,
        },
        {
          title: "终端号(IMEI)",
          key: "imei",
          align: "center",
          width: 160,
        },
        {
          title: "用户名称",
          key: "linkman",
          align: "center",
          width: 150,
        },
        {
          title: "手机号",
          key: "mobile",
          align: "center",
          width: 130,
        },
        // {
        //   title: "工单备注",
        //   key: "orderRemark",
        //   align: "center",
        //   minWidth: 300,
        // },
        {
          title: "工单状态",
          key: "status",
          align: "center",
          width: 100,
          render: (h, params) => {
            return h("div",this.convertObject("statusList","value","name",params.row.status))},
        },
        {
          title: "处理时间",
          key: "serviceTime",
          align: "center",
          width: 200,
        },
       
        {
          title: "操作",
          slot: "action",
          width: 100,
          align: "center",
          render: (h, params) => {
            return h("div",
              [
                h(
                  "Button",
                  {

                    class: "editDiv"
                    ,
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      margin: "0 5px",
                    },

                    on: {
                      click: () => {
                        this.editData(params.row.id);
                      },
                    },
                  },
                  "去处理"
                ),

                // h(
                //   "Button",
                //   {
                //     class: "deleteDiv"
                //     ,
                //     props: {
                //       type: "error",
                //       size: "small",
                //     },
                //     on: {
                //       click: () => {
                //         this.deleteData(params.row);
                //       },
                //     },
                //   },
                //   "删除"
                // ),

               
              ]);
          },
        },
      ],
      right: {},
      saveLoading: false,
      orderTypes:[],
      statusList:[],
      imei:null,
      captcha:"",
      randcodeAction: "./images/nopic.png", //验证码IMG base64
      captchaId: "", //验证码captchaId
    };
  },
  created() {
    this.imei=this.$core.getUrlParam("imei");
    if(this.imei){
      this.pageParams.keyword=this.imei;
    }
  },
  mounted() {
    this.loadModuleInfo();
  },
  methods: {
     loadModuleInfo() {
      //读取栏目信息
       this.$get({
        url: "/gateway/api/manage/type/moduleinfo",
        data: {
          functionId: this.$core.getFunctionId(),
        },
        success: (res) => {
          if (res.code == "200" && res.data) {
            this.title = res.data.typename;
          }
        }
      });
      this.$core.getStyleParamList("smartsmoke_orderType", true, "全部状态", (res) => {
        this.orderTypes = res || [{ name: "全部状态", value: "" }]
      })
      this.$core.getStyleParamList("smartsmoke_orderStatus", true, "全部状态", (res) => {
        this.statusList = res || [{ name: "全部状态", value: "" }]
      })
      //读取数据列表
      this.loadData(1);
      this.$core.getControlRight().then(res => {
        this.right = res;
      });
    },
    loadData(currentPage) {
      if (currentPage) {
        this.pageParams.currentPage = currentPage;
      } else {
        this.pageParams.currentPage = this.pageParams.currentPage + 1;
      }
      this.loading = true;
      this.$get({
        url: "/smartsmoke/serviceorder/queryServiceorder",
        data: this.pageParams,
        success: (res) => {
          this.tableData = []
          if (res.code == "200" && res.pageParams) {
            this.pageParams = {...this.pageParams,...res.pageParams}
            this.tableData = res.dataList
          } else {
            this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
          }
          this.loading = false;
        }
      })
    },

    // deleteData(row) {
    //   if (this.right["D"] == false) {
    //     this.$Message.error({ background: true, content: "你没有删除权限" })
    //     return;
    //   }
    //   var ids = ""
    //   if (row) {
    //     ids = row.id
    //   } else {
    //     let selectedList = this.$refs.fncTable.getSelection();
    //     if (selectedList && selectedList.length > 0) {
    //       ids = selectedList.map(item => {
    //         return item.id;
    //       }).join(",")
    //     }
    //   }
    //   if (!ids) {
    //     this.$Message.error({ background: true, content: "并没有选择审核项" })
    //     return;
    //   }

    //   this.$Modal.confirm({
    //     title: '删除确认',
    //     content: row ? '您确认要删除“<span style="color:red"> ' + row.brandName + '</span>”吗？' : "确认要删除选中吗？",
    //     onOk: () => {
    //       this.$post({
    //         url: "/smartsmoke/brandinfo/delBrandinfo", data: { ids: ids },
    //         success: (res) => {
    //           if (res.code == "200") {
    //             this.$Message.success({ background: true, content: res.desc })
    //             this.loadData(1);
    //           } else {
    //             this.$Message.error({ background: true, content: res.desc })
    //           }
    //         }
    //       });
    //     }
    //   });
    // },

    editData(id) {
      if (id && this.right["E"] == true || !id && this.right["A"] == true) {
        this.$refs.editModal.showModal();
        this.$refs.editContent.loadData(id);
      }
    },
    closeModal() {
      this.$refs.editModal.hideModal();
    },
    submitData() {
      this.$refs.editContent.submitData((b, reload) => {
        this.saveLoading = b;
        if (reload == true) {
          this.loadData(1);
          this.closeModal();
        }
      });
    },
    convertObject(target,inFeild,outFeild,value){
      var object=this[target].find(item=>value==item[inFeild])
      return object?object[outFeild]:"无"
    },

    // exportData() {
    //   this.$Modal.confirm({
    //     title: '导出确认',
    //     content: "确认要导出吗？",
    //     onOk: () => {
    //       top.location.href=this.$getProxy("/smartsmoke")+"/serviceorder/serviceorderExport?keyword="+this.pageParams.keyword;
    //     }
    //   });
    // },

    closecaptchaModal() {
      this.$refs.captchaContent.hideModal();
    },
    exportDataWin() {
      this.randCode();
      this.$refs.captchaContent.showModal();
    },

    exportData() {
      top.location.href = this.$getProxy("/smartsmoke") + "/serviceorder/serviceorderExport?randKey=" + this.captchaId + "&randCode="+this.captcha+"&keyword=" + this.pageParams.keyword;
      this.$refs.captchaContent.hideModal();
    },


    randCode() {
      this.randcodeAction= "./images/nopic.png"; //验证码IMG base64
      this.captchaId="";
      this.$get({ url: "/gateway/oauth/captchaImage" }).then(res => {
        if (res && res.data && res.data != null) {
          this.captchaId=res.data.randKey;
          this.randcodeAction=res.data.image;
        }
      })
    },

  }
};
</script>
    
<style scoped lang='less'></style>