<template>
    <FNCContent>
        <template v-slot:title>{{ title }}</template>
        <template v-slot:toolsbarLeft>
            <div style="width: 400px"><Input search enter-button="查询" clearable v-model="tableList.pageParams.keyword"
                    placeholder="输入批次号/档案编号/档案名称" @on-search="loadData(1)" /></div>
        </template>
        <template v-slot:toolsbarRight>
            <div style="color:#cc0000;margin-top:5px;">整编流程：文件整编
                <Icon type="md-arrow-forward" /> 内容整编
                <Icon type="md-arrow-forward" /> 完成入库
            </div>
        </template>
        <template v-slot:contentArea>
            <FNCTable ref="fncTable" :loading="loading" :fixTable="true" :talbeColumns="talbeColumns"
                :tableData="tableList.dataList" :pageParams="tableList.pageParams" :pagesizeOpts="tableList.pagesizeOpts"
                @handleLoadData="loadData"></FNCTable>
            <FNCModal ref="editModal" title="档案整编" :fullscreen="false" width="900px" height="750px">
                <template v-slot:contentarea>
                    <Edit ref="editContent"  @closeModal="closeModal('editModal')"></Edit>
                </template>
                <template v-slot:toolsbar>
                    <Button style="width: 200px" type="primary" @click="submitData" :loading="saveLoading == 3">
                        <Icon type="md-checkmark-circle" />
                        {{ saveLoading == 3 ? "保存中..." : "保存" }}
                    </Button>
                </template>
            </FNCModal>
            <FNCModal ref="viewDetailModal" :title="viewDetailTitle" :fullscreen="true">
                <template v-slot:contentarea>
                    <FNCTable ref="viewTable" :talbeColumns="viewDetailColumns" :loading="viewLoading"
                        :tableData="detailList" :hidePage="true"></FNCTable>
                </template>
            </FNCModal>
            <FNCModal ref="viewModal" :title="viewTitle" :fullscreen="false" width="80%" height="750px">
                <template v-slot:contentarea>
                    <div style="margin:-10px;border-radius: 10px;">
                        <FNCOfficePreview :src="filePath" height="748px" :toolbar="true" :download="true" :print="true"
                            :isAuthorize="true" @error="() => { }" @rendered="() => { }"></FNCOfficePreview>
                    </div>
                </template>
            </FNCModal>
            <FNCModal ref="editFileModal" title="文件修改" :fullscreen="false" width="800px" height="550px">
                <template v-slot:contentarea>
                    <FileEdit ref="editFileContent" @closeModal="closeModal('editFileModal')"></FileEdit>
                </template>
                <template v-slot:toolsbar>
                    <Button style="width: 150px" type="primary" @click="submitFileData" :loading="saveLoading == 3">
                        <Icon type="md-checkmark-circle" />
                        保存
                    </Button>
                </template>
            </FNCModal>
            <FNCModal ref="reorgImportModal" title="整编入库" :fullscreen="false" width="700px" height="370px">
                <template v-slot:contentarea>
                    <ReorganizeImport ref="reorgImportContent" @closeModal="closeModal('reorgImportModal')"></ReorganizeImport>
                </template>
                <template v-slot:toolsbar>
                    <Button style="width: 150px" type="primary" @click="submitImportData" :loading="saveLoading == 3">
                        <Icon type="md-checkmark-circle" />
                        完成入库
                    </Button>
                </template>
            </FNCModal>
        </template>
    </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/reorganize
import FNCContent from "@/components/FNCContent"
import FNCTable from "@/components/FNCTable"
import FNCModal from "@/components/FNCModal"
import Edit from "./childrens/ReorganizeEdit"
import FileEdit from "./childrens/FileEdit"
import ReorganizeImport from "./childrens/ReorganizeImport"
import { pageList } from "@/utils/pageList"
import { orgCascader } from "@/utils/orgCascader"
import FNCOfficePreview from "@/components/FNCOfficePreview"
import ReorganizeEdit from "./childrens/ReorganizeEdit.vue"
export default {
    components: {
    FNCTable,
    FNCContent,
    FNCModal,
    Edit, FileEdit, ReorganizeImport,
    FNCOfficePreview,
    ReorganizeEdit
},

    data() {
        return {
            title: "信息列表",
            viewTitle: "档案文件",
            viewDetailTitle: "档案文件明细",
            loading: true,
            viewLoading: false,
            tableList: {},
            orgCascader: {},
            archiveTypeList: [],
            talbeColumns: [
                // {
                //   type: 'selection',
                //   width: 60,
                //   fixed: "left",
                //   align: 'center'
                // },

                {
                    title: "档案编号",
                    key: "archiveNum",
                    align: "center",
                    width: 200,
                },
                {
                    title: "全宗号",
                    key: "fullFileNum",
                    align: "center",
                    width: 100,
                },
                {
                    title: "全宗名称",
                    key: "fullFileName",
                    align: "center",
                    width: 120,
                },
                {
                    title: "档案类型",
                    key: "archiveName",
                    align: "center",
                    width: 200,
                    render: (h, params) => {
                        return h("span", this.covertStatus("archiveTypeList", params.row.archiveType) || params.row.archiveName)
                    },
                },
                {
                    title: "文件名称",
                    key: "fileName",
                    align: "center",
                    width: 200,
                },
                {
                    title: "文件总数量",
                    key: "filesTotal",
                    align: "center",
                    minWidth: 120,
                },
                {
                    title: "文件总大小",
                    key: "filesSizeTotal",
                    align: "center",
                    width: 120,
                    render: (h, params) => {
                        return h("span", this.convertSize(params.row.filesSizeTotal))
                    },
                },

                {
                    title: "所属科室",
                    key: "orgName",
                    align: "center",
                    width: 150,
                    render: (h, params) => {
                        return h("span", this.orgCascader.convectOrgName(params.row.orgCode) || params.row.orgName)
                    },
                },
                {
                    title: "状态",
                    key: "status",
                    align: "center",
                    fixed: "right",
                    minWidth: 100,
                    render: (h, params) => {
                        return h("span", { style: { color: params.row.status == 1 ? "#333333" : "red" } }, this.covertStatus("reorganizeStatus", params.row.status))
                    },
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 300,
                    fixed: "right",
                    render: (h, params) => {
                        return h("div", [

                            h(
                                "Button",
                                {
                                    class: "viewDiv",
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: {
                                        margin: "0 5px",
                                    },

                                    on: {
                                        click: () => {
                                            this.viewDetailList(params.row)
                                        },
                                    },
                                },
                                "文件整编"
                            ),

                            h(
                                "Button",
                                {
                                    class: "editDiv",
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: {
                                        margin: "0 5px",
                                    },

                                    on: {
                                        click: () => {
                                            this.editData(params.row.id)
                                        },
                                    },
                                },
                                "内容整编"
                            ),
                            h(
                                "Button",
                                {
                                    class: "editDiv",
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        margin: "0 5px",
                                    },

                                    on: {
                                        click: () => {
                                            this.reorgImportData(params.row.id,params.row.archiveNum)
                                        },
                                    },
                                },
                                "完成入库"
                            ),
                        ])
                    },
                },
            ],
            viewDetailColumns: [
                {
                    title: "顺序号",
                    key: "sortNum",
                    align: "center",
                    width: 100,
                },
                {
                    title: "文件名称",
                    key: "fileName",
                    align: "center",
                    minWidth: 250,
                },
                {
                    title: "文件格式",
                    key: "fileFormat",
                    align: "center",
                    width: 100,
                },
                {
                    title: "文件大小",
                    key: "fileSize",
                    align: "center",
                    width: 120,
                    render: (h, params) => {
                        return h("span", this.convertSize(params.row.fileSize))
                    },
                },
                {
                    title: "状态",
                    key: "status",
                    align: "center",
                    fixed: "right",
                    width: 100,
                    render: (h, params) => {
                        return h("span", { style: { color: params.row.status == 1 ? "#333333" : "red" } }, this.covertStatus("reorganizeStatus", params.row.status))
                    },
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 300,
                    align: "center",
                    fixed: "right",
                    render: (h, params) => {
                        return h("div", [

                            h(
                                "Button",
                                {
                                    class: "viewDiv",
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: {
                                        margin: "0 5px",
                                    },

                                    on: {
                                        click: () => {
                                            this.viewDetail(params.row)
                                        },
                                    },
                                },
                                "阅览"
                            ),

                            h(
                                "Button",
                                {
                                    class: "viewDiv",
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: {
                                        margin: "0 5px",
                                    },

                                    on: {
                                        click: () => {
                                            this.editFileData(params.row)
                                        },
                                    },
                                },
                                "整编"
                            ),

                            h(
                                "Button",
                                {
                                    class: "viewDiv",
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: {
                                        margin: "0 5px",
                                    },

                                    on: {
                                        click: () => {
                                            this.deleteFileData(params.row)
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],
            detailList: [],
            right: {},
            saveLoading: 1,
            reorganizeStatus: [],
            timer: null,
            filePath: "",
        }
    },
    created() {
        this.orgCascader = orgCascader("dept")
        this.orgCascader.loadData();
        this.tableList = pageList(this.$get)
    },
    mounted() {
        this.loadModuleInfo()
    },
    beforeDestroy() {
    },
    methods: {
        loadModuleInfo() {
            //读取栏目信息
            this.$get({
                url: "/gateway/api/manage/type/moduleinfo",
                data: {
                    functionId: this.$core.getFunctionId(),
                },
                success: res => {
                    if (res.code == "200" && res.data) {
                        this.title = res.data.typename
                    }
                },
            })
            this.$core.getStyleParamList("reorganizeStatus", true, "", res => {
                this.reorganizeStatus = res || []
            })
            this.$core.getStyleParamList("archiveType", false, "", res => {
                this.archiveTypeList = res || []
            })
            //读取数据列表
            this.loadData(1)
            this.$core.getControlRight().then(res => {
                this.right = res
            })
        },
        covertStatus(type, value) {
            return (this[type].find(item => item.value == value) || {}).name
        },
        convertSize(size) {
            if (!size) {
                return "-";
            } else if (size < 1024) {
                return size + "B";
            } else if (size < 1024 * 1024) {
                return (size / (1024)).toFixed(3) + "KB";
            } else {
                return (size / (1024 * 1024)).toFixed(3) + "MB";
            }
        },
        loadData(currentPage, isTick) {
            if (!isTick) {
                this.loading = true;
            }
            var that = this;
            clearTimeout(that.timer);
            this.tableList.loadData({
                url: "/smartarchives/api/archivesstorage/reorganizelist",
                currentPage: currentPage,
                data: {},
                success: res => {
                    if (res.code != "200") {
                        this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
                    }
                },
                fail: () => {
                    this.loading = false;
                },
                complete: (res) => {
                    this.loading = false;
                }
            })
        },
        viewDetailList(row) {
            this.editFile = row;
            this.viewDetailTitle = (row.fileName || "") + "【" + row.archiveNum + "】" + "档案文件明细";
            this.viewLoading = true;
            this.detailList = [];
            this.$refs.viewDetailModal.showModal();
            this.$get({
                url: "/smartarchives/api/archivesstorage/filestoragelistbyid",
                data: { asId: row.id },
                success: (res) => {
                    if (res.code == "200") { }
                    this.detailList = res.dataList;
                },
                complete: () => {
                    this.viewLoading = false;
                }
            });
        },
        viewDetail(row) {
            this.viewTitle = (row.fileName || "");
            this.filePath = row.filePath;
            this.$refs.viewModal.showModal();
        },
        closeModal(modal){
            this.$refs[modal].hideModal()
        },
        editData(id) {
            if (this.right["A"] == true) {
                this.saveLoading = 1
                this.$refs.editModal.showModal();
                this.$refs.editContent.loadData(id);
            }
        },
        reorgImportData(id,archiveNum) {
            if (this.right["A"] == true) {
                this.saveLoading = 1
                this.$refs.reorgImportModal.showModal();
                this.$refs.reorgImportContent.loadData(id,archiveNum);
            }
        },
        
        submitData() {
            this.$refs.editContent.submitData((b, c) => {
                if (c) {
                    this.loadData(1)
                    this.$refs.editModal.hideModal()
                }
                this.saveLoading = b
            })
        },
        submitImportData() {
            this.$refs.reorgImportContent.submitData((b, c) => {
                if (c) {
                    this.loadData(1)
                    this.$refs.reorgImportModal.hideModal()
                }
                this.saveLoading = b
            })
        },
        editFileData(file) {
            if (this.right["A"] == true) {
                this.saveLoading = 1
                this.$refs.editFileModal.showModal();
                this.$refs.editFileContent.loadData(JSON.parse(JSON.stringify(file)));
            }
        },
        submitFileData() {
            this.$refs.editFileContent.submitData((b, c) => {
                if (c) {
                    this.loadData(1)
                    this.viewDetailList(this.editFile);
                    this.$refs.editFileModal.hideModal()
                }
                this.saveLoading = b
            })
        },
        deleteFileData(row) {
        if(!this.detailList||this.detailList.length<=1){
            this.$Message.error({ background: true, content: "档案文件必须保持在一个或以上" })
            return
        }
            if (this.right["D"] == false) {
                this.$Message.error({ background: true, content: "你没有删除权限" })
                return
            }

            this.$Modal.confirm({
                title: "删除确认",
                content: "将删除“" + row.fileName + "”文件，确认要删除选中吗？",
                onOk: () => {
                    this.$post({
                        url: "/smartarchives/api/archivesstorage/filedelete",
                        data: { ids: row.id,asId:row.asId },
                        success: res => {
                            if (res.code == "200") {
                                this.$Message.success({ background: true, content: "删除成功" })
                                this.loadData(1)
                                this.viewDetailList(this.editFile);
                            } else {
                                this.$Message.error({ background: true, content: res.desc })
                            }
                        },
                    })
                },
            })
        },
    },
}
</script>
  
<style scoped lang='less'></style>