<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="审批操作" prop="archiveId">
        <RadioGroup v-model="status" @on-change="formItems.status = $event">
            <Radio :label="2">通过</Radio>
            <Radio :label="3">不通过</Radio>
            <Radio :label="5">归还</Radio>
            <Radio :label="6">丢失</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="归还人" prop="returnUser">
        <Input type="text" v-model.trim="formItems.returnUser" placeholder="归还人"></Input>
      </FormItem>
      <FormItem label="备注" prop="remarks">
        <Input type="textarea" v-model.trim="formItems.remarks" placeholder="备注"></Input>
      </FormItem>
    
    </Form>
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
import FNCUpload from "@/components/FNCUpload"
import { orgCascader } from "@/utils/orgCascader";
export default {
  components: {
    FNCModal,
    FNCUpload,
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: "",//ID
        status: null,//状态
        returnUser: "",//归还人
        remarks: "",//备注
      }),
      status:0,
      remoteLoading:false,
      // filesStorage: [],//文件列表
      deptid: [],
      formItems: {},
      ruleItems: {
        // archiveNum: [{ required: true, message: "请填写档案编号", trigger: "blur" }],
        // fileName: [{ required: true, message: "请填写文件名称", trigger: "blur" }],

      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,
      archiveList:[],
      
    };
  },
  created() {
    // this.orgCascader = orgCascader("dept");
    // this.deptid = this.orgCascader.convectCascaderList(this.$core.getBranchUserInfo("deptid"))
    // this.orgCascader.loadData(
    //   depts => {
    //     this.depts = depts
    //   },
    //   this.deptid.length > 0 ? this.deptid[this.deptid.length - 1] : ""
    // )
    // this.loadBaseData();
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
    loadData(id) {
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.formItems.id = id;
        // this.$get({
        //   url: "/smartarchives/api/archives/queryArchivesDetail",
        //   data: { id: id },
        //   success: (res) => {
        //     if (res.code == "200" && res.data) {
        //       this.formItems = res.data;
        //       if (this.formItems.whId) {
        //         this.getMachinecabinetByWh(this.formItems.whId, this.formItems.mcId)
        //       }
        //       this.formItems.orgName = this.orgCascader.convectOrgName(this.formItems.orgCode);
        //     } else {
        //       this.$Message.error(res.desc || "读取数据出错");
        //       this.$emit("closeModal");
        //     }
        //   }
        // });
      }
    },

    /**
    * 获取选项数据
    */
    loadBaseData() {
      // this.$core.getStyleParamList("secretLevel", false, null, (res) => {
      //   this.secretLevelList = res
      // })
      // this.$core.getStyleParamList("archiveType", false, null, (res) => {
      //   this.archiveTypeList = res
      // })
     
    },
    // getMachinecabinetByWh(whId, mcId) {
    //   this.formItems.mcId = mcId;
    //   this.$get({
    //     url: "/smartarchives/api/machinecabinet/client/queryMachinecabinetByWh",
    //     data: { whId: whId },
    //     success: res => {
    //       if (res.code == "200") {
    //         this.machinecabinetList = res.dataList
    //         this.$forceUpdate()
    //       }
    //     }
    //   })
    // },
    // getMcList(e) {
    //   this.mcList = []
    //   this.$get({
    //     url: "/smartarchives/api/machinecabinet/queryMachinecabinet", data: { pageSize: 1000, whId: e }, success: (res) => {
    //       if (res.code == "200" && res.dataList) {
    //         this.machinecabinetList = res.dataList;
    //       }
    //     }
    //   })
    // },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          if(this.formItems.status == 5 && this.formItems.returnUser == '') {
            this.$Message.error({ background: true, content:  "请填写归还人" })
            return false;
          }
          this.$post({
            url: "/smartarchives/api/borrow/updateBorrowaudit",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    
    checkOrgRight(e) {
      this.deptid = this.orgCascader.checkOrgRight(e)
      this.formItems.orgCode = this.deptid[this.deptid.length - 1]
    },

    getArchiveParam(e) {
      for(var i = 0; i< this.archiveList.length; i++) {
        if(e.value = this.archiveList[i].id) {
          this.formItems.archiveId = e.value;
          // this.formItems.oldOrgCode = this.archiveList[i].orgCode;
          // this.formItems.oldWhId = this.archiveList[i].whId;
          // this.formItems.oldMcId = this.archiveList[i].mcId;
          break;
        }
      }
    },
    remoteMethod1 (query) {
      
      if (query !== ''||this.formItems.archiveId) {
        this.remoteLoading = true;
        setTimeout(() => {
          this.remoteLoading = false;
          this.$get({
        url: "/smartarchives/api/archives/queryArchives", data: { pageSize: 50,borrowFlag: "0",fileName: query}, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.archiveList = res.dataList
          }
        }
      })
          // this.options1 = this.archiveList.filter(item => item.fileName.toLowerCase().indexOf(query.toLowerCase()) > -1);
          }, 200);
      } else {
          this.archiveList = [];
      }
    },

    changeExpectedBorrowTime(value){
      this.formItems.expectedBorrowTime = new Date(value)
    },

    changeExpectedReturnTime(value){
      this.formItems.expectedReturnTime = new Date(value)
    },
  
  }
};
</script>
    
<style scoped lang='less'>
</style>