<template>
    <div style="">
        <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150" v-show="type == 1">
            <FormItem label="档案编号" prop="archiveNum">
                <Input v-model.trim="formItems.archiveNum" placeholder="档案编号"></Input>
            </FormItem>
            <FormItem label="文件名称" prop="fileName">
                <Input v-model.trim="formItems.fileName" placeholder="文件名称"></Input>
            </FormItem>
            <FormItem label="全宗号" prop="fullFileNum">
                <Input v-model.trim="formItems.fullFileNum" placeholder="全宗号"></Input>
            </FormItem>
            <FormItem label="全宗名称" prop="fullFileName">
                <Input v-model.trim="formItems.fullFileName" placeholder="全宗名称"></Input>
            </FormItem>
            <FormItem label="归档编号" prop="archivingNum">
                <Input v-model.trim="formItems.archivingNum" placeholder="归档编号"></Input>
            </FormItem>
            <FormItem label="绑定库房" prop="whId">
                <div style="width: 100%;display: flex;justify-content: flex-start;">
                    <Select v-model="formItems.whId" @on-change="getMachinecabinetByWh"
                        style="width: 180px;margin-right: 10px;">
                        <Option v-for="item in warehouseList" :value="item.id" :key="item.whName">{{ item.whName }}
                        </Option>
                    </Select>
                    <div style="color:#2db7f5;" v-if="formItems.batchNum&&formItems.whName">
                        <Icon type="md-arrow-round-forward" /> 导入的库房名称：{{ formItems.whName }}
                    </div>
                </div>
            </FormItem>
            <FormItem label="绑定机柜" prop="mcId">
                <div style="width: 100%;display: flex;justify-content: flex-start;">
                    <Select v-model="formItems.mcId" style="width: 180px;float:left;margin-right: 10px;">
                        <Option v-for="item in machinecabinetList" :value="item.id" :key="item.mcName">{{ item.mcName }}
                        </Option>
                    </Select>
                    <div style="float:left;color:#2db7f5;" v-if="formItems.batchNum&&formItems.mcName">
                        <Icon type="md-arrow-round-forward" /> 导入的机柜名称：{{ formItems.mcName }}
                    </div>
                </div>
            </FormItem>
            <FormItem label="所属科室" prop="orgCode">
                <div style="width: 100%;display: flex;justify-content: flex-start;">
                    <Cascader v-model="deptid" @on-change="checkOrgRight" placeholder="选择科室" change-on-select clearable
                        :data="depts" style="width: 300px;float:left;margin-right: 10px;" />
                    <div style="float:left;color:#2db7f5;" v-if="formItems.batchNum&&formItems.orgName">
                        <Icon type="md-arrow-round-forward" /> 导入的科室名称：{{ formItems.orgName }}
                    </div>
                </div>
            </FormItem>
            <FormItem label="档案类型" prop="archiveType">
                <div style="width: 100%;display: flex;justify-content: flex-start;">
                    <Select v-model="formItems.archiveType" style="width: 180px;float:left;margin-right: 10px;">
                        <Option v-for="item in archiveTypeList" :value="item.value" :key="item.name">{{ item.name }}
                        </Option>
                    </Select>
                    <div style="float:left;color:#2db7f5;" v-if="formItems.batchNum&&formItems.archiveName">
                        <Icon type="md-arrow-round-forward" /> 导入的档案类型：{{ formItems.archiveName }}
                    </div>
                </div>
            </FormItem>
            <FormItem label="密级" prop="secretLevel">
                <Select v-model="formItems.secretLevel" style="width: 180px;float:left;margin-right: 10px;">
                    <Option v-for="item in secretLevelList" :value="item.value" :key="item.name">{{ item.name }}
                    </Option>
                </Select>
            </FormItem>
            <FormItem label="档案年份" prop="archiveYear">
                <Input v-model.trim="formItems.archiveYear" placeholder="档案年份"></Input>
            </FormItem>
            <FormItem label="保管期限" prop="storageLife">
                <Input v-model.trim="formItems.storageLife" placeholder="保管期限"></Input>
            </FormItem>
            <FormItem label="存储位置" prop="storageLocation">
                <Input v-model.trim="formItems.storageLocation" placeholder="存储位置"></Input>
            </FormItem>
            <FormItem label="用地面积" prop="landArea">
                <Input v-model.trim="formItems.landArea" placeholder="用地面积"></Input>
            </FormItem>
            <FormItem label="地址" prop="address">
                <Input v-model.trim="formItems.address" placeholder="地址"></Input>
            </FormItem>
            <FormItem label="管理人员" prop="manager">
                <Input v-model.trim="formItems.manager" placeholder="管理人员"></Input>
            </FormItem>
            <FormItem label="上传档案文件" prop="filesStorage">
                <FNCUpload :defaultList="filesStorage" ref="fileUploader" type="file"
                    :action="$getProxy('/smartarchives') + '/api/filemanager/upload'" :hashMaxSize="500" :multiple="true"
                    :fileLength="100" accept="*/*" :showView="true" :showDel="true" @success="upFile"></FNCUpload>
                <input type="hidden" v-model="filesStorage" />
            </FormItem>
        </Form>
        <Form ref="importForm" :model="importFormItems" :rules="importRuleItems" :label-width="200" v-show="type == 2">
            <Alert style="font-weight: bold;color:#17233d;">文件打包规则：文件夹为“档案编号”，文件放进文件夹中，再打成ZIP包。<Button type="info"
                    @click="downExcel">下载EXCLE档案信息导入模板</Button></Alert>
            <FormItem label="上传Excel档案信息表" prop="excelPath">
                <FNCUpload ref="excelUploader" type="file" :action="$getProxy('/smartarchives') + '/api/filemanager/upload'"
                    :hashMaxSize="500" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    :showView="true" :showDel="true" @success="upExcel" v-if="loadingImport == 1"></FNCUpload>
                <div class="file-upload-list" v-else>{{ importFormItems.excelName }}</div>
                <input type="hidden" v-model="importFormItems.excelPath" />
            </FormItem>
            <FormItem label="上传档案文件zip包" prop="zipPath">
                <FNCUpload ref="zipUploader" type="file" :action="$getProxy('/smartarchives') + '/api/filemanager/upload'"
                    :hashMaxSize="500" accept="application/zip" :showView="true" :showDel="true" @success="upZip"
                    v-if="loadingImport == 1"></FNCUpload>
                <div class="file-upload-list" v-else>{{ importFormItems.zipName }}</div>
                <input type="hidden" v-model="importFormItems.zipPath" />
            </FormItem>
            <FormItem label="档案存在的执行方式" prop="exitsAction">
                <RadioGroup v-model="importFormItems.exitsAction">
                    <Radio label="insert" :border="true" :disabled="loadingImport != 1">继续入库</Radio>
                    <Radio label="skip" :border="true" :disabled="loadingImport != 1">跳过不入库</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="没有档案文件时的执行方式" prop="noFilesAction">
                <RadioGroup v-model="importFormItems.noFilesAction">
                    <Radio label="all" :border="true" :disabled="loadingImport != 1">全部入库</Radio>
                    <Radio label="no" :border="true" :disabled="loadingImport != 1">有文件则入库</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="导入进度" v-if="loadingImport != 1">
                <div style="margin:0 30px 0 0;float:right;color:#2b85e4;"> {{ importData.reason || "正在处理中..." }}</div>
            </FormItem>
            <div style="margin:0 30px 30px 30px;" v-if="loadingImport != 1">
                <Progress
                    :percent="!importData.status ? 0 : importData.status == 1 || importData.status == 3 ? Math.ceil(100 * importData.importCount / importData.excelCount) : 100"
                    :stroke-width="20" text-inside></Progress>
            </div>
        </Form>
    </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
import { orgCascader } from "@/utils/orgCascader"
import FNCUpload from "@/components/FNCUpload"
export default {
    components: {
        FNCModal,
        FNCUpload,
    },
    data() {
        return {
            type: 1, //1录入和修改,2导入
            id: "",
            deptid: [],
            formItemsDefault: JSON.stringify({
                id: "",//ID
                branchId: "",//BranchId
                batchNum: "",//批次号
                whId: "",//绑定库房ID
                mcId: "",//绑定机柜ID
                mcName: "",//机柜名称（导入文件）
                whName: "",//库房名称（导入文件）
                fullFileNum: "",//全宗号
                fullFileName: "",//全宗名称
                fileName: "",//文件名称
                filesTotal: "",//文件总数量
                filesSizeTotal: "",//文件总大小
                archiveNum: "",//档案编号
                archivingNum: "",//归档编号
                archiveYear: "",//档案年份
                archiveType: "",//档案类型ID
                orgCode: "",//所属科室ID
                orgName: "",//科室名称（导入文件）
                archiveName: "",//档案类型名称（导入文件）
                secretLevel: "",//密级
                storageLife: "",//保管期限
                storageLocation: "",//存储位置
                landArea: "",//用地面积
                address: "",//地址
                manager: "",//管理人员
                importOrgCode: "",//入库科室（用于列表筛选）
                importUser: "",//入库人员
                importTime: "",//入库时间
                status: 1,//处理状态：待整编1，整编中2，整编完成3，整编失败4
                reason: "",//处理信息
                dealTime: "",//处理时间
                dealUser: "",//处理人员
                filesStorage: [],//文件列表
            }),
            filesStorage: [],//文件列表
            formItems: {},
            ruleItems: {
                archiveNum: [{ required: true, message: "请填写档案编号", trigger: "blur" }],
                fileName: [{ required: true, message: "请填写文件名称", trigger: "blur" }],
                // whId: [{ required: true, message: "请绑定库房", trigger: "change" }],
                // mcId: [{ required: true, message: "请绑定机柜", trigger: "change" }],
                orgCode: [{ required: true, message: "请绑定所属科室", trigger: "change" }],
                filesStorage: [{ type: "array", required: true, message: "请上传文件", trigger: "change" }],
            },
            importFormItemsDefault: JSON.stringify({
                excelPath: "",
                zipPath: "",
                excelName: "",
                zipName: "",
                excelSize: 0,
                zipSize: 0,
                exitsAction: "skip",
                noFilesAction: "no",
            }),
            importFormItems: {},
            importRuleItems: {
                excelPath: [{ required: true, message: "请上传Excel文件", trigger: "change" }],
                zipPath: [{ required: true, message: "请上传档案zip包", trigger: "change" }],
            },
            loadingImport: 1, //等待导入中
            batchNum: null,
            importData: {},
            callback: null,
            machinecabinetList: [],
            archiveTypeList: [],
            warehouseList: [],
        }
    },
    created() {
        this.orgCascader = orgCascader("dept");
        this.deptid = this.orgCascader.convectCascaderList(this.$core.getBranchUserInfo("deptid"))
        this.orgCascader.loadData(
            depts => {
                this.depts = depts
            },
            this.deptid.length > 0 ? this.deptid[this.deptid.length - 1] : ""
        )
        this.loadBaseData();
    },
    mounted() {

    },
    methods: {
        loadBaseData() {
            this.$core.getStyleParamList("archiveType", false, "", res => {
                this.archiveTypeList = res || []
            })
            this.$core.getStyleParamList("secretLevel", false, "", res => {
                this.secretLevelList = res || []
            })

            this.$get({
                url: "/smartarchives/api/warehouse/client/queryWarehouse",
                data: {},
                success: res => {
                    if (res.code == "200") {
                        this.warehouseList = res.dataList
                    }
                }
            })

        },
        getMachinecabinetByWh(whId, mcId) {
            this.formItems.mcId = mcId;
            this.$get({
                url: "/smartarchives/api/machinecabinet/client/queryMachinecabinetByWh",
                data: { whId: whId },
                success: res => {
                    if (res.code == "200") {
                        this.machinecabinetList = res.dataList
                        this.$forceUpdate()
                    }
                }
            })
        },
        /**
        * 获取数据
        */
        loadArchivesStorageData() {
            if (this.id) {
                this.$get({
                    url: "/smartarchives/api/archivesstorage/archivesstoragedetail",
                    data: { id: this.id },
                    success: res => {
                        if (res.code == "200") {
                            this.formItems = res.data || {};
                            if (this.formItems.id) {
                                if (this.formItems.whId) {
                                    this.getMachinecabinetByWh(this.formItems.whId, this.formItems.mcId)
                                }
                                if (this.formItems.orgCode) this.deptid = this.orgCascader.convectCascaderList(this.formItems.orgCode)

                                if (this.formItems.filesStorage) {
                                    this.filesStorage = this.formItems.filesStorage.map(item => {
                                        return {
                                            name: item.fileName,
                                            url: item.filePath,
                                            size: item.fileSize,
                                            ext: item.fileFormat,
                                            id: item.id,
                                        }
                                    })
                                }
                                // console.log(this.filesStorage, this.formItems.filesStorage);
                            } else {
                                if (this.callback) this.callback(4);
                            }
                        }
                    },
                })
            }
        },

        /**
         * 获取导入数据
         */
        loadImportData() {
            if (this.batchNum) {
                this.$get({
                    url: "/smartarchives/api/archivesstorage/loadingimport",
                    data: { batchNum: this.batchNum },
                    success: res => {
                        if (res.code == "200") {
                            this.importData = res.data || {};
                            if (this.importData.status == 1) {
                                setTimeout(this.loadImportData, 5000);
                            } else {
                                if (this.callback) this.callback(4);
                            }
                        }
                    },
                })
            }
        },
        downExcel() {
            var a = document.createElement("a");
            a.href = "/smartarchives/archivesimportmodel.xlsx";
            a.download = "档案信息导入模板.xlsx";
            a.click();
        },
        loadData(type, id) {
            this.type = type
            this.id = id
            this.loadingImport = 1
            if (type == 1) {
                this.formItems = JSON.parse(this.formItemsDefault)
                this.formItems.orgCode=this.deptid&&this.deptid.length>0?this.deptid[this.deptid.length-1]:null;
                this.$nextTick(() => {
                    this.$refs.fileUploader.reset()
                    this.loadArchivesStorageData()
                });

            } else if (type == 2) {
                this.batchNum = null;
                this.importData = {}
                this.importFormItems = JSON.parse(this.importFormItemsDefault)
                this.$nextTick(() => {
                    this.$refs.excelUploader.reset()
                    this.$refs.zipUploader.reset()
                });
            }

            this.$forceUpdate()
        },
        upExcel(file) {
            this.importFormItems.excelPath = file.url
            this.importFormItems.excelName = file.name
            this.importFormItems.excelSize = file.size
        },
        upZip(file) {
            this.importFormItems.zipPath = file.url
            this.importFormItems.zipName = file.name
            this.importFormItems.zipSize = file.size
        },
        submitData(callback) {
            this.callback = callback;
            if (this.loadingImport == 2) {
                callback(1, true)
                return
            }
            if (this.type == 2) {
                this.$refs["importForm"].validate(valid => {
                    if (valid) {
                        this.loadingImport = 2
                        callback(3)
                        this.$Message.loading({ content: "正在执行导入工作", duration: 0 });
                        this.$post({
                            url: "/smartarchives/api/archivesstorage/importsave",
                            data: { ...this.importFormItems },
                            success: res => {
                                if (res.code == "200") {
                                    this.batchNum = res.data;
                                    this.loadImportData();
                                    callback(2)
                                    this.$Message.destroy()
                                    this.$Message.success({ background: true, content: "正在导入中，导入可能需要较长时间，请稍候" })
                                } else {
                                    this.loadingImport = 1
                                    callback(1)
                                    this.$Message.destroy()
                                    this.$Message.error({ background: true, content: res.desc || "导入出错" })
                                }
                            },
                            fail: () => {
                                callback(1)
                                this.loadingImport = 1
                                this.$Message.destroy()
                                this.$Message.error({ background: true, content: "导入出错" })
                            },
                        })
                    } else {
                        this.$Message.error({ background: true, content: "必填项没填写" })
                    }
                })
            } else {
                this.$refs["form"].validate(valid => {
                    if (valid) {
                        this.formItems.filesStorage = [];
                        this.filesStorage.map((item, index) => {
                            let files = {
                                fileName: item.name,
                                filePath: item.url,
                                fileSize: item.size,
                                fileFormat: item.ext,
                                id: item.id,
                                sortNum: index + 1,
                                asId: this.id,
                            };
                            this.formItems.filesStorage.push(files);
                        })

                        callback(3)
                        this.$post({
                            url: "/smartarchives/api/archivesstorage/save",
                            data: { ...this.formItems },
                            header: { "Content-Type": "application/json" },
                            success: res => {
                                if (res.code == "200") {
                                    callback(1, true)
                                    this.$Message.success({ background: true, content: "保存成功" })
                                } else {
                                    callback(1)
                                    this.$Message.error({ background: true, content: res.desc || "保存出错" })
                                }
                            },
                            fail: () => {
                                callback(1)
                                this.$Message.error({ background: true, content: "保存出错" })
                            },

                        })
                    } else {
                        this.$Message.error({ background: true, content: "部分必填项没填写" })
                    }
                })
            }
        },
        upFile(file) {
            console.log(file);
            this.filesStorage = file;
            this.formItems.filesStorage = file;
        },

        checkOrgRight(e) {
            this.deptid = this.orgCascader.checkOrgRight(e)
            this.formItems.orgCode = this.deptid[this.deptid.length - 1]
        },
    },
}
</script>
    
<style scoped lang='less'>
.file-upload-list {
    width: 100%;
    text-align: left;
    padding-bottom: 5px;
    overflow: hidden;
    position: relative;
    margin-right: 4px;
    color: #2b85e4;
}
</style>