<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="井盖编号" prop="coverOutNum">
        <Input type="text" v-model="formItems.coverOutNum" placeholder="输入井盖编号"></Input>
      </FormItem>
      <FormItem label="井盖名称" prop="coverName">
        <Input type="text" v-model="formItems.coverName" placeholder="50字以内"></Input>
      </FormItem>
      <FormItem label="具体地址" prop="address">
        <Input type="textarea" v-model="formItems.address"></Input>
      </FormItem>
      <FormItem label="经纬度">
        <Input v-model.trim="formItems.longitude" placeholder="经度" style="width: 120px; margin-right: 20px"></Input>
        <Input v-model.trim="formItems.latitude" placeholder="纬度" style="width: 120px; margin-right: 20px"></Input>
        <Button @click="mapFn">地图选点</Button>
      </FormItem>
      <FormItem  label="服务主体">
        <Cascader v-model="deptid" @on-change="checkOrgRight" placeholder="全部服务主体" change-on-select clearable :data="depts" style="width:300px;"/>
      </FormItem>
      <FormItem label="所属管线" prop="objectNum">
        <Select v-model="formItems.objectNum" style="width: 180px">
          <Option v-for="item in projects" :value="item.objectNum" :key="item.objectName">{{ item.objectName }}</Option>
        </Select>
      </FormItem>
      <FormItem label="管线设备序号" prop="objectSort">
        <Input type="text" v-model="formItems.objectSort" placeholder="不重复的数字，0为禁用" style="width: 180px" v-if="formItems.id"></Input>
        <div style="color:#ddd" v-else>自动生成</div>
      </FormItem>
      <!-- <FormItem label="流向" prop="version">
        <Select v-model="formItems.flowth" style="width: 180px">
          <Option v-for="item in flowths" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="水状态" prop="waterState">
        <Select v-model="formItems.waterState" style="width: 180px">
          <Option v-for="item in waterStates" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      </FormItem> -->
      <!-- <FormItem label="品类" prop="coverClass">
        <Select v-model="formItems.coverClass" style="width: 180px">
          <Option v-for="item in coverClassList" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      </FormItem> -->
      <FormItem label="形状" prop="manholeCoverType">
        <Select v-model="formItems.manholeCoverType" style="width: 180px">
          <Option v-for="item in manholeCoverTypeList" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="井深" prop="depth">
        <Input type="text" v-model="formItems.depth" placeholder="井深 米" style="width: 180px"></Input> 米
      </FormItem>
      <FormItem label="是否支持定位" prop="locationTag">
        <Select v-model="formItems.locationTag" style="width: 180px">
          <Option v-for="item in locationTagList" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
        <!-- <RadioGroup v-model="formItems.locationTag">
            <Radio :label="true" checked>
               支持
            </Radio>
            <Radio :label="false">
             不支持
            </Radio>
        </RadioGroup> -->
      </FormItem>
      <FormItem label="水状态" prop="waterState">
        <Input type="textarea" v-model="formItems.waterState" placeholder="水状态" rows="1"></Input>
      </FormItem>
      <FormItem label="备注" prop="remarks">
        <Input type="textarea" v-model="formItems.remarks" placeholder="备注" rows="4"></Input>
      </FormItem>

    </Form>
    <FNCMapCoordinatePickup ref="coordinatePickup" @close="onClose"></FNCMapCoordinatePickup>
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
import FNCMapCoordinatePickup from '@/components/FNCMapCoordinatePickup';
import {orgCascader} from "@/utils/orgCascader";
export default {
  components: {
    FNCModal, 
    FNCMapCoordinatePickup,
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: 0,
        createBy: "",
        createTime: "",
        updateTime: null,
        updateBy: null,
        objectNum: "",
        objectSort:0,
        coverNum: "",
        coverOutNum: "",
        coverName: null,
        address: null,
        longitude: null,
        latitude: null,
        remarks: null,
        depth: "",
        flowth: null,
        waterState: null,
        material: null,
        lengthSize: null,
        widthSize: null,
        manholeCoverType: null,
      }),
      formItems: {},
      ruleItems: {
        coverOutNum: [
          { required: true, message: '井盖编号不能为空', trigger: 'blur' }
        ],
        coverName: [
          { required: true, message: '井盖名称不能为空', trigger: 'blur' }
        ],
        // longitude: [
        //   { required: true, message: '定位不能为空', trigger: 'blur' }
        // ],
        objectNum: [
          { required: true, message: '必须绑定管线', trigger: 'blur' }
        ],
        depth: [
          { required: true, message: '必须填写井深', trigger: 'blur' }
        ],
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,
      projects: [],
      // flowths: [],
      // waterStates: [],
      coverClassList: [],
      manholeCoverTypeList: [],
      locationTagList: [{"name":"支持","value":"1"}],
      depts:[],
      deptid:[],
      // orgCode:"",
    };
  },
  created() {
    this.orgCascader = orgCascader("dept");
    this.deptid=this.orgCascader.convectCascaderList(this.$core.getBranchUserInfo("deptid"));
    this.orgCascader.loadData(
      (depts) => {
        this.depts = depts;
      },this.deptid[this.deptid.length-1]);
      //this.formItems.orgCode
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
   loadData(id) {
      this.deptid=[]
      if(!this.deptid||this.deptid.length==0)  this.updateDeptid();
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.$get({
          url: "/smartcovers/cover/queryCoverDetail",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
              let b = [res.data.orgCode];
              this.deptid = b;
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },
    updateDeptid() {
      this.orgCascader = orgCascader("dept");
      this.deptid=this.orgCascader.convectCascaderList(this.$core.getBranchUserInfo("deptid"));
      this.orgCascader.loadData(
      (depts) => {
        this.depts = depts;
      },this.deptid[this.deptid.length-1]);
    },
    /**
    * 获取选项数据
    */
    loadBaseData() {
      // this.$core.getStyleParamList("flowth", false, null, (res) => {
      //   this.flowths = res || []
      // })
      // this.$core.getStyleParamList("waterState", false, null, (res) => {
      //   this.waterStates = res || []
      // })

      this.$core.getStyleParamList("coverClass", false, null, (res) => {
        this.coverClassList = res || []
      })
      this.$core.getStyleParamList("manholeCoverType", false, null, (res) => {
        this.manholeCoverTypeList = res || []
      })

      this.$get({
        url: "/smartcovers/object/queryObject", data: { pageSize: 1000 }, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.projects = res.dataList
          }
        }
      })
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          this.$post({
            url: this.formItems.id ? "/smartcovers/cover/updateCover" : "/smartcovers/cover/addCover",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    
    mapFn() {
      this.$refs.coordinatePickup.open({ lng: this.formItems.longitude, lat: this.formItems.latitude }, this.formItems.address);
    },
    onClose(lnglat, address) {
      this.formItems.longitude = lnglat.lng;
      this.formItems.latitude = lnglat.lat;
      if (!this.formItems.address) {
        this.formItems.address = address;
      }
    },
    checkOrgRight(e){
      this.deptid=this.orgCascader.checkOrgRight(e);
      this.formItems.orgCode = this.deptid[this.deptid.length -1]
      // this.orgCode = this.deptid[this.deptid.length -1]
      this.projects = []
      this.$get({
        url: "/smartcovers/object/queryObject", data: {pageSize:1000,orgCode: this.deptid[this.deptid.length -1]}, success: (res) => {
          if (res.code == "200" && res.dataList) {
            // this.projects =[{ objectName: "全部管线", objectNum: "" }].concat(res.dataList);
            this.projects =res.dataList;
          }
        }
      })
    },
    
  }
};
</script>
    
<style scoped lang='less'></style>