<template>
    <div v-if="docType" class="FNCOfficePreview" oncontextmenu="return false">
        <iframe id="officePreview" :src="printUrl" @load="printLoad" style="overflow: auto; border: 0;display:none;width: 0px; height:0px; "></iframe>
        <div class="toolbar" v-if="showTool">
            <div class="toolbar-item" @click="zoom(-1)" v-if="toolbar && (docType == 'DOCX' || docType == 'PDF')">
                <Icon type="md-remove-circle" size="30" />
                缩小
            </div>
            <div class="toolbar-item" @click="zoom(0)" v-if="toolbar && (docType == 'DOCX' || docType == 'PDF')">
                <Icon type="ios-radio-button-on" size="30" />
                原始尺寸
            </div>
            <div class="toolbar-item" @click="zoom(1)" v-if="toolbar && (docType == 'DOCX' || docType == 'PDF')">
                <Icon type="md-add-circle" size="30" />
                放大
            </div>
            <div class="toolbar-item" style="float: right" @click="downloadFile" v-if="download">
                <Icon type="md-archive" size="30" />
                下载
            </div>
            <div class="toolbar-item" style="float: right" @click="printFile" v-if="print && (docType == 'IMG' || docType == 'XLSX' || docType == 'DOCX' || docType == 'PDF')">
                <Icon type="md-archive" size="30" />
                打印
            </div>
        </div>
        <div class="preview preview-pdf" :style="'height:' + (viewHeight || 'auto') + ';'" v-if="docType == 'PDF'">
            <div class="preview-pdf-items">
                <VuePdf class="preview-pdf-item" :src="href" :page="p" @error="error" @loaded="rendered" v-for="p in numPages" :key="'numPages' + p"></VuePdf>
            </div>
        </div>
        <VueOfficeDocx
            class="preview"
            :style="'height:' + (viewHeight || 'auto') + ';'"
            :src="href"
            :request-options="requestOptions"
            @error="error"
            @rendered="rendered"
            v-else-if="docType == 'DOCX'"
        ></VueOfficeDocx>
        <VueOfficeExcel
            class="preview"
            :style="'height:' + (viewHeight || 'auto') + ';'"
            :src="href"
            :request-options="requestOptions"
            @error="error"
            @rendered="rendered"
            v-else-if="docType == 'XLSX'"
        ></VueOfficeExcel>
        <div class="preview" :style="'height:' + (viewHeight || 'auto') + ';'" v-else-if="docType == 'IMG'">
            <img class="img" @load="rendered" :src="href" @error="error" />
        </div>
        <div class="preview noview" :style="'height:' + (viewHeight || 'auto') + ';'" v-else>该文档格式不允许在线查看。{{ rendered() }}</div>
    </div>
</template>

<script>
/**
 * 文档浏览器：pdf,excel,word,image
 * import FNCOfficePreview from "@/components/FNCOfficePreview"
 * <FNCOfficePreview :src="filePath" height="728px" :toolbar="true" :download="true" :requestOptions="{}" @error="()=>{}" @rendered="()=>{}"></FNCOfficePreview>
 */
import VuePdf from "vue-pdf"
import VueOfficeDocx from "@vue-office/docx"
import VueOfficeExcel from "@vue-office/excel"
import "@vue-office/excel/lib/index.css"
export default {
    components: {
        VuePdf,
        VueOfficeDocx,
        VueOfficeExcel,
    },
    props: {
        src: String,
        toolbar: Boolean, //工具条
        download: Boolean, //是否可下载
        print: Boolean, //是否可打印
        height: String, //指定高度
        requestOptions: {}, //请求参数
        isAuthorize: Boolean, //系统授权读取文件方式
    },
    data() {
        return {
            docType: "",
            href: "",
            showTool: false,
            viewHeight: "",
            numPages: 0,
            printUrl: "",
        }
    },
    created() {},
    mounted() {},
    watch: {
        src: {
            handler(n) {
                this.href = ""
                if (this.src) {
                    this.docType = ""
                    this.$Message.loading({ content: "正在加载中...", duration: 0 })
                    this.$nextTick(() => {
                        var docType = this.src.substring(this.src.lastIndexOf(".") + 1).toUpperCase()
                        if (docType == "PDF") {
                            this.docType = "PDF"
                        } else if (docType == "DOC" || docType == "DOCX") {
                            this.docType = "DOCX"
                        } else if (docType == "XLSX" || docType == "XLS") {
                            this.docType = "XLSX"
                        } else if (docType == "PNG" || docType == "JPG" || docType == "BMP" || docType == "GIF" || docType == "TIF") {
                            this.docType = "IMG"
                        } else {
                            this.docType = "unknown"
                        }
                        if (this.download || (this.toolbar && (this.docType == "DOCX" || this.docType == "PDF"))) {
                            this.showTool = true
                            this.viewHeight = "calc(" + this.height + " - 60px)"
                        } else {
                            this.showTool = false
                            this.viewHeight = this.height
                        }
                        this.loadFile(this.src)
                    })
                }
            },
            immediate: true,
        },
    },
    methods: {
        rendered() {
            this.$Message.destroy()
            this.$emit("rendered")
        },
        error(e) {
            this.$Message.destroy()
            this.$emit("error")
        },

        loadFile(href) {
            if (this.isAuthorize) {
                this.loadFileAuthCode(href, res => {
                    this.href = this.pdfSrc(res)
                })
            } else {
                this.href = href
            }
        },
        pdfSrc(fileUrl) {
            var src = fileUrl
            if (this.docType == "PDF") {
                //处理pdfUrl返回
                src = VuePdf.createLoadingTask({
                    url: fileUrl,
                    cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/",
                    cMapPacked: true,
                })
                src.promise.then(p => {
                    this.numPages = p.numPages
                })
            }
            return src
        },
        loadFileAuthCode(href, callback) {
            this.$get({
                url: "/smartarchives/api/filemanager/authcode",
                data: { filePath: href },
                success: res => {
                    if ((res.code = "200" && res.data)) {
                        var src = this.$getProxy("/smartarchives") + "/api/filemanager/download/" + res.data
                        if (callback) callback(src)
                    } else {
                        this.$Message.destroy()
                        this.$Message.error({ content: "系统找不到相应的文件。", background: true })
                    }
                },
                fail: () => {
                    this.$Message.destroy()
                    this.$Message.error({ content: "验证文件出错。", background: true })
                },
            })
        },
        zoom(i) {
            if (this.docType == "DOCX") {
                var zoom = 1
                zoom = $(".vue-office-docx-main").css("zoom") || 1
                if (i == 0) {
                    zoom = 1
                } else if (i == 1) {
                    zoom = parseFloat(zoom) + 0.2
                } else if (i == -1) {
                    zoom = parseFloat(zoom) - 0.2
                    if (zoom <= 0) zoom = 0.1
                }
                $(".vue-office-docx-main").css("zoom", zoom)
            } else if (this.docType == "PDF") {
                var width = $(".preview-pdf-items").width()
                if (i == 0) {
                    width = "100%"
                } else if (i == 1) {
                    width = parseFloat(width) + 100
                } else if (i == -1) {
                    width = parseFloat(width) - 100
                    if (width < 140) width = 140
                }
                if (width != "100%") {
                    width = width + "px"
                }
                $(".preview-pdf-items").css("width", width)
                var pwidth = $(".preview-pdf").width() || 0
                var nwidth = $(".preview-pdf-items").width() || 0
                if (pwidth - nwidth > 20) {
                    $(".preview-pdf-items").css("marginLeft", (pwidth - nwidth) / 2 + "px")
                } else {
                    $(".preview-pdf-items").css("marginLeft", "0")
                }
            }
        },
        downloadFile() {
            if (!this.src) {
                this.$Message.error({ content: "文件不存在", background: true })
                return
            }
            var fileName = this.src.substring(this.src.lastIndexOf("/") + 1)
            var a = document.createElement("a")
            a.download = fileName
            a.target = "downfile"
            if (this.isAuthorize) {
                this.$Message.loading({ content: "正在准备文件...", duration: 0 })
                this.loadFileAuthCode(this.src, res => {
                    a.href = res
                    a.click()
                    this.$Message.destroy()
                })
            } else {
                a.href = this.src
                a.click()
            }
        },
        printFile() {
            this.printUrl = ""
            this.$nextTick(() => {
                if (!this.src) {
                    this.$Message.error({ content: "文件不存在", background: true })
                    return
                }
                if (this.docType && this.docType != "IMG" && this.docType != "PDF") {
                  //word、excel打印，需要在后台将两者转为html
              //  if (!this.docType && this.docType != "IMG" && this.docType != "DOCX" && this.docType != "XLSX" && this.docType != "PDF") {
                    this.$Message.error({ content: "文件不支持打印", background: true })
                    return
                }
                this.$Message.loading({ content: "正在准备文件...", duration: 0 })
                if (this.isAuthorize) {
                  var types={
                    "PDF": "application/pdf",
                    "DOCX": "text/html",
                    "XLSX": "text/html",
                    "IMG": "image/jpeg",
                  }
                    this.loadFileAuthCode(this.src, res => {
                      this.$get({url:res,responseType:"blob",success:(data)=>{
                        var blob=new Blob([data],{type:types[this.docType]})
                        this.printUrl = window.URL.createObjectURL(blob);
                      }})
                        
                    })
                } else {
                    this.printUrl = this.src
                }
            })
        },
        printLoad() {
            var win = this.$("#officePreview")
            if (win.attr("src")) {
                if (win && win.length > 0) {
                    win = win[0].contentWindow
                    win.print()
                    this.$Message.destroy()
                    window.URL.revokeObjectURL(this.printUrl)
                }
            }
        },
    },
}
</script>

<style lang="less" scoped>
.FNCOfficePreview {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .toolbar {
        width: 100%;
        height: 60px;
        background: linear-gradient(to right, #2db7f5, #19be6b);
        color: #fff;
        box-shadow: 0px 1px 10px #88888888;
        position: relative;
        z-index: 1;

        .toolbar-item {
            float: left;
            margin: 5px 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            cursor: pointer;
        }
    }

    .preview {
        width: 100%;
        overflow: auto;
        background: #ffffff;
    }

    .preview-pdf {
        background: #aaa;

        .preview-pdf-items {
            width: 100%;

            .preview-pdf-item {
                margin: 20px;
                overflow: hidden;
            }
        }
    }

    .img {
        max-width: 100%;
        text-align: center;
    }

    .noview {
        font-size: 18px;
        font-weight: bold;
        padding-top: 50px;
        text-align: center;
    }

    /deep/.docx-wrapper {
        width: max-content;
        min-width: 100%;
    }

    /deep/.vue-office-pdf-wrapper {
        width: max-content;
        min-width: 100%;
        padding: 15px 30px !important;
        display: flex;
        flex-direction: column;
        align-items: center;

        canvas {
            margin: 15px 0;
        }
    }
}
@media print {
  @page{
    margin:0;
  }

  body{
    margin:1cm;
  }
}
</style>
